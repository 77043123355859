import { faArrowRightArrowLeft, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { useSession } from "app/hooks";
import { hasPermissionToAdd, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { SensorModalTranslations } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type SensorNotLinkedModalFooterProps = {
    onRedirectCreateAsset: () => void;
    onReplaceAsset: () => void;
};

export const SensorNotLinkedModalFooter: FC<SensorNotLinkedModalFooterProps> = ({
    onRedirectCreateAsset,
    onReplaceAsset,
}) => {
    const session = useSession();
    const { t } = useTranslation();

    const showCreateAsset = hasPermissionToView(session, SecScreen.ASSETS) && hasPermissionToAdd(SecScreen.ASSETS);

    return (
        <div className="sensorNotLinkedModalFooter">
            {showCreateAsset && (
                <AddNewButton onClick={onRedirectCreateAsset} icon={faPlus} variant="purple">
                    {t(SensorModalTranslations.SENSOR_NOT_LINKED_CREATE_ASSET)}
                </AddNewButton>
            )}

            <AddNewButton onClick={onReplaceAsset} icon={faArrowRightArrowLeft} variant="purple">
                {t(SensorModalTranslations.SENSOR_NOT_LINKED_REPLACE)}
            </AddNewButton>
        </div>
    );
};
