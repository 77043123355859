import { faCircleXmark, faPen, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { SelectActionsLastItemsModel, SelectActionsModel } from "app/components_v2/SelectWithActions/types";
import { Input } from "app/components_v2/__inputs";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { useSuccessHandler } from "app/hooks/SuccessHandler/useSuccessHandler";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { AssetDynamicFieldModel } from "app/models/05-QUA/AssetModels";
import { AssetFieldInsertModel } from "app/models/05-QUA/AssetModels/AssetFieldModel";
import { AssetFieldRangeModel } from "app/models/05-QUA/AssetModels/AssetFieldRangeModel";
import { MeasurementUnitModel } from "app/models/05-QUA/MeasurementUnitModels/MeasurementUnitModel";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import MeasurementUnitService from "app/services/05-QUA/MeasurementUnitService";
import { SecScreen } from "app/shared/Constants";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MeasurementUnitModal } from "../MeasurementUnitModal/MeasurementUnitModal";
import { ErrorDynamicAssetModel } from "../models/AssetFormModel";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";

type AssetDynamicFieldProps = {
    data: AssetFieldInsertModel;
    disabled?: boolean;
    customerError: ErrorDynamicAssetModel;
    onChange: (data: AssetFieldInsertModel) => void;
    onDelete: (id: string) => void;
    measurementTypeOptions: MeasurementUnitModel[];
    onChangeMeasurementTypeOptions: (value: MeasurementUnitModel[]) => void;
};

export const AssetDynamicField: FC<AssetDynamicFieldProps> = ({
    onChange,
    data,
    disabled,
    onDelete,
    customerError,
    measurementTypeOptions,
    onChangeMeasurementTypeOptions,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { handleSuccessManager } = useSuccessHandler();
    const { getErrorMessage } = useFetchErrors();

    const { assetDynamicField, label, assetFieldRange, id } = data;
    const { errorLabel, errorMax, errorMin, errorUnit } = customerError;

    const [measurementUnit, setMeasurementUnit] = useState<MeasurementUnitModel>({
        id: assetFieldRange?.fK_MeasurementUnit || 0,
        unit: assetFieldRange?.unit || assetFieldRange?.unit || "",
    });
    const [showSaveMeasurementUnitModal, setShowSaveMeasurementUnitModal] = useState(false);
    const [showDeleteMeasurementUnitModal, setShowDeleteMeasurementUnitModal] = useState(false);

    const inputLabel: Record<AssetDynamicFieldModel, string> = {
        NUMBER: t(TranslationKeys.ASSET_NUMBER_LABEL),
        TEXT: t(TranslationKeys.ASSET_TEXT_LABEL),
    };

    const handleInputChange = (values: Partial<AssetFieldInsertModel>) => onChange({ ...data, ...values });

    const handleRangeChange = (values: Partial<AssetFieldRangeModel>) => {
        if (!assetFieldRange) return;
        onChange({ ...data, assetFieldRange: { ...assetFieldRange, ...values } });
    };

    const handleSaveMeasurementUnit = (value: MeasurementUnitModel) => {
        onChangeMeasurementTypeOptions([...measurementTypeOptions, value]);
        setShowSaveMeasurementUnitModal(false);
    };

    const handleEditMeasurementUnit = async (value: MeasurementUnitModel) => {
        const updatedMeasurementTypeOptions = measurementTypeOptions.map(({ id, unit }) => ({
            id: id === value.id ? value.id : id,
            unit: id === value.id ? value.unit : unit,
        }));
        onChangeMeasurementTypeOptions(updatedMeasurementTypeOptions);
        setShowSaveMeasurementUnitModal(false);
    };

    const handleDeleteMeasurementUnit = async () => {
        const { status, getParsedError } = await MeasurementUnitService.Delete(measurementUnit.id);
        const error = getErrorMessage(getParsedError());
        if (!status()) {
            handleToast({
                title: t(TranslationModals.CANNOT_DELETE_MEASUREMENT_UNIT_TOAST_TITLE),
                subtitle: error,
                variant: "danger",
                type: "alert",
            });
            return;
        }

        handleSuccessManager(t(TranslationModals.MEASUREMENT_UNIT_DELETED_TOAST));

        const filteredMeasurementTypeOptions = measurementTypeOptions.filter(({ id }) => id !== measurementUnit.id);
        onChangeMeasurementTypeOptions(filteredMeasurementTypeOptions);
        setShowDeleteMeasurementUnitModal(false);
    };

    const measurementUnitActions: SelectActionsModel[] = [
        {
            icon: faPen,
            onClick: ({ label, value }: OptionsSearch) => {
                setMeasurementUnit({ id: Number(value), unit: label });
                setShowSaveMeasurementUnitModal(true);
            },
            hidden: !hasPermissionToEdit(SecScreen.ASSETS),
        },
        {
            icon: faTrash,
            onClick: ({ label, value }: OptionsSearch) => {
                setMeasurementUnit({ id: Number(value), unit: label });
                setShowDeleteMeasurementUnitModal(true);
            },
            hidden: !hasPermissionToEdit(SecScreen.ASSETS),
        },
    ];

    const measurementUnitLastItems: SelectActionsLastItemsModel[] = [
        {
            title: t(TranslationKeys.ADD_MEASUREMENT_UNIT),
            icon: faPlus,
            onClick: () => {
                setMeasurementUnit({ id: 0, unit: "" });
                setShowSaveMeasurementUnitModal(true);
            },
            hidden: !hasPermissionToEdit(SecScreen.ASSETS),
        },
    ];

    return (
        <WhiteBox>
            <Collapsable title={inputLabel[assetDynamicField]} background="transparent" border="none">
                <div className="assetDynamicField">
                    {!disabled && (
                        <div className="assetDynamicField__delete-icon">
                            <FontAwesomeIcon
                                className="deleteInputIcon"
                                onClick={() => onDelete(id)}
                                icon={faCircleXmark}
                            />
                        </div>
                    )}
                    <div className="assetDynamicField__title-input">
                        <Input
                            value={label}
                            onChange={(value) => handleInputChange({ label: value })}
                            placeholder={inputLabel[assetDynamicField]}
                            errorMessage={errorLabel}
                            disabled={disabled}
                        />
                    </div>
                    {showSaveMeasurementUnitModal && (
                        <MeasurementUnitModal
                            unit={measurementUnit}
                            variant="purple"
                            onSave={handleSaveMeasurementUnit}
                            onEdit={handleEditMeasurementUnit}
                            onCancel={() => setShowSaveMeasurementUnitModal(false)}
                        />
                    )}
                    {showDeleteMeasurementUnitModal && (
                        <ConfirmModal
                            title={t(TranslationModals.MEASUREMENT_UNIT_DELETE_TITLE)}
                            description={t(TranslationModals.MEASUREMENT_UNIT_DELETE_DESCRIPTION).replace(
                                "{0}",
                                measurementUnit.unit
                            )}
                            onConfirm={handleDeleteMeasurementUnit}
                            onConfirmText={t(TranslationCommon.DELETE)}
                            onClose={() => setShowDeleteMeasurementUnitModal(false)}
                            onCloseText={t(TranslationCommon.CANCEL)}
                            type="delete"
                        />
                    )}
                    {assetFieldRange && assetDynamicField === "NUMBER" && (
                        <div className="assetForm__container__section">
                            <Input
                                value={assetFieldRange.min !== 0 ? String(assetFieldRange.min) : ""}
                                onChange={(value) =>
                                    handleRangeChange({
                                        min: !value.length ? undefined : Number(value),
                                    })
                                }
                                label={t(TranslationKeys.ASSET_MIN)}
                                placeholder={t(TranslationKeys.INPUT_TEXT_PLACEHOLDER_MIN_ASSET)}
                                type="number"
                                errorMessage={errorMin}
                                disabled={disabled}
                            />
                            <Input
                                value={assetFieldRange.max !== 0 ? String(assetFieldRange.max) : ""}
                                onChange={(value) =>
                                    handleRangeChange({
                                        max: !value.length ? undefined : Number(value),
                                    })
                                }
                                label={t(TranslationKeys.ASSET_MAX)}
                                placeholder={t(TranslationKeys.INPUT_TEXT_PLACEHOLDER_MAX_ASSET)}
                                type="number"
                                errorMessage={errorMax}
                                disabled={disabled}
                            />
                            <SelectWithActions
                                actions={measurementUnitActions}
                                lastItems={measurementUnitLastItems}
                                label={t(TranslationKeys.ASSET_UNIT_OF_MEASURE)}
                                placeholder={t(TranslationKeys.ASSET_UNIT_OF_MEASURE)}
                                selectedValue={String(assetFieldRange?.fK_MeasurementUnit)}
                                options={measurementTypeOptions.map(({ unit, id }) => ({
                                    label: unit,
                                    value: String(id),
                                }))}
                                onChange={({ label, value }) =>
                                    handleRangeChange({ unit: label, fK_MeasurementUnit: Number(value) })
                                }
                                disabled={disabled}
                                errorMessage={errorUnit}
                                menuPosition="fixed"
                            />
                        </div>
                    )}
                </div>
            </Collapsable>
        </WhiteBox>
    );
};
