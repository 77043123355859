import { FC } from "react";
import { ActionButtonsModel } from "../TableTabHeader/TableTabHeader";
import { Icon } from "app/components_v2/Icon/Icon";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";

type TableTabHeaderActionsProps = {
    actionButtons?: ActionButtonsModel[];
    onClickCollapsable?: () => void;
    oneRow: boolean;
    isOpen?: boolean;
};
export const TableTabHeaderActions: FC<TableTabHeaderActionsProps> = ({
    actionButtons,
    onClickCollapsable,
    oneRow,
    isOpen,
}) => {
    return (
        <>
            {(onClickCollapsable || actionButtons) && (
                <div className={`tableTab__header__actions${oneRow ? "--row" : ""}`}>
                    {actionButtons && (
                        <div className="tableTab__header__actions__icons">
                            {actionButtons.map(
                                ({ icon, onClick, hidden, inputComponent, disabled, showMarkableIcon, title }, i) =>
                                    !hidden && (
                                        <div
                                            className={`tableTab__header__actions__icons__container ${
                                                !!showMarkableIcon ? "relative" : ""
                                            }`}
                                            key={i}
                                        >
                                            {inputComponent && inputComponent.show ? (
                                                inputComponent.component
                                            ) : (
                                                <>
                                                    {showMarkableIcon && (
                                                        <div className="markableIcon__notification tableTab__header__actions__markableIcon"></div>
                                                    )}
                                                    <Icon
                                                        disabled={disabled}
                                                        icon={icon}
                                                        onClick={onClick}
                                                        title={title}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    )
                            )}
                        </div>
                    )}
                    {onClickCollapsable && (
                        <Icon
                            icon={faChevronUp}
                            onClick={onClickCollapsable}
                            iconClassName={`tableTab__header__actions__icons__icon__svg ${
                                isOpen ? "isOpen" : "isClosed"
                            }`}
                        />
                    )}
                </div>
            )}
        </>
    );
};
