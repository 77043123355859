import { FC, FormEvent, useEffect, useState } from "react";
import { Input } from "app/components_v2/__inputs";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { DepartmentService, GroupCompanyService, SubdepartmentService } from "app/services";
import {
    NewCompanyGroupErrorFormValuesModel,
    NewCompanyGroupFormValuesModel,
    NewCompanyGroupValuesModel,
} from "./types";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import { GroupCompanyModel } from "app/models/01-SEG/GroupCompany/GroupCompanyModel";
import { SubdepartmentModel } from "app/models/01-SEG/Subdepartment/SubdepartmentModel";

type NewGroupCompanyFormProps = {
    onSubmit: (value: boolean) => void;
    values: NewCompanyGroupValuesModel;
    usersCount?: number;
    companyId?: number;
    isDepartment?: boolean;
    newSubdepartment?: boolean;
    subdepartmentToEdit?: SubdepartmentModel | null;
    setIsLoading: (value: boolean) => void;
};

const INITIAL_ERROR_FORM_VALUES: NewCompanyGroupErrorFormValuesModel = {
    errorName: "",
};

export const NewGroupCompanyForm: FC<NewGroupCompanyFormProps> = ({
    values,
    onSubmit,
    companyId,
    isDepartment,
    usersCount,
    newSubdepartment,
    subdepartmentToEdit,
    setIsLoading,
}) => {
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState<NewCompanyGroupFormValuesModel>({
        name: values.name,
    });
    const [errorFormValues, setErrorFormValues] =
        useState<NewCompanyGroupErrorFormValuesModel>(INITIAL_ERROR_FORM_VALUES);

    const { name } = formValues;
    const { errorName } = errorFormValues;

    const handleInputChange = (values: Partial<NewCompanyGroupFormValuesModel>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (values: Partial<NewCompanyGroupErrorFormValuesModel>) => {
        setErrorFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!name.length) {
            handleErrorChange({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return;
        }

        await handleService();
    };

    const handleService = async () => {
        setIsLoading(true);
        const { id } = values;
        const body: GroupCompanyModel = {
            ...values,
            name,
        };

        if (id > 0) {
            await handleEdit(body);
            setIsLoading(false);
            return;
        }

        await handleSave(body);
        setIsLoading(false);
    };

    const handleEdit = async (body: GroupCompanyModel) => {
        if (isDepartment) {
            const toSend: DepartmentModel = {
                id: body.id,
                name: body.name,
                companyId: companyId || 0,
                isActive: body.isActive,
                isDeletable: body.isDeletable,
                usersCount: usersCount || 0,
            };
            newSubdepartment ? handleEditNewSubdepartment(body) : isNotNewSubdepartment(toSend, body.id);
        } else {
            const { status } = await GroupCompanyService.Edit(body, body.id);
            onSubmit(status());
        }
    };

    const isNotNewSubdepartment = async (toSend: any, id: number) => {
        const { status } = await DepartmentService.Edit(toSend, id);
        onSubmit(status());
    };
    const handleEditNewSubdepartment = async (body: any) => {
        const subdepartment = {
            id: subdepartmentToEdit ? subdepartmentToEdit.id : 0,
            name: body.name,
            departmentId: subdepartmentToEdit ? subdepartmentToEdit?.departmentId : body.id,
            isActive: true,
            isDeletable: true,
            usersCount: subdepartmentToEdit ? subdepartmentToEdit?.usersCount : 0,
        };

        if (subdepartmentToEdit != null) {
            const { status } = await SubdepartmentService.Edit(subdepartment, Number(subdepartmentToEdit?.id));
            onSubmit(status());
            return;
        }

        const { status } = await SubdepartmentService.Save(subdepartment);
        onSubmit(status());
    };

    const handleSave = async (body: GroupCompanyModel) => {
        const { status } = await GroupCompanyService.Save(body);
        onSubmit(status());
    };

    useEffect(() => {
        newSubdepartment && !subdepartmentToEdit && handleInputChange({ name: "" });
    }, []);

    return (
        <form onSubmit={handleSubmit} className="newGroupCompanyForm" id="groupCompanyModal">
            <div className="newGroupCompanyForm__container">
                <Input
                    label={
                        newSubdepartment
                            ? t(TranslationKeys.SUBDEPARTMENT_NAME)
                            : isDepartment
                            ? t(TranslationKeys.LABEL_DEPARTMENT_NAME)
                            : t(TranslationKeys.GROUP_COMPANY_FORM_NAME_LABEL)
                    }
                    value={name}
                    errorMessage={errorName}
                    placeholder={
                        newSubdepartment
                            ? t(TranslationKeys.SUBDEPARTMENT_NAME)
                            : t(TranslationKeys.GROUP_COMPANY_FORM_NAME_PLACEHOLDER)
                    }
                    onChange={(newValue) => handleInputChange({ name: newValue })}
                />
            </div>
        </form>
    );
};
