import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { Input } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessHandler } from "app/hooks/SuccessHandler/useSuccessHandler";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { BaseTaskTypeModel } from "app/models/02-TAR/BaseTask/BaseTaskTypeModel";
import { ActionType, OptionsSearch } from "app/models/FormComponentsModel";
import BaseTaskTypeService from "app/services/02-TAR/BaseTaskTypeService";
import {
    BaseTaskTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

type BaseTaskTypeModalProps = {
    baseTaskType?: OptionsSearch;
    onSaveBaseTaskType: (baseTaskType: BaseTaskTypeModel) => void;
    onEditBaseTaskType: (baseTaskType: BaseTaskTypeModel) => void;
    onCancel: () => void;
    variant: TabHeaderVariants;
};

export const BaseTaskTypeModal: FC<BaseTaskTypeModalProps> = ({
    baseTaskType,
    onSaveBaseTaskType,
    onEditBaseTaskType,
    onCancel,
    variant,
}) => {
    const { t } = useTranslation();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessHandler();
    const { getErrorMessage } = useFetchErrors();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [baseTaskTypeName, setBaseTaskTypeName] = useState<string>(baseTaskType?.label || "");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const action: ActionType = !baseTaskType?.label.length ? "create" : "edit";

    const getTitle =
        action === "create" ? t(TranslationKeys.BASE_TASK_SAVE_TITLE) : t(TranslationKeys.BASE_TASK_EDIT_TITLE);

    const getConfirmButton = action === "create" ? t(TranslationCommon.CREATE) : t(TranslationCommon.EDIT);

    const handleSaveBaseTaskType = async () => {
        const { data, status, getParsedError } = await BaseTaskTypeService.Save({
            id: 0,
            type: baseTaskTypeName,
        });

        const error = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), error)) {
            setIsLoading(false);
            return;
        }
        handleSuccessManager(t(TranslationModals.BASE_TASK_TYPE_SAVED_SUCCESSFULLY));
        onSaveBaseTaskType(data);
    };

    const handleEditBaseTaskType = async () => {
        if (baseTaskType === undefined) return;
        const { data, status, getParsedError } = await BaseTaskTypeService.Edit(Number(baseTaskType.value), {
            id: Number(baseTaskType.value),
            type: baseTaskTypeName,
        });

        const error = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), error)) {
            setIsLoading(false);
            return;
        }
        handleSuccessManager(t(TranslationModals.BASE_TASK_TYPE_EDITED_SUCCESSFULLY));
        onEditBaseTaskType(data);
    };

    const validate = () => {
        setErrorMessage("");
        if (!baseTaskTypeName.length) {
            setErrorMessage(t(TranslationErrors.BASE_TASK_TYPE_NAME_ERROR));
            return false;
        }

        return true;
    };

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!validate()) return;

        setIsLoading(true);
        if (action === "create") await handleSaveBaseTaskType();
        if (action === "edit") await handleEditBaseTaskType();
        setIsLoading(false);
    };

    return (
        <GenericModal
            size="sm"
            header={{ title: getTitle, onClose: onCancel, variant }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: getConfirmButton,
                        form: "baseTaskType",
                        type: "submit",
                        variant,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "secondary",
                        onClick: onCancel,
                        variant,
                    }}
                    loading={isLoading}
                />
            }
        >
            <form onSubmit={onSubmit} id="baseTaskType">
                <Input
                    onChange={setBaseTaskTypeName}
                    label={t(BaseTaskTranslations.BASE_TASK_TYPE_LABEL)}
                    placeholder={t(BaseTaskTranslations.BASE_TASK_TYPE_LABEL)}
                    value={baseTaskTypeName}
                    errorMessage={errorMessage}
                    focus
                />
            </form>
        </GenericModal>
    );
};
