import { BaseTaskFilter } from "app/components_v2/__filters/BaseTaskFilter/BaseTaskFilter";
import { BaseTaskGridSFModel } from "../state/context/BaseTaskContext";
import { BaseTaskListModel } from "app/models/02-TAR/BaseTask/BaseTaskListModel";
import {
    BaseTaskTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType } from "app/components_v2/Table/types";
import { faCirclePlus, faPen, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { FC, useState } from "react";
import { formatBigNumber } from "app/helpers/formatBigNumbers";
import { hasPermission, hasPermissionToAdd, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { INITIAL_BASE_TASK_SF_VALUES, INITIAL_BASE_TASK_SORT_DIRECTION } from "../constants/baseTaskConstants";
import { Input } from "app/components_v2/__inputs";
import { MobileBaseTaskRow } from "../MobileBaseTaskRow/MobileBaseTaskRow";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { PlanAPPCCCounters } from "app/pages/05-QUA/PlanAPPCC/components/PlanAPPCCCounters/PlanAPPCCCounters";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useBaseTask } from "../hooks/useBaseTask";
import { useNavigate } from "react-router-dom";
import { useSession, useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import {
    faCamera,
    faCameraSlash,
    faClipboardList,
    faClipboardListCheck,
    faClose,
    faMagnifyingGlass,
    faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import { isEqual } from "lodash";

export const BaseTaskGrid: FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const session = useSession();
    useTitle(t(TranslationTitles.BASE_TASK_PAGE_TITLE));

    const {
        baseTask,
        showSearchInput,
        setShowSearchInput,
        total,
        fetchAll,
        handleExport,
        handleSortChange,
        isLoading,
        onChangePageIndex,
        onQuerySearch,
        onSFChange,
        pageIndex,
        sortDirection,
        sortField,
        secondaryFilterValues,
        handleCleanFetch,
        isAPPCC,
    } = useBaseTask();

    const { query } = secondaryFilterValues;

    const [isSecondaryFilterOpen, setIsSecondaryFilterOpen] = useState<boolean>(false);

    const columns: ColumnsType<BaseTaskListModel>[] = [
        {
            label: t(BaseTaskTranslations.BASE_TASK_NAME),
            sortedType: "default",
            dataIndex: "name",
            render: ({ name }) => <CellTitle title={name} bolder />,
        },
        {
            label: t(BaseTaskTranslations.BASE_TASK_TYPE_LABEL),
            sortedType: "default",
            dataIndex: "BaseTaskType.Type",
            className: "baseTaskGrid__minWidth",
            render: ({ type }) => <CellTitle title={type} />,
        },
        {
            label: t(BaseTaskTranslations.BASE_TASK_ASSOCIATED_TASKS_COUNT_LABEL),
            dataIndex: "AssociatedTasksCount",
            className: "baseTaskGrid__minWidth",
            render: ({ associatedTasksCount, id }) => (
                <div className="baseTaskGrid__center">
                    <PlanAPPCCCounters
                        readonly={!hasPermissionToView(session, SecScreen.TASK)}
                        title={formatBigNumber(associatedTasksCount, 2)}
                        to={`/${isAPPCC ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE}?baseTaskId=${id}`}
                    />
                </div>
            ),
        },
        {
            label: "",
            dataIndex: "icons",
            className: "baseTaskGrid__icons",
            render: ({ hasBaseTaskCheckList, fotoExample, isPhotoRequired, hasBaseTaskFields }) => {
                return (
                    <CellIcons
                        variant="ligthGray"
                        icons={[
                            {
                                icon: faClipboardListCheck,
                                hidden: !hasBaseTaskCheckList,
                            },
                            {
                                icon: fotoExample?.length ? faCamera : faCameraSlash,
                                hidden: !isPhotoRequired,
                            },
                            {
                                icon: faClipboardList,
                                hidden: !hasBaseTaskFields,
                            },
                        ]}
                    />
                );
            },
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "edit",
            alignCenter: true,
            className: "baseTaskGrid__actions",
            render: ({ id }) => (
                <div>
                    <CellIcons
                        icons={[
                            {
                                icon: faPen,
                                onClick: () => nav(`edit/${id}`),
                                title: t(TranslationCommon.EDIT),
                            },
                        ]}
                    />
                </div>
            ),
            hidden: !hasPermission(SecScreen.BASE_TASK_MANAGEMENT),
        },
    ];

    const handleSaveSecondaryFilter = (values: BaseTaskGridSFModel) => {
        onSFChange(values);
        setIsSecondaryFilterOpen(false);
        fetchAll({ pi: 0, baseTaskSf: values });
        onChangePageIndex(1);
    };

    return (
        <PageContainer paddingTop>
            <BaseTaskFilter
                filterData={secondaryFilterValues}
                initialFilterData={INITIAL_BASE_TASK_SF_VALUES}
                isOpen={isSecondaryFilterOpen}
                isSecondaryFilterOpen={isSecondaryFilterOpen}
                onCancel={() => setIsSecondaryFilterOpen(false)}
                onSave={handleSaveSecondaryFilter}
                isAPPCC={isAPPCC}
            />
            <TableCollapsable
                cols={columns.filter((col) => !col.hidden)}
                data={baseTask}
                placeholder={
                    <TableError description={t(TranslationKeys.TABLE_EMPTY_STATE_TASK)} icon={faClipboardList} />
                }
                title={
                    isAPPCC
                        ? `${t(TranslationTitles.BASE_TASK_REGISTRY_TITLE)}`
                        : `${t(TranslationTitles.BASE_TASK_TITLE)}`
                }
                subTitle={`${total} ${t(TranslationKeys.TASKS)}`}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={(pi) => {
                    onChangePageIndex(pi);
                    fetchAll({ pi: pi - 1 });
                }}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) =>
                    handleSortChange(sortFieldParam, sortedTypeParam || INITIAL_BASE_TASK_SORT_DIRECTION)
                }
                actionButtons={[
                    {
                        icon: faCirclePlus,
                        onClick: () => nav(`new`),
                        hidden: !hasPermissionToAdd(SecScreen.BASE_TASK_MANAGEMENT),
                        title: t(TranslationCommon.CREATE),
                    },
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => setShowSearchInput(true),
                        inputComponent: {
                            component: (
                                <div>
                                    <Input
                                        focus
                                        onChange={(value) => onSFChange({ query: value })}
                                        iconRight={!!query?.length ? faSearch : faClose}
                                        value={query}
                                        onClickIcon={handleCleanFetch}
                                        onPressEnter={onQuerySearch}
                                        whiteInput
                                        transparent
                                    />
                                </div>
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                    {
                        icon: faSliders,
                        onClick: () => setIsSecondaryFilterOpen(true),
                        showMarkableIcon: !isEqual(secondaryFilterValues, INITIAL_BASE_TASK_SF_VALUES),
                        title: t(TranslationCommon.FILTER),
                    },
                ]}
                onDoubleClick={(row) => nav(`edit/${row.id}`)}
                onClickExportCsv={handleExport}
                mobileBody={(row, _i, rowPosition) => (
                    <MobileBaseTaskRow
                        data={row}
                        key={row.id}
                        rowPosition={rowPosition}
                        onClick={() => nav(`edit/${row.id}`)}
                        isAPPCC={isAPPCC}
                    />
                )}
                variant={isAPPCC ? "purple" : "primary"}
            />
        </PageContainer>
    );
};
