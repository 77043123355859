import { faArrowRightArrowLeft, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { hasPermissionToDelete } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { SensorModalTranslations } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type SensorLinkedModalFooterProps = {
    onClickReplace: () => void;
    onClickDeactivate: () => void;
};

export const SensorLinkedModalFooter: FC<SensorLinkedModalFooterProps> = ({ onClickDeactivate, onClickReplace }) => {
    const { t } = useTranslation();

    return (
        <div className="sensorLinkedModalFooter">
            <AddNewButton onClick={onClickReplace} icon={faArrowRightArrowLeft} variant="purple">
                {t(SensorModalTranslations.SENSOR_LINKED_REPLACE)}
            </AddNewButton>
            {hasPermissionToDelete(SecScreen.ASSETS) && (
                <AddNewButton onClick={onClickDeactivate} icon={faXmark} variant="purple">
                    {t(SensorModalTranslations.SENSOR_LINKED_DELETE_ASSET)}
                </AddNewButton>
            )}
        </div>
    );
};
