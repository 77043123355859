import { OptionsSearch } from "app/models/FormComponentsModel";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";

export default class SenSelectorService {
    // GET all Gateways
    public static async GetGateways() {
        return FetchService.get<OptionsSearch[]>({
            url: `${ApiPaths.SEN_API}/SenSelector/GetGateways`,
        });
    }

    // GET all Sensors
    public static async GetSensors() {
        return FetchService.get<OptionsSearch[]>({
            url: `${ApiPaths.SEN_API}/SenSelector/GetSensors`,
        });
    }
}
