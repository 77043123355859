import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSession } from "app/hooks/useSession";
import { useAuditStart } from "../tabs/AuditStart/hook/useAuditStart";
import { useAuditIssues } from "../tabs/AuditIssues/hook/useAuditIssues";
import { useAuditReport } from "../tabs/AuditReport/hooks/useAuditReport";
import { SecScreen } from "app/shared/Constants";
import { hasPermissionToEdit, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { AuditTranslation, TranslationKeys } from "app/translation/translationKeys";
import { AuditStart } from "../tabs/AuditStart/AuditStart";
import { AuditIssues } from "../tabs/AuditIssues/AuditIssues";
import { AuditReport } from "../tabs/AuditReport/AuditReport";
import { TabPropsReduced } from "app/components_v2/Tabs/Tabs";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useEditAuditHeader } from "./useEditAuditHeader";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";

type UseEditAuditParams = {
    auditInstanceId: number;
    actualStatus: AuditInstanceStatus;
};

export const useEditAudit = ({ auditInstanceId, actualStatus }: UseEditAuditParams) => {
    const session = useSession();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const tab = searchParams.get("tab");
    const companyId = searchParams.get("companyId");
    const auditId = searchParams.get("auditId");

    const {
        isAuditStartSubmiting,
        isAuditStartConfirmModalOpen,
        setAuditStartIsConfirmModalOpen,
        confirmAuditStartModalSubmit,
        handleAuditStartConfirmModalOpen,
        isAuditStartLoading,
        fetchAuditStartData,
        auditStartData,
        setAuditStartData,
    } = useAuditStart({ auditInstanceId, actualStatus });

    const {
        fetchAuditIssuesData,
        isAuditIssuesLoading,
        auditIssuesList,
        auditIssuesPageIndex,
        setAuditIssuesPageIndex,
        totalAuditIssueList,
        handleAuditIssuesConfirmModalOpen,
        confirmAuditIssuesModalSubmit,
        isAuditIssuesConfirmModalOpen,
        setAuditIssuesIsConfirmModalOpen,
        isCompleting,
        isCloseAuditModalOpen,
        isClosing,
        setIsCloseAuditModalOpen,
        handleCloseAudit,
    } = useAuditIssues({ auditInstanceId, actualStatus });

    const { isEditAuditHeaderLoading, editAuditHeaderData, fetchEditAuditHeaderData } =
        useEditAuditHeader(auditInstanceId);

    const hideTab1 = !hasPermissionToView(session, SecScreen.AUDIT_INST_REV_NC);
    const hideTab2 = !hasPermissionToView(session, SecScreen.AUDIT_INST_REPORT) || actualStatus === "IN_PROGRESS";
    const hideTab0 = hideTab1 && hideTab2;
    const initialTabsToBeInSecondTab: AuditInstanceStatus[] = ["REVIEWED", "COMPLETED"];
    const initialCurrentTab = !!tab
        ? Number(tab)
        : initialTabsToBeInSecondTab.includes(actualStatus) && !hideTab1
        ? 1
        : 0;
    const [currentTab, setCurrentTab] = useState(initialCurrentTab);

    const { auditHistory, auditInstance, isLoading, fetchAuditReportData, renderAuditStars } = useAuditReport({
        auditInstanceId,
        actualStatus,
        auditId: editAuditHeaderData?.fK_Audit,
        companyId: editAuditHeaderData?.fK_Company,
    });

    const handleCurrentTabChange = (tab: number) => {
        if (tab === currentTab) return;
        setCurrentTab(tab);
        getAuditDetailData(tab);
    };

    const tabs: TabPropsReduced[] = [
        {
            onClick: () => handleCurrentTabChange(0),
            rightCount: editAuditHeaderData?.auditCounter,
            text: t(AuditTranslation.AUDIT_TITLE),
            type: "dark",
            children: (
                <AuditStart
                    auditInstanceId={auditInstanceId}
                    actualStatus={actualStatus}
                    isAuditInstanceLoading={isAuditStartLoading}
                    auditInstance={auditStartData}
                    setAuditInstance={setAuditStartData}
                    fetchEditAuditHeaderData={fetchEditAuditHeaderData}
                />
            ),
            hidden: hideTab0,
        },
        {
            onClick: () => handleCurrentTabChange(1),
            rightCount: editAuditHeaderData?.issueCounter,
            text: t(AuditTranslation.AUDIT_NON_CONFORMITY),
            type: "dark",
            children: (
                <AuditIssues
                    fetchData={fetchAuditIssuesData}
                    isLoading={isAuditIssuesLoading}
                    issueList={auditIssuesList}
                    pageIndex={auditIssuesPageIndex}
                    setPageIndex={setAuditIssuesPageIndex}
                    totalIssueList={totalAuditIssueList}
                    fetchEditAuditHeaderData={fetchEditAuditHeaderData}
                    auditStatus={actualStatus}
                />
            ),
            hidden: hideTab1,
        },
        {
            onClick: () => handleCurrentTabChange(2),
            rightCount: 0,
            text: t(AuditTranslation.AUDIT_REPORT),
            type: "dark",
            children: (
                <AuditReport
                    auditInstance={auditInstance}
                    auditHistory={auditHistory}
                    isLoading={isLoading}
                    auditStarsNode={renderAuditStars}
                />
            ),
            hidden: hideTab2,
        },
    ];

    const footers: {
        show: boolean;
        footer: ReactNode;
    }[] = [
        {
            show: actualStatus === "IN_PROGRESS" && hasPermissionToEdit(SecScreen.AUDIT_INST_MANAG),
            footer: (
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationKeys.AUDIT_FINISH),
                        type: "submit",
                        onClick: handleAuditStartConfirmModalOpen,
                        variant: "purple",
                    }}
                    loading={isAuditStartSubmiting}
                />
            ),
        },
        {
            show:
                ((actualStatus === "IN_PROGRESS" || actualStatus === "REVIEWED") &&
                    hasPermissionToEdit(SecScreen.AUDIT_INST_MANAG)) ||
                (actualStatus === "COMPLETED" && hasPermissionToEdit(SecScreen.AUDIT_INST_REV_NC)),
            footer: (
                <GenericModalFooter
                    confirmButton={{
                        text:
                            actualStatus === "IN_PROGRESS"
                                ? t(TranslationKeys.AUDIT_FINISH)
                                : actualStatus === "COMPLETED"
                                ? t(AuditTranslation.AUDIT_FINISH_REVIEW)
                                : t(AuditTranslation.AUDIT_CLOSE_AUDIT),
                        type: "submit",
                        onClick:
                            actualStatus === "IN_PROGRESS"
                                ? handleAuditStartConfirmModalOpen
                                : actualStatus === "COMPLETED"
                                ? handleAuditIssuesConfirmModalOpen
                                : () => setIsCloseAuditModalOpen(true),
                        variant: "purple",
                    }}
                    loading={isAuditStartSubmiting || isCompleting || isClosing}
                />
            ),
        },
    ];

    const getAuditDetailData = async (tab: number) => {
        fetchEditAuditHeaderData();
        if (tab === 0) await fetchAuditStartData();
        if (tab === 1) await fetchAuditIssuesData({});
        if (tab === 2) await fetchAuditReportData(Number(companyId), Number(auditId));
    };

    useEffect(() => {
        if (!!companyId) changeWorkingCompanyByCompanyId(Number(companyId));
        getAuditDetailData(currentTab);
    }, []);

    return {
        isEditAuditHeaderLoading,
        editAuditHeaderData,
        tabs,
        currentTab,
        footers,
        setAuditStartIsConfirmModalOpen,
        confirmAuditStartModalSubmit,
        isAuditStartConfirmModalOpen,
        confirmAuditIssuesModalSubmit,
        isAuditIssuesConfirmModalOpen,
        setAuditIssuesIsConfirmModalOpen,
        isCloseAuditModalOpen,
        setIsCloseAuditModalOpen,
        handleCloseAudit,
        renderAuditStars,
        auditInstance,
    };
};
