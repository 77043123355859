import { getFullDateWithTime, getWeekDay, transformMinutesHoursToDate } from "app/helpers";
import { selectTypeCode, selectTypeCodeCustom } from "./selectTypeCode";
import { WizardTaskBody, TaskFieldModel2 } from "app/models/02-TAR/Task/TaskBody";
import { AllSteps, RecurrentTaskModel, DynamicFieldsModel, reportDataList } from "app/models/02-TAR/TaskWizard";

export const fillTaskBody = (data: AllSteps, instanceId?: number) => {
    const {
        companyForTask,
        userForTask,
        DepartmentForTask,
        SubDepartementForTask,
        workingPositionForTask,
        criticalTask,
        taskStart,
        taskDesc,
        fK_PlanAPPCC,
        finish,
        recurringTask,
        taskHours,
        taskTitle,
        imageBase64,
        somethingToReportCheckBoxImage,
        reportData,
        reportType,
        checkList,
        dynamicFields,
        fK_BaseTask,
        fotoExampleId,
        taskType,
    } = data;

    const body: WizardTaskBody = {
        fK_BaseTask: fK_BaseTask,
        taskType,
        customerInstanceId: instanceId ? instanceId : 0,
        name: taskTitle,
        description: taskDesc,
        fK_PlanAPPCC: !!fK_PlanAPPCC.length && fK_PlanAPPCC !== "-1" ? Number(fK_PlanAPPCC) : null,
        fK_Company: Number(companyForTask.value),
        fK_User: userForTask.value !== "" ? Number(userForTask.value) : null,
        fK_Department: DepartmentForTask.value !== "" ? Number(DepartmentForTask.value) : null,
        fK_Subdepartment: SubDepartementForTask.value !== "" ? Number(SubDepartementForTask.value) : null,
        fK_WorkingPosition: workingPositionForTask.value !== "" ? Number(workingPositionForTask.value) : null,
        fK_Foto: null,
        fotoExampleId: fotoExampleId || null,
        isPhotoRequired: somethingToReportCheckBoxImage,
        imageBase64Content: imageBase64,
        isCritical: criticalTask,
        startDate: getFullDateWithTime(taskStart),
        endDate: finish.checked === "el" ? getFullDateWithTime(String(finish.value)) : null,
        neverEnds:
            finish.checked === "never" && recurringTask && recurringTask.isRecurrent
                ? finish.checked === "never"
                : null,
        numRecurrencies: finish.checked === "after" ? Number(finish.value) : null,

        taskRangeHours: taskHours.map(({ hour, maxHour, type }) => {
            const startDateConvertd = new Date(transformMinutesHoursToDate(hour)).toISOString();
            let endDateConvertd = null;
            if (type === "range" && maxHour) {
                const hourToDate = new Date(transformMinutesHoursToDate(hour));
                const maxHourFormated = new Date(transformMinutesHoursToDate(maxHour));
                if (hourToDate > maxHourFormated) {
                    maxHourFormated.setDate(maxHourFormated.getDate() + 1);
                }
                endDateConvertd = maxHourFormated.toISOString();
            }

            return {
                hour: startDateConvertd,
                maxHour: endDateConvertd,
            };
        }),
        taskScheduler: {
            name:
                recurringTask && recurringTask.isRecurrent
                    ? recurringTask.value !== "custom"
                        ? recurringTask.text
                        : `custom-every ${recurringTask.custom.repeatEvery} ${recurringTask.custom.customValue}`
                    : "Task is not recurring",

            calendarDayNumber:
                recurringTask &&
                recurringTask.isRecurrent &&
                recurringTask.value === "custom" &&
                recurringTask.custom.customValue === "everyMonth"
                    ? recurringTask.custom.selectedOptions.value === "everyMonthDayOfMonth"
                        ? new Date(taskStart).getDate()
                        : 0
                    : 0,
            calendarWeekNumber:
                recurringTask && recurringTask.isRecurrent
                    ? recurringTask.custom.customValue === "everyMonth"
                        ? recurringTask.custom.selectedOptions.value === "everyMonthFirstDayOfWeek"
                            ? getWeekDay(taskStart)
                            : 0
                        : recurringTask.value === "everyMonth"
                        ? getWeekDay(taskStart)
                        : 0
                    : 0,

            repeatEvery: recurringTask && recurringTask.value === "custom" ? recurringTask.custom.repeatEvery : 1,
            monday: checkIfIsWeekDayIsSelected(recurringTask, 0, new Date(taskStart).getDay() - 1),
            tuesday: checkIfIsWeekDayIsSelected(recurringTask, 1, new Date(taskStart).getDay() - 1),
            wednesday: checkIfIsWeekDayIsSelected(recurringTask, 2, new Date(taskStart).getDay() - 1),
            thursday: checkIfIsWeekDayIsSelected(recurringTask, 3, new Date(taskStart).getDay() - 1),
            friday: checkIfIsWeekDayIsSelected(recurringTask, 4, new Date(taskStart).getDay() - 1),
            saturday: checkIfIsWeekDayIsSelected(recurringTask, 5, new Date(taskStart).getDay() - 1),
            sunday: checkIfIsWeekDayIsSelected(recurringTask, 6, new Date(taskStart).getDay() - 1),
            typeCode:
                recurringTask && recurringTask.isRecurrent && recurringTask.value === "custom"
                    ? selectTypeCodeCustom(recurringTask.custom.customValue)
                    : recurringTask && recurringTask.isRecurrent
                    ? selectTypeCode(recurringTask.value)
                    : "ONE_TIME",
        },
        taskCheckList: reportType === "CHECKLIST" ? checkList.map(({ name }) => ({ name, isDeleted: false })) : [],
        taskFields2:
            reportType === "ASSET"
                ? getAssets(reportData)
                : reportType === "DYNAMIC_FIELD"
                ? getDynamicFields(dynamicFields)
                : [],
    };

    return body;
};

const checkIfIsWeekDayIsSelected = (
    recurringTask: RecurrentTaskModel | null,
    index: number,
    dayOnWeek: number
): boolean => {
    if (!recurringTask) return false;

    const recurringTaskPermitedValues = ["everyWeek", "everyMonth", "annually"];

    if (recurringTask.isRecurrent) {
        if (recurringTask.value === "custom") {
            if (recurringTask.custom.customValue === "week") {
                return recurringTask.custom.days[index].isActive;
            }

            if (
                recurringTask.custom.customValue === "everyMonth" &&
                recurringTask.custom.selectedOptions.value === "everyMonthFirstDayOfWeek"
            ) {
                return index === dayOnWeek;
            }
        }
    }

    if (recurringTaskPermitedValues.includes(recurringTask.value)) {
        return index === dayOnWeek;
    }

    if (recurringTask.value === "mondayToFriday") {
        return index <= 4;
    }
    return false;
};

const getDynamicFields = (dynamicFields: DynamicFieldsModel[]): TaskFieldModel2[] => {
    return dynamicFields.map(({ dynamicFieldsType, isDeleted, isRequired, label, taskFieldOptions }) => ({
        label,
        isRequired,
        isDeleted,
        dynamicFieldType: dynamicFieldsType,
        taskFieldOptions: taskFieldOptions.map(({ label }) => ({ label })),
        fK_Task: undefined,
    }));
};
const getAssets = (reportData: reportDataList[]): TaskFieldModel2[] => {
    return reportData
        .map(({ assetId, isDeleted, assetsFields }) =>
            assetsFields
                ? assetsFields?.map(({ name, assetDynamicField, assetFieldRangeId }) => {
                      return {
                          isRequired: true,
                          dynamicFieldType: assetDynamicField,
                          isDeleted,
                          label: name,
                          fK_Asset2: assetId,
                          fK_AssetFieldRange: assetDynamicField === "NUMBER" ? assetFieldRangeId : undefined,
                      };
                  })
                : []
        )
        .flat();
};
