import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { Input, TextArea } from "app/components_v2/__inputs";
import { MailModal } from "app/components_v2/__modals/base/MailModal/MailModal";
import { faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { ValorationDetailModal } from "app/components_v2/__modals/ValorationDetailModal/ValorationDetailModal";

export const ModalSection: FC<SectionsShowProp> = ({ show }) => {
    const [showGenericModalsm, setShowGenericModalsm] = useState(false);
    const [showGenericModalmd, setShowGenericModalmd] = useState(false);
    const [showGenericModallg, setShowGenericModallg] = useState(false);
    const [showGenericModalxl, setShowGenericModalxl] = useState(false);

    const [showMailModal, setShowMailModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const dummyData = {
        entityHeaderInfoModel: {
            photoURL:
                "https://az4linkblobstorage.blob.core.windows.net/security/85016da2-80fc-4fa2-b7db-ddc55adcab05.png",
            name: "Josep Cobo",
            nameInitials: "JC",
            avg: 4,
            totalTasks: 20,
            trend: "15",
        },
        supervisorTeamAVG: {
            avg: 3,
            count: 30,
            trend: "15",
            team: 2,
            people: 7,
        },
        supervisorFastReview: [
            {
                rangeType: "FAST_REVIEW",
                percentage: 0,
                count: 20,
            },
            {
                rangeType: "NO_REVIEW",
                percentage: 0,
                count: 20,
            },
            {
                rangeType: "SLOW_REVIEW",
                percentage: 100,
                count: 20,
            },
        ],
        supervisorFastCommentModel: [
            {
                rangeType: "FAST_COMMENT",
                percentage: 20,
                count: 20,
            },
            {
                rangeType: "NO_COMMENT",
                percentage: 20,
                count: 20,
            },
            {
                rangeType: "SLOW_COMMENT",
                percentage: 40,
                count: 40,
            },
        ],
        supervisorReviewQualityModel: [
            {
                rangeType: "REVIEW_QUALITY_1_STAR_NO_REV",
                percentage: 10,
                count: 40,
            },
            {
                rangeType: "REVIEW_QUALITY_3_STAR_NO_REV",
                percentage: 10,
                count: 40,
            },
            {
                rangeType: "REVIEW_QUALITY_HIGH_REVIEW",
                percentage: 40,
                count: 40,
            },
            {
                rangeType: "REVIEW_QUALITY_LOW_REVIEW",
                percentage: 40,
                count: 40,
            },
        ],
        supervisorTaskStateModel: [
            {
                rangeType: "TIMED_OUT",
                percentage: 20,
                count: 7,
            },
            {
                rangeType: "IN_TIME",
                percentage: 20,
                count: 3,
            },
            {
                rangeType: "OUT_OF_TIME",
                percentage: 60,
                count: 7,
            },
        ],
    };

    return (
        <Section title="Modals" show={show}>
            <button onClick={() => setShowGenericModalsm(!showGenericModalsm)}>Abrir Generic Modal sm</button>
            {showGenericModalsm && (
                <GenericModal
                    size="sm"
                    header={{ title: "Nueva rol", onClose: () => setShowGenericModalsm(false) }}
                    footer={
                        <GenericModalFooter
                            confirmButton={{
                                onClick: () => setShowGenericModalsm(false),
                                text: "Confirmar",
                            }}
                            closeButton={{
                                onClick: () => setShowGenericModalsm(false),
                                text: "cancelar",
                            }}
                            buttonsJustification="end"
                        />
                    }
                >
                    <Input onChange={() => {}} />
                </GenericModal>
            )}
            <button onClick={() => setShowGenericModalmd(!showGenericModalmd)}>Abrir Generic Modal md</button>
            {showGenericModalmd && (
                <GenericModal
                    size="md"
                    footer={
                        <GenericModalFooter
                            closeButton={{
                                onClick: () => setShowGenericModalmd(false),
                                text: "Cancelar",
                                fullWidth: true,
                            }}
                            confirmButton={{
                                onClick: () => setShowGenericModalmd(false),
                                text: "Confirmar",
                                fullWidth: true,
                            }}
                            boxShadow={false}
                        />
                    }
                >
                    <h2>Alerta programada</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa minima, illo voluptate libero
                        quaerat deleniti dicta distinctio neque. Illum excepturi provident nobis delectus, officiis
                        corrupti atque a aliquid asperiores impedit.
                    </p>
                </GenericModal>
            )}
            <button onClick={() => setShowGenericModallg(!showGenericModallg)}>
                Abrir Generic Modal lg (taskDetail)
            </button>
            {showGenericModallg && (
                <GenericModal
                    size="lg"
                    header={{ title: "Editar tarea", onClose: () => setShowGenericModallg(false) }}
                    footer={
                        <GenericModalFooter
                            confirmButton={{
                                onClick: () => setShowGenericModallg(false),
                                text: "Confirmar",
                                fullWidth: false,
                            }}
                            closeButton={{
                                onClick: () => setShowGenericModallg(false),
                                text: "Cancelar",
                                fullWidth: false,
                                buttonType: "tertiary",
                            }}
                            buttonsJustification="end"
                        />
                    }
                >
                    <h2>Nombre de la tarea</h2>
                    <Input onChange={() => {}} value="Arreglar luces despacho" />
                    <h2>Descripción de la tarea</h2>
                    <TextArea
                        onChange={() => {}}
                        text={
                            " ipsum dolor sit amet, consectetur adipisicing elit. Ipsa minima, illo voluptate libero quaerat deleniti dicta distinctio neque. Illum excepturi provident nobis delectus, officiis corrupti atque a aliquid asperiores impedit."
                        }
                    />
                </GenericModal>
            )}
            <button onClick={() => setShowGenericModalxl(!showGenericModalxl)}>Abrir Generic Modal xl</button>

            {showGenericModalxl && (
                <ValorationDetailModal
                    showModal={setShowGenericModalxl}
                    endDate={new Date()}
                    onDateChange={(dates) => console.log("dates", dates)}
                    startDate={new Date()}
                    //@ts-ignore
                    data={dummyData}
                />
            )}

            <button onClick={() => setShowMailModal(!showMailModal)}>Abrir Mail Modal</button>
            {showMailModal && (
                <MailModal
                    onClose={() => setShowMailModal(false)}
                    title="Mail Modal"
                    confirmButton={{
                        text: "Valorar",
                        iconRight: faStarSharp,
                        fullWidth: false,
                        onClick: () => setShowMailModal(!showMailModal),
                    }}
                >
                    <TextArea
                        label="Reseña"
                        placeholder="Escribe o graba tu reseña"
                        onChange={() => {}}
                        disabled={false}
                        text={""}
                    />
                </MailModal>
            )}
            <button onClick={() => setShowConfirmModal(!showConfirmModal)}>Abrir Confirm Modal</button>
            {showConfirmModal && (
                <ConfirmModal
                    onConfirmText="confirm"
                    onConfirm={() => setShowConfirmModal(false)}
                    onCloseText="cancel"
                    onClose={() => setShowConfirmModal(false)}
                    description="Todas las Tareas Asignadas a este QR quedarán Asignadas al Departamento."
                    title="Estás seguro de que quieres desactivar el Código QR?"
                    type="info"
                    children={
                        <>
                            <p>Un Coomponent Children</p>
                            <p>Un Coomponent Children</p>
                            <p>Un Coomponent Children</p>
                        </>
                    }
                />
            )}
            <button onClick={() => setShowDeleteModal(true)}>Abrir Delete modal</button>
            {showDeleteModal && (
                <ConfirmModal
                    onConfirmText="Eliminar"
                    onConfirm={() => setShowDeleteModal(false)}
                    onCloseText="cerrar"
                    onClose={() => setShowDeleteModal(false)}
                    description="Todas las Tareas Asignadas a este QR quedarán Asignadas al Departamento."
                    title="Estás seguro de que quieres desactivar el Código QR?"
                    type="delete"
                />
            )}
        </Section>
    );
};
