import { OptionCompany } from "app/components_v2/OptionCompany/OptionCompany";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { AuthLayout } from "app/components_v2/__containers/AuthLayout/AuthLayout";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { OptionCompanySkelleton } from "app/components_v2/__skelletons/OptionCompanySkelleton/OptionCompanySkelleton";
import { useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useUserType } from "app/hooks/useUserType";
import { OptionsSearch } from "app/models/FormComponentsModel";
import UserScanQrService from "app/services/02-TAR/UserScanQrService";
import WorkingPositionService from "app/services/02-TAR/WorkingPositionService";
import { PrivatePaths } from "app/shared/Constants";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type SelectDepartmentModel = OptionsSearch & {
    isActive: boolean;
};

export const QrChecker = () => {
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const nav = useNavigate();
    const session = useSession();
    const { t } = useTranslation();
    const { isSupervisorOrAnalist } = useUserType();
    const { changeWorkingCompanyByDepartmentId } = useChangeWorkingSession();

    useTitle(t(TranslationTitles.SCAN_QR_PAGE_TITLE));

    const [departments, setDepartments] = useState<SelectDepartmentModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedDepartment, setSelectedDepartment] = useState<OptionsSearch | undefined>(undefined);
    const [acceptButtonIsLoading, setAcceptButtonIsLoading] = useState<boolean>(false);
    const [nameQR, setNameQR] = useState<string | undefined>();

    const fetch = async (qrCode: string) => {
        setIsLoading(true);
        const { status, data, getParsedError } = await WorkingPositionService.GetDepartments(qrCode);
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                variant: "danger",
                type: "alert",
            });
            setIsLoading(false);
            removeQr();
            navigateNextPage();
            return;
        }
        const departmentsId: number[] = [];
        if (session?.user?.companies) {
            const companies = session.user.companies;
            const departmentsToAdd: number[] = companies
                .flatMap(({ departments }) => departments?.map(({ departmentId }) => departmentId) || [])
                .filter((x) => x !== undefined);

            departmentsId.push(...departmentsToAdd);
        }

        setDepartments(
            data.list.map(({ id, name }) => ({
                value: String(id),
                label: name,
                isActive: departmentsId.includes(id),
            }))
        );
        if (data.tab != null) setNameQR(data.tab);
        if (data.list.length === 1) {
            const { id, name } = data.list[0];
            handleOpenModal({ label: name, value: String(id) });
        }
        setIsLoading(false);
        return;
    };

    const handleFetch = async () => {
        const qr = localStorage.getItem("qrCode");
        if (!qr) {
            nav(`/${PrivatePaths.SELECT_DEPARTMENT}`);
            return;
        }
        await fetch(qr);
    };

    const handleOpenModal = (dept: OptionsSearch) => {
        setSelectedDepartment(dept);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        if (departments.length === 1) {
            removeQr();
            navigateNextPage();
            return;
        }
        setIsModalOpen(false);
        setSelectedDepartment(undefined);
    };

    const handleAccept = async (dept: OptionsSearch) => {
        const qr = localStorage.getItem("qrCode");
        if (!qr) return;
        if (!session?.user) return;
        setAcceptButtonIsLoading(true);
        const { status, getParsedError } = await UserScanQrService.Save(qr, {
            departmentId: Number(dept.value),
            userId: session.user.id,
        });

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                variant: "danger",
                type: "alert",
            });
            setAcceptButtonIsLoading(false);
            return;
        }

        removeQr();
        if (isSupervisorOrAnalist) {
            handleAcceptIsSupervisor(Number(dept.value));
            setAcceptButtonIsLoading(false);
            return;
        }

        handleAcceptIsNotSupervisor(Number(dept.value));
        setAcceptButtonIsLoading(false);
    };

    const handleAcceptIsSupervisor = (selectedDepartmentId: number) => {
        if (!session?.workingCompany) {
            nav(`/${PrivatePaths.SELECT_DEPARTMENT}`);
            return;
        }
        changeWorkingCompanyByDepartmentId(selectedDepartmentId);
        navigateNextPage();
        return;
    };

    const handleAcceptIsNotSupervisor = (selectedDepartmentId: number) => {
        if (!session?.workingCompany || !session.workingDepartment) {
            nav(`/${PrivatePaths.SELECT_DEPARTMENT}`);
            return;
        }
        changeWorkingCompanyByDepartmentId(selectedDepartmentId);
        navigateNextPage();
        return;
    };

    const navigateNextPage = () => {
        if (!session) {
            nav(`/${PrivatePaths.SELECT_DEPARTMENT}`);
            return;
        }
        const goToRoute = PrivatePaths.DASHBOARD;

        nav(`/${goToRoute}?view=shared`);
        return;
    };

    const removeQr = () => localStorage.removeItem("qrCode");

    useEffect(() => {
        handleFetch();
    }, []);

    return (
        <AuthLayout>
            {isModalOpen && selectedDepartment && nameQR && (
                <ConfirmModal
                    onConfirm={() => handleAccept(selectedDepartment)}
                    title={`${t(TranslationModals.QR_CHECKER_MODAL_TITLE).replace("{1}", nameQR)}`}
                    description={`${t(TranslationModals.QR_CHECKER_MODAL_DESCRIPTION).replace("{1}", nameQR)}`}
                    onClose={handleCloseModal}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    isLoading={acceptButtonIsLoading}
                />
            )}
            <div className="loginInputForm qrCheckerContainer">
                <h1 className="qrCheckerContainer__title">{t(TranslationKeys.QR_CHECKER_TITLE)}</h1>
                <div className="qrCheckerContainer__list">
                    {isLoading ? (
                        <OptionCompanySkelleton />
                    ) : (
                        departments.map(({ isActive, label, value }) => (
                            <OptionCompany
                                name={label}
                                onClick={() => handleOpenModal({ label, value })}
                                isActive={isActive}
                                key={value}
                            />
                        ))
                    )}
                </div>
                <div>
                    <NewButton
                        text={t(TranslationCommon.CANCEL)}
                        handleClickButton={navigateNextPage}
                        buttonType="secondary"
                    />
                </div>
            </div>
        </AuthLayout>
    );
};
