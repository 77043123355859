import { TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export const useFetchErrors = () => {
    const { t } = useTranslation();

    const getErrorMessage = (errorMessage: string): string => {
        return t(errorMessage) || t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE);
    };

    return { getErrorMessage };
};
