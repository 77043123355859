import { FC, useState } from "react";
import { NewCompanyGroupValuesModel } from "./types";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { NewGroupCompanyForm } from "./NewGroupCompanyForm";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { SubdepartmentModel } from "app/models/01-SEG/Subdepartment/SubdepartmentModel";

type GroupCompanyModalProps = {
    onClose: () => void;
    onSubmit: (value: boolean) => void;
    title: string;
    values: NewCompanyGroupValuesModel;
    isEdit: boolean;
    isDepartment?: boolean;
    companyId?: number;
    usersCount?: number;
    newSubdepartment?: boolean;
    subdepartment?: SubdepartmentModel | null;
};

export const GroupCompanyModal: FC<GroupCompanyModalProps> = ({
    onClose,
    onSubmit,
    title,
    values,
    isEdit,
    isDepartment,
    companyId,
    usersCount,
    newSubdepartment,
    subdepartment,
}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <GenericModal
            header={{ title: title, onClose: onClose }}
            portal
            size="sm"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        type: "submit",
                        text: isEdit ? t(TranslationCommon.SAVE) : t(TranslationCommon.CREATE),
                        fullWidth: true,
                        form: "groupCompanyModal",
                    }}
                    closeButton={{
                        onClick: onClose,
                        text: t(TranslationCommon.CANCEL),
                        fullWidth: true,
                        buttonType: "tertiary",
                    }}
                    buttonsJustification="end"
                    loading={isLoading}
                />
            }
        >
            <NewGroupCompanyForm
                values={values}
                onSubmit={onSubmit}
                isDepartment={isDepartment}
                companyId={companyId}
                usersCount={usersCount}
                newSubdepartment={newSubdepartment}
                subdepartmentToEdit={subdepartment}
                setIsLoading={setIsLoading}
            />
        </GenericModal>
    );
};
