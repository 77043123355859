import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { DepartmentWithSubsModel, SubdepartmentUsersModel } from "app/dtos/01-SEG/DepartmentDto";
import { useToast } from "app/hooks/Toast/useToast";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import { SubdepartmentModel } from "app/models/01-SEG/Subdepartment/SubdepartmentModel";
import { SubdepartmentService } from "app/services";
import { TranslationModals } from "app/translation/translationKeys";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckedDeps } from "../../types";

type DepartmentCheckBoxProps = {
    department: DepartmentModel;
    selectedDepartments: DepartmentWithSubsModel[];
    onSelectDepartment: (dept: DepartmentWithSubsModel, isChecked: boolean) => void;
    onSelectSubdepartment: (subdept: SubdepartmentUsersModel, isChecked: boolean) => void;
    checkedDepsAndSubdeps?: CheckedDeps;
    checkedSubdepartments: number[];
};

export const DepartmentCheckBox: FC<DepartmentCheckBoxProps> = ({
    department,
    selectedDepartments,
    onSelectDepartment,
    checkedDepsAndSubdeps,
    onSelectSubdepartment,
    checkedSubdepartments,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [checked, setChecked] = useState(checkedDepsAndSubdeps?.checked || false);
    const [subdepartments, setSubdepartments] = useState<SubdepartmentModel[]>();

    const getSubdepartments = async () => {
        const subdepartmentSr = await SubdepartmentService.GetData({
            extraParams: `DepartmentId=${department.id}`,
        });
        if (!subdepartmentSr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_ERROR_SUBDEPARTMENT),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setSubdepartments(subdepartmentSr.data.list);
    };

    useEffect(() => {
        checked && getSubdepartments();
    }, [checked]);

    return (
        <div>
            <CheckBox
                label={department.name}
                onChange={(checked) => {
                    setChecked(checked);
                    onSelectDepartment({ ...department, subs: [], users: [] }, checked);
                }}
                checked={checked}
                htmlFor={`departmentCheckbox${department.name}`}
            />
            {checked && subdepartments && !!subdepartments.length && (
                <div className="subdepartmentCheckbox">
                    {subdepartments.map((subdepartment) => (
                        <CheckBox
                            key={subdepartment.id}
                            label={subdepartment.name}
                            onChange={(checked) => onSelectSubdepartment({ ...subdepartment, users: [] }, checked)}
                            checked={
                                !!checkedSubdepartments.find((subdepatmentId) => subdepatmentId === subdepartment.id)
                            }
                            htmlFor={`subdepartmentCheckbox${subdepartment.id}`}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
