import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionCompanyImage } from "app/components_v2/OptionCompany/OptionCompanyImage/OptionCompanyImage";
import { CompanyModel } from "app/models/01-SEG/Company/CompanyModels";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
type MobileCompanyGridBodyProps = {
    company: CompanyModel;
};
export const MobileCompanyGridBody: FC<MobileCompanyGridBodyProps> = ({ company }) => {
    const nav = useNavigate();

    const { name, logoFileURL, address, id } = company;
    return (
        <div className="mobileCompanyGridBody" onDoubleClick={() => nav(`edit/${id}`)}>
            <div className="mobileCompanyGridBody__container">
                <OptionCompanyImage name={name} logoImage={logoFileURL} />
                <div className="mobileCompanyGridBody__body">
                    <p className="mobileCompanyGridBody__body__title">{name}</p>
                    <p className="mobileCompanyGridBody__body__address">{address}</p>
                </div>
            </div>
            <div className="mobileCompanyGridBody__icon" onClick={() => nav(`edit/${id}`)}>
                <FontAwesomeIcon className="mobileCompanyGridBody__icon__edit" icon={faPen} />
            </div>
        </div>
    );
};
