import { FC } from "react";
import { useGetDynamicFieldsTranslations } from "app/hooks/dynamicFields/useGetDynamicFieldsTranslations";
import { ChecklistItemReadOnly } from "../ChecklistItemReadOnly/ChecklistItemReadOnly";
import { DynamicFieldsModel, checkListModel, reportType } from "app/models/02-TAR/TaskWizard";
import { DynamicFieldItemReadOnly } from "../DynamicFieldItemReadOnly/DynamicFieldItemReadOnly";

type DataToReportReadOnlyProps = {
    dynamicfields: DynamicFieldsModel[];
    checkList: checkListModel[];
    reportType: reportType;
    variant?: "editTask" | "modal";
};

export const DataToReportReadOnly: FC<DataToReportReadOnlyProps> = ({
    dynamicfields,
    checkList,
    reportType,
    variant = "editTask",
}) => {
    const { getDynamicFieldTranslation } = useGetDynamicFieldsTranslations();

    return (
        <div className="dataToReportReadOnly">
            <h2 className="dataToReportReadOnly__title">
                {reportType === "DYNAMIC_FIELD" ? "Campos dinámicos" : "Checklist"}
            </h2>
            <div className={`dataToReportReadOnly__container dataToReportReadOnly__container--${variant}`}>
                {reportType === "DYNAMIC_FIELD" ? (
                    dynamicfields.map(({ dynamicFieldsType, label, taskFieldOptions, id }) => (
                        <DynamicFieldItemReadOnly
                            label={label}
                            type={getDynamicFieldTranslation(dynamicFieldsType)}
                            options={taskFieldOptions.map(({ label }) => label)}
                            key={id}
                        />
                    ))
                ) : (
                    <ChecklistItemReadOnly labels={checkList.map(({ name }) => name)} />
                )}
            </div>
        </div>
    );
};
