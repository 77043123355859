import { FC, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/CustomHooks";
import { useReceivedNotifications } from "app/hooks/Notification";
import { useTranslation } from "react-i18next";
import { useVariant } from "app/hooks/useVariant";
import { useShowHeader } from "app/hooks/useShowHeader";
import { TranslationKeys } from "app/translation/translationKeys";
import { SignalRService } from "../../services";
import SystemNotificationService from "app/services/02-TAR/SystemNotificationService";
import { IAppState } from "../../state/reducer";
import { AlertNotificationMapper } from "app/mappers/AlertNotificationMapper";
import { isChatRoom } from "app/helpers/isChatRoom";
import { AppSource, PrivatePaths, screenSize } from "../../shared/Constants";
import { Header } from "app/components_v2/Header/Header";
import ImpersonateHeader from "./ImpersonateHeader";
import { MobileFooter } from "app/components_v2/MobileFooter/MobileFooter";
import { AlertNotificationsModal } from "app/components_v2/__modals/AlertNotificationsModal/AlertNotificationsModal";

type AppHeaderProps = {
    systemNotificationsCount: number;
    setSystemNotificationsCount: React.Dispatch<SetStateAction<number>>;
    children: React.ReactNode | React.ReactNode[];
};

const AppHeader: FC<AppHeaderProps> = ({ systemNotificationsCount, setSystemNotificationsCount, children }) => {
    const showHeader = useShowHeader();
    const nav = useNavigate();
    const signalR = SignalRService.getSignalR();
    const { width } = useWindowSize();
    const [showModal, setShowModal] = useState(false);
    const { session, impersonateMode, wizardMode } = useSelector<IAppState, IAppState>((state) => state);
    const isOfflineApp = session?.appSource === AppSource.OFFLINE;
    const { variant } = useVariant();
    const { t } = useTranslation();

    const updateSystemNotif = async () => {
        const sr = await SystemNotificationService.GetSystemNotifications({
            extraParams: `ReceiverUserId=${session?.user.id}&NotSeen=true`,
            pageSize: 20,
            pageIndex: 0,
        });
        if (sr.status()) return sr.data.count;
    };

    const { receivedNotificationList, fetchNotifications } = useReceivedNotifications({
        id: session?.user?.id,
        notSeen: true,
    });

    useEffect(() => {
        signalR.SignalRConnection.on("SystemNotification", (_) => {
            updateSystemNotif().then((response) => {
                setSystemNotificationsCount((prev) => (prev === 0 ? response! + 1 : prev + 1));
            });
        });

        signalR.SignalRConnection.on("HandleNotificationMobile", () =>
            updateSystemNotif().then((response) => {
                setSystemNotificationsCount((prev) => (prev === 0 ? response! : prev - 1));
            })
        );

        signalR.SignalRConnection.on("AlertNotification", () => {
            if (session?.workingCompany?.companyId) fetchNotifications();
        });

        return () => {
            signalR.SignalRConnection.off("SystemNotification");
            signalR.SignalRConnection.off("HandleNotificationMobile");
            signalR.SignalRConnection.off("AlertNotification");
        };
    }, []);

    useEffect(() => {
        if (!receivedNotificationList.length) {
            setShowModal(false);
            return;
        }

        if (window.location.pathname.split("/")[1] !== PrivatePaths.PENDING_ALERTS) setShowModal(true);
    }, [receivedNotificationList]);

    useEffect(() => {
        if (session?.workingCompany) fetchNotifications();
    }, [session?.workingCompany]);

    return (
        <div className={`globalContainer${session?.user.doNotDisturbMode ? "--noDisturb" : ""}`}>
            {session?.user.doNotDisturbMode && !isOfflineApp && (
                <div className="globalContainer__mode--noDisturb">
                    {session?.user.doNotDisturbMode ? (
                        <>
                            {t(TranslationKeys.DO_NOT_DISTURB_MODE)},
                            <p
                                className="globalContainer__mode__link"
                                onClick={() => nav(`/${PrivatePaths.MY_PROFILE}`)}
                            >
                                desactívalo para recibir notificaciones.
                            </p>
                        </>
                    ) : (
                        "molestar"
                    )}
                </div>
            )}
            {showModal && !isOfflineApp && (
                <AlertNotificationsModal
                    data={AlertNotificationMapper(receivedNotificationList)}
                    closeModal={() => setShowModal(false)}
                />
            )}
            {impersonateMode && <ImpersonateHeader session={session!} />}
            {showHeader() && !wizardMode && !isOfflineApp && <Header variant={variant} />}
            {children}
            {width < screenSize.TABLET && !wizardMode && !isChatRoom() && (
                <MobileFooter
                    systemNotificationsCount={systemNotificationsCount}
                    setSystemNotificationsCount={setSystemNotificationsCount}
                />
            )}
        </div>
    );
};

export default AppHeader;
