import { AlertModel } from "app/components_v2/__modals/AlertNotificationsModal/types";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";
import { convertUTCtoLocaleDate } from "app/helpers";

export const AlertNotificationMapper = (
    receivedNotificationList: AlertNotificationDto[],
    sendedByMe?: boolean
): AlertModel[] => {
    return receivedNotificationList.map(
        ({
            audio,
            body,
            receiverProfilePictureURL,
            receivedDate,
            id,
            notificationFiles,
            senderUserId,
            isProgrammed,
            sendDate,
            title,
            senderUserName,
            senderProfilePictureURL,
            receiverNotificationList,
        }) => {
            return {
                date:
                    isProgrammed && !sendedByMe && receivedDate
                        ? convertUTCtoLocaleDate(new Date(receivedDate))
                        : convertUTCtoLocaleDate(new Date(sendDate)),
                fullName: senderUserName || "",
                audioUrl: audio,
                receiverProfilePictureURL,
                message: body,
                id,
                notificationFiles: notificationFiles || [],
                senderUserId,
                title,
                senderUserName,
                senderProfilePictureURL,
                receiverNotificationList,
            };
        }
    );
};
