import { FC, useState } from "react";
import Select, { MenuPosition } from "react-select";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { TranslationCommon } from "app/translation/translationKeys";
import isiOS from "app/helpers/isIos";
import { SelectTagOptions } from "./types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Hint } from "../Hint/Hint";
import { Tag } from "app/components_v2/Tag/Tag";
import { Label } from "../Label";
import { ReadOnlyInput } from "../ReadOnlyInput/ReadOnlyInput";

type SelectOptionsProps = {
    options: OptionModel[];
    label?: string;
    readonly?: boolean;
    disabled?: boolean;
    placeholder?: string;
    values: OptionModel[];
    errorMessage?: string;
    hint?: string;
    menuPosition?: MenuPosition;
    htmlFor?: string;
    onDeleteItem: (id: string) => void;
    hidden?: boolean;
    autoInitials?: boolean;
    onChange: (value: OptionModel[]) => void;
    isMulti?: boolean;
    inputWidth?: number;
    isSearchable?: boolean;
    tagOptions?: SelectTagOptions;
    isLoading?: boolean;
};

export const OptionsOutsideSelect: FC<SelectOptionsProps> = ({
    onChange,
    options,
    values,
    readonly,
    disabled,
    errorMessage,
    hint,
    htmlFor,
    label,
    menuPosition = "fixed",
    placeholder,
    onDeleteItem,
    hidden,
    autoInitials,
    isMulti = true,
    inputWidth = 100,
    isSearchable,
    tagOptions,
    isLoading,
}) => {
    const { t } = useTranslation();
    const handleRemoveValue = (item: string) => {
        if (!onChange) return;
        const removedValue = values.find(({ value }) => value === item);
        if (!removedValue) return;
        onDeleteItem(removedValue.value);
    };
    const [isHintVisible, setIsHintVisible] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleChangeHint = () => {
        if (!!errorMessage?.length) {
            setIsHintVisible(false);
            return;
        }
        setIsHintVisible(!isHintVisible);
    };
    const handleInitialsName = (input: string): string => {
        const palabras = input.split(" ");

        if (palabras.length >= 2) {
            const primeraLetraPrimeraPalabra = palabras[0].charAt(0).toUpperCase();
            const primeraLetraSegundaPalabra = palabras[1].charAt(0).toUpperCase();

            return primeraLetraPrimeraPalabra + primeraLetraSegundaPalabra;
        } else if (palabras.length === 1) return palabras[0].substring(0, 2).toUpperCase();
        else return "";
    };
    const handleInputChange = (selectedValues: OptionModel | OptionModel[]) => {
        if (isMulti && Array.isArray(selectedValues)) {
            onChange(selectedValues);

            return;
        }

        if (!isMulti && !Array.isArray(selectedValues)) {
            onChange([selectedValues]);

            return;
        }
    };

    return (
        <div className="selectOptions">
            {label && (
                <div className="selectOptions__label">
                    <Label label={label} disabled={disabled} htmlFor={htmlFor} />
                    {hint && (
                        <FontAwesomeIcon
                            icon={faCircleQuestion}
                            className={"inputTextWrapper__iconwrapper__icon"}
                            onClick={handleChangeHint}
                        />
                    )}
                </div>
            )}
            {!hidden && (
                <div
                    className={`selectOptions__select${errorMessage ? "__errored" : ""}${isFocused ? "--focused" : ""}`}
                    style={{ width: `${inputWidth}%` }}
                >
                    <Select
                        value={isMulti ? values : values[0]}
                        onChange={(selectedValue) => {
                            handleInputChange(selectedValue as OptionModel[]);
                        }}
                        options={options}
                        controlShouldRenderValue={isMulti ? false : true}
                        isClearable={false}
                        isMulti={isMulti}
                        classNames={{
                            control: () => "reactSelectClassName",
                            menuPortal: () => "zIndexUp",
                            placeholder: () => "reactSelectPlaceholder text_clamp text_clamp-1",
                        }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        inputId={htmlFor}
                        isDisabled={disabled}
                        placeholder={placeholder || t(TranslationCommon.SELECT_OPTION)}
                        isSearchable={isiOS() ? false : isSearchable}
                        menuPosition={menuPosition}
                        menuPortalTarget={document.body}
                        menuPlacement="bottom"
                        styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                        isLoading={isLoading}
                    />
                </div>
            )}
            {!!errorMessage?.length && !disabled ? (
                <ErrorMessage errorMessage={errorMessage} />
            ) : (
                hint && isHintVisible && <Hint text={hint} />
            )}
            {isMulti && !!values.length && (
                <div className="selectOptions__list">
                    {values.map(({ label, value, initials, profilePictureURL }) => {
                        if (readonly) return <ReadOnlyInput value={label} options={{ width: "auto" }} key={value} />;

                        return (
                            <Tag
                                size={tagOptions?.size}
                                clamp={tagOptions?.clamp}
                                key={value}
                                message={label}
                                onClose={() => !disabled && handleRemoveValue(value)}
                                variant={disabled ? "gray" : "white"}
                                width="auto"
                                disabled={disabled || hidden}
                                avatar={
                                    initials
                                        ? { id: value, initials, profilePictureURL, name: label }
                                        : autoInitials
                                          ? {
                                                id: value,
                                                initials: handleInitialsName(label),
                                                profilePictureURL,
                                                name: label,
                                            }
                                          : undefined
                                }
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};
