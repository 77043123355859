import { faPen, faTrash, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { SelectActionsModel, SelectActionsLastItemsModel } from "app/components_v2/SelectWithActions/types";
import { generateArrayOfConsecutiveNumbers } from "app/helpers/arrayGeneration";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessHandler } from "app/hooks/SuccessHandler/useSuccessHandler";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { BaseAuditGroupCheckListItemModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemModels/BaseAuditGroupCheckListItemModel";
import { BaseAuditGroupCheckListItemTypeModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemTypeModels/BaseAuditGroupCheckListItemTypeModel";
import { ActionType, OptionsSearch } from "app/models/FormComponentsModel";
import { hasPermissionToEdit, hasPermissionToDelete, hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import BaseAuditGroupCheckListItemTypeService from "app/services/05-QUA/BaseAuditGroupCheckListItemTypeService";
import { SecScreen } from "app/shared/Constants";
import { BaseAuditGroupCheckListItemTypeTranslations, TranslationModals } from "app/translation/translationKeys";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM } from "../../constants/BaseAuditGroupCheckListItemConstants";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";

export const useBaseAuditGroupCheckListItemModal = (baseAuditGroupCheckListItem?: BaseAuditGroupCheckListItemModel) => {
    const { t } = useTranslation();
    const { handleSuccessManager } = useSuccessHandler();
    const { handleErrorManager } = useErrorManager();
    const { getErrorMessage } = useFetchErrors();

    const [baseAuditGroupCheckListItemTypeOptions, setBaseAuditGroupCheckListItemTypeOptions] = useState<
        OptionsSearch[]
    >([]);
    const [internalData, setInternalData] = useState<BaseAuditGroupCheckListItemModel>(
        baseAuditGroupCheckListItem || INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM
    );
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [baseAuditGroupCheckListItemType, setBaseAuditGroupCheckListItemType] = useState<
        BaseAuditGroupCheckListItemTypeModel | undefined
    >(
        !!baseAuditGroupCheckListItem
            ? {
                  id: baseAuditGroupCheckListItem?.fK_BaseAuditGroupCheckListItemType,
                  name: baseAuditGroupCheckListItem?.type,
              }
            : undefined
    );
    const [nameError, setNameError] = useState<string>("");

    const ponderationLevelOptions: OptionsSearch[] = generateArrayOfConsecutiveNumbers(11).map((number) => ({
        label: number.toString(),
        value: number.toString(),
    }));

    const getBaseAuditGroupCheckListItemTypeOptions = async () => {
        const { data, status } = await QuaSelectorService.GetBaseAuditGroupCheckListItemType();

        if (!status) {
            setBaseAuditGroupCheckListItemTypeOptions([]);
            return;
        }

        setBaseAuditGroupCheckListItemTypeOptions(data);
    };

    const handleChange = (value: Partial<BaseAuditGroupCheckListItemModel>) =>
        setInternalData((prev) => ({ ...prev, ...value }));

    const selectActions: SelectActionsModel[] = [
        {
            icon: faPen,
            onClick: (item) => {
                const foundItem = baseAuditGroupCheckListItemTypeOptions.find(({ value }) => value === item.value);
                if (!foundItem) return;
                setBaseAuditGroupCheckListItemType({ id: Number(foundItem.value), name: foundItem.label });
                setShowEditModal(true);
            },
            hidden: !hasPermissionToEdit(SecScreen.AUDIT_MANAGEMENT),
        },
        {
            icon: faTrash,
            onClick: (item) => {
                const foundItem = baseAuditGroupCheckListItemTypeOptions.find(({ value }) => value === item.value);
                if (!foundItem) return;
                setBaseAuditGroupCheckListItemType({ id: Number(foundItem.value), name: foundItem.label });
                setShowDeleteModal(true);
            },
            hidden: !hasPermissionToDelete(SecScreen.AUDIT_MANAGEMENT),
        },
    ];

    const selectLastItems: SelectActionsLastItemsModel[] = [
        {
            title: t(BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_ADD_TITLE),
            icon: faPlus,
            onClick: () => {
                setBaseAuditGroupCheckListItemType(undefined);
                setShowEditModal(true);
            },
            hidden: !hasPermissionToAdd(SecScreen.AUDIT_MANAGEMENT),
        },
    ];

    const handleDelete = async () => {
        if (!baseAuditGroupCheckListItemType) return;
        const { id } = baseAuditGroupCheckListItemType;
        setIsLoadingModal(true);

        const { status, getParsedError } = await BaseAuditGroupCheckListItemTypeService.Delete(id);

        const success = handleResponse(
            status(),
            getParsedError(),
            TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_SUCCESS_TOAST,
            setShowDeleteModal,
            TranslationModals.CANNOT_DELETE_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_IN_USE_TITLE_TOAST
        );

        // Delete selected in memory if deleted
        if (success) {
            const selectedValue = baseAuditGroupCheckListItemTypeOptions.find(({ value }) => Number(value) === id);
            if (!selectedValue) return;
            handleChange({ fK_BaseAuditGroupCheckListItemType: 0 });
        }
    };

    const handleSubmitBaseAuditGroupCheckListItem = async (
        type: ActionType,
        item: BaseAuditGroupCheckListItemTypeModel
    ) => {
        if (!validate(item)) return;

        setIsLoadingModal(true);

        if (type === "create") {
            await handleSaveBaseAuditGroupCheckListItemType(item);
            return;
        }
        await handleEditBaseAuditGroupCheckListItemType(item);
    };

    const validate = (item: BaseAuditGroupCheckListItemTypeModel) => {
        setNameError("");
        let check = true;
        if (!item.name.length) {
            setNameError(t(BaseAuditGroupCheckListItemTypeTranslations.NAME_IS_REQUIRED));
            check = false;
        }

        return check;
    };

    const handleSaveBaseAuditGroupCheckListItemType = async (item: BaseAuditGroupCheckListItemTypeModel) => {
        const { status, getParsedError } = await BaseAuditGroupCheckListItemTypeService.Save(item);

        handleResponse(
            status(),
            getParsedError(),
            TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_SAVE_SUCCESS_TOAST,
            setShowEditModal
        );
    };

    const handleEditBaseAuditGroupCheckListItemType = async (item: BaseAuditGroupCheckListItemTypeModel) => {
        const { status, getParsedError } = await BaseAuditGroupCheckListItemTypeService.Edit(item.id, item);

        handleResponse(
            status(),
            getParsedError(),
            TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_EDIT_SUCCESS_TOAST,
            setShowEditModal
        );
    };

    const handleResponse = (
        status: boolean,
        error: string,
        successToastTitle: string,
        isOpenModal: (value: boolean) => void,
        customErrorMessage?: string
    ): boolean => {
        const errorMessage = getErrorMessage(error);
        const errorManager = customErrorMessage
            ? handleErrorManager(status, t(customErrorMessage), errorMessage)
            : handleErrorManager(status, errorMessage);

        if (errorManager) {
            setIsLoadingModal(false);
            isOpenModal(false);
            return false;
        }

        handleSuccessManager(t(successToastTitle));

        setIsLoadingModal(false);
        isOpenModal(false);
        getBaseAuditGroupCheckListItemTypeOptions();
        return true;
    };

    const handleClose = (isOpen: (value: boolean) => void) => {
        setNameError("");
        isOpen(false);
    };

    useEffect(() => {
        getBaseAuditGroupCheckListItemTypeOptions();
    }, []);

    return {
        showDeleteModal,
        baseAuditGroupCheckListItemType,
        handleDelete,
        setShowDeleteModal,
        isLoadingModal,
        showEditModal,
        handleSubmitBaseAuditGroupCheckListItem,
        setShowEditModal,
        handleChange,
        baseAuditGroupCheckListItemTypeOptions,
        selectActions,
        selectLastItems,
        ponderationLevelOptions,
        internalData,
        nameError,
        handleClose,
    };
};
