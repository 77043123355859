import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetPageTitle } from "app/hooks/useGetPageTitle";
import { useTitle, useWindowSize } from "app/hooks/CustomHooks";
import { useSession } from "app/hooks";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { NotificationsModal } from "app/components_v2/__modals/NotificationsModal/NotificationsModal";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { SendedAlerts } from "./SendedAlerts/SendedAlerts";
import { ProgrammedAlerts } from "./ProgrammedAlerts/ProgrammedAlerts";
import { ReceivedAlerts } from "./ReceivedAlerts/ReceivedAlerts";
import { faPaperPlane, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { AlertFilter } from "app/components_v2/__filters/AlertFilter/AlertFilter";
import { AlertFilterModel } from "app/components_v2/__filters/AlertFilter/types";
import { translateCurrentTabToModel } from "./helpers/translateCurrentTabToModel";
import { useAlert } from "./hooks/useAlert";
import { SecScreen, screenSize } from "app/shared/Constants";
import { useUserType } from "app/hooks/useUserType";
import { Icon } from "app/components_v2/Icon/Icon";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { FloatingButton } from "app/components_v2/__buttons/FloatingButton/FloatingButton";

export const AlertsPage = () => {
    const { t } = useTranslation();
    const session = useSession();
    const { isSupervisorOrAnalist, isWorker } = useUserType();
    const pageTitle = useGetPageTitle();
    const { width } = useWindowSize();
    const { setIsLoading, setFilterData, initialFilterData, filterData, ...alertFetchData } = useAlert();
    const { onChangePageIndex, getNotifications } = alertFetchData;

    useTitle(t(TranslationTitles.ALERTS_PAGE_TITLE));

    const [isNotficationModalOpened, setIsNotificationModalOpened] = useState(false);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const onClickChangeTab = (current: number) => {
        if (current === currentTab) return;
        if (alertFetchData.isLoading) return;
        onChangePageIndex(0);
        setCurrentTab(current);
        setFilterData(initialFilterData);
        setIsLoading(true);
    };

    const handleFilterData = (values: AlertFilterModel) => {
        setFilterData(values);
        setIsFilterOpen(false);
        onChangePageIndex(0);
        getNotifications(translateCurrentTabToModel(currentTab), 0, values);
    };

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.RECEIVED),
            children: <ReceivedAlerts refresh={refresh} {...alertFetchData} />,
            onClick: onClickChangeTab,
            type: "dark",
            hidden: isWorker,
        },
        {
            text: t(TranslationKeys.SENT),
            children: <SendedAlerts refresh={refresh} {...alertFetchData} />,
            onClick: onClickChangeTab,
            type: "dark",
            hidden: isWorker,
        },
        {
            text: t(TranslationKeys.PROGRAMMED),
            children: <ProgrammedAlerts refresh={refresh} {...alertFetchData} />,
            onClick: onClickChangeTab,
            type: "dark",
            hidden: isWorker,
        },
    ];

    return (
        <PageContainer
            fullHeight
            header={
                <TabHeader
                    tabs={
                        <>
                            <Tabs tabs={tabs} currentTab={currentTab} hideChildren />{" "}
                            <Icon icon={faSliders} onClick={() => setIsFilterOpen(true)} />
                        </>
                    }
                >
                    {!!pageTitle.length && width >= screenSize.TABLET && <p className="pageTitle">{pageTitle}</p>}
                </TabHeader>
            }
        >
            {tabs[currentTab].children}

            <AlertFilter
                filterData={filterData}
                initialFilterData={initialFilterData}
                onSave={handleFilterData}
                isOpen={isFilterOpen}
                onCancel={() => setIsFilterOpen(false)}
                currentTab={translateCurrentTabToModel(currentTab)}
            />
            {isNotficationModalOpened && (
                <NotificationsModal
                    onClose={() => setIsNotificationModalOpened(false)}
                    refreshList={() => setRefresh(!refresh)}
                    userId={!isSupervisorOrAnalist ? session?.user.id : undefined}
                    userName={
                        !isSupervisorOrAnalist ? `${session?.user.firstName} ${session?.user.lastName}` : undefined
                    }
                    profileURL={!isSupervisorOrAnalist ? session?.user.profilePictureURL : undefined}
                />
            )}
            {hasPermissionToAdd(SecScreen.ALERTS) && (
                <FloatingButton iconLeft={faPaperPlane} onClick={() => setIsNotificationModalOpened(true)} />
            )}
        </PageContainer>
    );
};
