import { FormEvent, useRef, useState } from "react";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationErrors } from "app/translation/translationKeys";
import { IssueAssetsModel } from "../../types";
import { IssueAssetStepErrorModel } from "../types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { IssueAssetModalStepProps } from "../IssueAssetModalStep";
import { convertAndCompressImages } from "app/helpers/dragFile/convertAndCompressImage";
import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";

const INITIAL_FORM_ERRORS: IssueAssetStepErrorModel = {
    errorDescription: "",
    errorCorrectiveMeassures: "",
};

export const useIssueAssetModalStep = ({ item, next, onChange, isLastStep, onFinish }: IssueAssetModalStepProps) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [errorFormValues, setErrorFormValues] = useState<IssueAssetStepErrorModel>(INITIAL_FORM_ERRORS);
    const corrMesRef = useRef<HTMLDivElement | null>(null);

    const { correctiveMeassures, description, attachments } = item;

    const handleInputChange = (values: Partial<IssueAssetsModel>) => {
        onChange({ ...item, ...values });
    };

    const handleAddCorrectiveMeassure = (values: OptionModel[]) => {
        if (values.length > 10) {
            handleToast({
                variant: "danger",
                title: t(TranslationErrors.ERROR_MESSAGE_MAX_10_COR_MES),
                type: "alert",
            });
            return;
        }
        onChange({ ...item, correctiveMeassures: values });
    };

    const handleDeleteCorrectiveMeassures = (id: string) => {
        const selectedCorMes = correctiveMeassures.find(({ value }) => value === id);
        if (!selectedCorMes) return;
        const corMesFiltered = correctiveMeassures.filter(({ value }) => value !== id);
        onChange({ ...item, correctiveMeassures: corMesFiltered });
    };

    const handleErrorChange = (values: Partial<IssueAssetStepErrorModel>) => {
        setErrorFormValues((prev) => ({ ...prev, ...values }));
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let isValid = true;
        handleErrorChange(INITIAL_FORM_ERRORS);
        if (!description.length) {
            handleErrorChange({ errorDescription: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isValid = false;
        }

        if (isLastStep) {
            onFinish();
            return;
        }
        if (isValid) next();
    };

    const handleChangeDragFile = async (imagesFiles: ImageDragFile[]) => {
        const newAttachments = [...attachments, ...imagesFiles];
        handleInputChange({
            attachments: newAttachments,
        });

        const photosConverted = await convertAndCompressImages(imagesFiles);
        handleInputChange({
            attachments: newAttachments.map((photo) => {
                const photoConverted = photosConverted.find(({ id }) => photo.id === id);
                return {
                    ...photo,
                    isLoading: false,
                    isErrored: false,
                    base64: photoConverted ? photoConverted.imageBase64Content : photo.base64,
                };
            }),
        });
    };

    const handleDeleteImage = (id: string) => {
        handleInputChange({ attachments: attachments.filter((attachment) => attachment.id !== id) });
    };

    const handleEditImage = (image: ImageDragFile, editedImageId: string) => {
        const attachmentsWithEditedImage = attachments.map((photo) =>
            photo.id === editedImageId ? { ...image, isLoading: false } : photo
        );
        handleInputChange({ attachments: attachmentsWithEditedImage });
    };

    return {
        ...errorFormValues,
        handleInputChange,
        handleAddCorrectiveMeassure,
        handleDeleteCorrectiveMeassures,
        validate,
        corrMesRef,
        handleChangeDragFile,
        handleDeleteImage,
        handleEditImage,
    };
};
