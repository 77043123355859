import { fillTaskInstanceExtraParams } from "app/helpers/ExtraParams/taskInstanceFillExtraParams";
import { useSession } from "app/hooks";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { TranslationCommon } from "app/translation/translationKeys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardType } from "../types";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

type DataProps = {
    companyId: string;
    type: DashboardType;
};

export const useGetUserDeptSub = () => {
    const [users, setUsers] = useState<OptionsSearch[]>([]);
    const [departments, setDepartments] = useState<OptionsSearch[]>([]);
    const [subdepartments, setSubDepartments] = useState<OptionsSearch[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const { t } = useTranslation();

    const session = useSession();

    const getUserDeptSub = async ({ companyId, type }: DataProps) => {
        if (companyId === "-1") {
            setUsers([]);
            setDepartments([]);
            setSubDepartments([]);

            return;
        }
        if (type === "MYTASKS") {
            getUser(Number(companyId));
            return;
        }

        getDeptSubdept(Number(companyId));
    };

    const getUser = async (companyId: number) => {
        setIsLoading(true);
        const { data, error, status } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: fillTaskInstanceExtraParams({
                supervisorId: session?.user.id,
                companyId,
            }),
        });

        if (!status()) {
            setError(t(TranslationCommon.SOMETHING_WENT_WRONG));
            setIsLoading(false);
            return;
        }

        setUsers([
            {
                value: session?.user.id.toString() || "",
                label: `${session?.user.firstName} ${session?.user.lastName}`,
            },
            ...data,
        ]);
        setDepartments([]);
        setSubDepartments([]);
        setIsLoading(false);
    };

    const getDeptSubdept = async (companyId: number) => {
        setIsLoading(true);
        const departmentsQuery = SegSelectorService.GetDepartmentNamesWithIds({
            extraParams: fillTaskInstanceExtraParams({
                supervisorId: session?.user.id,
                companyId,
            }),
        });

        const subdepartmentsQuery = SegSelectorService.GetSubDepartmentNamesWithIds({
            extraParams: fillTaskInstanceExtraParams({
                supervisorId: session?.user.id,
                companyId,
            }),
        });

        Promise.all([departmentsQuery, subdepartmentsQuery]).then(([departmentsSr, subdepartmentsSr]) => {
            if (!departmentsSr.status() && !subdepartmentsSr.status()) {
                setError(t(TranslationCommon.SOMETHING_WENT_WRONG));
                setIsLoading(false);
                return;
            }

            setUsers([]);
            setDepartments(departmentsSr.data);
            setSubDepartments(subdepartmentsSr.data);
            setIsLoading(false);
        });
    };

    return {
        getUserDeptSub,
        isLoading,
        error,
        users,
        departments,
        subdepartments,
    };
};
