import { FC } from "react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { AriaLabels } from "app/translation/translationKeys";
import { CollapsableBorder } from "../types";

type CollapsableTitleProps = {
    title: string;
    onClick: (value: boolean) => void;
    open: boolean;
    isCollapsable?: boolean;
    border?: CollapsableBorder;
};

export const CollapsableTitle: FC<CollapsableTitleProps> = ({
    title,
    onClick,
    open,
    isCollapsable = true,
    border = "bottom",
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={`collapsableTitle ${
                isCollapsable ? "" : "collapsableTitle--noCollapsable"
            } collapsableTitle--${border}`}
            onClick={() => isCollapsable && onClick(!open)}
            aria-label={t(AriaLabels.AL_COLLAPSABLE_TITLE)}
        >
            <h2 className="collapsableTitle__text">{title}</h2>
            {isCollapsable && (
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`collapsableTitle__icon${open ? "--close" : ""}`}
                    aria-label={t(AriaLabels.AL_COLLAPSABLE_TITLE_ICON)}
                />
            )}
        </div>
    );
};
