import { FC } from "react";
import { useTaskBelongs } from "./hooks";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import Spinner from "app/components_v2/Spinner/Spinner";
import { useSession } from "app/hooks";
import { CustomErrorTaskBelongsModel, EditDataModel, TaskBelongsModel } from "app/models/02-TAR/Task/EditTask";
import { hasPermissionToEdit, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { TaskTranslations, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import {
    SelectCompany,
    SelectDepartments,
    SelectSubDepartments,
    SelectUser,
} from "../../../WizardTasks/Step2/components";
import { SelectWorkingPosition } from "../../../WizardTasks/Step2/components/SelectWorkingPosition";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";

export const TaskBelongs: FC<EditDataModel<TaskBelongsModel, CustomErrorTaskBelongsModel>> = ({
    companyForTask,
    DepartmentForTask,
    SubDepartementForTask,
    onChange,
    userForTask,
    companyId,
    departmentId,
    customError,
    isEnabledUserDept,
    setEnableUserDept,
    activeReassignedTo,
    onCancelReasign,
    isEditing,
    companyValues,
    workingPositionForTask,
    isDisabled,
}) => {
    const {
        usersValues,
        departmentsValues,
        subDepartmentsValues,
        enableUser,
        enableSubdept,
        isLoading,
        isSubDepartmentLoading,
        changeUserSelected,
        changeDepartmentSelected,
        changeSubDepartmentSelected,
        changeCompanySelected,
        workingPositionValues,
        enableWP,
        changeWPSelected,
    } = useTaskBelongs(
        companyForTask,
        SubDepartementForTask,
        DepartmentForTask,
        userForTask,
        workingPositionForTask,
        onChange,
        companyId,
        departmentId,
        setEnableUserDept,
        companyValues
    );

    const session = useSession();
    const { t } = useTranslation();

    return (
        <div className="taskBelongs">
            <WhiteBoxCollapsable
                whiteBoxOptions={{ fullWidth: true }}
                collapsableOptions={{
                    title:
                        activeReassignedTo && isEditing
                            ? `${t(TranslationKeys.TASK_REASIGNED_TO)}: ${activeReassignedTo.reassignedToName}`
                            : t(TaskTranslations.TASK_ASSIGNED_TITLE),
                    border: "none",
                }}
            >
                <div className="editTask__column">
                    {isLoading ? (
                        <div className="editTask__spinner">
                            <Spinner />
                        </div>
                    ) : !isLoading &&
                      activeReassignedTo &&
                      isEditing &&
                      hasPermissionToView(session, SecScreen.REASIGN_TASK) ? (
                        <p
                            className="edit_task_column__cancelReasign"
                            onClick={() => {
                                if (isDisabled) return;
                                onCancelReasign();
                            }}
                        >
                            {t(TranslationKeys.CANCEL_REASIGNATION)}
                        </p>
                    ) : (
                        <>
                            <SelectCompany
                                changeCompanySelected={changeCompanySelected}
                                value={companyForTask.value}
                                options={companyValues}
                                customError={customError.companyForTask}
                                disabled={isDisabled}
                            />
                            <div
                                className={`editTask__column__radio ${
                                    isEnabledUserDept.subdepartment ? "isEnabled" : "isDisabled"
                                }`}
                            >
                                <SelectDepartments
                                    changeDepartmentSelected={changeDepartmentSelected}
                                    options={departmentsValues}
                                    value={DepartmentForTask.value}
                                    customError={customError.DepartmentForTask}
                                    isDisabled={isDisabled}
                                />
                            </div>
                            {departmentId !== 0 && (
                                <>
                                    {companyForTask.worksWithQr && !!customError.userOrQRForTask.length && (
                                        <ErrorMessage errorMessage={customError.userOrQRForTask} />
                                    )}
                                    <div
                                        className={`editTask__column__radio ${
                                            isEnabledUserDept.user ? "isEnabled" : "isDisabled"
                                        }`}
                                        onClick={() => {
                                            if (isDisabled) return;
                                            hasPermissionToEdit(SecScreen.TASK) && enableUser();
                                        }}
                                    >
                                        <SelectUser
                                            changeUserSelected={changeUserSelected}
                                            value={userForTask.value}
                                            options={usersValues.sort((userA, userB) =>
                                                userA.value.localeCompare(userB.value)
                                            )}
                                            isEnabled={!isDisabled ? !isEnabledUserDept.user : true}
                                            customError={customError.userForTask}
                                            isDisabled={isDisabled}
                                            optional={!companyForTask.worksWithQr}
                                        />
                                    </div>
                                    {isSubDepartmentLoading ? (
                                        <div className="editTask__spinner">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <>
                                            {!companyForTask.worksWithQr && (
                                                <div
                                                    className={`editTask__column__radio ${
                                                        isEnabledUserDept.subdepartment ? "isEnabled" : "isDisabled"
                                                    }`}
                                                    onClick={() => {
                                                        if (isDisabled) return;
                                                        enableSubdept();
                                                    }}
                                                >
                                                    <SelectSubDepartments
                                                        value={SubDepartementForTask.value}
                                                        options={subDepartmentsValues}
                                                        changeSubDepartmentSelected={changeSubDepartmentSelected}
                                                        isEnabled={
                                                            !isDisabled ? !isEnabledUserDept.subdepartment : true
                                                        }
                                                        isDisabled={isDisabled}
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className={`editTask__column__radio ${
                                                    isEnabledUserDept.workingPosition ? "isEnabled" : "isDisabled"
                                                }`}
                                                onClick={() => {
                                                    if (isDisabled) return;
                                                    enableWP();
                                                }}
                                            >
                                                <SelectWorkingPosition
                                                    value={workingPositionForTask.value}
                                                    options={workingPositionValues}
                                                    changeWorkingPositionelected={changeWPSelected}
                                                    isEnabled={!isDisabled ? !isEnabledUserDept.workingPosition : true}
                                                    isDisabled={isDisabled}
                                                    optional={!companyForTask.worksWithQr}
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </WhiteBoxCollapsable>
        </div>
    );
};
