import { FC } from "react";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { faArrowTrendDown, faArrowTrendUp } from "@fortawesome/pro-solid-svg-icons";
import { StarAndValorationSkeleton } from "app/components_v2/__skelletons/StarAndValorationSkeleton/StarAndValorationSkeleton";
import { UserValoration } from "app/components_v2/UserValoration/UserValoration";
import { Badge } from "app/components_v2/Badge/Badge";
import { faArrowRightLong } from "@fortawesome/pro-regular-svg-icons";

type StarRatingBadgeProps = {
    starRatingAVG?: number;
    trend?: number | null;
    isLoading?: boolean;
    total?: number;
    size?: "md" | "xl";
    showFullRatingText?: boolean;
    direction?: "col" | "row";
    startSize?: "md" | "xs";
    justifyContent?: "flex-start" | "flex-end";
    onClick?: () => void;
};

const ROUNDED = 10;

export const StarRatingBadge: FC<StarRatingBadgeProps> = ({
    starRatingAVG,
    trend,
    isLoading,
    total,
    size,
    showFullRatingText = true,
    direction = "col",
    startSize = "md",
    justifyContent,
    onClick,
}) => {
    const { t } = useTranslation();
    const trendFormatted = trend ? Math.round(trend * ROUNDED) / ROUNDED : null;
    const starRatingAVGFormatted = starRatingAVG ? Math.round(starRatingAVG * ROUNDED) / ROUNDED : 0;

    if (!starRatingAVG) return null;
    return (
        <>
            {isLoading ? (
                <StarAndValorationSkeleton />
            ) : (
                <div
                    className={`starRatingBadge${direction === "col" ? "--col" : "--row"} ${
                        justifyContent ? `starRatingBadge--${justifyContent}` : ""
                    } ${!!onClick ? "pointer" : ""}`}
                    onClick={onClick}
                >
                    <UserValoration
                        valoration={starRatingAVGFormatted}
                        total={total ? String(total) : undefined}
                        size={size}
                        startSize={startSize}
                        justify="start"
                    />
                    <div className="starRatingBadge__badge">
                        {trendFormatted != null && (
                            <Badge
                                theme="dark"
                                title={`${
                                    trendFormatted > 0
                                        ? showFullRatingText
                                            ? t(TranslationKeys.BETTER_THAN_LAST_MONTH).replace(
                                                  "{1}",
                                                  `${trendFormatted}%`
                                              )
                                            : `${trendFormatted}%`
                                        : trendFormatted < 0
                                        ? showFullRatingText
                                            ? t(TranslationKeys.WORSE_THAN_LAST_MONTH).replace(
                                                  "{1}",
                                                  `${trendFormatted}%`
                                              )
                                            : `${trendFormatted}%`
                                        : showFullRatingText
                                        ? t(TranslationKeys.SAME_THAN_LAST_MONTH)
                                        : "0%"
                                }`}
                                icon={
                                    trendFormatted > 0
                                        ? faArrowTrendUp
                                        : trendFormatted < 0
                                        ? faArrowTrendDown
                                        : faArrowRightLong
                                }
                                iconPosition="left"
                                variant={trendFormatted > 0 ? "green" : trendFormatted < 0 ? "red" : "grey"}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
