import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
import { useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { editCompanyFormToCompanyModel } from "app/mappers/EditCompanyFormToCompany";
import {
    CloseCompanyTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { CompanyService, GroupCompanyService } from "app/services";
import { validateEmail } from "app/helpers/__validates/validateCompanyForm";
import { hasPermissionToDelete, hasPermissionToEdit, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { CompanyModelToEditCompanyForm } from "app/mappers/CompanyModelToEditCompanyForm";
import { EditCompanyFormModal, ErrorEditCompanyFormModal } from "./types";
import { CompanyModel } from "app/models/01-SEG/Company/CompanyModels";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { MAX_STRING_LENGTH_255, PrivatePaths, SecScreen } from "app/shared/Constants";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import Departments from "../Department/Departments";
import { EditCompanyForm } from "./EditCompanyForm/EditCompanyForm";
import { hasOverlappingRanges } from "app/helpers/__validates/validateCompanyForm";
import { CloseCompanyModel } from "app/models/01-SEG/CloseCompany/CloseCompanyModel";

const INITIAL_FORM_VALUES: EditCompanyFormModal = {
    name: "",
    address: "",
    city: "",
    postalCode: "",
    companyGroupId: undefined,
    image: "",
    closeCompanies: null,
    worksWithQR: false,
};
const INITIAL_ERROR_FORM_VALUES: ErrorEditCompanyFormModal = {
    errorName: "",
    errorPhone: "",
    errorNif: "",
    errorEmail: "",
    errorLegalName: "",
    errorCompanyTypeId: "",
    errorAddress: "",
    errorCity: "",
    errorPostalCode: "",
    errorCompanyGroupId: "",
    errorImage: "",
    errorCloseCompanies: "",
};

export const EditCompany = () => {
    const { t } = useTranslation();
    const { companyId } = useParams();
    const session = useSession();
    const { handleToast } = useToast();
    const nav = useNavigate();

    useTitle(t(TranslationTitles.COMPANY_EDIT_PAGE));

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isCompanySaving, setisCompanySaving] = useState<boolean>(false);
    const [isCompanyDeleting, setIsCompanyDeleting] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [company, setCompany] = useState<CompanyModel>();
    const [prevCompany, setPrevCompany] = useState<CompanyModel>();
    const [formValues, setFormValues] = useState<EditCompanyFormModal>(INITIAL_FORM_VALUES);
    const [companyGroupOptions, setCompanyGroupOptions] = useState<OptionsSearch[]>([]);
    const [errorFormValues, setErrorFormValues] = useState<ErrorEditCompanyFormModal>(INITIAL_ERROR_FORM_VALUES);

    const handleInputChange = (values: Partial<EditCompanyFormModal>) =>
        setFormValues((prev) => ({ ...prev, ...values }));

    const handleErrorChange = (values: Partial<ErrorEditCompanyFormModal>) =>
        setErrorFormValues((prev) => ({ ...prev, ...values }));

    const getCompany = async () => {
        if (!companyId) return;

        setIsLoading(true);
        const resGroups = await GroupCompanyService.GetData({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId || 0}`,
        });
        const companySr = await CompanyService.GetOne(companyId, session?.user.customerInstanceId || 0);

        if (resGroups.status() && companySr.status()) {
            const companyData = CompanyModelToEditCompanyForm(
                companySr.data,
                companySr.data.logoFileURL,
                companySr.data.closeCompanies?.some(({ closeDate, openDate }) => !!closeDate && !!openDate)
            );

            handleInputChange(companyData);

            setCompany(companySr.data);
            setPrevCompany(companySr.data);
            setCompanyGroupOptions(resGroups.data.list.map(({ id, name }) => ({ value: String(id), label: name })));

            setIsLoading(false);
        } else {
            nav(`/${PrivatePaths.CENTERS_MANAGEMENT}`);
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getCompany();
    }, []);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const handleOpenCloseModal = () => setIsCancelModalOpen(true);
    const handleCloseCloseModal = () => setIsCancelModalOpen(false);

    const showCloseModal = () => {
        if (!prevCompany) return;
        !isEqual(CompanyModelToEditCompanyForm(prevCompany), formValues) && handleOpenCloseModal();
    };

    const handleDelete = async () => {
        if (!companyId) return;

        setIsCompanyDeleting(true);
        const sr = await CompanyService.Delete(parseInt(companyId));
        if (sr.status()) nav(PrivatePaths.CENTERS_MANAGEMENT);
        else
            handleToast({
                title: t(TranslationErrors.GENERIC_ERROR),
            });
        setIsCompanyDeleting(false);
    };

    const validate = () => {
        let error = false;
        handleErrorChange(INITIAL_ERROR_FORM_VALUES);
        const { name, closeCompany, closeCompanies } = formValues;

        if (!name.length) {
            error = true;
            handleErrorChange({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
        }

        // Check CloseCompanies empty data
        const closeCompanies2 = closeCompanies?.every(({ closeDate, openDate }) => !closeDate && !openDate)
            ? null
            : closeCompanies;
        handleInputChange({ closeCompanies: closeCompanies2 });

        if (closeCompany) {
            if (closeCompanies2?.some(({ closeDate, openDate }) => !closeDate || !openDate)) {
                error = true;
                handleErrorChange({ errorCloseCompanies: t(CloseCompanyTranslations.SOME_EMPTY_VALUES) });
            }

            if (
                closeCompanies2 != null &&
                closeCompanies2.every(({ closeDate, openDate }) => !!closeDate && !!openDate) &&
                hasOverlappingRanges(closeCompanies2)
            ) {
                error = true;
                handleErrorChange({ errorCloseCompanies: t(CloseCompanyTranslations.OVERLAPING_DATES_NOT_PERMITTED) });
            }
        }

        return error;
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!formValues) return;
        if (!companyId) return;
        if (!company) return;
        if (validate()) return;
        setisCompanySaving(true);
        const companyMapped = editCompanyFormToCompanyModel({
            formValues,
            customerInstanceId: company.customerInstanceId,
            id: Number(companyId),
            isActive: company.isActive,
            logoId: prevCompany?.logoFileId,
        });
        const { status } = await CompanyService.Edit(companyMapped, companyId);

        if (!status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setisCompanySaving(false);
            return;
        }
        handleToast({
            title: t(TranslationModals.SUCCESS_EDIT_TITLE_TOAST),
            type: "alert",
            variant: "success",
        });
        setPrevCompany(companyMapped);
        setisCompanySaving(false);
    };

    const handleCancel = () => {
        handleCloseCloseModal();
        if (!prevCompany) return;

        const companyData = CompanyModelToEditCompanyForm(
            prevCompany,
            prevCompany.logoFileURL,
            validateDates(prevCompany.closeCompanies, !!prevCompany.closeCompanies?.length)
        );
        setFormValues(companyData);
    };

    const validateDates = (closeCompanies: CloseCompanyModel[] | null, isClosedCompany?: boolean) => {
        if (!isClosedCompany) return false;

        const today = new Date().setHours(0, 0, 0, 0);
        return closeCompanies?.some(
            ({ closeDate, openDate }) =>
                !!closeDate &&
                !!openDate &&
                today >= new Date(closeDate).setHours(0, 0, 0, 0) &&
                today <= new Date(openDate).setHours(0, 0, 0, 0)
        );
    };

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.EDIT_COMPANY_TAB_INFO),
            onClick: () => setCurrentTab(0),
            children: (
                <EditCompanyForm
                    companyGroupOptions={companyGroupOptions}
                    formValues={formValues}
                    onInputChange={handleInputChange}
                    onErrorChange={handleErrorChange}
                    errorFormValues={errorFormValues}
                    onSubmit={handleSubmit}
                />
            ),
            type: "dark",
        },
        {
            text: t(TranslationKeys.EDIT_COMPANY_TAB_INTERNAL_ORG),
            onClick: () => setCurrentTab(1),
            children: <>{company && <Departments companyId={company.id} />}</>,
            type: "dark",
            hidden: !hasPermissionToView(session, SecScreen.TEAM_MANAGEMENT),
        },
    ];

    const getDescriptionDeleteModal = (): string => {
        return company?.isRelatedWithTasks
            ? t(TranslationKeys.EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY)
            : t(TranslationKeys.EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_NO_RELATED);
    };

    return (
        <>
            {isModalOpen && (
                <ConfirmModal
                    onClose={handleCloseModal}
                    onConfirm={handleDelete}
                    isLoading={isCompanyDeleting}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={getDescriptionDeleteModal()}
                    title={`${t(TranslationKeys.DELETE_COMPANY_TITLE)}`}
                    type="delete"
                />
            )}
            {isCancelModalOpen && (
                <ConfirmModal
                    onClose={handleCloseCloseModal}
                    onConfirm={handleCancel}
                    isLoading={isCompanyDeleting}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationKeys.UNDO_CHANGES)}
                />
            )}
            <FormLayout
                isLoading={isLoading}
                noHeight={currentTab === 1}
                variant={currentTab === 0 ? "gray" : "white"}
                headerOptions={{
                    isClosed: validateDates(formValues.closeCompanies, formValues.closeCompany),
                    isCompanyEdit: true,
                    buttonLabel: t(TranslationKeys.DELETE_COMPANY_TITLE),
                    showDeleteButton: hasPermissionToDelete(SecScreen.CENTERS_MANAGEMENT),
                    type: "delete",
                    isLoading: isLoading,
                    onClick: handleOpenModal,
                    title: company?.name || "",
                    tabsOptions: {
                        tabs: <Tabs tabs={tabs} currentTab={currentTab} hideChildren={true} />,
                    },
                    badge: {
                        isVisible: true,
                    },
                }}
                relative
                footer={
                    currentTab === 0 &&
                    hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT) && (
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationCommon.SAVE),
                                form: "editCompanyForm",
                                type: "submit",
                            }}
                            closeButton={{
                                text: t(TranslationCommon.CANCEL),
                                onClick: showCloseModal,
                                buttonType: "tertiary",
                                disabled: !prevCompany
                                    ? true
                                    : isEqual(
                                          CompanyModelToEditCompanyForm(
                                              prevCompany,
                                              undefined,
                                              validateDates(
                                                  prevCompany.closeCompanies,
                                                  !!prevCompany.closeCompanies?.length
                                              )
                                          ),
                                          formValues
                                      ),
                            }}
                            loading={isCompanySaving}
                        />
                    )
                }
            >
                {tabs[currentTab].children}
            </FormLayout>
        </>
    );
};
