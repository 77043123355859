import { CloseCompanyModel } from "app/models/01-SEG/CloseCompany/CloseCompanyModel";
import { RegexPatterns } from "app/shared/Constants";
import { getDateFormatted } from "../Date.utilities";

export const validatePhoneNumber = (phone: number | string) => {
    if (typeof phone === "number") phone = phone.toString();

    return RegexPatterns.phoneNumber.test(phone);
};

export const validateEmail = (email: string) => RegexPatterns.mail.test(email);

export const hasOverlappingRanges = (closeCompanies: CloseCompanyModel[]): boolean => {
    // First make all dates have same structure and sort by closeDate
    const closeCompaniesDates = closeCompanies
        .map(({ closeDate, openDate }) => {
            if (!closeDate || !openDate) return;
            return {
                closeDate: getDateFormatted(new Date(closeDate)),
                openDate: getDateFormatted(new Date(openDate)),
            };
        })
        .sort((a, b) => (!!a && !!b ? new Date(a.closeDate).getTime() - new Date(b.closeDate).getTime() : 0));

    // Check overlapment
    for (let i = 0; i < closeCompaniesDates.length - 1; i++) {
        const currentRange = closeCompaniesDates[i];
        const nextRange = closeCompaniesDates[i + 1];

        // If has overlapment
        if (!!currentRange?.openDate && !!nextRange?.closeDate && currentRange.openDate >= nextRange.closeDate)
            return true;
    }

    return false;
};
