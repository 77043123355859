import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseAuditGroupCheckListItemService from "app/services/05-QUA/BaseAuditGroupCheckListItemService";
import { TranslationErrors } from "app/translation/translationKeys";
import {
    mapBaseAuditGroupCheckListItemsToOptions,
    mapBaseAuditGroupCheckListItemToAuditGroupCheckListItem,
} from "../helpers/auditGroupCheckListItemHelper";
import { BaseAuditGroupCheckListItemModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemModels/BaseAuditGroupCheckListItemModel";
import { AuditGroupCheckListModel } from "app/models/05-QUA/AuditModels/AuditGroupCheckListModels";
import { AuditGroupCheckListItemModel } from "app/models/05-QUA/AuditModels/AuditGroupCheckListItemModels";
import { OptionsSearch } from "app/models/FormComponentsModel";

export const useAuditGroupCheckListModal = (
    data: AuditGroupCheckListModel,
    onSubmit: (checkList: AuditGroupCheckListModel) => void
) => {
    const { t } = useTranslation();

    const {
        id: checkListId,
        name: checkListName,
        auditGroupCheckListItems: checkListItems,
        fK_AuditGroup,
        checkListItemCount,
    } = data;

    const [baseAuditGroupCheckListItems, setBaseAuditGroupCheckListItems] = useState<
        BaseAuditGroupCheckListItemModel[]
    >([]);
    const [baseAuditGroupCheckListItemOptions, setBaseAuditGroupCheckListItemOptions] = useState<OptionsSearch[]>([]);
    const [auditGroupCheckListItemSelectedOptions, setAuditGroupCheckListItemSelectedOptions] = useState<
        AuditGroupCheckListItemModel[]
    >(checkListItems || []);
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState(checkListName || "");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [itemErrorMessage, setItemErrorMessage] = useState<string>("");

    const getBaseAuditGroupCheckListItemOptions = async () => {
        setIsLoading(true);

        const { data, status } = await BaseAuditGroupCheckListItemService.GetData();

        if (!status()) {
            setBaseAuditGroupCheckListItems([]);
            setBaseAuditGroupCheckListItemOptions([]);
            setIsLoading(false);
        }
        setBaseAuditGroupCheckListItems(data.list);
        if (!!checkListItems.length) {
            const filteredOptions = data.list.filter(
                (item) =>
                    !checkListItems.some((checkListItem) => checkListItem.fK_BaseAuditGroupCheckListItem === item.id)
            );
            setBaseAuditGroupCheckListItemOptions(mapBaseAuditGroupCheckListItemsToOptions(filteredOptions));
        } else setBaseAuditGroupCheckListItemOptions(mapBaseAuditGroupCheckListItemsToOptions(data.list));
        setIsLoading(false);
    };

    const handleChangeName = (name: string) => {
        setName(name);
        setErrorMessage("");
    };

    const handleChange = ({ value }: OptionsSearch) => {
        const selected = baseAuditGroupCheckListItems.find(({ id }) => id === Number(value));
        if (!selected) return;
        setAuditGroupCheckListItemSelectedOptions((prev) => [
            ...prev,
            mapBaseAuditGroupCheckListItemToAuditGroupCheckListItem(selected),
        ]);

        // Delete from options
        const filteredOptions = baseAuditGroupCheckListItemOptions.filter((item) => item.value !== value);
        setBaseAuditGroupCheckListItemOptions(filteredOptions);

        // Delete item error
        setItemErrorMessage("");
    };

    const handleDeleteItem = (id: number) => {
        const selectedAuditGroupCheckListItem = auditGroupCheckListItemSelectedOptions.find(
            (item) => item.fK_BaseAuditGroupCheckListItem === id
        );
        if (!selectedAuditGroupCheckListItem) return;

        // Map db options to mark isDeleted
        const updatedSelectedOptions = auditGroupCheckListItemSelectedOptions.map((item) => {
            if (item.fK_BaseAuditGroupCheckListItem === id && item.id !== 0) return { ...item, isDeleted: true };
            return item;
        });
        setAuditGroupCheckListItemSelectedOptions(updatedSelectedOptions);

        // Check not added to db options
        if (selectedAuditGroupCheckListItem.id === 0) {
            const filteredNotDbOptions = updatedSelectedOptions.filter(
                (item) => item.fK_BaseAuditGroupCheckListItem !== id
            );
            setAuditGroupCheckListItemSelectedOptions(filteredNotDbOptions);
        }

        // Add deleted item to options list
        const selected = baseAuditGroupCheckListItems.find((item) => item.id === id);
        if (!selected) return;
        setBaseAuditGroupCheckListItemOptions((prev) => [
            ...prev,
            { label: selected.name, value: String(selected.id) },
        ]);
    };

    const handleChangePonderationLevel = (ponderationLevel: string, id: number) => {
        const updatedSelectedOptions: AuditGroupCheckListItemModel[] = auditGroupCheckListItemSelectedOptions.map(
            (item) => {
                if (item.fK_BaseAuditGroupCheckListItem !== id) return item;
                return { ...item, ponderationLevel: Number(ponderationLevel) };
            }
        );
        setAuditGroupCheckListItemSelectedOptions(updatedSelectedOptions);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!validate()) return;

        onSubmit({
            id: checkListId,
            name,
            checkListItemCount,
            auditGroupCheckListItems: auditGroupCheckListItemSelectedOptions.map((item) => ({
                ...item,
                fK_AuditGroupCheckList: checkListId,
            })),
            fK_AuditGroup,
        });
    };

    const validate = (): boolean => {
        setErrorMessage("");
        if (!name.length) {
            setErrorMessage(t(TranslationErrors.AUDIT_GROUP_CHECKLIST_NAME_ERROR));
            return false;
        }
        setItemErrorMessage("");
        if (!auditGroupCheckListItemSelectedOptions.filter(({ isDeleted }) => !isDeleted).length) {
            setItemErrorMessage(t(TranslationErrors.AUDIT_GROUP_CHECKLIST_ITEM_ERROR));
            return false;
        }

        return true;
    };

    useEffect(() => {
        getBaseAuditGroupCheckListItemOptions();
    }, []);

    return {
        baseAuditGroupCheckListItems,
        baseAuditGroupCheckListItemOptions,
        auditGroupCheckListItemSelectedOptions,
        isLoading,
        errorMessage,
        itemErrorMessage,
        name,
        handleChangeName,
        handleChange,
        handleChangePonderationLevel,
        handleDeleteItem,
        handleSubmit,
    };
};
