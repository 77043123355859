import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { TranslationKeys, CloseCompanyTranslations } from "app/translation/translationKeys";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { CloseCompanyModel } from "app/models/01-SEG/CloseCompany/CloseCompanyModel";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { Label, Switch, DateRangePicker } from "app/components_v2/__inputs";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";

type CloseCompaniesProps = {
    closeCompany?: boolean;
    closeCompanies: CloseCompanyModel[] | null;
    onSwitchClick: (switchValue: boolean) => void;
    onClosingDates: (dates: Date[] | undefined[], index: number) => void;
    onAddNewRangeHour: () => void;
    onDeleteNewRangeHour: (index: number) => void;
    errorMessage: string;
};

export const CloseCompanyDates: FC<CloseCompaniesProps> = ({
    closeCompany,
    closeCompanies,
    onSwitchClick,
    onClosingDates,
    onAddNewRangeHour,
    onDeleteNewRangeHour,
    errorMessage,
}) => {
    const { t } = useTranslation();

    return (
        <div className="closeCompanyDates">
            <Label label={`${t(TranslationKeys.CLOSE_COMPANY)} (${t(TranslationKeys.INPUT_OPTIONAL)})`} />
            <div className="closeCompanyDates__dates">
                <div className="closeCompanyDates__dates__switch">
                    <Switch
                        onChange={onSwitchClick}
                        checked={!!closeCompany}
                        disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                    />
                </div>
                {closeCompany && (
                    <div className="closeCompanyDates__dates__container">
                        {closeCompanies != null &&
                            closeCompanies.map(({ closeDate, openDate }, i) => (
                                <div key={i} className="closeCompanyDates__dates__container__select">
                                    <DateRangePicker
                                        onChange={(dates) => onClosingDates(dates, i)}
                                        startDate={closeDate ? new Date(closeDate) : undefined}
                                        endDate={openDate ? new Date(openDate) : undefined}
                                        range={true}
                                        disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                                    />
                                    {
                                        <FontAwesomeIcon
                                            icon={faCircleXmark}
                                            className="deleteInputIcon"
                                            onClick={() => onDeleteNewRangeHour(i)}
                                        />
                                    }
                                </div>
                            ))}
                        {(closeCompanies == null || (closeCompanies != null && closeCompanies.length < 3)) && (
                            <div className="closeCompanyDates__dates__button">
                                <AddNewButton onClick={onAddNewRangeHour}>
                                    {t(CloseCompanyTranslations.ADD_NEW_CLOSE_DATE_RANGE)}
                                </AddNewButton>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {!!errorMessage.length && <ErrorMessage errorMessage={errorMessage} />}
        </div>
    );
};
