import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import TaskService from "app/services/02-TAR/TaskService";
import { faCirclePlus, faEllipsisVertical, faMagnifyingGlass, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { Section, SectionsShowProp } from "../Section/Section";
import Table from "app/components_v2/Table/Table";
import { ColumnsType } from "app/components_v2/Table/types";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { TabPropsReduced } from "app/components_v2/Tabs";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { Badge } from "app/components_v2/Badge/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faComment, faFileExclamation, faPen, faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { TaskListModel } from "app/models/02-TAR/Task/TaskListModel";

export type Backtype = {
    data1: string;
    data2: string;
    data3: string;
    data4: string;
    data5: string;
};

const values: Backtype[] = [
    {
        data1: "Lorem ipsum dolor sit amet, consectetur adipiscing elitt et sodales tortor.",
        data2: "Juan Carlos Domingo Salvador",
        data3: "09:30, 12:00 - 13:30, 17:45",
        data5: "data5",
        data4: "data4",
    },
    {
        data1: "Lorem ipsum dolor sit amet, consectetur adipiscing elitt et sodales tortor.",
        data2: "Juan Carlos Domingo Salvador",
        data3: "09:30, 12:00 - 13:30, 17:45",
        data5: "data5",
        data4: "data4",
    },
    {
        data1: "Lorem ipsum dolor sit amet, consectetur adipiscing elitt et sodales tortor.",
        data2: "Juan Carlos Domingo Salvador",
        data3: "09:30, 12:00 - 13:30, 17:45",
        data5: "data5",
        data4: "data4",
    },
    {
        data1: "Lorem ipsum dolor sit amet, consectetur adipiscing elitt et sodales tortor.",
        data2: "Juan Carlos Domingo Salvador",
        data3: "09:30",
        data5: "data5",
        data4: "data4",
    },
    {
        data1: "Lorem ipsum dolor sit amet, consectetur adipiscing elitt et sodales tortor.",
        data2: "Juan Carlos Domingo Salvador",
        data3: "09:30, 12:00 - 13:30, 17:45",
        data5: "data5",
        data4: "data4",
    },
    {
        data1: "Lorem ipsum dolor sit amet, consectetur adipiscing elitt et sodales tortor.",
        data2: "Juan Carlos Domingo Salvador",
        data3: "09:30, 12:00 - 13:30, 17:45",
        data5: "data5",
        data4: "data4",
    },
    {
        data1: "Lorem ipsum dolor sit amet, consectetur adipiscing elitt et sodales tortor.",
        data2: "Juan Carlos Domingo Salvador",
        data3: "09:30, 12:00 - 13:30, 17:45",
        data5: "data5",
        data4: "data4",
    },
    {
        data1: "data oeeo",
        data2: "Juan Carlos Domingo Salvador",
        data3: "09:30, 12:00 - 13:30, 17:45",
        data5: "data5",
        data4: "data4",
    },
    {
        data1: "data oeeo",
        data2: "Juan Carlos Domingo Salvador",
        data3: "09:30, 12:00 - 13:30, 17:45",
        data5: "data5",
        data4: "data4",
    },
];

const TableSection: FC<SectionsShowProp> = ({ show }) => {
    const { t } = useTranslation();

    const [data, setData] = useState<Backtype[]>([]);
    const [dataWithFetch, setDataWithFetch] = useState<TaskListModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);

    const columnsIsmael: ColumnsType<TaskListModel>[] = [
        {
            dataIndex: "name",
            render: (record) => <CellTitle title={record.name || ""} />,
            label: t(TranslationKeys.DETAIL),
        },
        {
            dataIndex: "name",
            render: (record) => <CellTitle title={record.name || ""} />,
            label: t(TranslationKeys.DETAIL),
        },
        {
            dataIndex: "name",
            render: (record) => <CellTitle title={record.name || ""} />,
            label: t(TranslationKeys.DETAIL),
        },
        {
            dataIndex: "name",
            render: (record) => <CellTitle title={record.name || ""} />,
            label: t(TranslationKeys.DETAIL),
        },
    ];
    const fetch = async () => {
        setIsLoading(true);

        const { data: dataList, status } = await TaskService.GetData({
            pageSize: 10,
            pageIndex: pageIndex - 1,
        });

        if (!status()) {
            console.log("mal");
            setIsLoading(false);
            return;
        }
        setDataWithFetch(dataList.list);
        setTotal(dataList.count);
        setIsLoading(false);
    };

    useEffect(() => {
        setData(values);
    }, []);

    const columns: ColumnsType<Backtype>[] = [
        {
            label: "Detalle",
            className: "test_col1",
            render: (value) => <CellTitle title={value.data1} />,
            sortedType: "default",
            dataIndex: "Detalle",
            onClick: (dataIndex) => console.log(dataIndex),
        },
        {
            label: "Responsable",
            className: "test_col2",
            render: (value) => <Avatar avatarName={value.data2} colorId={1} name={value.data2} size="xs" clamp />,
            sortedType: "asc",
            dataIndex: "Responsable",
            onClick: (dataIndex) => console.log(dataIndex),
        },
        {
            label: "Horas",
            className: "test_col3",
            render: (value) => <CellTitle title={value.data3} />,
            sortedType: "default",
            dataIndex: "Horas",
            onClick: (dataIndex) => console.log(dataIndex),
        },
        {
            label: "Frecuencia",
            className: "test_col4",
            dataIndex: "Frecuencia",
            render: (value) => <Badge title="Mensual" variant="grey" />,
        },
        {
            label: "",
            className: "test_col5",
            dataIndex: "test_col5",
            render: (value) => (
                <div className="test_col5__icons">
                    <FontAwesomeIcon icon={faComment} />
                    <FontAwesomeIcon icon={faFileExclamation} />
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                </div>
            ),
        },
        {
            label: "Acciones",
            className: "test_col6",
            dataIndex: "Acciones",
            render: (value) => (
                <div className="test_col6__actionButtons">
                    <div className="test_col6__actionButtons__button">
                        <FontAwesomeIcon icon={faClone} />
                    </div>
                    <div className="test_col6__actionButtons__button">
                        <FontAwesomeIcon icon={faPen} />
                    </div>
                </div>
            ),
        },
    ];
    const [currentTab, setCurrentTab] = useState<number>(0);
    const tabs: TabPropsReduced[] = [
        { onClick: setCurrentTab, text: "Usuarios", type: "dark" },
        { onClick: setCurrentTab, text: "Departamentos", type: "dark" },
        { onClick: setCurrentTab, text: "Subdepartamentos", type: "dark" },
    ];

    useEffect(() => {
        fetch();
    }, [pageIndex, currentTab]);
    const [isTableOpen, setIsTableOpen] = useState<boolean>(true);

    return (
        <Section title="Tables" show={show}>
            <div style={{ width: "100%" }}>
                <p>table:</p>
                <Table<Backtype>
                    cols={columns}
                    data={data}
                    isLoading={isLoading}
                    onChangePageIndex={setPageIndex}
                    pageIndex={pageIndex}
                    pageSize={10}
                    total={130}
                    onClickExportCsv={() => {}}
                    placeholder={<div>No hay datos</div>}
                />
            </div>
            <div style={{ width: "100%" }}>
                <p>table con fetch:</p>

                <div style={{ width: "100%" }}>
                    <TableCollapsable
                        cols={columnsIsmael}
                        data={dataWithFetch}
                        isLoading={isLoading}
                        onChangePageIndex={setPageIndex}
                        pageIndex={pageIndex}
                        pageSize={10}
                        total={total}
                        onClickExportCsv={() => {}}
                        tabs={tabs}
                        title="Grupo 1"
                        subTitle={`${total} tareas`}
                        currentTab={currentTab}
                        onClickCollapsable={() => setIsTableOpen((prev) => !prev)}
                        actionButtons={[
                            { icon: faCirclePlus, onClick: () => {} },
                            { icon: faEllipsisVertical, onClick: () => {} },
                            { icon: faMagnifyingGlass, onClick: () => {} },
                            { icon: faSliders, onClick: () => {} },
                        ]}
                        isOpen={isTableOpen}
                        placeholder={<div>No hay datos</div>}
                    />
                </div>
            </div>
            <div style={{ width: "100%" }}>
                <p>table sin datos:</p>
                <Table<Backtype>
                    cols={columns}
                    data={[]}
                    isLoading={isLoading}
                    onChangePageIndex={setPageIndex}
                    pageIndex={pageIndex}
                    pageSize={10}
                    total={0}
                    onClickExportCsv={() => {}}
                    placeholder={<div>No hay datos</div>}
                />
            </div>
        </Section>
    );
};

export default TableSection;
