import { useState, useEffect, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { validations } from "../models";
import {
    CustomMonthlyOption2,
    RecurrentTaskModel,
    RecurrentTaskOption2,
    Step4,
    taskEnds,
    taskHoursModel,
} from "app/models/02-TAR/TaskWizard";
import { getDayOfWeek, getMonthName, getWeekDay } from "app/helpers/Date.utilities";
import { isHoursOutOfRange, validateHours } from "app/helpers/__validates/validateTaskHours";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { days } from "../../../EditTask/utilities/initialValues";
import { TaskType } from "app/models/02-TAR/Task/TaskBody";

type useStepFourProps = {
    taskHours: taskHoursModel[];
    recurringTask: RecurrentTaskModel | null;
    onChange: (fields: Partial<Step4>) => void;
    taskStart: string;
    finish: taskEnds;
    taskType: TaskType;
    next: () => void;
};

export const useStepFour = ({
    onChange,
    recurringTask,
    taskHours,
    taskStart,
    finish,
    taskType,
    next,
}: useStepFourProps) => {
    const { t } = useTranslation();
    const weekTextOptions = [
        t(TranslationKeys.FIRST_DAY_WEEK),
        t(TranslationKeys.SECOND_DAY_WEEK),
        t(TranslationKeys.THIRD_DAY_WEEK),
        t(TranslationKeys.FOURTH_DAY_WEEK),
        t(TranslationKeys.FIFTH_DAY_WEEK),
    ];
    // Valores del select de la recurrencia

    const recurringTaskValuesConst: RecurrentTaskOption2[] = [
        { value: "", label: t(TranslationCommon.SELECT_OPTION), hidden: true },
        { value: "everyDay", label: t(TranslationKeys.EVERY_DAY) },
        { value: "everyWeek", label: t(TranslationKeys.EVERY_WEEK_DAY) },
        { value: "everyMonth", label: t(TranslationKeys.EVERY_MONTH_DAY) },
        { value: "annually", label: t(TranslationKeys.EVERY_YEAR_DAY_MONTH) },
        {
            value: "mondayToFriday",
            label: t(TranslationKeys.EVERY_LABORAL_DAYS),
        },
        { value: "custom", label: t(TranslationKeys.WIZARD_SELECT_CUSTOM) },
    ];

    // Valores del select cuando seleccionas custom
    // -> custom
    const options: OptionsSearch[] = [
        {
            value: "day",
            label:
                recurringTask && recurringTask.custom.repeatEvery > 1
                    ? t(TranslationKeys.DAYS)
                    : t(TranslationKeys.DAY),
        },
        {
            value: "week",
            label:
                recurringTask && recurringTask.custom.repeatEvery > 1
                    ? t(TranslationKeys.WEEKS)
                    : t(TranslationKeys.WEEK),
        },
        {
            value: "everyMonth",
            label:
                recurringTask && recurringTask.custom.repeatEvery > 1
                    ? t(TranslationKeys.MONTHS)
                    : t(TranslationKeys.MONTH),
        },
        {
            value: "everyYear",
            label:
                recurringTask && recurringTask.custom.repeatEvery > 1
                    ? t(TranslationKeys.YEARS)
                    : t(TranslationKeys.YEAR),
        },
    ];

    // Valores del select cuando seleccionas custom y el mes
    // -> custom
    //  -> Mes
    const weekOptions: CustomMonthlyOption2[] = [
        { value: "", label: t(TranslationCommon.SELECT_OPTION), hidden: true },
        {
            value: "everyMonthDayOfMonth",
            label: t(TranslationKeys.SPECIFIC_DAY),
        }, // 5
        {
            value: "everyMonthFirstDayOfWeek",
            label: weekTextOptions[getWeekDay(taskStart) - 1],
        }, // martes
    ];

    // Never -> No se acaba nunca
    // EL -> Dia que se acaba la tarea
    // After -> Repeticiones para acabar

    // Valores del select de la recurrencia
    const [recurentTaskSelectorValues, setRecurentTaskSelectorValues] =
        useState<RecurrentTaskOption2[]>(recurringTaskValuesConst);

    // Valores del select cuando seleccionas custom y el mes
    // -> custom
    //  -> Mes
    const [customMonthlySelectorValues, setCustomMonthlySelectorValues] = useState<CustomMonthlyOption2[]>(weekOptions);

    const [validations, setValidations] = useState<validations>({
        taskStart: "",
        taskHours: {
            value: "",
            errors: [],
        },
        finishEl: "",
        finishResp: "",
        customReps: "",
        customDaysBubles: "",
        customMonth: "",
        selectOption: "",
    });

    const [isValidating, setIsValidating] = useState<boolean>(false);
    const [showModalHoursOutOfTime, setShowModalHoursOutOfTime] = useState<boolean>(false);

    // Cuando añades la hora te comprueba si hay 3 o más
    // si la hay no te deja hacer nada
    // si no hay más de 2 te la añade
    const onAddNewHour = () => {
        if (taskHours.length >= 3) return;

        onChange({
            taskHours: [...taskHours, { hour: "", id: v4(), maxHour: "", type: "hour" }],
        });
    };

    const onAddNewRangeHour = () => {
        if (taskHours.length >= 3) return;
        onChange({
            taskHours: [...taskHours, { hour: "", id: v4(), maxHour: "", type: "range" }],
        });
    };

    const onDeleteHour = (id: string) => {
        onChange({
            taskHours: taskHours.filter((hour) => id !== hour.id),
        });
    };

    // Cuando borras o dejas sin valor el input de añadir horas
    // Si hay solo una hora pero vacía no hace nada
    // Si hay un input sin valor y das click fuera te lo elimina
    const onBlur = () => {
        if (taskHours.length <= 1) return;
        return onChange({
            taskHours: taskHours.filter(({ hour }) => hour !== ""),
        });
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsValidating(true);
        let check = true;
        const aux = { ...validations };
        // Check if start date is correct
        aux.taskStart = "";
        if (taskStart.length === 0) {
            aux.taskStart = t(TranslationCommon.INPUT_NOT_EMPTY);

            check = false;
        }

        // Check if task finish date is correct
        aux.finishEl = "";
        if (finish.checked === "el" && finish.value === "") {
            aux.finishEl = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        // Check if task ends repetitions are greater than 0
        aux.finishResp = "";
        if (finish.checked === "after" && typeof finish.value === "number" && finish.value <= 0) {
            aux.finishResp = t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1");
            check = false;
        }

        // Check if custom repeticions are greater than 0
        aux.customReps = "";
        aux.customDaysBubles = "";
        aux.customMonth = "";
        aux.taskHours = { errors: [], value: "" };
        if (recurringTask && taskType === "NORMAL") {
            // Check that the times are not the same and that they don't overlap.
            aux.taskHours = validateHours(taskHours, t);
            if (aux.taskHours.value.length > 0 || aux.taskHours.errors.length > 0) {
                check = false;
            }
            if (
                recurringTask.isRecurrent &&
                recurringTask.value === "custom" &&
                recurringTask.custom.repeatEvery <= 0
            ) {
                aux.customReps = t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1");
                check = false;
            }

            // Check if custom bubles are at leat one selected
            if (
                recurringTask.isRecurrent &&
                recurringTask.custom.customValue === "week" &&
                !recurringTask.custom.days.some(({ isActive }) => isActive === true)
            ) {
                aux.customDaysBubles = t(TranslationKeys.SELECT_ONE_DAY);
                check = false;
            }

            // Check if select is selected
            if (
                recurringTask.isRecurrent &&
                recurringTask.custom.customValue === "everyMonth" &&
                recurringTask.custom.selectedOptions.value.length === 0
            ) {
                aux.customMonth = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
                check = false;
            }
        }

        setValidations(aux);
        if (check) {
            if (isHoursOutOfRange(taskHours)) {
                setShowModalHoursOutOfTime(true);
                return;
            }
            next();
        }
    };

    const onConfirmModal = () => {
        setShowModalHoursOutOfTime(false);
        next();
    };

    const onCloseModal = () => {
        setShowModalHoursOutOfTime(false);
    };

    const onTaskTypeChange = (isChecked: boolean) => {
        if (!isChecked) {
            onChange({ taskType: "NORMAL" });
            return;
        }
        onChange({
            taskType: "SPORADIC",
            taskHours: [],
            finish: {
                checked: "never",
                value: null,
            },
            recurringTask: {
                isRecurrent: false,
                options: [],
                value: "",
                custom: {
                    customValue: "day",
                    days,
                    repeatEvery: 1,
                    selectedOptions: { text: "", value: "" },
                },
                text: "",
            },
        });

        return;
    };

    useEffect(() => {
        // Si el dia seleccionado es jueves 5
        // te pone los valores del select acorde a la fecha estipulada
        // Select de tareas recurrentes
        if (taskStart !== "") {
            setRecurentTaskSelectorValues(
                recurringTaskValuesConst.map(({ label, value, hidden }) => {
                    return {
                        label:
                            value === "annually"
                                ? label
                                      .replace("{1}", `${new Date(taskStart).getDate()}`)
                                      .replace("{2}", getMonthName(taskStart))
                                : value === "everyMonth"
                                ? label?.replace(
                                      "{1}",
                                      weekTextOptions[getWeekDay(taskStart) - 1]
                                          ?.toLocaleLowerCase()
                                          ?.replace("{1}", getDayOfWeek(taskStart)?.toLocaleLowerCase())
                                  )
                                : label?.replace("{1}", getDayOfWeek(taskStart)),
                        value,
                        hidden,
                    };
                })
            );

            // Si el dia seleccionado es jueves 5
            // te pone los valores del select acorde a la fecha estipulada
            // Select de tareas recurrentes cuando has seleccionado custom y el mes
            // -> custom
            //  -> Mes

            setCustomMonthlySelectorValues(
                customMonthlySelectorValues.map(({ label, value, hidden }, i) => {
                    return {
                        label:
                            value === "everyMonthDayOfMonth"
                                ? weekOptions[i].label?.replace("{1}", `${new Date(taskStart).getDate()}`)
                                : weekOptions[i].label?.replace("{1}", getDayOfWeek(taskStart)),
                        value,
                        hidden,
                    };
                })
            );
        }
    }, [taskStart]);

    return {
        recurentTaskSelectorValues,
        onAddNewHour,
        onBlur,
        validate,
        validations,
        options,
        weekOptions: customMonthlySelectorValues,
        onDeleteHour,
        onAddNewRangeHour,
        isValidating,
        onConfirmModal,
        onCloseModal,
        showModalHoursOutOfTime,
        onTaskTypeChange,
    };
};
