import { FC } from "react";
import { DynamicFieldItem } from "./DynamicFieldItem/DynamicFieldItem";
import { DynamicFieldItemDropdown } from "./DynamicFieldItemDropdown/DynamicFieldItemDropdown";
import { isDropdownDynamicField } from "app/helpers/dynamicFields/isDropdownDynamicField";
import { taskfield2Error } from "app/models/02-TAR/Task/EditTask";
import { DynamicFieldsModel } from "app/models/02-TAR/TaskWizard";

type DynamicFieldsProps = {
    isLoadingDynamicFields: boolean;
    dynamicFields: DynamicFieldsModel[];
    errors: taskfield2Error[];
    focus?: boolean;
    disabled?: boolean;
    variant?: "editTask" | "modal";
    onInputChange: (value: string, id: string) => void;
    onCheckboxChange: (value: boolean, id: string) => void;
    onDeleteDynamicField: (id: string) => void;
    onDeleteDynamicDropDownField: (id: string, itemId: string) => void;
    onChangeDynamicDropDownField: (id: string, itemId: string, value: string) => void;
    onAddNewDynamicDropDownField: (id: string) => void;
};

export const DynamicFields: FC<DynamicFieldsProps> = ({
    errors,
    focus,
    dynamicFields,
    onInputChange,
    onCheckboxChange,
    onDeleteDynamicField,
    onDeleteDynamicDropDownField,
    onChangeDynamicDropDownField,
    onAddNewDynamicDropDownField,
    disabled,
    variant = "editTask",
}) => {
    const dynamicFieldsFiltered = dynamicFields.filter(({ isDeleted }) => !isDeleted);

    return (
        <div className={variant === "editTask" ? "dynamicFields__container" : "dynamicFields__container--small"}>
            {dynamicFieldsFiltered.map(({ dynamicFieldsType, id, isRequired, label, taskFieldOptions }) =>
                isDropdownDynamicField(dynamicFieldsType) ? (
                    <DynamicFieldItemDropdown
                        isRequired={isRequired}
                        onChange={(value) => onInputChange(value, id)}
                        onCheckboxChange={(value) => onCheckboxChange(value, id)}
                        value={label}
                        id={id}
                        key={id}
                        onDelete={() => onDeleteDynamicField(id)}
                        onDeleteItem={(itemId) => onDeleteDynamicDropDownField(id, itemId)}
                        onChangeItem={(itemId, value) => onChangeDynamicDropDownField(id, itemId, value)}
                        onAddNewItem={() => onAddNewDynamicDropDownField(id)}
                        items={taskFieldOptions}
                        hideAddNew={taskFieldOptions.length >= 10}
                        error={errors.find((error) => error.id === id)}
                        focus={focus}
                        disabled={disabled}
                    />
                ) : (
                    <DynamicFieldItem
                        checked={isRequired}
                        dynamicFieldType={dynamicFieldsType}
                        onChange={(value) => onInputChange(value, id)}
                        onChangeCheckbox={(value) => onCheckboxChange(value, id)}
                        value={label}
                        id={id}
                        key={id}
                        onDeleteItem={() => onDeleteDynamicField(id)}
                        errorMessage={errors.find((x) => x.id === id)?.errorMessage}
                        focus={focus}
                        disabled={disabled}
                    />
                )
            )}
        </div>
    );
};
