import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "app/hooks";
import { useBaseAuditGroupCheckListItem } from "../hooks/useBaseAuditGroupCheckListItem";
import {
    BaseAuditGroupCheckListItemTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import {
    faCirclePlus,
    faClipboardList,
    faClose,
    faMagnifyingGlass,
    faPen,
    faSearch,
    faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { ColumnsType } from "app/components_v2/Table/types";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { BaseAuditGroupCheckListItemModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemModels/BaseAuditGroupCheckListItemModel";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { MobileBaseAuditGroupCheckListItemRow } from "../MobileBaseAuditGroupCheckListItemRow/MobileBaseAuditGroupCheckListItemRow";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { BaseAuditGroupCheckListItemModal } from "../BaseAuditGroupCheckListItemModal/BaseAuditGroupCheckListItemModal";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { Input } from "app/components_v2/__inputs/Input/Input";

export const BaseAuditGroupCheckListItemGrid: FC = () => {
    const { t } = useTranslation();

    useTitle(t(TranslationTitles.BASE_AUDIT_GROUP_CHECKLIST_ITEM));

    const {
        baseAuditGroupCheckListItems,
        total,
        isLoading,
        filterValues,
        showSearchInput,
        setShowSearchInput,
        baseAuditGroupCheckListItem,
        setShowEditModal,
        showEditModal,
        isLoadingModal,
        showDeleteModal,
        baseAuditGroupCheckListItemErrors,
        handleSearch,
        handleSubmit,
        handleClose,
        handleDelete,
        handleEditClick,
        handleDeleteClick,
        handleQueryChange,
        handleChangePageIndex,
        handleChangeSortDirectionField,
        handleExportCsv,
    } = useBaseAuditGroupCheckListItem();

    const cols: ColumnsType<BaseAuditGroupCheckListItemModel>[] = [
        {
            dataIndex: "name",
            label: t(BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_NAME_LABEL),
            sortedType: "default",
            render: ({ name }) => <CellTitle title={name} />,
        },
        {
            dataIndex: "fK_BaseAuditGroupCheckListItemType",
            label: t(BaseAuditGroupCheckListItemTranslations.TYPE_LABEL),
            sortedType: "default",
            render: ({ type }) => <CellTitle title={type} />,
        },
        {
            dataIndex: "ponderationLevel",
            label: t(BaseAuditGroupCheckListItemTranslations.PONDERATION_LEVEL_LABEL),
            sortedType: "default",
            alignCenter: true,
            className: "baseAuditGroupCheckListItemGrid__ponderation",
            render: ({ ponderationLevel }) => (
                <div className="baseAuditGroupCheckListItemGrid__ponderation__row">
                    <CellTitle title={ponderationLevel} />
                </div>
            ),
        },
        {
            dataIndex: "cellButtons",
            label: t(BaseAuditGroupCheckListItemTranslations.CELL_BUTTONS_LABEL),
            alignCenter: true,
            className: "baseAuditGroupCheckListItemGrid__actionButtons",
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => handleEditClick(record),
                            hidden: !hasPermissionToEdit(SecScreen.AUDIT_MANAGEMENT),
                        },
                        {
                            icon: faTrash,
                            onClick: () => handleDeleteClick(record),
                            hidden: !hasPermissionToDelete(SecScreen.AUDIT_MANAGEMENT),
                        },
                    ]}
                />
            ),
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faMagnifyingGlass,
            onClick: () => setShowSearchInput(true),
            inputComponent: {
                component: (
                    <Input
                        focus
                        onChange={handleQueryChange}
                        iconRight={!!filterValues.query?.length ? faSearch : faClose}
                        value={filterValues.query}
                        onClickIcon={handleSearch}
                        onPressEnter={handleSearch}
                        whiteInput
                        transparent
                    />
                ),
                show: showSearchInput,
            },
        },
        {
            icon: faCirclePlus,
            onClick: () => setShowEditModal(true),
            hidden: !hasPermissionToAdd(SecScreen.AUDIT_MANAGEMENT),
        },
    ];

    return (
        <PageContainer paddingTop>
            {showEditModal && (
                <BaseAuditGroupCheckListItemModal
                    type={baseAuditGroupCheckListItem ? "edit" : "create"}
                    onSubmit={handleSubmit}
                    onClose={() => handleClose("edit")}
                    baseAuditGroupCheckListItem={baseAuditGroupCheckListItem}
                    baseAuditGroupCheckListItemErrors={baseAuditGroupCheckListItemErrors}
                    isLoading={isLoadingModal}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    title={t(TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_TITLE).replace(
                        "{0}",
                        baseAuditGroupCheckListItem?.name || ""
                    )}
                    description={t(TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_DESCRIPTION)}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onConfirm={handleDelete}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={handleClose}
                    isLoading={isLoadingModal}
                    type="delete"
                    variant="purple"
                />
            )}
            <TableCollapsable
                cols={cols}
                data={baseAuditGroupCheckListItems}
                variant="purple"
                title={t(BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_GRID_TITLE)}
                placeholder={
                    <TableError description={t(TranslationKeys.TABLE_EMPTY_STATE_TASK)} icon={faClipboardList} />
                }
                total={total}
                isLoading={isLoading}
                actionButtons={actionButtons}
                onChangePageIndex={handleChangePageIndex}
                onChangeSortDirectionField={handleChangeSortDirectionField}
                pageIndex={filterValues.pageIndex}
                pageSize={filterValues.pageSize}
                sortDirection={filterValues.sortDirection}
                sortField={filterValues.sortField}
                mobileBody={(row, _i, rowPosition) => (
                    <MobileBaseAuditGroupCheckListItemRow
                        data={row}
                        key={row.id}
                        rowPosition={rowPosition}
                        onEditClick={handleEditClick}
                        onDeleteClick={handleDeleteClick}
                    />
                )}
                onDoubleClick={handleEditClick}
                onClickExportCsv={handleExportCsv}
            />
        </PageContainer>
    );
};
