import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "app/state/reducer";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

export const useCompanies = () => {
    const [companies, setCompanies] = useState<OptionModel[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const { session } = useSelector<IAppState, IAppState>((state) => state);

    useEffect(() => {
        setLoading(true);
        getCompanies();
        setLoading(false);
    }, []);
    const getCompanies = async () => {
        const companiesSr = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}`,
        });

        if (!companiesSr.status()) {
            console.error("error", companiesSr);
            setError(companiesSr.error);
            return;
        }
        setCompanies(companiesSr.data);
    };
    return { companies, error, loading };
};
