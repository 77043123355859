import { FC, useState } from "react";
import { ProviderProps } from "../types";
import { CloseCompanySeenModel } from "app/models/01-SEG/CloseCompanySeen/CloseCompanySeen";
import { CloseCompanyModalContext } from "app/state/context/CloseCompanyModalContext/CloseCompanyModalContext";

export const CloseCompanyModalProvider: FC<ProviderProps> = ({ children }) => {
    const [closeOpenCompanies, setCloseOpenCompanies] = useState<CloseCompanySeenModel[]>([]);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const open = (closeOpenCompanies: CloseCompanySeenModel[]) => {
        setIsVisible(true);
        setCloseOpenCompanies(closeOpenCompanies);
    };

    const reset = () => {
        setIsVisible(false);
        setCloseOpenCompanies([]);
    };

    return (
        <CloseCompanyModalContext.Provider
            value={{
                closeOpenCompanies,
                isOpen: isVisible,
                open,
                reset,
            }}
        >
            {children}
        </CloseCompanyModalContext.Provider>
    );
};
