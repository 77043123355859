import { useState } from "react";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useSuccessHandler } from "app/hooks/SuccessHandler/useSuccessHandler";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import EmailDistributionListService from "app/services/01-SEG/EmailDistributionListService";
import { EmailDistributionListTranslations, TranslationErrors } from "app/translation/translationKeys";
import { fillExtraParams } from "../helpers/emailDistributionListHelper";
import { PaginationDefaults } from "app/shared/Constants";
import {
    INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS,
    INITIAL_EMAIL_DISTRIBUTION_LIST_FORM_VALUES,
} from "../constants/EmailDistributionListConstants";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { EmailDistributionListGridModel } from "../models/EmailDistributionListGridModels";
import { EmailDistributionListModel } from "app/models/01-SEG/EmailDistributionList/EmailDistributionListModel";
import { EmailDistributionListFormValues } from "../models/EmailDistributionListFormValues";
import { ActionType } from "app/models/FormComponentsModel";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";

export const useEmailDistributionList = () => {
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessHandler();
    const session = useSession();
    const { filterIcon, handleCompanyChange, selectCompany } = useSelectCompanyTable();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();

    const [emailDistributionList, setEmailDistributionList] = useState<EmailDistributionListModel[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [sortField, setSortField] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>("default");
    const [query, setQuery] = useState<string>("");
    const [showSearchInput, setShowSearchInput] = useState<boolean>(!!query?.length);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [currentEmailDistributionList, setCurrentEmailDistributionList] = useState<EmailDistributionListFormValues>(
        INITIAL_EMAIL_DISTRIBUTION_LIST_FORM_VALUES
    );
    const [emailDistributionListErrors, setEmailDistributionListErrors] = useState(
        INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS
    );

    const getEmailDistributionList = async ({
        pi,
        q,
        sortF,
        sortD,
        companyId,
    }: EmailDistributionListGridModel = {}) => {
        setIsLoading(true);

        const queryFetch = q !== undefined ? q : query;
        const selectedCompany = companyId ?? selectCompany.company.value;

        const { data, status, getParsedError } = await EmailDistributionListService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pi !== undefined ? pi : pageIndex - 1,
            sortField: sortF ?? sortField,
            sortDirection: sortD ?? sortDirection,
            query: queryFetch,
            extraParams: fillExtraParams({}, selectedCompany),
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setIsLoading(false);
            setEmailDistributionList([]);
            setTotal(0);
        }
        setEmailDistributionList(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    const onChangePageIndex = (pi: number) => {
        setPageIndex(pi);
        getEmailDistributionList({ pi: pi - 1 });
    };

    const onChangeSortDirectionField = (sortF: string, sortD?: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD ?? "default");
        getEmailDistributionList({ sortF, sortD });
    };

    const onClickEdit = (fK_Company: number, fK_DistributionList: number, userList: OptionModel[]) => {
        setCurrentEmailDistributionList({
            fK_Company: String(fK_Company),
            fK_DistributionList: String(fK_DistributionList),
            userList,
        });
        setShowEditModal(true);
    };

    const onClickDelete = (fK_Company: number, fK_DistributionList: number, userList: OptionModel[]) => {
        setCurrentEmailDistributionList({
            fK_Company: String(fK_Company),
            fK_DistributionList: String(fK_DistributionList),
            userList,
        });
        setShowDeleteModal(true);
    };

    const handleCleanFetch = () => {
        setQuery("");
        setShowSearchInput(false);
        getEmailDistributionList({ pi: 0, q: "" });
        setPageIndex(1);
    };

    const handleChange = (values: Partial<EmailDistributionListFormValues>) => {
        setEmailDistributionListErrors(INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS);
        setCurrentEmailDistributionList((prev) => ({ ...prev, ...values }));
    };

    const handleSubmit = async (type: ActionType) => {
        if (!validate(type)) return;

        const service = type === "create" ? EmailDistributionListService.Save : EmailDistributionListService.Edit;
        const closeModal = type === "create" ? setShowCreateModal : setShowEditModal;
        const successMessage =
            type === "create"
                ? t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_SAVE_SUCCESS)
                : t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_EDIT_SUCCESS);

        const { status, getParsedError } = await service({
            fK_Company: Number(currentEmailDistributionList.fK_Company) ?? session?.workingCompany?.companyId,
            fK_DistributionList: Number(currentEmailDistributionList.fK_DistributionList),
            userIdList: currentEmailDistributionList.userList.map(({ value }) => Number(value)),
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            closeModal(false);
            setIsLoading(false);
            return;
        }

        handleSuccessManager(successMessage);
        changeWorkingCompanyByCompanyId(Number(currentEmailDistributionList.fK_Company));
        handleCompanyChange({ company: { label: "", value: currentEmailDistributionList.fK_Company } });
        getEmailDistributionList({ companyId: currentEmailDistributionList.fK_Company });

        closeModal(false);
        setIsLoading(false);
    };

    const validate = (type: ActionType) => {
        let check = true;
        const errors = { ...emailDistributionListErrors };
        errors.errorFunctionality = "";
        if (type === "create" && !currentEmailDistributionList.fK_DistributionList.length) {
            check = false;
            errors.errorFunctionality = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
        }
        errors.errorCompany = "";
        if (type === "create" && !currentEmailDistributionList.fK_Company.length) {
            check = false;
            errors.errorCompany = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
        }
        errors.errorUserList = "";
        if (!currentEmailDistributionList.userList.length) {
            check = false;
            errors.errorUserList = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
        }
        setEmailDistributionListErrors(errors);
        return check;
    };

    const handleDelete = async () => {
        setIsLoading(true);

        const { status, getParsedError } = await EmailDistributionListService.Delete({
            fK_Company: Number(currentEmailDistributionList.fK_Company) ?? session?.workingCompany?.companyId,
            fK_DistributionList: Number(currentEmailDistributionList.fK_DistributionList),
            userIdList: currentEmailDistributionList.userList.map(({ value }) => Number(value)),
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setShowDeleteModal(false);
            setIsLoading(false);
            return;
        }

        handleSuccessManager(t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_SUCCESS_DELETE));
        getEmailDistributionList({ companyId: currentEmailDistributionList.fK_Company });

        setShowDeleteModal(false);
        setIsLoading(false);
    };

    return {
        currentEmailDistributionList,
        emailDistributionList,
        emailDistributionListErrors,
        filterIcon,
        getEmailDistributionList,
        handleCleanFetch,
        handleSubmit,
        isLoading,
        onChange: handleChange,
        onChangePageIndex,
        onChangeSortDirectionField,
        onClickDelete,
        onClickEdit,
        onCompanyChange: handleCompanyChange,
        onDelete: handleDelete,
        onQueryChange: setQuery,
        pageIndex,
        query,
        selectCompany,
        setCurrentEmailDistributionList,
        setEmailDistributionListErrors,
        setShowCreateModal,
        setShowDeleteModal,
        setShowEditModal,
        setShowSearchInput,
        showCreateModal,
        showDeleteModal,
        showEditModal,
        showSearchInput,
        sortDirection,
        sortField,
        total,
    };
};
