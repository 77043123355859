import { useEffect, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useToast } from "app/hooks/Toast/useToast";
import { UserService } from "app/services";
import { TranslationErrors, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { UserCentersAndDepartmentsData } from "./types";
import CompanyDepartmentBox from "app/components_v2/CompanyDepartmentBox/CompanyDepartmentBox";
import { CIDepartamentalStructureModel, SimpleDepartmentModel } from "app/models/01-SEG/CIDepartamentalStructureModel";
import { useTitle } from "app/hooks";

type CentersAndDepartmentsTabProps = {
    onChangeNewData: (data: UserCentersAndDepartmentsData) => void;
    isDisabled?: boolean;
    values: CIDepartamentalStructureModel[];
    modifiedValues: CIDepartamentalStructureModel[];
};

export const CentersAndDepartmentsTab: FC<CentersAndDepartmentsTabProps> = ({
    onChangeNewData,
    isDisabled,
    modifiedValues,
    values,
}) => {
    const { t } = useTranslation();
    const { userId } = useParams<{ userId: string }>();
    const { handleToast } = useToast();

    useTitle(t(TranslationTitles.USER_CENTERS_AND_DEPARTMENTS_PAGE_TITLE));

    const [originalData, setOriginalData] = useState<CIDepartamentalStructureModel[]>([]);
    const [modifiedData, setModifiedData] = useState<CIDepartamentalStructureModel[]>([]);

    const getData = async () => {
        if (!userId) return;

        const { data, status } = await UserService.GetAllCompanies(Number(userId));
        if (status()) {
            setOriginalData(data);
            setModifiedData(data);
        } else {
            handleToast({
                title: t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE),
                variant: "danger",
                type: "alert",
            });
        }
    };

    const handleModifyData = (companyId: number, newData: SimpleDepartmentModel[]) => {
        setModifiedData((prevData) => {
            const userCompanyData = prevData.map((company) => {
                if (company.companyId === companyId) {
                    return {
                        ...company,
                        departments: newData,
                    };
                }
                return company;
            });
            onChangeNewData({ modifiedData: userCompanyData, originalData: originalData });
            return userCompanyData;
        });
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setOriginalData(values);
    }, [values]);

    useEffect(() => {
        setModifiedData(modifiedValues);
    }, [modifiedValues]);

    const handleChangeAssigned = (companyId: number) => {
        setModifiedData((prevData) => {
            const userCompanyData = prevData.map((company) => {
                if (company.companyId === companyId) {
                    return {
                        ...company,
                        assigned: !company.assigned,
                        departments: company.departments.map((department) => ({
                            ...department,
                            assigned: company.assigned ? false : department.assigned,
                            subdepartments: department.subdepartments.map((subdept: any) => ({
                                ...subdept,
                                assigned: company.assigned ? false : subdept.assigned,
                            })),
                        })),
                    };
                }
                return company;
            });
            onChangeNewData({ modifiedData: userCompanyData, originalData: originalData });
            return userCompanyData;
        });
    };

    return (
        <div className="userInfoForm ">
            <div className="userInfoForm--secondTab ">
                {modifiedData.map(({ assigned, companyId, companyName, departments }) => (
                    <CompanyDepartmentBox
                        assigned={assigned}
                        key={companyId}
                        title={companyName}
                        departments={departments}
                        sendModifiedData={(newData: any) => handleModifyData(companyId, newData)}
                        handleToggleChecked={() => handleChangeAssigned(companyId)}
                        isDisabled={isDisabled}
                    />
                ))}
            </div>
        </div>
    );
};
