import { isHoursOutOfRange, validateHours } from "app/helpers/__validates/validateTaskHours";
import { isDropdownDynamicField } from "app/helpers/dynamicFields/isDropdownDynamicField";
import { useDynamicFieldsErrorHandler } from "app/hooks/dynamicFields/useDynamicFieldsErrorHandler";
import { useToast } from "app/hooks/Toast/useToast";
import { editTasktValidation, TaskfieldOptionsError } from "app/models/02-TAR/Task/EditTask";
import { AllSteps, taskHoursModel } from "app/models/02-TAR/TaskWizard";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { IsEnabledModel } from "../../WizardTasks/Step2/models";
import { INITIAL_EDIT_TASK_FORM_VALUE_ERRORS } from "../constants/editTaskConstants";

export const useEditTask = (initialStepsValues: AllSteps) => {
    const [data, setData] = useState(initialStepsValues);
    const [dataCopy, setDataCopy] = useState(initialStepsValues);
    const { handleToast } = useToast();
    const { filterTaskFieldsWithoutErrorMessage, taskfieldOptionsDropdown } = useDynamicFieldsErrorHandler();

    const [validations, setValidations] = useState<editTasktValidation>(INITIAL_EDIT_TASK_FORM_VALUE_ERRORS);

    const [isEnabled, setIsEnabled] = useState<IsEnabledModel>({
        subdepartment: false,
        user: true,
        workingPosition: false,
    });

    const [showModalHoursOutOfTime, setShowModalHoursOutOfTime] = useState<boolean>(false);

    const { t } = useTranslation();

    function updateFields(fields: Partial<AllSteps>) {
        if (!!fields.checkList || !!fields.dynamicFields || !!fields.reportData)
            setValidations((prev) => ({ ...prev, reportData: "" }));
        setData((prev: AllSteps) => {
            return { ...prev, ...fields };
        });
    }

    // Comprueba que las horas sean diferentes
    const isHoursEquals = (hours: taskHoursModel[]) => {
        for (let i = 0; i < hours.length; i++) {
            for (let x = 0; x < hours.length; x++) {
                if (i === x) continue;
                if (
                    new Date(hours[i].hour).getMinutes() === new Date(hours[x].hour).getMinutes() &&
                    new Date(hours[i].hour).getHours() === new Date(hours[x].hour).getHours()
                )
                    return true;
            }
        }

        return false;
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;
        const aux = { ...validations };

        aux.taskTitle = "";
        if (!data.taskTitle.length) {
            aux.taskTitle = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        aux.companyForTask = "";
        if (!data.companyForTask.value.length) {
            aux.companyForTask = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        aux.DepartmentForTask = "";
        if (data.companyForTask.value.length && !data.DepartmentForTask.value.length) {
            aux.DepartmentForTask = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        aux.userOrQRForTask = "";
        if (
            data.companyForTask.worksWithQr &&
            (!data.userForTask.value.length || data.userForTask.value === "-1") &&
            (!data.workingPositionForTask.value.length || data.workingPositionForTask.value === "-1")
        ) {
            aux.userOrQRForTask = t(TranslationKeys.MUST_SELECT_USER_OR_QR_BODY);
            check = false;
        }

        aux.reportData = "";
        if (data.reportType === "DATA") {
            const isAtLeastOneValue = data.reportData.filter(({ assetId }) => !assetId).filter(({ value }) => value);

            if (!isAtLeastOneValue.length) {
                aux.reportData = t(TranslationCommon.INPUT_NOT_EMPTY);
                check = false;
            }
        }

        if (data.reportType === "ASSET") {
            const isAtLeastOneValue = data.reportData
                .filter(({ assetId }) => assetId)
                .filter(({ value, isDeleted }) => value && !isDeleted);

            if (!isAtLeastOneValue.length) {
                aux.reportData = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
                check = false;
            }
        }

        if (data.reportType === "DYNAMIC_FIELD") {
            const dynamicFields = data.dynamicFields.filter(({ isDeleted }) => !isDeleted);
            if (!dynamicFields.length) {
                aux.reportData = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
                check = false;
            }

            aux.dynamicFields = dynamicFields
                .map(({ id, label, dynamicFieldsType, taskFieldOptions }) => ({
                    dynamicFieldsType: dynamicFieldsType,
                    errorMessage: !label.length ? t(TranslationCommon.INPUT_NOT_EMPTY) : "",
                    id: id,
                    taskfieldOptions: isDropdownDynamicField(dynamicFieldsType)
                        ? taskfieldOptionsDropdown(taskFieldOptions)
                        : [],
                }))
                .filter(filterTaskFieldsWithoutErrorMessage);
            if (aux.dynamicFields.length) check = false;
        }

        aux.checklist = [];
        if (data.reportType === "CHECKLIST") {
            const checklist = data.checkList.filter(({ isDeleted }) => !isDeleted);
            const errorChecklist: TaskfieldOptionsError[] = checklist.map(({ id, name }) => ({
                errorMessage: !name.length ? t(TranslationKeys.CHECKLIST_CANNOT_BE_EMPTY) : "",
                id,
            }));

            aux.checklist = errorChecklist;
        }

        aux.taskStart = "";
        if (!data.taskStart.length) {
            aux.taskStart = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        aux.finishEl = "";
        if (data.finish.checked === "el" && !data.finish.value) {
            aux.finishEl = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        aux.finishResp = "";
        if (data.finish.checked === "after" && typeof data.finish.value === "number" && data.finish.value <= 0) {
            aux.finishResp = t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1");
            check = false;
        }

        aux.customReps = "";
        aux.customDaysBubles = "";
        aux.customMonth = "";
        aux.taskHours = { errors: [], value: "" };

        if (data.recurringTask && data.taskType === "NORMAL") {
            aux.taskHours = validateHours(data.taskHours, t);
            if (aux.taskHours.value.length || aux.taskHours.errors.length) check = false;

            if (isHoursEquals(data.taskHours)) {
                aux.taskHours = t(TranslationKeys.INVALID_HOURS_SAME_HOURS);
                check = false;
            }
            if (
                data.recurringTask.isRecurrent &&
                data.recurringTask.value === "custom" &&
                data.recurringTask.custom.repeatEvery <= 0
            ) {
                aux.customReps = t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1");
                check = false;
            }

            if (
                data.recurringTask.isRecurrent &&
                data.recurringTask.value === "custom" &&
                data.recurringTask.custom.customValue === "week" &&
                !data.recurringTask.custom.days.some(({ isActive }) => isActive === true)
            ) {
                aux.customDaysBubles = t(TranslationKeys.SELECT_ONE_DAY);
                check = false;
            }

            if (
                data.recurringTask.isRecurrent &&
                data.recurringTask.value === "custom" &&
                data.recurringTask.custom.customValue === "everyMonth" &&
                !data.recurringTask.custom.selectedOptions.value.length
            ) {
                aux.customMonth = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
                check = false;
            }
        }
        setValidations({ ...aux });
        if (!check) {
            handleToast({
                title: t(TranslationModals.FAILED_SAVE_TASK_TITLE),
                subtitle: t(TranslationModals.FAILED_SAVE_TASK_DESCRIPTION),
                variant: "danger",
                type: "alert",
            });
            return false;
        }
        if (isHoursOutOfRange(data.taskHours)) {
            setShowModalHoursOutOfTime(true);
            return false;
        }

        return true;
    };

    const setEnableUserDept = (values: IsEnabledModel) => setIsEnabled(values);

    const onConfirmModalHoursOutOfTime = () => {
        setShowModalHoursOutOfTime(false);
    };

    const onCloseModalHoursOutOfTime = () => {
        setShowModalHoursOutOfTime(false);
    };

    return {
        data,
        onChange: updateFields,
        setData,
        validate,
        validations,
        isEnabledUserDept: isEnabled,
        setEnableUserDept,
        dataCopy,
        setDataCopy,
        onConfirmModalHoursOutOfTime,
        onCloseModalHoursOutOfTime,
        showModalHoursOutOfTime,
        setValidations,
    };
};
