import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { SelectOptions } from "app/components_v2/__inputs";
import { setWorkingEntity } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useSystemUser } from "app/hooks/useSystemUser";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ComponentStatus, OptionsSearch } from "../../models/FormComponentsModel";
import { CompanyService, InstanceService } from "../../services";
import { PrivatePaths } from "../../shared/Constants";
import { Actions } from "../../state/actions";
import Spinner from "../Spinner/Spinner";

const InstanceSelector: FC = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const session = useSession();
    const customerInstanceId = session?.user.customerInstanceId;
    const d = useDispatch();
    const [instances, setInstances] = useState<OptionsSearch[]>([]);
    const [status, setStatus] = useState<ComponentStatus>("loading");
    const nav = useNavigate();
    const { handleSystemUser } = useSystemUser();

    const fetchInstances = async () => {
        const instancesSr = await InstanceService.GetInstances();
        if (instancesSr.status()) {
            setInstances(
                instancesSr.data.list.map((i) => ({
                    label: i.name,
                    value: String(i.id),
                }))
            );
            setStatus("complete");
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setStatus("errored");
        }
    };

    const getAllCompanies = useCallback(async () => {
        let sr = await CompanyService.GetData({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId || 0}`,
        });
        if (sr?.status() && session) {
            let companies: UserCompanyModel[] = sr.data.list.map((company) => ({
                companyId: company.id,
                companyName: company.name,
                logoUrl: company.logoFileURL,
            }));
            d(
                Actions.setAppSession({
                    ...session,
                    user: {
                        ...session.user,
                        companies: companies,
                    },
                })
            );
        } else
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
    }, [session?.user.customerInstanceId, d]);

    const onChangeInstance = async ({ value }: OptionsSearch) => {
        const systemUser = await handleSystemUser(Number(value));
        if (!!systemUser) d(Actions.updateUserSession(systemUser));
        setWorkingEntity({
            workingCompany: undefined,
            workingDepartment: undefined,
            workingSubDepartment: undefined,
        });
        nav(`/${PrivatePaths.SELECT_DEPARTMENT}`);
    };

    useEffect(() => {
        if (status === "loading") fetchInstances();
    }, [session, d, status, customerInstanceId]);

    useEffect(() => {
        if (status === "complete" && session && !customerInstanceId) fetchInstances();
    }, [session, d, status, customerInstanceId]);

    useEffect(() => {
        if (session) getAllCompanies();
    }, [getAllCompanies]);

    switch (status) {
        case "loading":
            return (
                <div className="instanceSelector">
                    <Spinner />
                </div>
            );
        case "errored":
            return (
                <div className="instanceSelector">
                    <NewButton
                        handleClickButton={() => setStatus("loading")}
                        iconRight={faRefresh}
                        text={t(TranslationKeys.RELOAD_INSTANCES)}
                    />
                </div>
            );
        case "complete":
            return (
                <div className="instanceSelector">
                    <SelectOptions
                        isMulti={false}
                        onChange={onChangeInstance}
                        options={instances}
                        selectedValue={String(customerInstanceId)}
                        inverted
                    />
                </div>
            );
    }
};

export default InstanceSelector;
