import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { useTitle } from "app/hooks";
import { hasPermissionToDelete } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import {
    BaseTaskTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { isEqual } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TaskData } from "../../07-TAR-CRUD/EditTask/Components";
import { BaseTaskDataToReport } from "./components/BaseTaskDataToReport/BaseTaskDataToReport";
import { BaseTaskExtraData } from "./components/BaseTaskExtraData/BaseTaskExtraData";
import { useEditBaseTask } from "./hooks/useEditBaseTask";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

export const EditBaseTask: FC = () => {
    const { t } = useTranslation();

    const {
        showDeleteModal,
        setShowDeleteModal,
        handleDelete,
        showCancelModal,
        setShowCancelModal,
        handleCancel,
        isLoading,
        isCreatePage,
        isDisabled,
        isSaving,
        onSubmit,
        dataTask,
        dataTaskCopy,
        onChange,
        selectedBaseTaskType,
        setSelectedBaseTaskType,
        baseTaskTypes,
        setBaseTaskTypes,
        selectedPlanAPPCC,
        setSelectedPlanAPPCC,
        planAPPCCOptions,
        validations,
        showUpdateModal,
        setShowUpdateModal,
        handleEditBaseTask,
        requiredPlanAPPCC,
        isBaseTaskPopoverLoading,
        isBaseTaskPopoverVisible,
        onChangeInputValue,
        baseTaskOptions,
        setIsBaseTaskPopoverVisible,
        handleSubmitWithSameTitle,
        setShowBaseTaskTitleModal,
        showBaseTaskTitleModal,
        onFocusInputValue,
    } = useEditBaseTask();

    const variant: TabHeaderVariants = requiredPlanAPPCC ? "purple" : "primary";

    useTitle(
        t(isCreatePage() ? TranslationTitles.NEW_BASE_TASK_PAGE_TITLE : TranslationTitles.EDIT_BASE_TASK_PAGE_TITLE)
    );

    return (
        <>
            {showBaseTaskTitleModal && (
                <ConfirmModal
                    title={
                        requiredPlanAPPCC
                            ? t(BaseTaskTranslations.BASE_TASK_SAME_TITLE_REGISTRY_TITLE)
                            : t(BaseTaskTranslations.BASE_TASK_SAME_TITLE_TASK_TITLE)
                    }
                    description={
                        requiredPlanAPPCC
                            ? t(BaseTaskTranslations.BASE_TASK_SAME_TITLE_REGISTRY_DESCRIPTION)
                            : t(BaseTaskTranslations.BASE_TASK_SAME_TITLE_TASK_DESCRIPTION)
                    }
                    onClose={() => {
                        setShowBaseTaskTitleModal(false);
                        onFocusInputValue();
                    }}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={handleSubmitWithSameTitle}
                    onConfirmText={t(TranslationCommon.SAVE)}
                    type="info"
                    variant={variant}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    variant={variant}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={handleDelete}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationModals.DELETE_TASK_TITLE)}
                    type="delete"
                />
            )}
            {showCancelModal && (
                <ConfirmModal
                    variant={variant}
                    onClose={() => setShowCancelModal(false)}
                    onConfirm={handleCancel}
                    isLoading={isLoading}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationKeys.UNDO_CHANGES)}
                />
            )}
            {showUpdateModal && (
                <ConfirmModal
                    variant={variant}
                    onClose={() => setShowUpdateModal(false)}
                    onConfirm={handleEditBaseTask}
                    isLoading={isLoading}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(BaseTaskTranslations.MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_BODY)}
                    title={t(BaseTaskTranslations.MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_TITLE)}
                />
            )}
            <FormLayout
                isLoading={isLoading}
                variant="gray"
                headerOptions={{
                    buttonLabel: t(TranslationModals.DELETE_TASK_TITLE),
                    showDeleteButton: !isCreatePage() && hasPermissionToDelete(SecScreen.BASE_TASK_MANAGEMENT),
                    type: "delete",
                    onClick: () => setShowDeleteModal(true),
                    variant: variant,
                    title: isCreatePage()
                        ? requiredPlanAPPCC
                            ? t(BaseTaskTranslations.NEW_APPCC_BASE_TASK)
                            : t(BaseTaskTranslations.NEW_BASE_TASK)
                        : requiredPlanAPPCC
                        ? t(BaseTaskTranslations.EDIT_APPCC_BASE_TASK)
                        : t(BaseTaskTranslations.EDIT_BASE_TASK),
                }}
                footer={
                    <>
                        {!isDisabled() && (
                            <GenericModalFooter
                                confirmButton={{
                                    text: t(TranslationCommon.SAVE),
                                    type: "submit",
                                    form: "editBaseTaskId",
                                    variant,
                                }}
                                closeButton={
                                    !isCreatePage()
                                        ? {
                                              text: t(TranslationCommon.CANCEL),
                                              onClick: () => setShowCancelModal(true),
                                              disabled: isEqual(dataTask, dataTaskCopy),
                                              buttonType: "tertiary",
                                              variant,
                                          }
                                        : undefined
                                }
                                loading={isSaving}
                            />
                        )}
                    </>
                }
            >
                <div className="editBaseTask">
                    <form onSubmit={onSubmit} id="editBaseTaskId" className="editBaseTask__container">
                        <div className="editBaseTask__basicInfo">
                            <TaskData
                                {...dataTask}
                                onChange={onChange}
                                customError={validations}
                                isDisabled={isDisabled()}
                                showPlanAPPCC={false}
                                hasPlanAPPCC={requiredPlanAPPCC}
                                inputWithPopover
                                isBaseTaskPopoverLoading={isBaseTaskPopoverLoading}
                                isBaseTaskPopoverVisible={isBaseTaskPopoverVisible}
                                onChangeInputValue={onChangeInputValue}
                                baseTaskOptions={baseTaskOptions}
                                setIsBaseTaskPopoverVisible={setIsBaseTaskPopoverVisible}
                                onFocusInputValue={onFocusInputValue}
                            />
                            <BaseTaskExtraData
                                isDisabled={isDisabled()}
                                isPhotoRequired={dataTask.isPhotoRequired}
                                isCritical={dataTask.criticalTask}
                                somethingToReportImage={dataTask.somethingToReportImage}
                                onChange={onChange}
                                baseTaskTypes={baseTaskTypes}
                                setBaseTaskTypes={setBaseTaskTypes}
                                selectedBaseTaskType={selectedBaseTaskType}
                                setSelectedBaseTaskType={setSelectedBaseTaskType}
                                planAPPCCOptions={planAPPCCOptions}
                                selectedPlanAPPCC={selectedPlanAPPCC}
                                setSelectedPlanAPPCC={setSelectedPlanAPPCC}
                                requiredPlanAPPCC={requiredPlanAPPCC}
                                validations={validations}
                                imageBase64={dataTask.imageBase64}
                                variant={variant}
                            />
                            <BaseTaskDataToReport
                                {...dataTask}
                                isDisabled={isDisabled()}
                                onChange={onChange}
                                customError={validations}
                            />
                        </div>
                    </form>
                </div>
            </FormLayout>
        </>
    );
};
