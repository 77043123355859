import { getDateFormatted } from "app/helpers";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

const today = new Date();

export const useBarChartDates = () => {
    const { t } = useTranslation();

    const getBarChartDate = (startDate?: Date, endDate?: Date): string => {
        const defautlDate = `${getDateFormatted(startDate)} - ${getDateFormatted(endDate)}`;

        if (startDate?.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) && endDate)
            return t(TranslationCommon.TODAY);
        else if (isYesterday(startDate) && isYesterday(endDate)) return t(TranslationCommon.YESTERDAY);
        else if (isOneWeekAgo(startDate) && endDate?.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0))
            return t(TranslationKeys.LAST_SEVEN_DAYS);
        else if (isOneMonthAgo(startDate) && endDate?.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0))
            return t(TranslationKeys.LAST_THIRTY_DAYS);

        return defautlDate;
    };

    const isYesterday = (fecha: Date | undefined) => {
        const fechaAyer = new Date(today);
        fechaAyer.setDate(today.getDate() - 1);

        return fecha?.setHours(0, 0, 0, 0) === fechaAyer.setHours(0, 0, 0, 0);
    };

    const isOneWeekAgo = (fecha: Date | undefined) => {
        const fechaHaceSieteDias = new Date(today);
        fechaHaceSieteDias.setDate(today.getDate() - 7);

        return fecha?.setHours(0, 0, 0, 0) === fechaHaceSieteDias.setHours(0, 0, 0, 0);
    };

    const isOneMonthAgo = (fecha: Date | undefined) => {
        const fechaHaceUnMes = new Date(today);
        fechaHaceUnMes.setDate(today.getDate() - 30);

        return fecha?.setHours(0, 0, 0, 0) === fechaHaceUnMes.setHours(0, 0, 0, 0);
    };

    return getBarChartDate;
};
