import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { CompanyModel } from "../../models/01-SEG/Company/CompanyModels";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { WizardCompanyModel } from "app/dtos/01-SEG/Company/CompanyDto";
import { CloseCompanySeenModel } from "app/models/01-SEG/CloseCompanySeen/CloseCompanySeen";

export default class CompanyService {
    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<CompanyModel>>({
            url: `${ApiPaths.SEG_API}/companies`,
            paginationParams: paginationParams,
        });
    }

    public static async GetDataExport(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<CompanyModel>>({
            url: `${ApiPaths.SEG_API}/companies/export`,
            paginationParams: paginationParams,
            csvExport: true,
        });
    }

    public static async GetOne(companyId: number | string, instanceId: number) {
        return FetchService.get<CompanyModel>({
            url: `${ApiPaths.SEG_API}/companies/${companyId}?customerInstanceId=${instanceId}`,
        });
    }

    public static async GetClosedCompanies() {
        return FetchService.get<CloseCompanySeenModel[]>({
            url: `${ApiPaths.SEG_API}/companies/ClosedCompanies`,
        });
    }

    //TODO: Remove hardcoded rolesId
    public static async Save(values: CompanyModel) {
        const body = {
            ...values,
            isActive: true,
            rolesId: [1],
        };
        return FetchService.post<CompanyModel>({
            url: `${ApiPaths.SEG_API}/companies`,
            body: body,
        });
    }

    public static async SaveCompanyWizard(body: WizardCompanyModel) {
        return FetchService.post<number>({
            url: `${ApiPaths.SEG_API}/companies/wizard`,
            body: body,
        });
    }

    //TODO Remove hardcoded rolesId
    public static async Edit(values: CompanyModel, companyId: string) {
        const body = {
            ...values,
            isActive: true,
        };
        return FetchService.put<CompanyModel>({
            url: `${ApiPaths.SEG_API}/companies/${companyId}`,
            body: body,
        });
    }

    public static async Delete(companyId: number) {
        return FetchService.delete<CompanyModel>({
            url: `${ApiPaths.SEG_API}/companies/${companyId}`,
        });
    }
}
