import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RowPositionModel } from "app/components_v2/Table/types";
import { WorkingPositionModel } from "app/models/02-TAR/WorkingPosition/WorkingPositionModel";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type WorkingPositionGridMobileBodyProps = {
    workingPosition: WorkingPositionModel;
    rowPosition: RowPositionModel;
};

export const WorkingPositionGridMobileBody: FC<WorkingPositionGridMobileBodyProps> = ({
    workingPosition,
    rowPosition,
}) => {
    const nav = useNavigate();
    const { name, customerInstanceWorkingPosition, id, description } = workingPosition;
    return (
        <div className={`workingPositionGridMobileBody--${rowPosition}`}>
            <div className="workingPositionGridMobileBody__text">
                <div className="workingPositionGridMobileBody__text__headers">
                    <p className="workingPositionGridMobileBody__title">{name}</p>
                    <p className="workingPositionGridMobileBody__code">{customerInstanceWorkingPosition?.qrCode}</p>
                </div>
                <p className="workingPositionGridMobileBody__description">{description || "-"}</p>
            </div>
            <div className="workingPositionGridMobileBody__actions" onClick={() => nav(`edit/${id}`)}>
                <FontAwesomeIcon className="workingPositionGridMobileBody__actions__edit" icon={faPen} />
            </div>
        </div>
    );
};
