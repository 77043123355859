import { FC } from "react";
import { CrudHeaderAvatarProps, CrudHeaderBadge, CrudHeaderType } from "./types";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";
import { faCheck, faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { Badge } from "app/components_v2/Badge/Badge";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { CrudHeaderLeftNodeSkelleton } from "app/components_v2/__skelletons/CrudHeaderLeftNodeSkelleton/CrudHeaderLeftNodeSkelleton";

type CrudHeaderLeftNodeProps = {
    title?: string;
    avatar?: CrudHeaderAvatarProps;
    type: CrudHeaderType;
    badge?: CrudHeaderBadge;
    isActive?: boolean;
    isLoading?: boolean;
    isClosed?: boolean;
    isCompanyEdit?: boolean;
    leftNodeOneColumn?: boolean;
};

export const CrudHeaderLeftNode: FC<CrudHeaderLeftNodeProps> = ({
    title,
    avatar,
    type,
    badge,
    isActive,
    isLoading,
    isClosed,
    isCompanyEdit,
    leftNodeOneColumn,
}) => {
    const { width } = useWindowSize();
    const { t } = useTranslation();
    const getBadgeTitle = (isBadgeActive: boolean) => {
        if (isCompanyEdit && width > screenSize.TABLET) {
            return isClosed ? t(TranslationKeys.COMPANY_CLOSED) : t(TranslationKeys.COMPANY_OPEN);
        } else {
            const activeTitle = badge?.title || t(TranslationKeys.TITLE_ACTIVE);
            const inactiveTitle = badge?.title || t(TranslationKeys.TITLE_INACTIVE);

            return width < screenSize.TABLET && !badge?.hideIcon ? "" : isBadgeActive ? activeTitle : inactiveTitle;
        }
    };

    const showTitle = title !== undefined;
    const showAvatar = !!avatar;
    const showBadge = !!(badge && badge.isVisible);

    const getIcon = () => {
        if (badge?.hideIcon) return undefined;
        if (badge?.icon) return badge?.icon;
        return isActive || (isCompanyEdit && !isClosed) ? faCheck : faCircleXmark;
    };

    const getVariant = () => {
        if (badge?.variant) return badge?.variant;
        return isActive || (isCompanyEdit && !isClosed) ? "green" : "grey";
    };

    return (
        <div className={`crudLayoutLeftNode${leftNodeOneColumn ? "--column" : ""}`}>
            {isLoading ? (
                <CrudHeaderLeftNodeSkelleton showTitle={showTitle} showAvatar={showAvatar} showBadge={showBadge} />
            ) : (
                <>
                    {showTitle && (
                        <h1 className={`crudLayoutLeftNode__title${leftNodeOneColumn ? "--column" : ""}`}>{title}</h1>
                    )}
                    {showAvatar && (
                        <div className="newUserTabs__header__container">
                            <Avatar size={width < screenSize.TABLET ? "sm" : "lg"} {...avatar} />
                            <p
                                className={`newUserTabs__header__container__username${
                                    width >= screenSize.TABLET ? "--lg" : ""
                                }`}
                            >
                                {avatar.avatarName}
                            </p>
                        </div>
                    )}
                    {showBadge && (
                        <Badge
                            icon={getIcon()}
                            size="sm"
                            variant={getVariant()}
                            iconPosition="left"
                            title={getBadgeTitle(!!isActive)}
                            theme="dark"
                        />
                    )}
                </>
            )}
        </div>
    );
};
