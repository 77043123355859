import { AttachedFile } from "app/components_v2/AttachedFile/AttachedFile";
import { NotificationFileModel } from "app/models/02-TAR/AlertNotification/AlertNotificationModel";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type AlertPillFilesProps = {
    audio?: string;
    files?: NotificationFileModel[];
};

export const AlertPillFiles: FC<AlertPillFilesProps> = ({ audio, files }) => {
    const { t } = useTranslation();

    if (!audio && !files) return null;

    return (
        <div className="alertPillFiles text_clamp text_clamp-1">
            {(audio && files) || files ? (
                <AttachedFile
                    message={`${audio ? files.length + 1 : files.length} ${
                        files.length === 1 ? t(TranslationKeys.FILE) : t(TranslationKeys.FILES)
                    }`}
                    isSmall
                />
            ) : (
                audio && <AttachedFile message={audio} isSmall />
            )}
        </div>
    );
};
