import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { RadioButton } from "app/components_v2/RadioButton/RadioButton";
import { DateRangePicker } from "app/components_v2/__inputs/DateRangePicker/DateRangePicker";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Step4, taskEnds } from "app/models/02-TAR/TaskWizard";

type TaskEndsSpecificDayProps = {
    onChange: (fields: Partial<Step4>) => void;
    finish: taskEnds;
    customError?: string;
    min: string;
    disabled?: boolean;
    className?: string;
    errorClassName?: string;
    inverted?: boolean;
};

export const TaskEndsSpecificDay: FC<TaskEndsSpecificDayProps> = ({
    finish,
    onChange,
    customError,
    min,
    disabled,
    inverted,
}) => {
    const { t } = useTranslation();

    if (finish.checked !== "el" && disabled) return null;
    return (
        <div className="taskEndsContainer">
            <div className="taskEndsContainer__radioInput">
                <RadioButton
                    name="taskEnds"
                    onChange={() =>
                        onChange({
                            finish: {
                                ...finish,
                                checked: "el",
                                value: "",
                            },
                        })
                    }
                    checked={finish.checked === "el"}
                    label={t(TranslationKeys.TASK_END_LABEL)}
                    htmlFor={"taskEnds_el"}
                    disabled={disabled}
                    inverted={inverted}
                />
                <div className="taskEndsContainer__radioInput__date">
                    <DateRangePicker
                        startDate={
                            finish.value && typeof finish.value === "string" && finish.checked === "el"
                                ? new Date(finish.value)
                                : undefined
                        }
                        onChange={(date: Date) => {
                            onChange({
                                finish: {
                                    ...finish,
                                    value: date.toISOString(),
                                },
                            });
                        }}
                        range={false}
                        disabled={!disabled ? finish.checked !== "el" : true}
                        placeholder={t(TranslationKeys.PLACEHOLDER_WIZARD_TASK_START)}
                        min={new Date(min)}
                        inverted={inverted}
                    />
                </div>
            </div>
            {!!customError?.length && <ErrorMessage errorMessage={customError} />}
        </div>
    );
};
