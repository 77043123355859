import { ConfirmModal } from "../../ConfirmModal/ConfirmModal";
import { faTemperatureList } from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { FullScreenQrModal } from "../../FullScreenQrModal/FullScreenQrModal";
import { MailModal } from "../../base/MailModal/MailModal";
import { SensorModalTranslations, TranslationCommon } from "app/translation/translationKeys";
import { SensorNotLinkedModalFooter } from "./SensorNotLinkedModalFooter/SensorNotLinkedModalFooter";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useSensorNotLinkedModal } from "./hooks/useSensorNotLinkedModal";
import { useTranslation } from "react-i18next";

type SensorNotLinkedModalProps = {
    sensorSerialNumber: string;
    onClose: () => void;
};

export const SensorNotLinkedModal: FC<SensorNotLinkedModalProps> = ({ sensorSerialNumber, onClose }) => {
    const { t } = useTranslation();
    const {
        assetStatusPostScanned,
        handleCloseReplaceScanModal,
        handleRedirectCreateAsset,
        handleReplaceSensor,
        handleScanSensorQr,
        isAssetReplacingLoading,
        setShowReplaceModal,
        showConfirmModal,
        showReplaceModal,
        assetSearched,
    } = useSensorNotLinkedModal({ onClose, sensorSerialNumber });
    return (
        <>
            <MailModal
                closeButton={{ text: t(TranslationCommon.CANCEL), onClick: onClose, variant: "purple" }}
                onClose={onClose}
                title={t(SensorModalTranslations.SENSOR_NOT_LINKED_TITLE).replace("{0}", sensorSerialNumber)}
                variant="purple"
            >
                <div className="sensorNotLinkedModal">
                    <TableError
                        icon={faTemperatureList}
                        title={t(SensorModalTranslations.SENSOR_NOT_LINKED_PLACEHOLDER_TITLE)}
                        description={`${t(SensorModalTranslations.SENSOR_NOT_LINKED_DESCRIPTION)}.`}
                    />
                    <SensorNotLinkedModalFooter
                        onRedirectCreateAsset={handleRedirectCreateAsset}
                        onReplaceAsset={() => setShowReplaceModal(true)}
                    />
                </div>
            </MailModal>
            {showReplaceModal && (
                <FullScreenQrModal
                    onClose={() => setShowReplaceModal(false)}
                    onScanQr={handleScanSensorQr}
                    type="SENSOR"
                    title={t(SensorModalTranslations.SENSOR_NOT_LINKED_QR_SCANNER_TITLE)}
                />
            )}
            {showConfirmModal && assetStatusPostScanned === "LINKED" && (
                <ConfirmModal
                    title={t(SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_LINKED_TITLE)}
                    description={t(SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_LINKED_DESCRIPTION).replace(
                        "{0}",
                        assetSearched?.name || ""
                    )}
                    onConfirm={handleReplaceSensor}
                    onConfirmText={t(SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON)}
                    onClose={handleCloseReplaceScanModal}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    variant="purple"
                    isLoading={isAssetReplacingLoading}
                />
            )}
        </>
    );
};
