import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { FC, useEffect, useState } from "react";
import { AlertFilterModel } from "./types";
import Spinner from "app/components_v2/Spinner/Spinner";
import { isEqual } from "lodash";
import { AlertTabsModel } from "app/pages/02-TAR/11-Alerts/types";
import { useAlertFilter } from "./hooks/useAlertFilter";
import { DateRangePicker, SelectOptions } from "app/components_v2/__inputs";
import { useTranslation } from "react-i18next";
import { TranslationKeys, TranslationCommon } from "app/translation/translationKeys";

type AlertFilterProps = {
    filterData: AlertFilterModel;
    initialFilterData: AlertFilterModel;
    isOpen: boolean;
    currentTab: AlertTabsModel;
    onSave: (values: AlertFilterModel) => void;
    onCancel?: () => void;
};

export const AlertFilter: FC<AlertFilterProps> = ({
    isOpen,
    onSave,
    onCancel,
    filterData,
    initialFilterData,
    currentTab,
}) => {
    const { t } = useTranslation();
    const {
        getUsers,
        usersOptions,
        isUserLoading,
        departmentsOptions,
        getDepartments,
        isDepartmentLoading,
        companiesOptions,
        getCompanies,
        isCompanyLoading,
    } = useAlertFilter();

    const [internalFilterData, setInternalFilterData] = useState<AlertFilterModel>(filterData);

    const handleInputChange = (values: Partial<AlertFilterModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleLeaveFilter = () => {
        handleInputChange(filterData || {});
        onCancel && onCancel();
    };

    const onReset = () => handleInputChange(initialFilterData || {});

    useEffect(() => {
        if (isOpen) {
            getUsers();
            getDepartments();
            getCompanies();
        }
    }, [isOpen]);

    return (
        <SecondaryFilter
            isOpen={isOpen}
            onClickOutside={handleLeaveFilter}
            onCancel={handleLeaveFilter}
            onReset={onReset}
            onSave={() => onSave && onSave(internalFilterData)}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
        >
            {isUserLoading || isDepartmentLoading || isCompanyLoading ? (
                <Spinner />
            ) : (
                <>
                    <SelectOptions
                        isMulti={false}
                        onChange={({ value }) => handleInputChange({ userId: value })}
                        options={usersOptions}
                        label={t(TranslationKeys.USER)}
                        selectedValue={internalFilterData.userId}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                    />
                    <SelectOptions
                        isMulti={false}
                        onChange={({ value }) => handleInputChange({ departmentId: value })}
                        options={departmentsOptions}
                        label={t(TranslationKeys.TEAMS)}
                        selectedValue={internalFilterData.departmentId}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                    />
                    <SelectOptions
                        isMulti={false}
                        onChange={({ value }) => handleInputChange({ companyId: value })}
                        options={companiesOptions}
                        label={t(TranslationKeys.COMPANIES)}
                        selectedValue={internalFilterData.companyId}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                    />
                    {currentTab === "sended" && (
                        <DateRangePicker
                            onChange={([seenStartDate, seenEndDate]) =>
                                handleInputChange({ seenStartDate, seenEndDate })
                            }
                            startDate={internalFilterData.seenStartDate}
                            endDate={internalFilterData.seenEndDate}
                            showMoreOptions
                            label={"Fecha vista"}
                        />
                    )}
                    <DateRangePicker
                        onChange={([receivedStartDate, receivedEndDate]) =>
                            handleInputChange({ receivedStartDate, receivedEndDate })
                        }
                        startDate={internalFilterData.receivedStartDate}
                        endDate={internalFilterData.receivedEndDate}
                        showMoreOptions
                        label={currentTab === "programmed" ? "Fecha recibida" : "Fecha recibida"}
                    />
                </>
            )}
        </SecondaryFilter>
    );
};
