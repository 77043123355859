import { useCallback, useEffect, useState } from "react";
import { faArrowDown, faArrowUp, faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { SortedTypeModel, TableMobileHeaderProps } from "../../types";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { IDropDownListItem } from "app/components_v2/Dropdown/types";
import { useWindowSize } from "usehooks-ts";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { screenSize } from "app/shared/Constants";
import { Popover } from "app/components_v2/Popover/Popover";

export const MobileTableHeader = <T extends object>({
    sortedType,
    allowCheckbox,
    headerCheckBox,
    indeterminateCheckbox,
    handleHeaderCheck,
    columns,
    selectedColumn,
    onChangeSortDirectionField,
}: TableMobileHeaderProps<T>) => {
    const { width } = useWindowSize();
    const { t } = useTranslation();

    const [isDropdownvisible, setIsDropdownvisible] = useState<boolean>(false);
    const [internalSortedType, setInternalSortedType] = useState<SortedTypeModel>(sortedType || "default");
    const [popoverTarget, setPopoverTarget] = useState<Element | null>(null);

    const showOrderby = columns.some((x) => !!x.sortedType);
    const showHeader = showOrderby || allowCheckbox;

    const sortIcon = useCallback(() => {
        return internalSortedType === "default"
            ? faArrowUpArrowDown
            : internalSortedType === "asc"
            ? faArrowUp
            : faArrowDown;
    }, [internalSortedType]);

    const onClickItem = (sortField: string, sortDirection?: SortedTypeModel) => {
        if (sortField === selectedColumn && sortDirection === internalSortedType)
            onChangeSortDirectionField(sortField, "default");
        else onChangeSortDirectionField(sortField, sortDirection);
        setIsDropdownvisible(false);
        if (!sortDirection) return;
        setInternalSortedType(sortDirection);
    };

    const getItems = (): IDropDownListItem[] => {
        const items: IDropDownListItem[] = [];

        columns.forEach(({ label, sortedType: colSorted, dataIndex }) => {
            if (typeof label !== "string") return;
            if (!colSorted) return;
            items.push({
                text: `${label} ${t(TranslationCommon.ASCENDENT)}`,
                onClick: () => onClickItem(dataIndex, "asc"),
                active: sortedType === "asc" && selectedColumn === dataIndex,
            });

            items.push({
                text: `${label} ${t(TranslationCommon.DESCENDENT)}`,
                onClick: () => onClickItem(dataIndex, "desc"),
                active: sortedType === "desc" && selectedColumn === dataIndex,
            });
        });

        return items;
    };

    const getSelectedSortField = () => {
        const col = columns.find(({ dataIndex }) => selectedColumn === dataIndex);
        if (!col) return;
        return col.label;
    };

    const hidePopover = () => setIsDropdownvisible(false);

    useEffect(() => {
        if (sortedType) setInternalSortedType(sortedType);
    }, [sortedType]);

    if (!showHeader) return null;

    return (
        <>
            <div className="mobileTableHeader" ref={setPopoverTarget}>
                {isDropdownvisible && width < screenSize.TABLET && (
                    <Popover
                        target={popoverTarget}
                        position="bottomCenter"
                        onScroll={hidePopover}
                        onResize={hidePopover}
                        scrollTarget={document.querySelector(".customContainer")}
                        className="mobileTableHeader__popover"
                    >
                        <DropdownList items={getItems()} size="md" />
                    </Popover>
                )}
                <div className="mobileTableHeader__container">
                    {allowCheckbox && (
                        <CheckBox
                            checked={headerCheckBox}
                            intermediate={indeterminateCheckbox}
                            onChange={handleHeaderCheck}
                        />
                    )}
                    {showOrderby && (
                        <div
                            className="mobileTableHeader__container__info"
                            onClick={() => setIsDropdownvisible((prev) => !prev)}
                        >
                            <p className="mobileTableHeader__container__info__sortBy">
                                {t(TranslationCommon.ORDER_BY_TABLE)} {getSelectedSortField()}
                            </p>
                            {internalSortedType && getSelectedSortField() && (
                                <FontAwesomeIcon
                                    className={`tableHeader__icon--${
                                        internalSortedType === "default" ? "default" : "sorted"
                                    }`}
                                    icon={sortIcon()}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
