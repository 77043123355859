import { FC } from "react";

import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import {
    Step4,
    RecurrentTaskModel,
    RecurrentTaskOption2,
    RecurrentTaskValuesModel,
} from "app/models/02-TAR/TaskWizard";

type SelectRecurringTaskProps = {
    onChange: (fields: Partial<Step4>) => void;
    recurringTask: RecurrentTaskModel;
    recurentTaskSelectorValues: RecurrentTaskOption2[];
    disabled?: boolean;
};

export const SelectRecurringTask: FC<SelectRecurringTaskProps> = ({
    onChange,
    recurentTaskSelectorValues,
    recurringTask,
    disabled,
}) => {
    return (
        <SelectOptions
            isMulti={false}
            onChange={({ label, value }) => {
                onChange({
                    recurringTask: {
                        ...recurringTask,
                        value: value as RecurrentTaskValuesModel,
                        text: label,
                    },
                });
            }}
            selectedValue={recurringTask.value}
            options={recurentTaskSelectorValues}
            disabled={disabled}
            isSearchable={false}
        />
    );
};
