import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseTaskTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { isEqual } from "lodash";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { BaseTaskGridSFModel } from "app/pages/02-TAR/15-BaseTask/state/context/BaseTaskContext";
import { OptionsSearch, OptionsSearchT } from "app/models/FormComponentsModel";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { SelectOptions } from "app/components_v2/__inputs";
import { BaseTaskReportType } from "app/shared/BaseTaskConstantTypes";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";

type BaseTaskFilterProps = {
    filterData: BaseTaskGridSFModel;
    initialFilterData: BaseTaskGridSFModel;
    isSecondaryFilterOpen: boolean;
    onCancel?: () => void;
    onSave?: (values: BaseTaskGridSFModel) => void;
    isOpen: boolean;
    isAPPCC?: boolean;
};

export const BaseTaskFilter: FC<BaseTaskFilterProps> = ({
    filterData,
    initialFilterData,
    isSecondaryFilterOpen,
    onCancel,
    onSave,
    isOpen,
    isAPPCC,
}) => {
    const { t } = useTranslation();

    const [internalFilterData, setInternalFilterData] = useState<BaseTaskGridSFModel>(filterData);
    const [planAPPCCOptions, setPlanAPPCCOptions] = useState<OptionsSearch[]>([]);
    const [baseTaskTypeOptions, setBaseTaskTypeOptions] = useState<OptionsSearch[]>([]);

    const handleInputChange = (values: Partial<BaseTaskGridSFModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleClickOutside = () => {
        if (!isOpen) return;
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    const handleReset = () => handleInputChange(initialFilterData);

    const handleSave = () => onSave && onSave(internalFilterData);

    const baseTaskReportTypeOptions: OptionsSearchT<BaseTaskReportType>[] = [
        { label: t(BaseTaskTranslations.BASE_TASK_ALL_REPORT_TYPES_LABEL), value: "ALL" },
        { label: t(BaseTaskTranslations.BASE_TASK_NONE_REPORT_TYPES_LABEL), value: "NONE" },
        { label: t(BaseTaskTranslations.BASE_TASK_DYNAMIC_FIELD_REPORT_TYPES_LABEL), value: "DYNAMIC_FIELD" },
        { label: t(BaseTaskTranslations.BASE_TASK_CHECKLIST_REPORT_TYPES_LABEL), value: "CHECKLIST" },
        { label: t(BaseTaskTranslations.BASE_TASK_ASSET_REPORT_TYPES_LABEL), value: "ASSET" },
    ];

    const getBaseTaskTypes = async () => {
        const { data, status } = await TarSelectorService.GetBaseTaskTypes();
        if (!status()) {
            setBaseTaskTypeOptions([]);
            return;
        }
        setBaseTaskTypeOptions(data);
    };

    const getPlans = async () => {
        const { status, data } = await QuaSelectorService.GetPlansAPPCC();
        if (!status()) {
            setPlanAPPCCOptions([]);
            return;
        }
        setPlanAPPCCOptions(data);
    };

    useEffect(() => {
        getBaseTaskTypes();
    }, []);

    useEffect(() => {
        if (isSecondaryFilterOpen && isAPPCC) getPlans();
    }, [isSecondaryFilterOpen]);

    return (
        <SecondaryFilter
            isOpen={isSecondaryFilterOpen}
            onClickOutside={handleClickOutside}
            onCancel={handleClickOutside}
            onReset={handleReset}
            onSave={handleSave}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
            variant={isAPPCC ? "purple" : "primary"}
        >
            <SelectOptions
                label={t(BaseTaskTranslations.BASE_TASKS_TYPE_LABEL)}
                selectedValue={internalFilterData.baseTaskTypeId?.toString()}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                options={baseTaskTypeOptions}
                onChange={({ value }) => handleInputChange({ baseTaskTypeId: value })}
                isMulti={false}
            />
            <SelectOptions
                label={t(BaseTaskTranslations.BASE_TASKS_REPORT_TYPE_LABEL)}
                selectedValue={internalFilterData.reportType?.toString()}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                options={baseTaskReportTypeOptions}
                onChange={({ value }) => handleInputChange({ reportType: value as BaseTaskReportType })}
                isMulti={false}
            />
            {isAPPCC && (
                <SelectOptions
                    label={t(BaseTaskTranslations.BASE_TASKS_PLAN_APPCC_LABEL)}
                    selectedValue={internalFilterData.planId?.toString()}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    options={planAPPCCOptions}
                    onChange={({ value }) => handleInputChange({ planId: value })}
                    isMulti={false}
                />
            )}
            <div className="baseTaskFilter__switch">
                <CheckBox
                    checked={internalFilterData.isPhotoRequired || false}
                    onChange={(value) => handleInputChange({ isPhotoRequired: value })}
                    label={t(BaseTaskTranslations.BASE_TASKS_IS_PHOTO_REQUIRED_LABEL)}
                    size="sm"
                />
            </div>
        </SecondaryFilter>
    );
};
