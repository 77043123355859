import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { CellWithAvatar } from "app/components_v2/Table/CellWithAvatar/CellWithAvatar";
import { ColumnsType } from "app/components_v2/Table/types";
import { convertUTCtoLocaleDate, getFullDateWithTimeReadable } from "app/helpers";
import { FC } from "react";
import { getValueTaskInstanceStatus } from "app/helpers/taskInstance/getValueTaskInstanceStatus";
import { INITIAL_TH_SF_VALUES, INITIAL_TH_SORT_DIRECTION } from "../constants/taskHistoryConstants";
import { Input } from "app/components_v2/__inputs";
import { isEqual } from "lodash";
import { MobileTaskInstanceRow } from "./components/MobileTaskInstanceRow/MobileTaskInstanceRow";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { TaskHistoryFilter } from "app/components_v2/__filters/TaskHistoryFilter/TaskHistoryFilter";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { TaskTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTaskHistoryGrid } from "./hooks/useTaskHistoryGrid";
import { useTranslation } from "react-i18next";
import { validateStatus } from "app/helpers/__validates/validateTaskpillStatus";
import {
    faClose,
    faEye,
    faFilter,
    faListCheck,
    faMagnifyingGlass,
    faSearch,
    faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import TaskDetailModal from "../../01-TAR-Dashboard/TaskDetail/TaskDetailModal";

export const TaskHistoryGrid: FC = () => {
    const { t } = useTranslation();

    const {
        data,
        handleChangePageIndex,
        handleCleanFetch,
        handleSaveSecondaryFilter,
        handleSortChange,
        isLoading,
        isModalOpened,
        onCompanyChange,
        onQueryChange,
        onQuerySearch,
        onSelectCompany,
        openTask,
        pageIndex,
        query,
        secondaryFilterValues,
        selectCompany,
        setIsModalOpened,
        setShowSearchInput,
        setShowSecondaryFilter,
        showSearchInput,
        showSecondaryFilter,
        sortDirection,
        sortField,
        subtitle,
        taskInstanceId,
        title,
        total,
        handleExport,
    } = useTaskHistoryGrid();

    const columns: ColumnsType<TaskInstanceModel>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "TaskName",
            sortedType: "default",
            className: "taskInstanceGrid__col",
            render: (record) => <CellTitle title={record.taskName} />,
        },
        {
            label: t(TranslationKeys.TASK_HISTORY_ASIGNED),
            dataIndex: "DeadlineDate",
            sortedType: "desc",
            className: "taskInstanceGrid__col",
            render: (record) => {
                const avatarName = !!record.asignedToUserName?.length
                    ? record.asignedToUserName
                    : !!record.asignedToSubdepartmentName?.length
                      ? record.asignedToSubdepartmentName
                      : !!record.asignedToWorkingPositionName?.length
                        ? record.asignedToWorkingPositionName
                        : !!record.asignedToDepartmentName?.length
                          ? record.asignedToDepartmentName
                          : "";

                const colorId =
                    !!record.asignedToUserName?.length && !!record.asignedToUserId
                        ? record.asignedToUserId
                        : !!record.asignedToSubdepartmentName?.length && !!record.asignedToSubdepartmentId
                          ? record.asignedToSubdepartmentId
                          : !!record.asignedToWorkingPositionName?.length && !!record.asignedToWorkingPositionId
                            ? record.asignedToWorkingPositionId
                            : !!record.asignedToDepartmentName?.length && !!record.asignedToDepartmentId
                              ? record.asignedToDepartmentId
                              : 0;
                const badgeTitle =
                    record.deadlineDate != null && record.taskType === "NORMAL"
                        ? getFullDateWithTimeReadable(convertUTCtoLocaleDate(record.deadlineDate).toString())
                        : t(TaskTranslations.TASK_TYPE_SPORADIC);
                return (
                    <CellWithAvatar
                        avatarName={avatarName}
                        colorId={colorId}
                        badgeTitle={badgeTitle}
                        badgeVariant="grey"
                        img={record.asignedToUserId != null ? record.profilePictureURL : undefined}
                    />
                );
            },
        },
        {
            label: t(TranslationKeys.TASK_HISTORY_FINISHED_BY),
            dataIndex: "FinishedDate",
            sortedType: "default",
            className: "taskInstanceGrid__col",
            render: (record) => {
                const statusInfo = validateStatus(record);
                const finishedDate = record.finishedDate || new Date();
                const badgeTitle = getFullDateWithTimeReadable(convertUTCtoLocaleDate(finishedDate).toString());
                const value = getValueTaskInstanceStatus({
                    t,
                    type: statusInfo,
                    date: badgeTitle,
                });
                return (
                    <CellWithAvatar
                        avatarName={record.finishedByUserName}
                        colorId={record.editedBy || 0}
                        badgeTitle={value?.value || ""}
                        badgeVariant={value?.badgeVariant || "grey"}
                        icon={value.icon}
                        img={!!record.finishedByUserName ? record.profilePictureURL : undefined}
                    />
                );
            },
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "acciones",
            className: "taskInstanceGrid__actions",
            render: ({ id }) => (
                <CellIcons
                    icons={[
                        {
                            icon: faEye,
                            onClick: () => openTask(id),
                            title: t(TranslationCommon.VIEW),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <PageContainer paddingTop>
            <TaskHistoryFilter
                filterData={secondaryFilterValues}
                initialFilterData={INITIAL_TH_SF_VALUES}
                showSecondaryFilter={showSecondaryFilter}
                onCancel={() => setShowSecondaryFilter(false)}
                onSave={handleSaveSecondaryFilter}
                companyId={selectCompany.company.value}
            />
            <TableCollapsable
                cols={columns}
                data={data}
                placeholder={<TableError icon={faListCheck} />}
                title={title}
                subTitle={subtitle}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={10}
                total={total}
                onDoubleClick={({ id }) => {
                    openTask(id);
                }}
                onChangePageIndex={handleChangePageIndex}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                    handleSortChange(sortFieldParam, sortedTypeParam || INITIAL_TH_SORT_DIRECTION);
                }}
                onClickExportCsv={handleExport}
                actionButtons={[
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <Input
                                    focus
                                    onChange={onQueryChange}
                                    iconRight={!!query?.length ? faSearch : faClose}
                                    value={query}
                                    onClickIcon={handleCleanFetch}
                                    onPressEnter={onQuerySearch}
                                    whiteInput
                                    transparent
                                />
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                    {
                        icon: faFilter,
                        onClick: () => {
                            onCompanyChange({ isOpen: true });
                            setShowSearchInput(false);
                        },
                        inputComponent: {
                            component: (
                                <TableSelectCompany
                                    onChange={onSelectCompany}
                                    value={selectCompany.company}
                                    onClickOutside={() => onCompanyChange({ isOpen: false })}
                                />
                            ),
                            show: selectCompany.isOpen,
                        },
                        title: t(TranslationCommon.CHANGE_COMPANY),
                    },
                    {
                        icon: faSliders,
                        onClick: () => setShowSecondaryFilter(true),
                        showMarkableIcon: !isEqual(secondaryFilterValues, INITIAL_TH_SF_VALUES),
                        title: t(TranslationCommon.FILTER),
                    },
                ]}
                mobileBody={(row, _i, rowPosition) => (
                    <MobileTaskInstanceRow
                        key={row.id}
                        data={row}
                        onClick={() => {
                            openTask(row.id);
                        }}
                        rowPosition={rowPosition}
                    />
                )}
            />
            {isModalOpened && (
                <TaskDetailModal
                    onClose={() => setIsModalOpened(false)}
                    taskInstanceId={String(taskInstanceId)}
                    editTaskDetail={false}
                    isReviewPhotos
                    showValoratedBy
                    editStarRating={false}
                    allowOpenIssue={false}
                />
            )}
        </PageContainer>
    );
};
