import { AuditGroupCheckListItemModel } from "app/models/05-QUA/AuditModels/AuditGroupCheckListItemModels";
import { BaseAuditGroupCheckListItemModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemModels/BaseAuditGroupCheckListItemModel";
import { OptionsSearch } from "app/models/FormComponentsModel";

export const mapBaseAuditGroupCheckListItemsToOptions = (
    baseAuditGroupCheckListItems: BaseAuditGroupCheckListItemModel[]
): OptionsSearch[] => baseAuditGroupCheckListItems.map(({ name, id }) => ({ label: name, value: id.toString() }));

export const mapBaseAuditGroupCheckListItemToAuditGroupCheckListItem = ({
    id,
    name,
    ponderationLevel,
}: BaseAuditGroupCheckListItemModel): AuditGroupCheckListItemModel => ({
    name: name,
    ponderationLevel,
    fK_BaseAuditGroupCheckListItem: id,
    alwaysRequired: true,
    isDeleted: false,
    fK_AuditGroupCheckList: 0,
    id: 0,
});
