import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellPhoto } from "app/components_v2/Table/CellPhoto/CellPhoto";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType, SortedTypeModel } from "app/components_v2/Table/types";
import { CompanyModel } from "app/models/01-SEG/Company/CompanyModels";
import { CompanyService } from "app/services";
import CompanyWizard from "app/components_v2/wizards/Company/CompanyWizard";
import { faBuildings, faCirclePlus, faMagnifyingGlass, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FC, useEffect, useState } from "react";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GroupCompanyModal } from "../GroupCompanyModal";
import {
    hasPermission,
    hasPermissionToAdd,
    hasPermissionToDelete,
    hasPermissionToEdit,
} from "app/routes/HelperRoleBasedAccess";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { MobileCompanyGridBody } from "./MobileCompanyGridBody/MobileCompanyGridBody";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { useNavigate } from "react-router";
import { useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";

type Props = {
    groupId: number;
    showOpened: boolean;
    groupName: string;
    onEdit: (value: boolean) => void;
    onDelete: () => void;
};

const INITIAL_SORT_FIELD = "name";
const INITIAL_SORT_DIRECTION: SortedTypeModel | undefined = "asc";

const CompaniesGrid: FC<Props> = ({ groupId, showOpened, groupName, onEdit, onDelete }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleToast } = useToast();
    const session = useSession();

    useTitle(t(TranslationTitles.GROUP_COMPANY_PAGE_TITLE));

    const [data, setData] = useState<CompanyModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const [sortField, setSortField] = useState<string>(INITIAL_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel | undefined>(INITIAL_SORT_DIRECTION);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isOpen, setisOpen] = useState<boolean>(showOpened);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [wizardCompanyOpen, setWizardCompanyOpen] = useState(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const columns: ColumnsType<CompanyModel>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "name",
            render: ({ logoFileURL, name }) => <CellPhoto name={name} img={logoFileURL} />,
            sortedType: "asc",
        },
        {
            label: t(TranslationKeys.LABEL_ADDRESS),
            dataIndex: "address",
            render: (value) => <CellTitle title={value.address} />,
            sortedType: "default",
        },
        {
            label: t(TranslationCommon.EDIT),
            dataIndex: "edit",
            hidden: !hasPermission(SecScreen.CENTERS_MANAGEMENT),
            className: "companyGrid__actions",
            render: (record) => (
                <CellIcons
                    icons={[
                        { icon: faPen, onClick: () => navigate(`edit/${record.id}`), title: t(TranslationCommon.EDIT) },
                    ]}
                />
            ),
            alignCenter: true,
        },
    ];

    const getCompany = async (pageOne?: boolean) => {
        setIsLoading(true);
        pageOne && setPageIndex(1);

        const extraparams = `GroupCompanyId=${groupId}`;

        const {
            data: companieData,
            error,
            status,
        } = await CompanyService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageOne ? 0 : pageIndex - 1,
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
        });

        firstLoad && setFirstLoad(false);
        if (!status()) {
            console.error(error);
            handleToast({ title: error, variant: "danger" });
            setIsLoading(false);
            return;
        }

        setData(companieData.list);
        setTotal(companieData.count);
        setIsLoading(false);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const extraparams = `GroupCompanyId=${groupId}`;

        return CompanyService.GetDataExport({
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
            ...params,
        });
    };

    const handleCleanFetch = () => {
        setQuery("");
        setShowSearchInput(false);
        getCompany(true);
    };

    const handleEdit = (success: boolean) => {
        onEdit(success);
        setIsEditModalOpen(false);
    };

    useEffect(() => {
        getCompany();
    }, [pageIndex, sortField, sortDirection]);

    return (
        <>
            {isEditModalOpen && (
                <GroupCompanyModal
                    onClose={() => setIsEditModalOpen(false)}
                    onSubmit={handleEdit}
                    title={t(TranslationKeys.EDIT_COMPANY_GROUP_TITLE)}
                    values={{
                        customerInstanceId: session?.user.customerInstanceId || 0,
                        id: groupId,
                        name: groupName,
                        isActive: true,
                        isDeletable: false,
                    }}
                    isEdit={true}
                />
            )}
            {wizardCompanyOpen && (
                <GenericModal
                    header={{
                        title: t(TranslationKeys.NEW_COMPANY),
                        onClose: () => setWizardCompanyOpen(false),
                    }}
                    size="lg"
                    hideChildrenPadding
                >
                    <CompanyWizard
                        closeModal={() => {
                            setWizardCompanyOpen(false);
                            getCompany(true);
                        }}
                        selectedCompanyGroup={{ value: String(groupId), label: groupName }}
                    />
                </GenericModal>
            )}
            <TableCollapsable
                title={groupName}
                onClickCollapsable={() => setisOpen(!isOpen)}
                actionButtons={[
                    {
                        icon: faCirclePlus,
                        onClick: () => setWizardCompanyOpen(true),
                        hidden: !hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT),
                        title: t(TranslationCommon.CREATE),
                    },
                    {
                        icon: faPen,
                        onClick: () => {
                            setIsEditModalOpen(true);
                        },
                        hidden: !hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT),
                        title: t(TranslationCommon.EDIT),
                    },
                    {
                        icon: faTrash,
                        onClick: onDelete,
                        hidden: !hasPermissionToDelete(SecScreen.CENTERS_MANAGEMENT),
                        title: t(TranslationCommon.DELETE),
                    },
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <TableInputText
                                    onChange={setQuery}
                                    value={query}
                                    fetch={() => getCompany(true)}
                                    cleanFunction={handleCleanFetch}
                                />
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                ]}
                isOpen={isOpen}
                cols={columns}
                data={data}
                placeholder={<TableError icon={faBuildings} />}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={setPageIndex}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                    setSortField(sortFieldParam);
                    setSortDirection(sortedTypeParam);
                }}
                onClickExportCsv={handleExport}
                onDoubleClick={(record) => navigate(`edit/${record.id}`)}
                mobileBody={(row) => <MobileCompanyGridBody key={row.id} company={row} />}
                fitHeight={(firstLoad && !isLoading) || (!firstLoad && total < 10)}
            />
        </>
    );
};

export default CompaniesGrid;
