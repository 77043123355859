import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { DateRangePicker } from "app/components_v2/__inputs/DateRangePicker/DateRangePicker";
import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";

type TaskStartProps = {
    taskStart: string;
    customError?: string;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
    disabled?: boolean;
    min?: Date;
};

export const TaskStart: FC<TaskStartProps> = ({ taskStart, onChange, customError, disabled, min }) => {
    const { t } = useTranslation();

    return (
        <DateRangePicker
            startDate={taskStart ? new Date(taskStart) : new Date()}
            label={t(TranslationKeys.TASK_START)}
            range={false}
            placeholder={t(TranslationKeys.PLACEHOLDER_WIZARD_TASK_START)}
            onChange={(date: Date) => {
                onChange({
                    taskStart: date.toISOString(),
                });
            }}
            errorMessage={customError}
            disabled={disabled}
            min={min}
        />
    );
};
