import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { RadioButton } from "app/components_v2/RadioButton/RadioButton";
import { Label } from "app/components_v2/__inputs/Label";

type SelectSubDepartmentsProps = {
    changeSubDepartmentSelected: (value: string, text: string) => void;
    value: string;
    options: OptionModel[];
    isEnabled: boolean;
    isDisabled?: boolean;
};

export const SelectSubDepartments: FC<SelectSubDepartmentsProps> = ({
    changeSubDepartmentSelected,
    isEnabled,
    options,
    value,
    isDisabled,
}) => {
    const { t } = useTranslation();

    const [selectorOptions, setSelectorOptions] = useState<OptionModel[]>([]);

    useEffect(() => {
        setSelectorOptions(options);
    }, [options]);

    return (
        <div className="stepTwoSelectWithRadio">
            <Label
                label={`${t(TranslationKeys.SUBDEPARTMENT)} (${t(TranslationKeys.INPUT_OPTIONAL)})`}
                className="stepTwoSelectWithRadio__label"
            />
            <div
                className={`stepTwoSelectWithRadio__container ${
                    isDisabled ? "stepTwoSelectWithRadio__container--disabled" : ""
                }`}
            >
                {!isDisabled && <RadioButton checked={!isEnabled} disabled={isDisabled} />}
                <SelectOptions
                    options={selectorOptions}
                    isMulti={false}
                    onChange={(newValue) => {
                        changeSubDepartmentSelected(newValue.value, newValue.label);
                    }}
                    selectedValue={value}
                    htmlFor="selectSubdepartment"
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
};
