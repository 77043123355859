import { useEffect, useState } from "react";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSuccessHandler } from "app/hooks/SuccessHandler/useSuccessHandler";
import { useTranslation } from "react-i18next";
import BaseAuditGroupCheckListItemService from "app/services/05-QUA/BaseAuditGroupCheckListItemService";
import { BaseAuditGroupCheckListItemTranslations, TranslationModals } from "app/translation/translationKeys";
import {
    INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM_ERRORS,
    INITIAL_FETCH_BASE_AUDIT_GROUP_CHECKLIST_ITEM,
} from "../constants/BaseAuditGroupCheckListItemConstants";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { ActionType } from "app/models/FormComponentsModel";
import {
    BaseAuditGroupCheckListItemErrorsModel,
    BaseAuditGroupCheckListItemGridModel,
} from "../models/BaseAuditGroupCheckListItemGridModels";
import { BaseAuditGroupCheckListItemModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemModels/BaseAuditGroupCheckListItemModel";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";

export const useBaseAuditGroupCheckListItem = () => {
    const { t } = useTranslation();
    const { handleErrorManager } = useErrorManager();
    const { getErrorMessage } = useFetchErrors();
    const { handleSuccessManager } = useSuccessHandler();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [baseAuditGroupCheckListItem, setBaseAuditGroupCheckListItem] = useState<BaseAuditGroupCheckListItemModel>();
    const [filterValues, setFilterValues] = useState<BaseAuditGroupCheckListItemGridModel>(
        INITIAL_FETCH_BASE_AUDIT_GROUP_CHECKLIST_ITEM
    );
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [baseAuditGroupCheckListItems, setBaseAuditGroupCheckListItems] = useState<
        BaseAuditGroupCheckListItemModel[]
    >([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [baseAuditGroupCheckListItemErrors, setBaseAuditGroupCheckListItemErrors] =
        useState<BaseAuditGroupCheckListItemErrorsModel>(INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM_ERRORS);

    const getBaseAuditGroupCheckListItems = async ({
        query,
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        extraParams,
    }: BaseAuditGroupCheckListItemGridModel) => {
        setIsLoading(true);

        const { data, status, getParsedError } = await BaseAuditGroupCheckListItemService.GetData({
            pageSize,
            pageIndex: pageIndex !== 0 ? pageIndex - 1 : 0,
            query,
            sortDirection,
            sortField,
            extraParams,
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setBaseAuditGroupCheckListItems([]);
            setTotal(0);
            setIsLoading(false);
            return;
        }
        setBaseAuditGroupCheckListItems(data.list);
        setTotal(data.count);

        setIsLoading(false);
    };

    const handleEditClick = (record: BaseAuditGroupCheckListItemModel) => {
        setBaseAuditGroupCheckListItem(record);
        setShowEditModal(true);
    };

    const handleDeleteClick = (record: BaseAuditGroupCheckListItemModel) => {
        setBaseAuditGroupCheckListItem(record);
        setShowDeleteModal(true);
    };

    const handleQueryChange = (value: string) => setFilterValues((prev) => ({ ...prev, query: value }));

    const handleSearch = () => {
        if (!!filterValues.query?.length) {
            getBaseAuditGroupCheckListItems({ ...filterValues, pageIndex: 0 });
            setFilterValues((prev) => ({ ...prev, pageIndex: 1 }));
            return;
        }
        handleCleanFetch();
    };

    const handleCleanFetch = () => {
        handleQueryChange("");
        setShowSearchInput(false);
        getBaseAuditGroupCheckListItems(filterValues);
        setFilterValues(filterValues);
    };

    const handleDelete = async () => {
        if (!baseAuditGroupCheckListItem) return;

        setIsLoadingModal(true);

        const { status, getParsedError } = await BaseAuditGroupCheckListItemService.Delete(
            baseAuditGroupCheckListItem.id
        );

        handleResponse(
            status(),
            getParsedError(),
            TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_SUCCESS_TOAST,
            setShowDeleteModal
        );
    };

    const handleSubmit = async (type: ActionType, item: BaseAuditGroupCheckListItemModel) => {
        if (!validate(item)) return;

        setIsLoadingModal(true);

        if (type === "create") {
            await handleSave(item);
            return;
        }
        await handleEdit(item);
    };

    const handleErrorChange = (values: Partial<BaseAuditGroupCheckListItemErrorsModel>) =>
        setBaseAuditGroupCheckListItemErrors((prev) => ({ ...prev, ...values }));

    const validate = (item: BaseAuditGroupCheckListItemModel): boolean => {
        setBaseAuditGroupCheckListItemErrors(INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM_ERRORS);
        let check = true;

        if (!item.name.length) {
            handleErrorChange({
                nameError: t(BaseAuditGroupCheckListItemTranslations.NAME_IS_REQUIRED),
            });
            check = false;
        }

        if (isNaN(item.fK_BaseAuditGroupCheckListItemType) || item.fK_BaseAuditGroupCheckListItemType === 0) {
            handleErrorChange({
                fK_BaseAuditGroupCheckListItemTypeError: t(
                    BaseAuditGroupCheckListItemTranslations.FK_BASEAUDITGROUPCHECKLISTITEMTYPE_IS_REQUIRED
                ),
            });
            check = false;
        }

        if (isNaN(item.ponderationLevel)) {
            handleErrorChange({
                ponderationLevelError: t(BaseAuditGroupCheckListItemTranslations.PONDERATIONLEVEL_IS_REQUIRED),
            });
            check = false;
        } else if (item.ponderationLevel < 0 || item.ponderationLevel > 10) {
            handleErrorChange({
                ponderationLevelError: t(BaseAuditGroupCheckListItemTranslations.PONDERATION_RANGE_ERROR),
            });
            check = false;
        }

        return check;
    };

    const handleSave = async (baseAuditGroupCheckListItem: BaseAuditGroupCheckListItemModel) => {
        const { status, getParsedError } = await BaseAuditGroupCheckListItemService.Save(baseAuditGroupCheckListItem);

        handleResponse(
            status(),
            getParsedError(),
            TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_SAVE,
            setShowEditModal
        );
    };

    const handleEdit = async (baseAuditGroupCheckListItem: BaseAuditGroupCheckListItemModel) => {
        const { status, getParsedError } = await BaseAuditGroupCheckListItemService.Edit(
            baseAuditGroupCheckListItem.id,
            baseAuditGroupCheckListItem
        );

        handleResponse(
            status(),
            getParsedError(),
            TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_EDIT,
            setShowEditModal
        );
    };

    const handleResponse = (
        status: boolean,
        error: string,
        successToastTitle: string,
        isOpenModal: (value: boolean) => void
    ) => {
        const errorMessage = getErrorMessage(error);
        if (handleErrorManager(status, errorMessage)) {
            setIsLoadingModal(false);
            setBaseAuditGroupCheckListItem(undefined);
            return;
        }

        handleSuccessManager(t(successToastTitle));
        isOpenModal(false);
        setIsLoadingModal(false);
        getBaseAuditGroupCheckListItems(filterValues);
        setBaseAuditGroupCheckListItem(undefined);
    };

    const handleClose = (type?: ActionType) => {
        type === "edit" ? setShowEditModal(false) : setShowDeleteModal(false);
        setBaseAuditGroupCheckListItem(undefined);
        setBaseAuditGroupCheckListItemErrors(INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM_ERRORS);
    };

    const handleChangePageIndex = (pageIndex: number) => {
        setFilterValues((prev) => ({ ...prev, pageIndex }));
        getBaseAuditGroupCheckListItems({ ...filterValues, pageIndex });
    };

    const handleChangeSortDirectionField = (sortField: string, sortDirection?: SortedTypeModel) => {
        setFilterValues((prev) => ({ ...prev, sortField, sortDirection }));
        getBaseAuditGroupCheckListItems({ ...filterValues, sortField, sortDirection });
    };

    const handleExportCsv = (params?: IDownLoadCsvReturn) =>
        BaseAuditGroupCheckListItemService.Export({
            query: filterValues.query,
            sortDirection: filterValues.sortDirection,
            sortField: filterValues.sortDirection,
            ...params,
        });

    useEffect(() => {
        getBaseAuditGroupCheckListItems(INITIAL_FETCH_BASE_AUDIT_GROUP_CHECKLIST_ITEM);
    }, []);

    return {
        baseAuditGroupCheckListItems,
        total,
        isLoading,
        filterValues,
        showSearchInput,
        setShowSearchInput,
        baseAuditGroupCheckListItem,
        setShowEditModal,
        showEditModal,
        isLoadingModal,
        showDeleteModal,
        setShowDeleteModal,
        baseAuditGroupCheckListItemErrors,
        handleSearch,
        handleSubmit,
        handleClose,
        handleDelete,
        handleEditClick,
        handleDeleteClick,
        handleQueryChange,
        handleChangePageIndex,
        handleChangeSortDirectionField,
        handleExportCsv,
    };
};
