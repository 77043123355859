import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    AuditTranslation,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { getDayMonthFormatedWithYearNumbers } from "app/helpers";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { useTitle } from "app/hooks";
import { Tabs } from "app/components_v2/Tabs/Tabs";
import { useEditAudit } from "./hook/useEditAudit";
import { CrudHeaderProps } from "app/components_v2/Layout/CrudHeader/CrudHeader";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";

export const EditAudit = () => {
    const { id: auditId } = useParams();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const nav = useNavigate();
    const actualStatus: AuditInstanceStatus = pathname.includes("completed")
        ? "COMPLETED"
        : pathname.includes("reviewed")
          ? "REVIEWED"
          : pathname.includes("closed")
            ? "CLOSED"
            : "IN_PROGRESS";

    useTitle(
        t(
            actualStatus === "IN_PROGRESS"
                ? TranslationTitles.RESOLVE_AUDIT_PAGE_TITLE
                : actualStatus === "COMPLETED"
                  ? TranslationTitles.COMPLETED_AUDIT_PAGE_TITLE
                  : actualStatus === "REVIEWED"
                    ? TranslationTitles.REVIEWED_AUDIT_PAGE_TITLE
                    : actualStatus === "CLOSED"
                      ? TranslationTitles.REVIEW_AUDIT_PAGE_TITLE
                      : TranslationTitles.NEW_AUDIT_PAGE_TITLE
        )
    );

    const {
        isEditAuditHeaderLoading,
        editAuditHeaderData,
        tabs,
        currentTab,
        footers,
        confirmAuditStartModalSubmit,
        setAuditStartIsConfirmModalOpen,
        isAuditStartConfirmModalOpen,
        confirmAuditIssuesModalSubmit,
        isAuditIssuesConfirmModalOpen,
        setAuditIssuesIsConfirmModalOpen,
        isCloseAuditModalOpen,
        setIsCloseAuditModalOpen,
        handleCloseAudit,
        renderAuditStars,
        auditInstance,
    } = useEditAudit({
        auditInstanceId: Number(auditId),
        actualStatus,
    });

    const headerOptions: CrudHeaderProps = {
        type: "delete",
        title: !isEditAuditHeaderLoading
            ? `${t(
                  TranslationKeys.AUDIT
              )} "${editAuditHeaderData?.auditName}" #${auditId} - ${getDayMonthFormatedWithYearNumbers(
                  editAuditHeaderData?.closeDate
                      ? editAuditHeaderData?.closeDate.toString()
                      : editAuditHeaderData?.openDate.toString() || "",
                  "/"
              )}`
            : "",
        variant: "purple",
        isLoading: isEditAuditHeaderLoading,
        badge: {
            title:
                actualStatus === "IN_PROGRESS"
                    ? t(AuditTranslation.AUDIT_IN_PROGRESS)
                    : actualStatus === "COMPLETED"
                      ? t(AuditTranslation.AUDIT_IN_REVIEW)
                      : actualStatus === "REVIEWED"
                        ? t(AuditTranslation.AUDIT_REVIEWED_SINGULAR)
                        : t(TranslationKeys.AUDIT_CLOSED),
            isVisible: true,
            variant:
                actualStatus === "IN_PROGRESS"
                    ? "blue"
                    : actualStatus === "COMPLETED"
                      ? "orange"
                      : actualStatus === "REVIEWED"
                        ? "orange"
                        : "green",
            hideIcon: true,
        },
        isActive: actualStatus !== "IN_PROGRESS",
        tabsOptions: {
            tabs: tabs.some((tab) => !tab.hidden) ? (
                <Tabs tabs={tabs} currentTab={currentTab} hideChildren />
            ) : undefined,
        },
        topNode: currentTab === 2 && (
            <NewButton
                text={t(TranslationCommon.PRINT)}
                handleClickButton={() =>
                    nav(`print?auditId=${editAuditHeaderData?.fK_Audit}&companyId=${editAuditHeaderData?.fK_Company}`)
                }
                iconLeft={faPrint}
                buttonType={"emptyWhite"}
            />
        ),
        rightNode: currentTab === 2 && renderAuditStars(auditInstance?.grade ?? 0, "white"),
        leftNodeOneColumn: true,
    };

    return (
        <>
            {isAuditStartConfirmModalOpen && (
                <ConfirmModal
                    onConfirm={confirmAuditStartModalSubmit}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setAuditStartIsConfirmModalOpen(false)}
                    title={t(TranslationKeys.AUDIT_FINISH_CONFIRM_MODAL_TITLE)}
                    description={t(TranslationKeys.AUDIT_FINISH_CONFIRM_MODAL_SUBTITLE)}
                    portal
                    variant="purple"
                />
            )}
            {isAuditIssuesConfirmModalOpen && (
                <ConfirmModal
                    onConfirm={confirmAuditIssuesModalSubmit}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setAuditIssuesIsConfirmModalOpen(false)}
                    title={t(AuditTranslation.AUDIT_FINISH_MODAL_TITLE)}
                    description={t(AuditTranslation.AUDIT_FINISH_MODAL_DESCRIPTION)}
                    portal
                    variant="purple"
                />
            )}
            {isCloseAuditModalOpen && (
                <ConfirmModal
                    onConfirm={handleCloseAudit}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setIsCloseAuditModalOpen(false)}
                    title={t(AuditTranslation.AUDIT_CLOSE_MODAL_TITLE)}
                    description={t(AuditTranslation.AUDIT_CLOSE_MODAL_DESCRIPTION)}
                    portal
                    variant="purple"
                />
            )}
            <FormLayout
                headerOptions={headerOptions}
                isLoading={false}
                footer={footers[currentTab] && footers[currentTab].show && footers[currentTab].footer}
            >
                {tabs[currentTab].children}
            </FormLayout>
        </>
    );
};
