import { FC, useRef } from "react";

import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { DataToReportList } from "app/components_v2/WizardTask/DataToReportList";
import { SelectWithDisplay, SelectWithDisplayItems } from "app/components_v2/SelectWithDisplay";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { DynamicFields } from "../DynamicFields/DynamicFields";
import { taskfield2Error, TaskfieldOptionsError } from "app/models/02-TAR/Task/EditTask";
import {
    reportType,
    DynamicFieldsType,
    reportDataList,
    checkListModel,
    DynamicFieldsModel,
} from "app/models/02-TAR/TaskWizard";

export type FieldValue = { id: string; value: string; isDeleted?: boolean };

export type FieldValues = {
    selectableValue: reportType;
    fieldValue: FieldValue | null;
    dynamicFieldsType?: DynamicFieldsType;
};

type SelectDataToReportProps = {
    options: OptionsSearch[];
    value: reportType;
    reportData: reportDataList[];
    customError?: string;
    assets: AssetModel2[];
    isAssetErrored?: boolean;
    isAssetsLoading: boolean;
    variant?: "editTask" | "modal";
    onChange: (field: Partial<FieldValues>) => void;
    onDeleteAsset: (id: number) => void;
    onAddNewChecklist: () => void;
    onDeleteCheckList: (id: string) => void;
    onBlurCheckList: () => void;
    disabled?: boolean;
    checkList: checkListModel[];
    dynamicFieldsOptions: OptionsSearch[];
    isLoadingDynamicFields: boolean;
    dynamicFields: DynamicFieldsModel[];
    dynamicFieldsError: taskfield2Error[];
    checklistError: TaskfieldOptionsError[];
    onDynamicFieldInputChange: (value: string, id: string) => void;
    onDynamicFieldCheckboxChange: (value: boolean, id: string) => void;
    onDeleteDynamicField: (id: string) => void;
    onDeleteDynamicDropDownField: (id: string, itemId: string) => void;
    onChangeDynamicDropDownField: (id: string, itemId: string, value: string) => void;
    onAddNewDynamicDropDownField: (id: string) => void;
    disabledChecklist?: boolean;
    disabledDynamicFields?: boolean;
    hideSelect?: boolean;
};

export const SelectDataToReport: FC<SelectDataToReportProps> = ({
    options,
    value: selectedValue,
    reportData,
    customError,
    onChange,
    assets,
    isAssetErrored,
    isAssetsLoading,
    onDeleteAsset,
    disabled,
    checkList,
    onAddNewChecklist,
    onDeleteCheckList,
    onBlurCheckList,
    dynamicFieldsOptions,
    isLoadingDynamicFields,
    dynamicFields,
    onDynamicFieldCheckboxChange,
    onDynamicFieldInputChange,
    onDeleteDynamicField,
    onDeleteDynamicDropDownField,
    onChangeDynamicDropDownField,
    onAddNewDynamicDropDownField,
    dynamicFieldsError,
    variant = "editTask",
    checklistError,
    disabledChecklist,
    disabledDynamicFields,
    hideSelect,
}) => {
    const { t } = useTranslation();
    const ref = useRef(false);

    const fillAssetsOptions = () => {
        const assetOptions: OptionsSearch[] = assets.map(({ name, id }) => ({
            label: name,
            value: String(id),
        }));

        return assetOptions;
    };

    const fillAssetsItems = (): SelectWithDisplayItems[] => {
        return reportData
            .filter(({ isDeleted }) => !isDeleted)
            .map(({ value, assetId }) => {
                return {
                    id: Number(assetId) || 0,
                    label: value,
                };
            });
    };

    const handleDynamicFieldsChange = ({ value }: OptionsSearch) => {
        ref.current = true;
        onChange({
            dynamicFieldsType: (value as DynamicFieldsType) || "DATE",
            selectableValue: "DYNAMIC_FIELD",
        });
    };

    return (
        <div className="selectDataToReport">
            {!hideSelect && (
                <div
                    className={`${
                        variant === "editTask"
                            ? "selectDataToReport__selectors"
                            : "selectDataToReport__selectors--small"
                    } ${
                        selectedValue !== "DYNAMIC_FIELD"
                            ? variant === "editTask"
                                ? "selectDataToReport__selectors--half"
                                : ""
                            : ""
                    }`}
                >
                    <SelectOptions
                        selectedValue={selectedValue}
                        options={options.map(({ label, value }) => ({ label, value }))}
                        onChange={(newValue) => {
                            const dataToReportPosibleOptions = ["DATA", "NONE", "ASSET", "CHECKLIST", "DYNAMIC_FIELD"];
                            if (!dataToReportPosibleOptions.includes(newValue.value)) return;
                            onChange({
                                selectableValue: newValue.value as reportType,
                                fieldValue: null,
                            });
                        }}
                        label={t(TranslationKeys.DATAS_TO_REPORT)}
                        isMulti={false}
                        disabled={disabled || disabledChecklist || disabledDynamicFields}
                    />
                </div>
            )}
            <div>
                {selectedValue === "ASSET" && (
                    <div
                        className={
                            variant === "editTask" ? `selectDataToReport__asset` : `selectDataToReport__asset--small`
                        }
                    >
                        <SelectWithDisplay
                            isLoading={isAssetsLoading}
                            isErrored={!!isAssetErrored}
                            options={fillAssetsOptions()}
                            customError={customError}
                            items={fillAssetsItems()}
                            onClick={onDeleteAsset}
                            onChange={({ value }) =>
                                onChange({
                                    selectableValue: "ASSET",
                                    fieldValue: { id: value, value },
                                })
                            }
                            value={t(TranslationKeys.ASSET_SELCT_OPTION)}
                            placeholder={t(TranslationKeys.ASSET_SELCT_OPTION)}
                            reportData={reportData}
                            disabled={disabled}
                        />
                    </div>
                )}

                {selectedValue === "CHECKLIST" && (
                    <div
                        className={
                            variant === "editTask"
                                ? `selectDataToReport__checklist`
                                : `selectDataToReport__checklist--small`
                        }
                    >
                        <DataToReportList
                            items={checkList.map(({ id, isDeleted, name }) => ({
                                id,
                                value: name,
                                isDeleted,
                            }))}
                            onAddNew={onAddNewChecklist}
                            onBlur={onBlurCheckList}
                            onInputChange={(values) =>
                                onChange({
                                    selectableValue: selectedValue,
                                    fieldValue: values,
                                })
                            }
                            customError={checklistError}
                            onDelete={onDeleteCheckList}
                            disabled={disabled || disabledChecklist}
                            placeholder={t(TranslationKeys.PLACEHOLDER_CHECKLIST)}
                        />
                    </div>
                )}

                {selectedValue === "DYNAMIC_FIELD" && (
                    <div className="dynamicFields">
                        <div className="dynamicFields__content">
                            <DynamicFields
                                isLoadingDynamicFields={isLoadingDynamicFields}
                                dynamicFields={dynamicFields}
                                onCheckboxChange={onDynamicFieldCheckboxChange}
                                onInputChange={onDynamicFieldInputChange}
                                onDeleteDynamicField={onDeleteDynamicField}
                                onDeleteDynamicDropDownField={onDeleteDynamicDropDownField}
                                onChangeDynamicDropDownField={onChangeDynamicDropDownField}
                                onAddNewDynamicDropDownField={onAddNewDynamicDropDownField}
                                errors={dynamicFieldsError}
                                focus={ref.current}
                                disabled={disabled || disabledChecklist}
                                variant={variant}
                            />
                        </div>
                        <SelectOptions
                            isMulti={false}
                            onChange={handleDynamicFieldsChange}
                            options={dynamicFieldsOptions}
                            errorMessage={customError}
                            disabled={disabled || disabledDynamicFields}
                            placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_FIELD)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
