import { dayLetters } from "app/helpers";
import { UserWorkDayModel } from "app/models/01-SEG/User/UserWorkDayModel";
import { WorkDay } from "app/components_v2/__inputs/SelectWeekDayBubbles/SelectWeekDayBubbles";

export const UserWorkDaysMapper = (list?: UserWorkDayModel[]): WorkDay[] => {
    if (!!list?.length)
        return list.map(({ day, isActive }, i) => ({
            id: i,
            letter: dayLetters[day],
            isActive,
        }));

    const workDays: WorkDay[] = dayLetters.map((letter, key) => ({
        id: key,
        letter,
        isActive: false,
    }));

    return workDays;
};

export const ReverseUserWorkDaysMapper = (list: WorkDay[], userId: number): UserWorkDayModel[] => {
    return list.map(({ isActive }, i) => ({
        userId,
        day: i,
        isActive,
    }));
};
