import { FC, useState, useEffect } from "react";
import { useCompanies } from "../../../hooks/useCompanies";
import { useTranslation } from "react-i18next";
import { useUsers } from "app/hooks/useUsers";
import { useDepartments } from "app/hooks/useDepartments";
import { useWindowDimension } from "app/hooks";
import { TranslationKeys } from "app/translation/translationKeys";
import { screenSize } from "app/shared/Constants";
import { ISelectCompanyUserDepartment } from "./types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import Spinner from "app/components_v2/Spinner/Spinner";
import { OptionsOutsideSelect } from "../SelectOptions/OptionsOutsideSelect";

export type SelectCompanyUserDepartmentProps = {
    onSelect: (value: ISelectCompanyUserDepartment) => void;
    onCheckboxChange: (boolean: boolean) => void;
};

export const SelectCompanyUserDepartment: FC<SelectCompanyUserDepartmentProps> = ({ onSelect, onCheckboxChange }) => {
    const { t } = useTranslation();
    const [selectedCompany, setSelectedCompany] = useState<OptionModel[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<OptionModel[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<OptionModel[]>([]);
    const [showMore, setShowMore] = useState<boolean>(false);

    const selectedCompanyValue = !!selectedCompany[0]?.value ? Number(selectedCompany[0].value) : null;

    const { companies, loading } = useCompanies();
    const { users, loading: loadingUsers } = useUsers(selectedCompanyValue);
    const { departments, loading: loadingDepartments } = useDepartments(selectedCompanyValue);

    const handleCompanyInputChange = (inputValue: OptionModel[]) => {
        setShowMore(true);
        setSelectedUsers([]);
        setSelectedDepartments([]);
        setSelectedCompany(inputValue);
        handleSubmit();
    };

    const handleUserInputChange = (inputValue: OptionModel[]) => {
        setSelectedUsers(inputValue);
    };

    const handleDepartmentInputChange = (inputValue: OptionModel[]) => {
        setSelectedDepartments(inputValue);
    };

    const handleSubmit = () => {
        onSelect({
            companies: selectedCompany,
            departments: selectedDepartments,
            users: selectedUsers,
        });
    };

    useEffect(() => {
        if (selectedCompany || selectedDepartments || selectedUsers) {
            handleSubmit();
        }
    }, [selectedCompany, selectedDepartments, selectedUsers]);

    const [checked, setChecked] = useState<boolean>(false);

    const handleCheckbox = (isChecked: boolean) => {
        if (!isChecked) {
            setSelectedCompany(selectedCompany[0] !== undefined ? [selectedCompany[0]] : []);
        } else {
            setSelectedUsers([]);
            setSelectedDepartments([]);
        }
        onCheckboxChange(isChecked);
        setChecked(isChecked);
        handleSubmit();
    };

    const handleDeleteListItems = (id: string) => {
        setSelectedCompany((prev) => prev.filter(({ value }) => value !== id));
    };
    const handleDeleteListItemsUsers = (id: string) => {
        setSelectedUsers((prev) => prev.filter(({ value }) => value !== id));
    };
    const handleDeleteListItemsDepartments = (id: string) => {
        setSelectedDepartments((prev) => prev.filter(({ value }) => value !== id));
    };
    const { width } = useWindowDimension();

    return (
        <>
            {!loading && companies ? (
                <div className="selectLabel__container">
                    <div className="selectLabel">
                        <OptionsOutsideSelect
                            label={t(TranslationKeys.COMPANY)}
                            options={companies}
                            autoInitials
                            onChange={handleCompanyInputChange}
                            values={selectedCompany ? selectedCompany?.map((values) => ({ ...values })) : []}
                            onDeleteItem={handleDeleteListItems}
                            isMulti={checked}
                            inputWidth={width >= screenSize.TABLET ? 50 : 100}
                        />
                        <div className="selectLabel__checkbox">
                            <CheckBox
                                checked={checked}
                                onChange={handleCheckbox}
                                label={t(TranslationKeys.SEND_ALL_COMPANY)}
                                htmlFor="selectLabel__checkbox"
                            />
                        </div>
                    </div>
                    {!checked && showMore && users && departments ? (
                        <div className="selectLabel__container2">
                            <div className="selectLabel">
                                <OptionsOutsideSelect
                                    label={t(TranslationKeys.USERS)}
                                    options={users}
                                    autoInitials
                                    onChange={handleUserInputChange}
                                    values={selectedUsers ? selectedUsers?.map((values) => ({ ...values })) : []}
                                    onDeleteItem={handleDeleteListItemsUsers}
                                    isMulti
                                />
                            </div>
                            <div className="selectLabel">
                                <OptionsOutsideSelect
                                    label={t(TranslationKeys.DEPARTMENT)}
                                    options={departments}
                                    autoInitials
                                    onChange={handleDepartmentInputChange}
                                    values={
                                        selectedDepartments
                                            ? selectedDepartments?.map((values) => ({
                                                  ...values,
                                              }))
                                            : []
                                    }
                                    onDeleteItem={handleDeleteListItemsDepartments}
                                    isMulti
                                />
                            </div>
                        </div>
                    ) : (
                        (loadingUsers || loadingDepartments) && <Spinner />
                    )}
                </div>
            ) : (
                <Spinner />
            )}
        </>
    );
};
