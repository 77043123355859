import { FC, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSession } from "app/hooks/useSession";
import { PrivatePaths, PublicPaths, SecScreen } from "app/shared/Constants";
import { MarkableIcon } from "../MarkableIcon/MarkableIcon";
import {
    faListCheck,
    faStar as activeStar,
    faImage as activeImage,
    faRankingStar as activeRankingstar,
    faBell as faBellSolid,
    faCheckDouble as faCheckDoubleSolid,
    faShieldHalved as faShieldHalvedSolid,
    faTicketAirline as faTicketAirlineSolid,
} from "@fortawesome/pro-solid-svg-icons";
import {
    faStar as inactiveStar,
    faImage as inactiveImage,
    faRankingStar as inactiveRankingstar,
    faQrcode,
    faBell,
    faCheckDouble,
    faShieldHalved,
    faTicketAirline,
} from "@fortawesome/pro-regular-svg-icons";
import { MobileFooterProps } from "./MobileFooterProps";
import { isCurrentPage } from "app/helpers/__validates/validateIsCurrentPage";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { useUserType } from "app/hooks/useUserType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isiOS from "app/helpers/isIos";
import { AvatarPopover } from "../__popovers/AvatarPopover/AvatarPopover";
import { HeaderContext } from "../Header/context/headerContext";
import { FullScreenQrModal } from "../__modals/FullScreenQrModal/FullScreenQrModal";
import { useVariant } from "app/hooks/useVariant";

export const MobileFooter: FC<MobileFooterProps> = () => {
    const { hasNotification, handleClearAllNotifications } = useContext(HeaderContext);
    const [showQrModal, setShowQrModal] = useState<boolean>(false);
    const nav = useNavigate();
    const { pathname } = useLocation();
    const session = useSession();
    const isOnBoarding = session?.isOnBoarding;
    const { isAnalist, isSupervisor, isAuditor } = useUserType();
    const { variant } = useVariant();

    const hideFooter =
        pathname.includes(PrivatePaths.ACCEPT_POLICY) ||
        pathname.includes(PublicPaths.NEW_PASSWORD) ||
        pathname.includes(PrivatePaths.SELECT_DEPARTMENT) ||
        pathname.includes(PrivatePaths.AUDIT_INST_MANAG && PublicPaths.PRINT) ||
        pathname.includes(`${PrivatePaths.DASHBOARD}/qr`);

    const handleNotificationClick = () => {
        nav(`/${PrivatePaths.NOTIFICATION}`);
        handleClearAllNotifications();
    };

    const onScanQr = (qrCode: string) => {
        setShowQrModal(false);
        nav(`/${PrivatePaths.DASHBOARD}/qr/${qrCode}`);
    };

    if (session?.appSource !== undefined || hideFooter) return null;
    if (isOnBoarding)
        return (
            <div className={`mobileFooter ${isiOS() ? "paddingIos" : ""} mobileFooter--${variant}`}>
                <MarkableIcon
                    isActive={isCurrentPage(PrivatePaths.ON_BOARDING)}
                    onClick={() => nav(`/${PrivatePaths.ON_BOARDING}`)}
                    activeIcon={faTicketAirlineSolid}
                    inactiveIcon={faTicketAirline}
                    variant={variant}
                />
                <div className="mobileFooter__iconContainer">
                    <AvatarPopover position="topLeft" showBlur />
                </div>
            </div>
        );
    return (
        <div className={`mobileFooter ${isiOS() ? "paddingIos" : ""} mobileFooter--${variant}`}>
            <MarkableIcon
                variant={variant}
                isActive={isCurrentPage(PrivatePaths.DASHBOARD)}
                onClick={() => nav(`/${PrivatePaths.DASHBOARD}`)}
                activeIcon={faListCheck}
            />
            {isAuditor ? (
                <>
                    <div className="mobileFooter__iconContainer">
                        {hasPermissionToView(session, SecScreen.AUDIT_INST_MANAG) && (
                            <MarkableIcon
                                variant={variant}
                                isActive={isCurrentPage(PrivatePaths.AUDIT_INST_MANAG)}
                                onClick={() => nav(`/${PrivatePaths.AUDIT_INST_MANAG}`)}
                                activeIcon={faCheckDoubleSolid}
                                inactiveIcon={faCheckDouble}
                            />
                        )}
                    </div>
                    {hasPermissionToView(session, SecScreen.COMPANY_RANKING) ? (
                        <div className="mobileFooter__iconContainer">
                            <MarkableIcon
                                variant={variant}
                                isActive={isCurrentPage(PrivatePaths.COMPANY_RANKING)}
                                onClick={() => nav(`/${PrivatePaths.COMPANY_RANKING}`)}
                                activeIcon={activeRankingstar}
                                inactiveIcon={inactiveRankingstar}
                            />
                        </div>
                    ) : (
                        hasPermissionToView(session, SecScreen.PLAN_APPCC) && (
                            <div className="mobileFooter__iconContainer">
                                <MarkableIcon
                                    variant={variant}
                                    isActive={isCurrentPage(PrivatePaths.PLAN_APPCC)}
                                    onClick={() => nav(`/${PrivatePaths.PLAN_APPCC}`)}
                                    activeIcon={faShieldHalvedSolid}
                                    inactiveIcon={faShieldHalved}
                                />
                            </div>
                        )
                    )}
                </>
            ) : (
                <>
                    <div className="mobileFooter__iconContainer">
                        {(isSupervisor || isAnalist) && hasPermissionToView(session, SecScreen.TASK_REVIEW) ? (
                            <MarkableIcon
                                variant={variant}
                                isActive={isCurrentPage(PrivatePaths.TASK_PHOTO)}
                                onClick={() => nav(`/${PrivatePaths.TASK_PHOTO}`)}
                                activeIcon={activeImage}
                                inactiveIcon={inactiveImage}
                            />
                        ) : (
                            hasPermissionToView(session, SecScreen.MY_VALORATION) && (
                                <MarkableIcon
                                    variant={variant}
                                    isActive={isCurrentPage(PrivatePaths.FEEDBACK_TASKS)}
                                    onClick={() => nav(`/${PrivatePaths.FEEDBACK_TASKS}?comments=true`)}
                                    activeIcon={activeStar}
                                    inactiveIcon={inactiveStar}
                                />
                            )
                        )}
                    </div>
                    {!hasPermissionToView(session, SecScreen.COMPANY_RANKING) ? (
                        <>
                            <div className={`containerQr  ${isiOS() ? "containerQrIos" : ""}`}>
                                <FontAwesomeIcon
                                    className="qrIcon"
                                    onClick={() => setShowQrModal(!showQrModal)}
                                    icon={faQrcode}
                                />
                            </div>
                            <div className="containerQr__ghostDiv"></div>
                        </>
                    ) : (
                        <div className="mobileFooter__iconContainer">
                            <MarkableIcon
                                variant={variant}
                                isActive={isCurrentPage(PrivatePaths.COMPANY_RANKING)}
                                onClick={() => nav(`/${PrivatePaths.COMPANY_RANKING}`)}
                                activeIcon={activeRankingstar}
                                inactiveIcon={inactiveRankingstar}
                            />
                        </div>
                    )}
                </>
            )}
            <div className="mobileFooter__iconContainer relative">
                {hasPermissionToView(session, SecScreen.SYSTEM_NOTIFICATION) && (
                    <>
                        {hasNotification && <div className="markableIcon__notification" />}
                        <MarkableIcon
                            variant={variant}
                            isActive={isCurrentPage(PrivatePaths.NOTIFICATION)}
                            onClick={handleNotificationClick}
                            activeIcon={faBellSolid}
                            inactiveIcon={faBell}
                        />
                    </>
                )}
            </div>
            <div className="mobileFooter__iconContainer">
                <AvatarPopover position="topLeft" showBlur />
            </div>
            {showQrModal && <FullScreenQrModal onClose={() => setShowQrModal(false)} onScanQr={onScanQr} />}
        </div>
    );
};
