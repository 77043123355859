import { FC, useEffect, useRef, useState } from "react";
import Select, { MenuPosition, createFilter } from "react-select";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { TranslationCommon } from "app/translation/translationKeys";
import { Hint } from "../Hint/Hint";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import isiOS from "app/helpers/isIos";
import { Label } from "../Label";
import { useOnClickOutside } from "usehooks-ts";
import { ReadOnlyInput } from "../ReadOnlyInput/ReadOnlyInput";

type SelectOptionsProps = {
    options: OptionsSearch[];
    label?: string;
    readonly?: boolean;
    disabled?: boolean;
    placeholder?: string;
    selectedValue?: string;
    errorMessage?: string;
    hint?: string;
    hideSelectedValue?: boolean;
    menuPosition?: MenuPosition;
    htmlFor?: string;
    isSearchable?: boolean;
    showBorderColor?: boolean;
    inverted?: boolean;
    isOptional?: boolean;
    name?: string;
    isLoading?: boolean;
    onClickOutside?: () => void;
    autoFocus?: boolean;
};

type MultipleSelectedOptionsProps = SelectOptionsProps & {
    onChange: (value: OptionsSearch[]) => void;
    isMulti: true;
};

type SelectedOptionsProps = SelectOptionsProps & {
    onChange: (value: OptionsSearch) => void;
    isMulti: false;
};

export const SelectOptions: FC<MultipleSelectedOptionsProps | SelectedOptionsProps> = ({
    label,
    readonly,
    disabled,
    placeholder,
    options,
    onChange,
    selectedValue,
    isMulti,
    errorMessage,
    hint,
    hideSelectedValue,
    htmlFor,
    menuPosition = "fixed",
    isSearchable,
    showBorderColor,
    inverted,
    isOptional,
    isLoading,
    onClickOutside,
    autoFocus = false,
}) => {
    const [isHintVisible, setIsHintVisible] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [internalSelectedValue, setInternalSelectedValue] = useState<string | undefined>(selectedValue);
    const { t } = useTranslation();
    const selectDivRef = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(selectDivRef, () => onClickOutside && onClickOutside());

    const handleChangeHint = () => {
        if (!!errorMessage?.length) {
            setIsHintVisible(false);
            return;
        }
        setIsHintVisible(!isHintVisible);
    };

    const getInvertedClassName = () => {
        if (inverted)
            return `${`selectOptions__select--inverted${errorMessage || showBorderColor ? "__errored" : ""}${
                isFocused ? "--focused" : ""
            }`}`;

        return `selectOptions__select${errorMessage || showBorderColor ? "__errored" : ""}${
            isFocused ? "--focused" : ""
        } `;
    };

    useEffect(() => {
        setInternalSelectedValue(selectedValue);
    }, [selectedValue]);

    const customStyles = {
        placeholder: (styles: any) => ({
            ...styles,
            color: inverted ? "white" : "gray",
            fontSize: "14px",
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    };

    const getSelectedValue = () => {
        const selectedOption = options.find(({ value }) => value === internalSelectedValue);

        if (!selectedOption) return "";
        return selectedOption.label;
    };

    if (readonly)
        return <ReadOnlyInput value={getSelectedValue()} label={label} type="select" placeholder={placeholder} />;

    return (
        <div className={`selectOptions`}>
            {label && (
                <div className="selectOptions__label">
                    <Label isOptional={isOptional} label={label} disabled={disabled} htmlFor={htmlFor} />
                    {hint && (
                        <FontAwesomeIcon
                            icon={faCircleQuestion}
                            className={"inputTextWrapper__iconwrapper__icon"}
                            onClick={handleChangeHint}
                            data-testid="react-select-hint-icon"
                        />
                    )}
                </div>
            )}
            <div data-testid="selectOptions__select" className={getInvertedClassName()} ref={selectDivRef}>
                <Select
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    options={options}
                    styles={customStyles}
                    onChange={(newValue) => {
                        isMulti ? onChange(newValue as OptionsSearch[]) : onChange(newValue as OptionsSearch);
                    }}
                    name={htmlFor}
                    value={
                        internalSelectedValue
                            ? options.filter((option) => option.value === internalSelectedValue)
                            : isMulti
                            ? undefined
                            : null
                    }
                    filterOption={createFilter({})}
                    inputId={htmlFor}
                    menuPosition={menuPosition}
                    menuPortalTarget={document.body}
                    menuPlacement="bottom"
                    isDisabled={disabled}
                    isMulti={isMulti}
                    classNames={{
                        control: () =>
                            `reactSelectClassName ${inverted ? "reactSelectClassName--inverted" : ""} ${
                                isFocused ? "reactSelectClassName--focused" : ""
                            }
                            ${disabled ? "reactSelectClassName--disabled" : ""}`,
                        menuPortal: () => "zIndexUp",
                        indicatorsContainer: () => (inverted ? "reactSelectIndicator--inverted" : ""),
                        singleValue: () => (inverted ? "reactSelectSingleValue--inverted" : ""),
                        placeholder: () => "reactSelectPlaceholder text_clamp text_clamp-1",
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    controlShouldRenderValue={!hideSelectedValue}
                    isClearable={false}
                    noOptionsMessage={() => <p>{t(TranslationCommon.NO_MORE_OPTIONS)}</p>}
                    isSearchable={isiOS() ? false : isSearchable}
                    isLoading={isLoading}
                />
            </div>

            {!!errorMessage?.length && !disabled ? (
                <ErrorMessage errorMessage={errorMessage} />
            ) : (
                hint && isHintVisible && <Hint text={hint} />
            )}
        </div>
    );
};
