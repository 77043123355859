import { CloseCompanySeenModel } from "app/models/01-SEG/CloseCompanySeen/CloseCompanySeen";
import { createContext } from "react";

export type CloseCompanyModalContextModel = {
    closeOpenCompanies: CloseCompanySeenModel[];
    isOpen: boolean;
    open: (closeOpenCompanies: CloseCompanySeenModel[]) => void;
    reset: () => void;
};

export const CloseCompanyModalContext = createContext<CloseCompanyModalContextModel>(
    {} as CloseCompanyModalContextModel
);
