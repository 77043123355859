const allowedExtensions: string[] = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "avif"];

export const isPhoto = (file: File): boolean => {
    const fileName: string = file.type.toLowerCase();
    const fileExtension: string | undefined = fileName.split("/").pop();
    return fileExtension !== undefined && allowedExtensions.includes(fileExtension);
};

export const isFileNamePhoto = (filename: string): boolean => {
    const indexDot = filename.lastIndexOf(".");

    if (indexDot === -1 || indexDot === filename.length - 1) {
        return false;
    }

    const fileNameExtension = filename.slice(indexDot + 1).toLowerCase();

    return allowedExtensions.includes(fileNameExtension);
};
