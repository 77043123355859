export const isVideoOrPhoto = (nombreArchivo: string): string => {
    const indexDot = nombreArchivo.lastIndexOf(".");

    if (indexDot === -1 || indexDot === nombreArchivo.length - 1) {
        return "otro";
    }

    const extension = nombreArchivo.slice(indexDot + 1).toLowerCase();

    const extensionesPhoto = ["jpg", "jpeg", "png", "gif", "webp", "bmp"];
    const extensionesVideo = ["mp4", "avi", "mkv", "mov"];
    const extensionesAudio = ["wav", "mp3", "ogg", "mpeg", "flac", "acc", "aiff"];

    if (extensionesPhoto.includes(extension.toLowerCase())) {
        return "image";
    } else if (extensionesVideo.includes(extension.toLowerCase())) {
        return "video";
    } else if (extensionesAudio.includes(extension.toLowerCase())) {
        return "audio";
    } else {
        return "file";
    }
};
