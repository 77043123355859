import {
    EmailDistributionListErrors,
    EmailDistributionListFormValues,
} from "../models/EmailDistributionListFormValues";

export const INITIAL_EMAIL_DISTRIBUTION_LIST_FORM_VALUES: EmailDistributionListFormValues = {
    fK_Company: "",
    fK_DistributionList: "",
    userList: [],
};

export const INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS: EmailDistributionListErrors = {
    errorFunctionality: "",
    errorCompany: "",
    errorUserList: "",
};
