import { faArrowLeft, faCheck, faCirclePlus, faPen, faTrash, faUsers } from "@fortawesome/pro-regular-svg-icons";
import AddUsersModal from "app/components_v2/__modals/AddUsersModal/AddUsersModal";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import Table from "app/components_v2/Table/Table";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableTabHeader } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { ColumnsType, SortedTypeModel } from "app/components_v2/Table/types";
import { Tag } from "app/components_v2/Tag/Tag";
import { checkAddedDeletedBetweenTwoArraysOfNumbers } from "app/helpers/checkAddedDeletedBetweenTwoArraysOfNumbers";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import { SubdepartmentModel } from "app/models/01-SEG/Subdepartment/SubdepartmentModel";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { CompanyService, DepartmentService, SubdepartmentService } from "app/services";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupCompanyModal } from "../GroupCompanyModal";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

type IAddUsers = {
    usersId: number[];
    name: string;
    allUsers?: any;
};

type IGetDescriptionDeleteModal = {
    isRelatedWithTasks: boolean;
};

type Props = {
    onEdit: (value: boolean) => void;
    onDelete: () => void;
    department: DepartmentModel;
    handleReloadData: () => void;
    showOpened?: boolean;
};

const SubdepartmentsGrid: FC<Props> = ({ onDelete, department, handleReloadData, showOpened }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const session = useSession();
    const editedName = department.name;

    const [data, setData] = useState<SubdepartmentModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [sortField, setSortField] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<SortedTypeModel | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isOpen, setisOpen] = useState<boolean>(showOpened || false);
    const [newSubdepartmentModal, setNewSubdepartmentModal] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [usersModal, setUsersModal] = useState<boolean>(false);
    const [selectedSubdepartmentUsers, setSelectedSubdepartmentUsers] = useState<OptionModel[]>([]);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const [departmentUsers, setDepartmentUsers] = useState<OptionModel[]>([]);
    const [subdepartmentId, setSubdepartmentId] = useState<number>(0);
    const [subdepartment, setSubdepartment] = useState<SubdepartmentModel | null>(null);
    const [selectedName, setSelectedName] = useState<string>("");
    const [isConfirmAddUsersModalVisible, setIsConfirmAddUsersModalVisible] = useState<boolean>(false);
    const [addUsersData, setAddUsersData] = useState<IAddUsers | null>(null);

    const [initialUsersInDepartment, setInitialUsersInDepartment] = useState<IAddUsers | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const columns: ColumnsType<SubdepartmentModel>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "name",
            render: (value) => <CellTitle title={value.name} />,
            sortedType: "default",
        },
        {
            label: t(TranslationCommon.EDIT),
            dataIndex: "edit",
            hidden:
                !hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT) && !hasPermissionToEdit(SecScreen.TEAM_MANAGEMENT),
            className: "subdepartmentGrid__actions",
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => handleGetUsersInSubdepartment(record),
                            hidden:
                                !hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT) &&
                                !hasPermissionToEdit(SecScreen.TEAM_MANAGEMENT),
                        },
                    ]}
                />
            ),
            alignCenter: true,
        },
        {
            label: t(TranslationCommon.DELETE),
            dataIndex: "delete",
            hidden:
                !hasPermissionToDelete(SecScreen.CENTERS_MANAGEMENT) &&
                !hasPermissionToDelete(SecScreen.TEAM_MANAGEMENT),
            className: "subdepartmentGrid__actions",
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faTrash,
                            onClick: () => {
                                setSubdepartment(record);
                                setShowDeleteModal(true);
                            },
                            hidden:
                                !hasPermissionToDelete(SecScreen.CENTERS_MANAGEMENT) &&
                                !hasPermissionToDelete(SecScreen.TEAM_MANAGEMENT),
                        },
                    ]}
                />
            ),
            alignCenter: true,
        },
    ];

    const getCompany = async (pageOne?: boolean) => {
        setIsLoading(true);
        pageOne && setPageIndex(1);

        const {
            data: companieData,
            error,
            status,
        } = await SubdepartmentService.GetData({ extraParams: `departmentId=${department?.id}` });

        if (!status()) {
            console.error(error);
            handleToast({ title: error, variant: "danger", type: "alert" });
            setIsLoading(false);
            return;
        }

        setData(companieData.list);
        setIsLoading(false);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const extraParams = `GroupCompanyId=${department?.id}`;

        return CompanyService.GetDataExport({
            extraParams,
            sortDirection,
            sortField,
            ...params,
        });
    };

    const handleEdit = (success: boolean) => {
        if (!success) return;
        handleReloadData();
        setSubdepartment(null);
    };

    useEffect(() => {
        getCompany();
    }, [pageIndex, sortField, sortDirection]);

    const handleGetUsersInSubdepartment = async ({ name, id }: SubdepartmentModel) => {
        setDepartmentUsers([]);
        setSubdepartmentId(id);
        setSelectedName(name);

        const { status, data } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}&subdepartmentId=${id}`,
        });

        if (!status()) {
            console.error(t(TranslationErrors.GENERIC_ERROR));
            return;
        }
        setSelectedSubdepartmentUsers(data);
        setInitialUsersInDepartment({
            name: department.name,
            usersId: data.map(({ value }) => Number(value)),
        });
        setUsersModal(true);
    };

    const handleAddUsers = async ({ name, usersId }: IAddUsers) => {
        setIsSaving(true);
        if (subdepartmentId) {
            const { status } = await SubdepartmentService.PutUsersInSubdepartment(subdepartmentId, {
                userIds: usersId,
                companyId: department.companyId,
                departmentId: department.id,
                name,
            });
            if (!status()) {
                handleToast({
                    title: t(TranslationErrors.GENERIC_ERROR),
                    variant: "danger",
                    type: "alert",
                });
            } else {
                handleToast({
                    title: t(TranslationKeys.USERS_DETAIL_MODAL_SUCCESS_ADD_MESSAGE),
                    variant: "success",
                    type: "alert",
                });
            }
        } else {
            const { status } = await DepartmentService.PutUsersInDepartment(department.id, {
                userIds: usersId,
                companyId: department.companyId,
                name,
            });
            if (!status()) {
                handleToast({
                    title: t(TranslationErrors.GENERIC_ERROR),
                    variant: "danger",
                    type: "alert",
                });
            } else {
                handleToast({
                    title: t(TranslationKeys.USERS_DETAIL_MODAL_SUCCESS_ADD_MESSAGE),
                    variant: "success",
                    type: "alert",
                });
            }
        }
        setUsersModal(false);
        setIsSaving(false);
        handleReloadData();
    };

    function filterUsers(valores: number[], objetos: OptionModel[]): OptionModel[] {
        const valoresComoString = valores.map((valor) => valor.toString());
        return objetos.filter((objeto) => valoresComoString.includes(objeto.value));
    }
    const [addedUsersList, setAddedUsersList] = useState<OptionModel[]>([]);
    const [deletedUsersList, setDeletedUsersList] = useState<OptionModel[]>([]);

    const handleOpenModifyUsers = ({ name, usersId, allUsers }: IAddUsers) => {
        const { added, deleted } = checkAddedDeletedBetweenTwoArraysOfNumbers(
            initialUsersInDepartment?.usersId || [],
            usersId
        );

        const addedUsers = filterUsers(added, allUsers);
        const deletedusers = filterUsers(deleted, allUsers);

        setAddedUsersList(addedUsers);
        setDeletedUsersList(deletedusers);
        if (!added.length && !deleted.length && name === initialUsersInDepartment?.name) {
            setUsersModal(false);
            return;
        }

        if (added.length || deleted.length) {
            setAddUsersData({ name, usersId });
            setIsConfirmAddUsersModalVisible(true);
            return;
        }

        handleAddUsers({ name, usersId });
    };

    const getConfirmModifyUserTitle = () => {
        const { added, deleted } = checkAddedDeletedBetweenTwoArraysOfNumbers(
            initialUsersInDepartment?.usersId || [],
            addUsersData?.usersId || []
        );

        if (added.length && deleted.length) return t(TranslationModals.COMPANIES_ADD_DELETE_USERS_TITLE);
        if (added.length) return t(TranslationModals.COMPANIES_ADD_USERS_TITLE);
        return t(TranslationModals.COMPANIES_DELETE_USERS_TITLE);
    };

    const getConfirmModifyUserDescription = () => {
        const { added, deleted } = checkAddedDeletedBetweenTwoArraysOfNumbers(
            initialUsersInDepartment?.usersId || [],
            addUsersData?.usersId || []
        );

        if (added.length && deleted.length) return t(TranslationModals.COMPANIES_ADD_DELETE_USERS_DESCRIPTION);
        if (added.length) return undefined;
        return t(TranslationModals.COMPANIES_DELETE_USERS_DESCRIPTION);
    };

    const handleConfirmModifyUsers = async () => {
        setIsConfirmAddUsersModalVisible(false);
        setInitialUsersInDepartment(null);
        if (addUsersData === null) {
            setAddUsersData(null);
            return;
        }

        await handleAddUsers(addUsersData);
        setAddUsersData(null);
    };

    const getDepartmentUsers = async () => {
        const { status, data } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}&departmentId=${department.id}`,
        });
        if (!status) {
            console.error("error");
            return;
        }
        setDepartmentUsers(data);
        setSelectedName(department.name);
        setInitialUsersInDepartment({
            name: department.name,
            usersId: data.map(({ value }) => Number(value)),
        });
        setUsersModal(true);
    };

    const handleDeleteSubdepartment = async () => {
        setIsDeleting(true);
        const delSr = await SubdepartmentService.Delete(Number(subdepartment?.id));

        if (delSr.status()) {
            handleToast({
                title: t(TranslationModals.SUCCESS_DELETE),
                type: "alert",
                variant: "success",
            });
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
        }
        setShowDeleteModal(false);
        setIsDeleting(false);
        handleReloadData();
    };

    const getDeleteModalDescription = ({ isRelatedWithTasks }: IGetDescriptionDeleteModal): string => {
        return isRelatedWithTasks
            ? t(TranslationKeys.EDIT_COMPANY_DELETE_SUBDEPARTMENT)
            : t(TranslationKeys.EDIT_COMPANY_DELETE_SUBDEPARTMENT_NO_RELATED);
    };

    return (
        <>
            {showDeleteModal && (
                <ConfirmModal
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => {
                        setShowDeleteModal(false);
                        setSubdepartment(null);
                    }}
                    onConfirm={handleDeleteSubdepartment}
                    description={getDeleteModalDescription({
                        isRelatedWithTasks: !!subdepartment?.isRelatedWithTasks,
                    })}
                    title={`${t(TranslationKeys.DELETE_MESSAGE)} ${subdepartment?.name}`}
                    isLoading={isDeleting}
                />
            )}

            {newSubdepartmentModal && (
                <GroupCompanyModal
                    onClose={() => setNewSubdepartmentModal(false)}
                    onSubmit={handleEdit}
                    title={t(TranslationKeys.NEW_SUBDEPARTMENT)}
                    values={{
                        customerInstanceId: session?.user.customerInstanceId || 0,
                        id: department?.id,
                        name: department.name,
                        isActive: true,
                        isDeletable: false,
                    }}
                    isEdit={false}
                    isDepartment
                    companyId={department.companyId}
                    usersCount={department.usersCount}
                    newSubdepartment
                />
            )}
            {usersModal && (
                <AddUsersModal
                    service={() =>
                        SegSelectorService.GetUserNamesWithIds({
                            extraParams: `customerInstanceId=${session?.user.customerInstanceId}`,
                        })
                    }
                    onClose={() => {
                        setUsersModal(false);
                        setInitialUsersInDepartment(null);
                        !!selectedSubdepartmentUsers.length && setSelectedSubdepartmentUsers([]);
                    }}
                    title={
                        selectedSubdepartmentUsers.length
                            ? t(TranslationKeys.EDIT_SUBDEPARTMENTS)
                            : t(TranslationKeys.EDIT_DEPARTMENTS)
                    }
                    handleAddUsers={handleOpenModifyUsers}
                    users={selectedSubdepartmentUsers.length ? selectedSubdepartmentUsers : departmentUsers}
                    name={selectedName}
                    nameLabel={
                        selectedSubdepartmentUsers.length
                            ? t(TranslationKeys.SUBDEPARTMENTS)
                            : t(TranslationKeys.DEPARTMENTS)
                    }
                    isSaving={isSaving}
                />
            )}

            {isConfirmAddUsersModalVisible && (
                <ConfirmModal
                    onConfirm={handleConfirmModifyUsers}
                    onClose={() => {
                        setIsConfirmAddUsersModalVisible(false);
                        setAddUsersData(null);
                    }}
                    onConfirmText={t(TranslationCommon.YES_CHANGE)}
                    onConfirmIcon={faCheck}
                    title={getConfirmModifyUserTitle()}
                    description={getConfirmModifyUserDescription()}
                    onCloseText={t(TranslationCommon.NO_GOBACK)}
                    onCloseIcon={faArrowLeft}
                >
                    {!!addedUsersList.length && (
                        <Collapsable
                            isCollapsable={!!deletedUsersList.length}
                            title={t(TranslationKeys.ADDEDS_USERS_TO_TEAM)}
                        >
                            <div className="gap--8 flex  fullWidth wrap">
                                {addedUsersList.map((user) => (
                                    <Tag
                                        key={user.value}
                                        message={user.label}
                                        avatar={{
                                            id: user.value,
                                            initials: user.initials || "",
                                            name: user.label,
                                            profilePictureURL: user.profilePictureURL,
                                        }}
                                        width="auto"
                                        pointer={false}
                                        variant="green"
                                    />
                                ))}
                            </div>
                        </Collapsable>
                    )}
                    {!!deletedUsersList.length && (
                        <Collapsable
                            isCollapsable={!!addedUsersList.length}
                            title={t(TranslationKeys.DELETEDS_USERS_TO_TEAM)}
                        >
                            <div className="gap--8 flex  fullWidth wrap">
                                {deletedUsersList.map((user) => (
                                    <Tag
                                        key={user.value}
                                        message={user.label}
                                        avatar={{
                                            id: user.value,
                                            initials: user.initials || "",
                                            name: user.label,
                                            profilePictureURL: user.profilePictureURL,
                                        }}
                                        width="auto"
                                        pointer={false}
                                        variant="red"
                                    />
                                ))}
                            </div>
                        </Collapsable>
                    )}
                </ConfirmModal>
            )}
            <div>
                <div className="tableHeaderContainer">
                    <TableTabHeader
                        title={`${editedName} `}
                        subTitle={`${department.usersCount} ${t(TranslationKeys.USERS)}`}
                        onClickCollapsable={() => setisOpen(!isOpen)}
                        actionButtons={[
                            {
                                icon: faCirclePlus,
                                onClick: () => setNewSubdepartmentModal(true),
                                hidden: !hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT),
                            },
                            {
                                icon: faPen,
                                onClick: () => {
                                    setSubdepartmentId(0);
                                    setSelectedSubdepartmentUsers([]);
                                    getDepartmentUsers();
                                },
                                hidden: !hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT),
                            },
                            {
                                icon: faTrash,
                                onClick: onDelete,
                                hidden: !hasPermissionToDelete(SecScreen.CENTERS_MANAGEMENT),
                            },
                        ]}
                        isOpen={isOpen}
                    />
                    <Table
                        cols={columns.filter((col) => !col.hidden)}
                        data={data}
                        placeholder={<TableError title={t(TranslationKeys.NO_SUBDEPARTMENTS)} icon={faUsers} />}
                        isLoading={isLoading}
                        pageIndex={pageIndex}
                        pageSize={PaginationDefaults.PAGE_SIZE}
                        onChangePageIndex={setPageIndex}
                        sortField={sortField}
                        sortDirection={sortDirection}
                        onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                            setSortField(sortFieldParam);
                            setSortDirection(sortedTypeParam);
                        }}
                        isOpen={isOpen}
                        onClickExportCsv={handleExport}
                        onDoubleClick={(record) => handleGetUsersInSubdepartment(record)}
                    />
                </div>
            </div>
        </>
    );
};

export default SubdepartmentsGrid;
