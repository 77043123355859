import { mapEmojiToString } from "app/helpers/Emojis/emojisHelper";
import { ImageNamesModel } from "app/models/FormComponentsModel";
import { FileData } from "app/shared/types/FileDataTypes";
import { Dispatch, SetStateAction, useState, useRef, useEffect } from "react";

type UseTextAreaParams = {
    text: string;
    onChange: (text: string) => void;
    sendFiles?: Dispatch<SetStateAction<ImageNamesModel[]>>;
};

export const useTextArea = ({ onChange, text, sendFiles }: UseTextAreaParams) => {
    /* textarea */
    const handleTextareaChange = (value: string) => {
        const outputValue = mapEmojiToString(value);
        onChange(outputValue);
    };
    /* textarea end */
    /* emojis */
    const handleSelectEmoji = (emoji: number) => handleTextareaChange(`${text} ${String.fromCodePoint(emoji)}`);
    /* emojis end */
    /* files */
    const [files, setFiles] = useState<FileData[]>([]);
    const handleSelectFile = (base64String: string, file: File) => {
        setFiles((prev: FileData[]) => [...prev, { base64String, file }]);
        sendFiles && sendFiles((prev: ImageNamesModel[]) => [...prev, { base64: base64String, file }]);
    };
    const handleDeleteFile = (selectedFile: string) => {
        const filtered = files.filter((file: FileData) => file.file.name !== selectedFile);
        setFiles(filtered);
        sendFiles && sendFiles(filtered);
    };
    /* files end */

    return {
        handleTextareaChange,
        handleSelectEmoji,
        files,
        handleSelectFile,
        handleDeleteFile,
    };
};
