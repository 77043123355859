import { FC, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "app/hooks";
import {
    CompanyTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { convertUTCtoLocaleDate } from "app/helpers";
import { EditCompanyFormModal, ErrorEditCompanyFormModal } from "../types";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { Input, Label, SelectOptions, Switch } from "app/components_v2/__inputs";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";
import { CompanyLogo } from "./CompanyLogo/CompanyLogo";
import { CloseCompanyDates } from "./CloseCompanyDates/CloseCompanyDates";

type EditCompanyFormProps = {
    onInputChange: (values: Partial<EditCompanyFormModal>) => void;
    onErrorChange: (values: Partial<ErrorEditCompanyFormModal>) => void;
    formValues: EditCompanyFormModal;
    companyGroupOptions: OptionsSearch[];
    errorFormValues: ErrorEditCompanyFormModal;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const EditCompanyForm: FC<EditCompanyFormProps> = ({
    companyGroupOptions,
    formValues,
    onInputChange,
    errorFormValues,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const { address, city, name, postalCode, companyGroupId, image, closeCompany, closeCompanies, worksWithQR } =
        formValues;
    const { errorAddress, errorCity, errorName, errorPostalCode, errorCloseCompanies } = errorFormValues;

    useTitle(t(TranslationTitles.COMPANY_EDIT_PAGE));

    const handleClosingDates = (dates: Date[] | undefined[], index: number) => {
        if (!closeCompanies) return;
        if (!dates) return;

        const [start, end] = dates;

        onInputChange({
            closeCompanies: closeCompanies.map((cc, i) => {
                if (i === index)
                    return { ...cc, closeDate: start ? convertUTCtoLocaleDate(start) : undefined, openDate: undefined };
                return cc;
            }),
        });
        if (end)
            onInputChange({
                closeCompanies: closeCompanies.map((cc, i) => {
                    if (i === index)
                        return {
                            ...cc,
                            closeDate: start ? convertUTCtoLocaleDate(start) : undefined,
                            openDate: end ? convertUTCtoLocaleDate(end) : undefined,
                        };
                    return cc;
                }),
            });
    };

    const handleSwitchClick = (switchValue: boolean) => {
        onInputChange({ closeCompany: switchValue });
        const hasEmptyValues = closeCompanies?.every(({ closeDate, openDate }) => !closeDate || !openDate);
        const hasSomeEmptyValues = closeCompanies?.some(({ closeDate, openDate }) => !closeDate || !openDate);
        if (!switchValue) {
            onInputChange({
                closeCompanies: hasEmptyValues
                    ? null
                    : hasSomeEmptyValues
                    ? closeCompanies?.filter(({ closeDate, openDate }) => !!closeDate && !!openDate)
                    : closeCompanies,
            });
            return;
        }

        onInputChange({
            closeCompanies: hasEmptyValues ? null : closeCompanies,
        });
    };

    const handleAddNewRangeHour = () => {
        if (closeCompanies == null) {
            onInputChange({ closeCompanies: [{ id: 0, companyId: 0, closeDate: new Date(), openDate: new Date() }] });
            return;
        }
        onInputChange({
            closeCompanies: [...closeCompanies, { id: 0, companyId: 0, closeDate: new Date(), openDate: new Date() }],
        });
    };

    const handleDeleteNewRangeHour = (index: number) => {
        const filteredCloseCompanies = closeCompanies?.filter((_, i) => i !== index);
        onInputChange({ closeCompanies: filteredCloseCompanies, closeCompany: false });
    };

    const handleWorksWithQR = (value: boolean) => onInputChange({ worksWithQR: value });

    return (
        <form className="editForm" id="editCompanyForm" onSubmit={onSubmit}>
            <WhiteBoxCollapsable
                collapsableOptions={{ title: t(CompanyTranslations.EDIT_COMPANY_DETAIL_TITLE), border: "none" }}
            >
                <div className="editForm__container">
                    <CompanyLogo
                        onInputChange={({ imageBase64Content }) =>
                            onInputChange({
                                image: imageBase64Content,
                                imageBase64: imageBase64Content,
                            })
                        }
                        image={image}
                        disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                    />

                    <div className="editForm__container__inputs">
                        <Input
                            onChange={(value) => onInputChange({ name: value })}
                            label={t(TranslationKeys.EDIT_FORM_COMPANY_NAME)}
                            placeholder={t(TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_NAME)}
                            value={name}
                            errorMessage={errorName}
                            disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                        />
                        <Input
                            onChange={(value) => onInputChange({ city: value })}
                            label={t(TranslationKeys.EDIT_FORM_COMPANY_CITY)}
                            placeholder={t(TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_CITY)}
                            value={city}
                            errorMessage={errorCity}
                            disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                            isOptional
                        />
                        <Input
                            onChange={(value) => onInputChange({ address: value })}
                            label={t(TranslationKeys.EDIT_FORM_COMPANY_ADDRESS)}
                            placeholder={t(TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_ADDRESS)}
                            value={address}
                            errorMessage={errorAddress}
                            disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                            isOptional
                        />
                        <Input
                            onChange={(value) => onInputChange({ postalCode: value })}
                            label={t(TranslationKeys.EDIT_FORM_COMPANY_POSTAL_CODE)}
                            placeholder={t(TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_POSTAL_CODE)}
                            value={postalCode}
                            errorMessage={errorPostalCode}
                            disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                            isOptional
                        />
                        <SelectOptions
                            isMulti={false}
                            onChange={({ value }) => {
                                onInputChange({ companyGroupId: Number(value) });
                            }}
                            options={companyGroupOptions}
                            label={t(TranslationKeys.EDIT_FORM_COMPANY_GROUP)}
                            selectedValue={String(companyGroupId)}
                            disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                            isSearchable
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                        />
                    </div>
                </div>
            </WhiteBoxCollapsable>
            <div className="editForm__whiteBoxOptions">
                <WhiteBoxCollapsable
                    collapsableOptions={{ title: t(CompanyTranslations.EDIT_COMPANY_OPTIONS_TITLE), border: "none" }}
                    whiteBoxOptions={{ fullWidth: true }}
                >
                    <div className="editForm__options">
                        <div className="editForm__container__switch">
                            <Switch onChange={handleWorksWithQR} checked={worksWithQR} />
                            <Label label={t(TranslationKeys.WORKS_WITH_QR)} />
                        </div>
                        <CloseCompanyDates
                            closeCompany={closeCompany}
                            closeCompanies={closeCompanies}
                            onSwitchClick={handleSwitchClick}
                            onClosingDates={handleClosingDates}
                            onAddNewRangeHour={handleAddNewRangeHour}
                            onDeleteNewRangeHour={handleDeleteNewRangeHour}
                            errorMessage={errorCloseCompanies}
                        />
                    </div>
                </WhiteBoxCollapsable>
            </div>
        </form>
    );
};
