import { ActionType, OptionsSearch } from "app/models/FormComponentsModel";
import {
    EmailDistributionListTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
} from "app/translation/translationKeys";
import { faEnvelopes } from "@fortawesome/pro-regular-svg-icons";
import { FC, FormEvent, useEffect, useState } from "react";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionsOutsideSelect, SelectOptions } from "app/components_v2/__inputs";
import { SecScreen } from "app/shared/Constants";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import {
    EmailDistributionListErrors,
    EmailDistributionListFormValues,
} from "../models/EmailDistributionListFormValues";
import RepSelectorService from "app/services/03-REP/RepSelectorService";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";

type EmailDistributionListUsersModalProps = {
    title: string;
    type: ActionType;
    emailDistributionList: EmailDistributionListFormValues;
    emailDistributionListErrors: EmailDistributionListErrors;
    onClose: () => void;
    onSubmit: (type: ActionType) => void;
    onChange: (value: Partial<EmailDistributionListFormValues>) => void;
};

export const EmailDistributionListUsersModal: FC<EmailDistributionListUsersModalProps> = ({
    title,
    type,
    emailDistributionList,
    emailDistributionListErrors,
    onClose,
    onSubmit,
    onChange,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const { handleErrorManager } = useErrorManager();
    const { getErrorMessage } = useFetchErrors();

    const [usersOptions, setUsersOptions] = useState<OptionModel[]>([]);
    const [distributionListTypes, setDistributionListTypes] = useState<OptionModel[]>([]);
    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [companySelected, setCompanySelected] = useState<boolean>(type === "create" ? false : true);

    const { errorFunctionality, errorCompany, errorUserList } = emailDistributionListErrors;

    const getData = async () => {
        setIsLoading(true);

        await executePromises();
        setIsLoading(false);
    };

    const getUserListPromise = (): Promise<ServiceResponse<OptionModel[]>> => {
        const supervisorParams = `userId=${session?.user.id}&includeMyself=true`;
        const supervisorAndAnalistUsersPromise = RepSelectorService.GetSupervisorAndAnalyst({
            extraParams: supervisorParams,
        });

        return supervisorAndAnalistUsersPromise;
    };

    const executePromises = async () => {
        if (type === "create") {
            const companyOptionsPromise = SegSelectorService.GetCompanyNamesWithIds({
                extraParams: `supervisorId=${session?.user.id}`,
            });
            const { data, status, getParsedError } = await companyOptionsPromise;
            const companyErrorMessage = getErrorMessage(getParsedError());
            if (handleErrorManager(status(), companyErrorMessage)) {
                return;
            }
            setCompanyOptions(data);

            return;
        }

        const { data, status } = await getUserListPromise();
        if (!status) {
            setUsersOptions([]);
            setIsLoading(false);
        }

        const filteredOptions = data.filter(
            (dataOption) => !emailDistributionList?.userList.some((o) => o.value === dataOption.value)
        );
        setUsersOptions(filteredOptions);
    };

    const getDistributionTypesAndUserList = (companyId: string) => {
        const distributionListTypesPromise = SegSelectorService.GetDistributionListTypes(companyId);
        Promise.all([distributionListTypesPromise, getUserListPromise()]).then(
            ([distributionListTypesSr, supervisorAndAnalistUsersSr]) => {
                const distributionErrorMessage = getErrorMessage(distributionListTypesSr.getParsedError());
                const supervisorAndAnalistErrorMessage = getErrorMessage(supervisorAndAnalistUsersSr.getParsedError());

                const hasErrors =
                    handleErrorManager(distributionListTypesSr.status(), distributionErrorMessage) ||
                    handleErrorManager(supervisorAndAnalistUsersSr.status(), supervisorAndAnalistErrorMessage);
                if (hasErrors) return;

                if (!distributionListTypesSr.data.length) {
                    setDistributionListTypes([]);
                    return;
                }

                setDistributionListTypes(
                    distributionListTypesSr.data.map((option) => ({ ...option, label: t(option.label) }))
                );

                const filteredOptions = supervisorAndAnalistUsersSr.data.filter(
                    (dataOption) => !emailDistributionList?.userList.some((o) => o.value === dataOption.value)
                );
                setUsersOptions(filteredOptions);
            }
        );
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoadingSave(true);
        onSubmit(type);
        setIsLoadingSave(false);
    };

    const handleChange = (items: OptionModel[]) => {
        onChange({ userList: items });
        setUsersOptions((prev) => prev.filter(({ value }) => !items.some((o) => o.value === value)));
    };

    const handleDeleteItem = (itemId: string) => {
        const selectedItem = emailDistributionList.userList.find(({ value }) => value === itemId);
        if (!selectedItem) return;
        setUsersOptions((prev) => [...prev, selectedItem]);

        onChange({ userList: emailDistributionList.userList.filter(({ value }) => value !== itemId) });
    };

    const handleChangeCompany = ({ value }: OptionsSearch) => {
        onChange({ fK_Company: value });
        setCompanySelected(true);
        getDistributionTypesAndUserList(value);
    };

    useEffect(() => {
        getData();
    }, []);

    const SelectUserList: FC = () => (
        <OptionsOutsideSelect
            onChange={handleChange}
            options={usersOptions}
            onDeleteItem={handleDeleteItem}
            values={emailDistributionList.userList}
            hidden={!hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST)}
            placeholder={t(TranslationKeys.SELECT_USERS)}
            label={t(TranslationKeys.USERS)}
            isLoading={isLoading}
            isMulti
            errorMessage={errorUserList}
            autoInitials
        />
    );

    return (
        <GenericModal
            header={{ title, onClose }}
            size="md"
            footer={
                hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST) && (
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.SAVE),
                            form: "emailDistributionListUsers",
                            type: "submit",
                            disabled: companySelected && type === "create" && !distributionListTypes.length,
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            buttonType: "tertiary",
                            onClick: onClose,
                        }}
                        loading={isLoadingSave}
                    />
                )
            }
        >
            <form onSubmit={handleSubmit} className="addUsersModal" id="emailDistributionListUsers">
                <div className="emailDistributionList__modal">
                    {type === "create" && (
                        <SelectOptions
                            isMulti={false}
                            onChange={handleChangeCompany}
                            options={companyOptions}
                            label={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_COMPANY_LABEL)}
                            selectedValue={
                                companyOptions.find(({ value }) => value === String(emailDistributionList?.fK_Company))
                                    ?.value
                            }
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                            isLoading={isLoading}
                            errorMessage={errorCompany}
                        />
                    )}
                    {companySelected ? (
                        type === "create" ? (
                            !!distributionListTypes.length ? (
                                <>
                                    <SelectOptions
                                        isMulti={false}
                                        onChange={({ value }) => onChange({ fK_DistributionList: value })}
                                        options={distributionListTypes}
                                        label={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_TYPE_LABEL)}
                                        selectedValue={
                                            distributionListTypes.find(
                                                ({ value }) =>
                                                    value === String(emailDistributionList?.fK_DistributionList)
                                            )?.value
                                        }
                                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                                        isLoading={isLoading}
                                        errorMessage={errorFunctionality}
                                    />
                                    <SelectUserList />
                                </>
                            ) : (
                                <TableError
                                    title={t(TranslationErrors.EMAIL_DISTRIBUTION_LIST_FULL_ERROR)}
                                    icon={faEnvelopes}
                                />
                            )
                        ) : (
                            <SelectUserList />
                        )
                    ) : null}
                </div>
            </form>
        </GenericModal>
    );
};
