import WentWrong from "app/components_v2/Errors/WentWrong";
import Loading from "app/components_v2/Loading/Loading";
import Spinner from "app/components_v2/Spinner/Spinner";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { useSession } from "app/hooks";
import { useTitle } from "app/hooks/CustomHooks";
import { useToast } from "app/hooks/Toast/useToast";
import { GroupCompanyModel } from "app/models/01-SEG/GroupCompany/GroupCompanyModel";
import { ComponentStatus } from "app/models/FormComponentsModel";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { GroupCompanyService } from "app/services";
import { ON_BOARDING_SUB_STEPS, SecScreen } from "app/shared/Constants";
import { IAppState } from "app/state/reducer";
import { TranslationKeys, TranslationModals, TranslationTitles } from "app/translation/translationKeys";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GroupCompanyCollapse from "./GroupCompanyCollapse";
import { GroupCompanyModal } from "./GroupCompanyModal";

const GroupCompany: FC = () => {
    const { t } = useTranslation();
    const session = useSession();
    const instanceId = useSelector<IAppState, number | undefined>((state) => state.customerInstanceId);
    const { handleToast } = useToast();

    useTitle(t(TranslationTitles.GROUP_COMPANY_PAGE_TITLE));

    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [groups, setGroups] = useState<GroupCompanyModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [httpCode, setHttpCode] = useState<number>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const fetch = async (id?: number) => {
        const sr = await GroupCompanyService.GetData({
            extraParams: id ? `customerInstanceId=${id}` : undefined,
        });
        if (sr.status()) {
            setGroups(sr.data.list);
            setStatus("complete");
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setStatus("errored");
        }
        setHttpCode(sr.httpCodeStatus);
        setRefresh(false);
        setLoading(false);
    };

    const handleSubmit = (success: boolean) => {
        if (!success) {
            setLoading(false);
            setShowEditModal(false);
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setRefresh(true);
        setShowEditModal(false);
    };

    const handleNew = () => {
        if (!session?.user.customerInstanceId && !instanceId) {
            handleToast({
                title: t(TranslationModals.TOAST_WARNING_SELECT_OPTION),
                type: "alert",
                variant: "warning",
            });
        } else setShowEditModal(true);
    };

    useEffect(() => {
        if (refresh) {
            setLoading(true);
            fetch(instanceId);
        }
        if (instanceId) {
            setLoading(true);
            fetch(instanceId);
            return;
        }
    }, [refresh, instanceId, session?.workingCompany?.companyId]);

    switch (status) {
        case "loading":
            return Loading();

        case "errored":
            return <WentWrong errorType={httpCode?.toString()} />;

        case "complete":
            return (
                <PageContainer paddingTop>
                    {showEditModal && (
                        <GroupCompanyModal
                            onClose={() => setShowEditModal(false)}
                            onSubmit={handleSubmit}
                            title={t(TranslationKeys.NEW_GROUP_COMPANY)}
                            values={{
                                customerInstanceId: instanceId!,
                                id: 0,
                                name: "",
                                isActive: true,
                                isDeletable: false,
                            }}
                            isEdit={false}
                        />
                    )}
                    <div className="gridPage__title">
                        <p>{t(TranslationKeys.COMPANIES)}</p>
                        {hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT) && (
                            <NewButton text={t(TranslationKeys.NEW_GROUP_COMPANY)} handleClickButton={handleNew} />
                        )}
                    </div>
                    <div className="custom-collapse-container">
                        {loading && <Spinner />}
                        {!loading && groups.length === 0 && <p>{t(TranslationKeys.NO_GROUPS_TEXT)}</p>}
                        {!loading &&
                            groups.map((group, i) => (
                                <GroupCompanyCollapse
                                    model={group}
                                    key={"cllps" + i}
                                    afterDelete={() => setRefresh(true)}
                                    showOpened={i === 0}
                                />
                            ))}
                    </div>
                </PageContainer>
            );
    }
};

export default GroupCompany;
