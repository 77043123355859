import { FC, useRef } from "react";
import { IssueAttachmentModel } from "app/models/05-QUA/IssueModels/IssueAttachmentModel";
import { IssueBodyTitle } from "../IssueBodyTitle/IssueBodyTitle";
import { Attachment } from "app/components_v2/Attachment/Attachment";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { useTranslation } from "react-i18next";
import { useIssueBodyAttachment } from "./useIssueBodyAttachment";
import { IssueTranslation } from "app/translation/translationKeys";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";

export type IssueBodyAttachmentsProps = {
    attachments: IssueAttachmentModel[];
    id: number;
    onAddNewAttachments: (value: IssueAttachmentModel[]) => void;
};

export const IssueBodyAttachments: FC<IssueBodyAttachmentsProps> = ({ attachments, onAddNewAttachments, id }) => {
    const { t } = useTranslation();
    const { handleFileChange } = useIssueBodyAttachment(id, onAddNewAttachments);

    const fileRef = useRef<null | HTMLInputElement>(null);

    const handleOpen = () => {
        fileRef.current?.click();
    };

    return (
        <>
            <div className="issueBodyAttachments">
                <div className="issueBodyAttachments__container">
                    <IssueBodyTitle>{t(IssueTranslation.ISSUE_BODY_ATTACHMENT_TITLE)}</IssueBodyTitle>
                    {attachments.length !== 0 && (
                        <div className="issueBodyAttachments__attachments">
                            {attachments.map(({ fileName, id }) => (
                                <Attachment fileName={fileName} key={id} />
                            ))}
                        </div>
                    )}
                </div>
                {hasPermissionToAdd(SecScreen.ATTACH_FILES) && (
                    <AddNewButton onClick={handleOpen}>
                        {t(IssueTranslation.ISSUE_BODY_ATTACHMENT_ADD_DOCUMENT)}
                    </AddNewButton>
                )}
                <input
                    type="file"
                    ref={fileRef}
                    onChange={handleFileChange}
                    hidden
                    accept="image/jpeg, image/jpg, image/webp"
                    multiple
                    role="textbox"
                />
            </div>
        </>
    );
};
