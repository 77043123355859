import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "app/state/reducer";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

export const useDepartments = (value: number | null) => {
    const [departments, setDepartments] = useState<OptionModel[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const { session } = useSelector<IAppState, IAppState>((state) => state);

    useEffect(() => {
        if (!value) return;
        setLoading(true);
        getDepartment();
        setLoading(false);
    }, [value]);

    const getDepartment = async () => {
        const departmentsSr = await SegSelectorService.GetDepartmentNamesWithIds({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}&companyId=${value}`,
        });

        if (!departmentsSr.status()) {
            console.error("error", departmentsSr);
            setError(departmentsSr.error);
            return;
        }
        setDepartments(departmentsSr.data);
    };
    return { departments, error, loading };
};
