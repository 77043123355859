import { handleToastModel, useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionsSearch, OptionsSearchT } from "app/models/FormComponentsModel";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { UserService } from "app/services";
import { SecScreen } from "app/shared/Constants";
import { UserType } from "app/shared/types/UserTypes";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationModules,
} from "app/translation/translationKeys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserInfoFormProps } from "../UserInfoForm";

type UseUserInfoForm = UserInfoFormProps;

export const useUserInfoForm = ({
    userFormValues,
    companyOptions,
    isActive,
    onInputChange,
    roleOptions,
    isEditable,
    userId,
    onRoleChange,
}: UseUserInfoForm) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState<boolean>(false);
    const [isLoadingResetPassword, setIsLoadingResetPassword] = useState<boolean>(false);

    const { userRoleModelList, userCompanyList, automaticActivationDate } = userFormValues;

    const disabled = (isEditable && !hasPermissionToEdit(SecScreen.USER_MANAGEMENT)) || !isActive;

    const showActivationDate = (): boolean => {
        const isAutomaticActivationNull = automaticActivationDate != null;
        const isAutomaticActivationFutureDate =
            isAutomaticActivationNull && new Date(automaticActivationDate) > new Date();
        return (!userId || (!isActive && isAutomaticActivationFutureDate)) && !disabled;
    };
    const onChangeInitialView = ({ value }: OptionsSearch) => onInputChange({ initialScreen: value });

    const fillOptionsMultiSelect = (): OptionsSearch[] => {
        const roleList = userRoleModelList.map(({ roleId }) => roleId);
        return roleOptions.filter(({ value }) => !roleList.includes(Number(value)));
    };

    const fillValues = (): OptionsSearch[] => {
        const roleList = userRoleModelList.map(({ roleId }) => roleId);
        return roleOptions.filter(({ value }) => roleList.includes(Number(value)));
    };

    const fillOptions = (): OptionsSearch[] => {
        const defaultOption = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: String(-1),
        };
        return [defaultOption, ...roleOptions];
    };

    const fillCompanyOptionsMultiSelect = (): OptionsSearch[] => {
        const companyList = userCompanyList.map(({ value }) => value);
        return companyOptions.filter(({ value }) => !companyList.includes(value));
    };

    const fillCompanyValues = (): OptionsSearch[] => {
        const companyList = userCompanyList.map(({ value }) => value);
        return companyOptions.filter(({ value }) => companyList.includes(value));
    };

    const userTypeOptions: OptionsSearchT<UserType | "-1">[] = [
        { label: t(TranslationCommon.SELECT_OPTION), value: "-1" },
        { label: "Supervisor", value: "SUPERVISOR" },
        { label: t(TranslationModules.TAR_REGISTRY), value: "WORKER" },
        { label: "Analista", value: "ANALIST" },
    ];

    const onConfirmModal = async () => {
        if (!userId) return;
        setIsLoadingResetPassword(true);
        const { getParsedError, status } = await UserService.ResetPaswordManual(userId);
        const toastValues: handleToastModel = {
            title: status() ? t(TranslationModals.EDIT_FORM_USER_TITLE_TOAST) : getErrorMessage(getParsedError()),
            variant: status() ? "success" : "danger",
            type: "alert",
        };
        handleToast(toastValues);
        setIsLoadingResetPassword(false);
        setIsResetPasswordModalOpen(false);
    };

    const onChangeRole = (value: OptionModel[]) => {
        const userRoleList = value.map(({ label, value }) => ({
            roleId: Number(value),
            roleName: label,
        }));
        onInputChange({
            userRoleModelList: userRoleList,
        });
        onRoleChange(userRoleList.map(({ roleId }) => roleId));
    };

    const onDeleteRole = (id: string) => {
        const userRoleList = userRoleModelList.filter(({ roleId }) => String(roleId) !== id);
        onInputChange({
            userRoleModelList: userRoleList,
        });
        onRoleChange(userRoleList.map(({ roleId }) => roleId));
    };

    const onChnageSingleRole = ({ label, value }: OptionModel) => {
        const userRoleModelList =
            value === "-1"
                ? []
                : [
                      {
                          roleId: Number(value),
                          roleName: label,
                      },
                  ];

        onInputChange({
            userRoleModelList,
        });
    };

    const onDeleteCompany = (id: string) =>
        onInputChange({
            userCompanyList: userCompanyList.filter(({ value }) => value !== id),
        });
    return {
        isResetPasswordModalOpen,
        isLoadingResetPassword,
        showActivationDate,
        onChangeInitialView,
        fillOptionsMultiSelect,
        fillValues,
        fillOptions,
        fillCompanyOptionsMultiSelect,
        fillCompanyValues,
        userTypeOptions,
        onConfirmModal,
        onChangeRole,
        onDeleteRole,
        onChnageSingleRole,
        onDeleteCompany,
        setIsResetPasswordModalOpen,
        setIsLoadingResetPassword,
        disabled,
    };
};
