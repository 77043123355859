import { FC } from "react";
import { EmailDistributionListModel } from "app/models/01-SEG/EmailDistributionList/EmailDistributionListModel";
import { useTranslation } from "react-i18next";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { OptionModel } from "app/models/02-TAR/OptionModel";

type MobileEmailDistributionListRowProps = {
    data: EmailDistributionListModel;
    onClickEdit: (fK_Company: number, fK_DistributionList: number, userList: OptionModel[]) => void;
};

export const MobileEmailDistributionListRow: FC<MobileEmailDistributionListRowProps> = ({ data, onClickEdit }) => {
    const { t } = useTranslation();
    const { functionality, companyName, userList, fK_Company, fK_DistributionList } = data;

    return (
        <div className="mobileEmailDistributionListRow">
            <p className="mobileEmailDistributionListRow__title text_clamp text_clamp-2">{t(functionality)}</p>
            <p className="mobileEmailDistributionListRow__small text_clamp text_clamp-2">{companyName}</p>
            <p className="mobileEmailDistributionListRow__counter">
                <Badge title={String(userList.length)} variant="blue" />
            </p>
            <CellIcons
                icons={[
                    {
                        icon: faPen,
                        onClick: () => onClickEdit(fK_Company, fK_DistributionList, userList),
                    },
                ]}
            />
        </div>
    );
};
