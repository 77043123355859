import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { OnBoardingStepsInstanceModel } from "app/models/01-SEG/Onboarding/OnBoardingStepsInstanceModel";
import { UpdateOnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingServiceModel";

export default class OnBoardingStepInstanceService {
    public static async GetData() {
        return FetchService.get<OnBoardingStepsInstanceModel[]>({
            url: `${ApiPaths.SEG_API}/OnBoardingStepInstance`,
        });
    }

    public static async CompleteStep(body: UpdateOnBoardingStatus) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/OnBoardingStepInstance/${body.id}`,
            body,
        });
    }
}
