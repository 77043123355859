import { OptionsSearch } from "app/models/FormComponentsModel";
import { FC, useRef, useState } from "react";
import { MenuPosition, SelectInstance, createFilter } from "react-select";
import { Hint } from "../__inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { TranslationCommon } from "app/translation/translationKeys";
import isiOS from "app/helpers/isIos";
import { SelectWithActionsMenu } from "./SelectWithActionsMenu/SelectWithActionsMenu";
import { SelectWithActionsProvider } from "./SelectWithActionsProvider/SelectWithActionsProvider";
import { SelectActionsLastItemsModel, SelectActionsModel, SelectActionsOptions } from "./types";
import { Label } from "../__inputs/Label";
import { ReadOnlyInput } from "../__inputs/ReadOnlyInput/ReadOnlyInput";
import { TabHeaderVariants } from "../__containers/TabHeader/types";

type SelectOptionsProps = {
    options: SelectActionsOptions[];
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    selectedValue?: string;
    errorMessage?: string;
    hint?: string;
    hideSelectedValue?: boolean;
    menuPosition?: MenuPosition;
    htmlFor?: string;
    isSearchable?: boolean;
    showBorderColor?: boolean;
    inverted?: boolean;
    isOptional?: boolean;
    onChange: (value: OptionsSearch) => void;
    actions?: SelectActionsModel[];
    lastItems?: SelectActionsLastItemsModel[];
};

export const SelectWithActions: FC<SelectOptionsProps> = ({
    label,
    isOptional,
    disabled,
    htmlFor,
    hint,
    errorMessage,
    onChange,
    options,
    hideSelectedValue,
    inverted,
    isSearchable,
    menuPosition,
    placeholder,
    selectedValue,
    showBorderColor,
    actions,
    lastItems,
}) => {
    const [isHintVisible, setIsHintVisible] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const selectRef = useRef<SelectInstance<OptionsSearch>>(null);
    const [searchedValue, setSearchedValue] = useState<string>("");
    const { t } = useTranslation();

    const handleChangeHint = () => {
        if (!!errorMessage?.length) {
            setIsHintVisible(false);
            return;
        }
        setIsHintVisible(!isHintVisible);
    };

    const handleInputChange = (value: OptionsSearch) => {
        onChange(value);
        closeMenu();
    };

    const closeMenu = () => selectRef.current?.onMenuClose();

    const customStyles = {
        placeholder: (styles: any) => ({
            ...styles,
            color: inverted ? "white" : "gray",
            fontSize: "14px",
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 99 }),
    };

    const getSelectedValue = () => {
        const selectedOption = options.find(({ value }) => value === selectedValue);

        if (!selectedOption) return "";
        return selectedOption.label;
    };

    if (disabled && !!getSelectedValue().length)
        return <ReadOnlyInput value={getSelectedValue()} label={label} type="select" />;

    return (
        <SelectWithActionsProvider
            onSelect={handleInputChange}
            selectOptions={options}
            selectedValue={selectedValue || ""}
            searchedValue={searchedValue}
            actions={actions || []}
            lastItems={lastItems || []}
            closeMenu={closeMenu}
        >
            <div className="selectWithActions">
                {label && (
                    <div className="selectWithActions__label">
                        <Label isOptional={isOptional} label={label} disabled={disabled} htmlFor={htmlFor} />
                        {hint && (
                            <FontAwesomeIcon
                                icon={faCircleQuestion}
                                className={"inputTextWrapper__iconwrapper__icon"}
                                onClick={handleChangeHint}
                                data-testid="react-select-hint-icon"
                            />
                        )}
                    </div>
                )}
                <div
                    data-testid="selectOptions__select"
                    className={`selectOptions__select${errorMessage || showBorderColor ? "__errored" : ""}${
                        isFocused ? "--focused" : ""
                    } `}
                >
                    <Select
                        ref={selectRef}
                        placeholder={placeholder}
                        options={options}
                        styles={customStyles}
                        components={{ MenuList: SelectWithActionsMenu }}
                        onChange={(newValue) => onChange(newValue as OptionsSearch)}
                        name={htmlFor}
                        value={selectedValue ? options.filter((option) => option.value === selectedValue) : null}
                        filterOption={createFilter({})}
                        inputId={htmlFor}
                        menuPosition={menuPosition}
                        menuPortalTarget={document.body}
                        menuPlacement="bottom"
                        isDisabled={disabled}
                        classNames={{
                            control: () =>
                                `reactSelectClassName ${inverted ? "reactSelectClassName--inverted" : ""} ${
                                    isFocused ? "reactSelectClassName--focused" : ""
                                }
                            ${disabled ? "reactSelectClassName--disabled" : ""}`,
                            menuPortal: () => "zIndexUp",
                            indicatorsContainer: () => (inverted ? "reactSelectIndicator--inverted" : ""),
                            singleValue: () => (inverted ? "reactSelectSingleValue--inverted" : ""),
                            placeholder: () => "reactSelectPlaceholder text_clamp text_clamp-1",
                        }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        controlShouldRenderValue={!hideSelectedValue}
                        isClearable={false}
                        noOptionsMessage={() => <p>{t(TranslationCommon.NO_MORE_OPTIONS)}</p>}
                        isSearchable={isiOS() ? false : isSearchable}
                        onInputChange={(newValue) => setSearchedValue(newValue)}
                    />
                </div>

                {!!errorMessage?.length && !disabled ? (
                    <ErrorMessage errorMessage={errorMessage} />
                ) : (
                    hint && isHintVisible && <Hint text={hint} />
                )}
            </div>
        </SelectWithActionsProvider>
    );
};
