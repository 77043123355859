import { FC } from "react";
import { OptionCompanyImage } from "./OptionCompanyImage/OptionCompanyImage";
import { Badge } from "../Badge/Badge";
import { OpionCompanyBadge } from "./types";

type OptionCompanyProps = {
    logoImage?: string;
    name: string;
    isActive?: boolean;
    count?: number;
    onClick?: () => void;
    isDisabled?: boolean;
    badge?: OpionCompanyBadge;
    justifyContent?: "center" | "spaceBetween";
};

export const OptionCompany: FC<OptionCompanyProps> = ({
    name,
    isActive,
    logoImage,
    count,
    onClick,
    badge,
    isDisabled,
    justifyContent = "center",
}) => {
    return (
        <div
            className={`optionCompany${!onClick ? "--noHover" : isActive ? "--active" : ""} ${
                isDisabled ? "optionCompany--disabled" : ""
            }`}
            onClick={onClick}
        >
            <div className="optionCompany__container">
                <OptionCompanyImage name={name} logoImage={logoImage} />
                <div className={`optionCompany__header--${justifyContent}`}>
                    <div className="optionCompany__header__info">
                        <p className="optionCompany__text">{name}</p>
                        {!!count && <p className="optionCompany__counter">{count}</p>}
                    </div>
                    {badge && <Badge {...badge} />}
                </div>
            </div>
        </div>
    );
};
