import { FC } from "react";
import { IssueListModel } from "app/models/05-QUA/IssueModels/IssueListModel";
import { MobileRowHeader } from "app/pages/05-QUA/Issue/IssueGrid/components/MobileIssueRow/MobileRowHeader/MobileRowHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";

type AuditIssuePillProps = {
    issue: IssueListModel;
    onPillClick: () => void;
};
export const AuditIssuePill: FC<AuditIssuePillProps> = ({ issue, onPillClick }) => {
    const {
        actualStatus,
        openedByUserId,
        openedByUserName,
        openedByProfilePictureUrl,
        closedByUserId,
        closedByUserName,
        closedByProfilePictureUrl,
        description,
        closeDate,
        openDate,
        isCritical,
    } = issue;

    return (
        <div className="auditIssuePill" onClick={onPillClick}>
            <MobileRowHeader
                actualStatus={actualStatus}
                colorId={closedByUserId || openedByUserId}
                name={closedByUserName || openedByUserName}
                openDate={closeDate || openDate}
                profilePictureUrl={closedByProfilePictureUrl || openedByProfilePictureUrl || ""}
            />
            <div className="auditIssuePill__body">
                <p className="auditIssuePill__body__description">{description}</p>
                {!!isCritical && (
                    <div className="auditIssuePill__body__icon">
                        <FontAwesomeIcon className="auditIssuePill__body__icon__svg" icon={faTriangleExclamation} />
                    </div>
                )}
            </div>
        </div>
    );
};
