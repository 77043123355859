import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Label } from "app/components_v2/__inputs/Label";

type SelectDepartmentsProps = {
    changeDepartmentSelected: (value: string, text: string) => void;
    value: string;
    options: OptionModel[];
    customError?: string;
    isDisabled?: boolean;
};

export const SelectDepartments: FC<SelectDepartmentsProps> = ({
    changeDepartmentSelected,
    options,
    value,
    customError,
    isDisabled,
}) => {
    const { t } = useTranslation();

    const [selectorOptions, setSelectorOptions] = useState<OptionModel[]>([]);

    useEffect(() => {
        setSelectorOptions(options);
    }, [options]);

    return (
        <div className="stepTwoSelectWithRadio">
            <Label label={t(TranslationKeys.DEPARTMENT)} className="selectOptions__label" />
            <div className="stepTwoSelectWithRadio__container">
                <SelectOptions
                    options={selectorOptions}
                    isMulti={false}
                    onChange={(newValue) => {
                        changeDepartmentSelected(newValue.value, newValue.label);
                    }}
                    selectedValue={value}
                    htmlFor="selectDepartment"
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    showBorderColor={!!customError?.length}
                    disabled={isDisabled}
                />
            </div>
            {customError && (
                <div className="stepTwoSelectWithRadio__errorMessage">
                    <ErrorMessage errorMessage={customError} />
                </div>
            )}
        </div>
    );
};
