import { DynamicFieldItem } from "../DynamicFieldItem/DynamicFieldItem";
import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { TranslationKeys } from "app/translation/translationKeys";
import { taskfield2Error } from "app/models/02-TAR/Task/EditTask";
import { DynamicTaskFieldOption } from "app/models/02-TAR/TaskWizard";
import { Label } from "app/components_v2/__inputs/Label";

type DynamicFieldItemDropdownProps = {
    value: string;
    items: DynamicTaskFieldOption[];
    isRequired: boolean;
    id: string;
    hideAddNew: boolean;
    error?: taskfield2Error;
    focus?: boolean;
    disabled?: boolean;
    onChange: (value: string) => void;
    onCheckboxChange: (value: boolean) => void;
    onDelete: () => void;
    onDeleteItem: (id: string) => void;
    onAddNewItem: () => void;
    onChangeItem: (id: string, value: string) => void;
};

export const DynamicFieldItemDropdown: FC<DynamicFieldItemDropdownProps> = ({
    id,
    isRequired,
    items,
    onAddNewItem,
    onChange,
    onChangeItem,
    onCheckboxChange,
    onDelete,
    onDeleteItem,
    value,
    hideAddNew,
    error,
    focus,
    disabled,
}) => {
    const { t } = useTranslation();
    const ref = useRef(false);
    const handleAddNew = () => {
        if (disabled) return;
        ref.current = true;
        onAddNewItem();
    };

    return (
        <div className="dynamicFieldItemDropdown">
            <DynamicFieldItem
                checked={isRequired}
                dynamicFieldType="DROPDOWN"
                id={id}
                onChange={onChange}
                onChangeCheckbox={onCheckboxChange}
                onDeleteItem={onDelete}
                value={value}
                errorMessage={error?.errorMessage}
                focus={focus}
                disabled={disabled}
            />
            <div className="dynamicFieldItemDropdown__items">
                <Label label={t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_OPTION_TITLE)} size="sm" disabled={disabled} />
                {items.map((item) => (
                    <DynamicFieldItem
                        id={item.id}
                        onChange={(value) => onChangeItem(item.id, value)}
                        onDeleteItem={() => onDeleteItem(item.id)}
                        value={item.label}
                        key={item.id}
                        errorMessage={
                            error?.taskfieldOptions.find((taskfield) => taskfield.id === item.id)?.errorMessage
                        }
                        focus={ref.current}
                        disabled={disabled}
                        hideDeleteButton={items.length === 1}
                        isAnOption
                        dynamicFieldType="DROPDOWN"
                    />
                ))}
                {!hideAddNew && !disabled && (
                    <div className="dynamicFieldItemDropdown__addNew">
                        <div className="dynamicFieldItemDropdown__addNew__container" onClick={handleAddNew}>
                            <FontAwesomeIcon className="dynamicFieldItemDropdown__addNew__icon" icon={faPlus} />
                            <p className="dynamicFieldItemDropdown__addNew__text">
                                {t(TranslationKeys.ADD_DATA_TO_REPORT)}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
