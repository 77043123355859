import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAssetFormInfo } from "./hooks/useAssetFormInfo";
import { useSession } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { useSensorBattery } from "app/pages/04-SEN/AutomaticRecordGrid/hooks/useSensorBattery";
import { useSensorSignal } from "app/pages/04-SEN/AutomaticRecordGrid/hooks/useSensorSignal";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import {
    AssetTranslation,
    AutomaticRecordTranslations,
    TaskTranslations,
    TranslationCommon,
    TranslationKeys,
} from "app/translation/translationKeys";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { AssetFormInfoModel } from "app/models/05-QUA/AssetModels";
import { AssetFormProps, AssetFormValidationsModel } from "../models/AssetFormModel";
import { TextArea } from "app/components_v2/__inputs/TextArea/TextArea";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { Input } from "app/components_v2/__inputs/Input/Input";
import { AssetNotWorking } from "../AssetNotWorking/AssetNotWorking";
import { Switch } from "app/components_v2/__inputs";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";
import { Badge } from "app/components_v2/Badge/Badge";

export const AssetFormInfo: FC<AssetFormProps<AssetFormInfoModel, AssetFormValidationsModel>> = ({
    assetId,
    description,
    fK_AssetType,
    fK_Company,
    isDisabled,
    name,
    onInputChange,
    customError,
    notWorking,
    fK_PlanAPPCC,
    id,
    allowSensorSerialNumber,
    sensorSerialNumber,
    battery,
    signalLevel,
}) => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const session = useSession();
    const isOnBoarding = session?.isOnBoarding;
    const hasPermissionsToViewSensors = hasPermissionToView(session, SecScreen.AUTOMATIC_RECORD);

    const { assetTypeOptions, companyOptions, planAPPCCOptions } = useAssetFormInfo();
    const { batteryBadgeTitle, batteryBadgeColor, batteryBadgeIcon } = useSensorBattery();
    const { signalBadgeTitle, signalBadgeColor, signalBadgeIcon } = useSensorSignal();

    const { errorCompany, errorName, errorType, errorPlanAPPCC, errorAssetId, errorSerialNumber } = customError;
    const showNotWorkingSwitch = !!id && hasPermissionToView(session, SecScreen.OPEN_MANUAL_ISSUE) && !isOnBoarding;
    const [showNoDataPlaceholder, setShowNoDataPlaceholder] = useState<boolean>(true);

    return (
        <>
            <WhiteBoxCollapsable
                collapsableOptions={{
                    background: "transparent",
                    border: "none",
                    title: t(AssetTranslation.ASSET_INFO_TITLE),
                }}
            >
                <div className="assetForm__info">
                    <div className="assetForm__container__assetName">
                        <Input
                            value={name}
                            onChange={(value) => onInputChange({ name: value })}
                            label={t(TranslationKeys.NAME)}
                            placeholder={t(TranslationKeys.ASSET_NAME_PLACEHOLDER)}
                            errorMessage={errorName}
                            disabled={isDisabled}
                        />
                        <Input
                            value={assetId}
                            onChange={(value) => {
                                onInputChange({ assetId: value });
                            }}
                            label={t(TranslationKeys.ASSET_ID)}
                            placeholder={t(TranslationKeys.ASSET_ID)}
                            disabled={isDisabled}
                            isOptional
                            errorMessage={errorAssetId}
                            max={8}
                        />
                    </div>
                    <div className="assetForm__container__assetDescription">
                        <TextArea
                            text={description}
                            onChange={(value) => onInputChange({ description: value })}
                            label={`${t(TranslationKeys.DESCRIPTION)} (${t(TranslationKeys.INPUT_OPTIONAL)})`}
                            placeholder={t(TranslationKeys.ASSET_DESCRIPTION_PLACEHOLDER)}
                            disabled={isDisabled}
                        />
                    </div>
                    <div className="assetForm__container__fields">
                        <div className="assetForm__container__fields">
                            <div className="assetForm__container__section">
                                <SelectOptions
                                    selectedValue={String(fK_Company)}
                                    onChange={({ value }) => onInputChange({ fK_Company: Number(value) })}
                                    options={companyOptions}
                                    label={t(TranslationKeys.WORKING_POSITION_COMPANY_LABEL)}
                                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                                    isMulti={false}
                                    disabled={isDisabled}
                                    errorMessage={errorCompany}
                                />
                                <SelectOptions
                                    label={t(TaskTranslations.TASK_APPCC_LABEL)}
                                    selectedValue={String(fK_PlanAPPCC)}
                                    onChange={({ value }) => {
                                        onInputChange({
                                            fK_PlanAPPCC: Number(value),
                                        });
                                    }}
                                    options={planAPPCCOptions}
                                    errorMessage={errorPlanAPPCC}
                                    disabled={isDisabled}
                                    isMulti={false}
                                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                                />
                                <SelectOptions
                                    label={t(TranslationKeys.ASSET_TYPE)}
                                    selectedValue={assetTypeOptions
                                        .find((option) => option.id === fK_AssetType)
                                        ?.id?.toString()}
                                    onChange={({ value }) => {
                                        onInputChange({
                                            fK_AssetType: Number(value),
                                        });
                                    }}
                                    options={assetTypeOptions.map(({ id, name }) => ({
                                        label: t(name),
                                        value: String(id),
                                    }))}
                                    errorMessage={errorType}
                                    disabled={isDisabled}
                                    isMulti={false}
                                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                                />
                                {showNotWorkingSwitch && (
                                    <AssetNotWorking
                                        assetId={id}
                                        isWorking={!notWorking}
                                        onChange={(isNotWorking) => onInputChange({ notWorking: isNotWorking })}
                                        companyId={fK_Company}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </WhiteBoxCollapsable>
            {hasPermissionsToViewSensors && (
                <WhiteBoxCollapsable
                    collapsableOptions={{
                        background: "transparent",
                        border: "none",
                        title: t(AssetTranslation.ASSET_SENSOR_TITLE),
                    }}
                >
                    <div className="assetForm__serialNumber">
                        <Switch
                            checked={allowSensorSerialNumber}
                            onChange={(isChecked) => onInputChange({ allowSensorSerialNumber: isChecked })}
                            label={t(AssetTranslation.ASSET_LINKED_TO_A_SENSOR)}
                        />
                        {allowSensorSerialNumber && (
                            <div className="assetForm__sensorInfo">
                                <div>
                                    <Input
                                        value={sensorSerialNumber}
                                        onChange={(value) => {
                                            onInputChange({ sensorSerialNumber: value });
                                            setShowNoDataPlaceholder(false);
                                        }}
                                        placeholder={t(AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_LABEL)}
                                        disabled={isDisabled}
                                        errorMessage={errorSerialNumber}
                                        max={8}
                                    />
                                </div>
                                <div className="assetForm__sensorInfo__badges">
                                    {battery && signalLevel ? (
                                        <>
                                            <Badge
                                                size="lg"
                                                title={battery ? batteryBadgeTitle(battery) : "-"}
                                                variant={battery ? batteryBadgeColor(battery) : "grey"}
                                                icon={battery ? batteryBadgeIcon(battery) : undefined}
                                                iconPosition="right"
                                            />
                                            <Badge
                                                size="lg"
                                                title={signalLevel ? signalBadgeTitle(signalLevel) : "-"}
                                                variant={signalLevel ? signalBadgeColor(signalLevel) : "grey"}
                                                icon={signalLevel ? signalBadgeIcon(signalLevel) : undefined}
                                                iconPosition="right"
                                            />
                                            <Button
                                                size="xs"
                                                text={t(AssetTranslation.ASSET_REDIRECT_AUTOMATIC_RECORD_LABEL)}
                                                iconRight={faArrowRight}
                                                onClick={() =>
                                                    nav(
                                                        `/${PrivatePaths.AUTOMATIC_RECORDS}?sensorSerialNumbers=${sensorSerialNumber}`
                                                    )
                                                }
                                                buttonType="button"
                                                variant="purple"
                                                type="secondary"
                                            />
                                        </>
                                    ) : (
                                        sensorSerialNumber.length == 8 &&
                                        showNoDataPlaceholder && (
                                            <p className="assetForm__sensorInfo__badges__noData">
                                                {t(AssetTranslation.ASSET_SENSOR_NO_DATA)}
                                            </p>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </WhiteBoxCollapsable>
            )}
        </>
    );
};
