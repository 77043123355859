import { CurrentPage } from "../TaskGrid";
import { fillTaskCountersExtraparams } from "../utilities/fillTaskCountersExtraparams";
import { fillTaskExtraParams } from "../utilities";
import { getTabIndexFromTabValue } from "../utilities/getTabIndexFromTabValue";
import { getTaskTab } from "../utilities/getTaskTab";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { IOnReasign } from "app/components_v2/__modals/ReasignModal/types";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { selectAssigned } from "app/helpers";
import { SelectAssignedModel } from "app/models/02-TAR/TaskPage";
import { SelectedColumnViewModel } from "../../models/taskPageModels";
import { TabPropsReduced } from "app/components_v2/Tabs";
import { TaskCountersDto } from "app/dtos/02-TAR/Task/TaskCountersDto";
import { TaskGridContext } from "../../state/context/taskGridContext";
import { TaskGridSecondaryFilterModel } from "../../models/taskGridSecondaryFilterModel";
import { TaskListModel } from "app/models/02-TAR/Task/TaskListModel";
import { TaskSchedulerModel } from "app/models/02-TAR/Task/TaskSchedulerModel";
import { TaskService } from "app/services";
import {
    TaskTranslations,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { usePlanAPPCC } from "app/hooks/usePlanAPPCC";
import { useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { useWizardTaskVisibility } from "app/hooks/useWizardTaskVisibility";
import { WorkDay } from "app/components_v2/__inputs";
import {
    INITIAL_TASK_GRID_COUNTERS,
    INITIAL_TASK_GRID_SF_VALUES,
    INITIAL_TASK_GRID_SORT_DIRECTION,
    INITIAL_TASK_GRID_SORT_FIELD,
} from "../../constants/taskGridConstants";
import TaskAPPCCService from "app/services/05-QUA/TaskAPPCCService";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";

type onChangeTabProps = {
    tabIndex: number;
    value: SelectedColumnViewModel;
};

type FetchParams = {
    pi?: number;
    filterData?: TaskGridSecondaryFilterModel;
    assignedToView?: SelectedColumnViewModel;
    companyId?: string;
};

export const useTaskGrid = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const nav = useNavigate();
    const location = useLocation();
    const [urlParams, setUrlParams] = useSearchParams();
    const { getPlans, planOptions } = usePlanAPPCC();
    const ref = useRef<SelectAssignedModel>("all");

    const {
        currentTab,
        initialRender,
        onChangeCurrentTab,
        onChangePageIndex,
        onCompanyChange,
        onInitialRenderChange,
        onSFChange,
        onSelectFilterButton,
        onSortChange,
        pageIndex,
        secondaryFilterValues,
        selectCompany,
        filterIcon,
        selectedColumnView,
        sortDirection,
        sortField,
        onQueryChange,
        query,
    } = useContext(TaskGridContext);

    const { closeWizardTask, isWizardTaskOpen } = useWizardTaskVisibility();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();

    const [data, setData] = useState<TaskListModel[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [counters, setCounters] = useState<TaskCountersDto>(INITIAL_TASK_GRID_COUNTERS);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(!!query.length);
    const [checkedRows, setCheckedRows] = useState<TaskListModel[]>([]);
    const [isReasignModalOpen, setIsReasignModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isReasignLoading, setIsReasignLoading] = useState<boolean>(false);
    const [showSecondaryFilter, setShowSecondaryFilter] = useState(false);
    const [clearAll, setClearAll] = useState<boolean>(false);

    const currentPage: CurrentPage = location.pathname === `/${PrivatePaths.REGISTRY_MANAGEMENT}` ? "plan" : "task";
    const actualService = currentPage === "plan" ? TaskAPPCCService : TaskService;
    const taskGridSecScreen = currentPage === "plan" ? SecScreen.REGISTRY_MANAGEMENT : SecScreen.TASK;

    const initialFilterData = {
        ...INITIAL_TASK_GRID_SF_VALUES,
        responsable: {
            assignedTo: secondaryFilterValues.responsable.assignedTo,
            text: "",
            value: "-1",
        },
    };
    const title: Record<CurrentPage, string> = {
        plan: t(TranslationKeys.TASK_PAGE_REGISTRY_TITLE),
        task: t(TranslationTitles.TASK_PAGE_TITLE),
    };

    const subtitle: Record<CurrentPage, string> = {
        plan: t(TranslationKeys.TASK_PAGE_REGISTRY_SUBTITLE),
        task: t(TranslationKeys.TASKS),
    };

    const subtitleSingular: Record<CurrentPage, string> = {
        plan: t(TranslationKeys.TASK_PAGE_REGISTRY_SUBTITLE_SINGULAR),
        task: t(TranslationKeys.TASK_PAGE_TASK_SUBTITLE),
    };

    useTitle(t(currentPage === "plan" ? TranslationTitles.TASK_APPCC_PAGE_TITLE : TranslationTitles.TASK_PAGE_TITLE));

    const getSubtitle = () => {
        if (total === 1) return subtitleSingular[currentPage];
        return subtitle[currentPage];
    };

    const tableTitle = currentPage === "plan" ? "" : `${title["task"]} (${selectCompany.company.label})`;
    const tableSubtitle = `${total} ${getSubtitle()}`;

    const onChangeTab = ({ tabIndex, value }: onChangeTabProps) => {
        if (tabIndex === currentTab) return;
        onChangeTabInitialRender({ tabIndex, value });
        setIsLoading(true);
        fetch({ pi: 0, assignedToView: getTaskTab(tabIndex) });
    };

    const onChangeTabInitialRender = ({ tabIndex, value }: onChangeTabProps) => {
        onChangeCurrentTab(tabIndex);
        onSelectFilterButton(value);
        onChangePageIndex(1);
        onSortChange(INITIAL_TASK_GRID_SORT_FIELD, INITIAL_TASK_GRID_SORT_DIRECTION);
        setClearAll((prev) => !prev);
    };

    const tabs: TabPropsReduced[] = [
        {
            text: t(TaskTranslations.TASK_ALL),
            onClick: () => {
                onChangeTab({
                    tabIndex: 0,
                    value: getTaskTab(0),
                });
            },
            type: "dark",
            rightCount: counters.all,
        },
        {
            text: t(TranslationKeys.WORKING_POSITION),
            onClick: () => {
                onChangeTab({
                    tabIndex: 1,
                    value: getTaskTab(1),
                });
            },
            type: "dark",
            rightCount: counters.workingPositionCounter,
        },
        {
            text: t(TranslationKeys.DEPARTMENTS),
            onClick: () => {
                onChangeTab({
                    tabIndex: 2,
                    value: getTaskTab(2),
                });
            },
            type: "dark",
            rightCount: counters.teamsCounter,
        },
        {
            text: t(TranslationKeys.SUBDEPARTMENTS),
            onClick: () => {
                onChangeTab({
                    tabIndex: 3,
                    value: getTaskTab(3),
                });
            },
            type: "dark",
            rightCount: counters.subteamsCounter,
        },
        {
            text: t(TranslationKeys.USERS),
            onClick: () => {
                onChangeTab({
                    tabIndex: 4,
                    value: getTaskTab(4),
                });
            },
            type: "dark",
            rightCount: counters.userCounter,
        },
    ];

    const fetch = async ({ assignedToView, companyId, filterData, pi }: FetchParams = {}) => {
        setIsLoading(true);
        const assignedTo =
            assignedToView !== undefined ? selectAssigned(assignedToView) : selectAssigned(selectedColumnView);

        const filterDataValues = filterData ? filterData : secondaryFilterValues;
        const planId = currentPage === "task" ? null : urlParams.get("planId") || "-1";
        const selectedCompanyFetch = companyId ?? selectCompany.company.value;
        const extraParams = fillTaskExtraParams(
            filterDataValues,
            planId,
            assignedTo,
            initialRender,
            selectedCompanyFetch
        );

        const taskCountersExtraparams = fillTaskCountersExtraparams({
            assignedTo,
            filterdata: filterDataValues,
            planId,
            companyId: selectedCompanyFetch,
        });

        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;

        const taskPromise = actualService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexFetch,
            extraParams,
            query,
            sortDirection: sortDirection === "default" ? INITIAL_TASK_GRID_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_TASK_GRID_SORT_FIELD : sortField,
        });

        const taskCountersPromise = actualService.GetCounters({
            extraParams: taskCountersExtraparams,
            query,
        });

        Promise.all([taskPromise, taskCountersPromise]).then(([taskSr, taskCountersSr]) => {
            if (!taskSr.status()) {
                handleToast({ title: taskSr.error, variant: "danger" });
                setIsLoading(false);
                setData([]);
                setTotal(0);
                setCounters(INITIAL_TASK_GRID_COUNTERS);
                return;
            }

            const exceedsPageLimit = taskSr.data.count / PaginationDefaults.PAGE_SIZE <= pageIndexFetch;
            if (exceedsPageLimit) onChangePageIndex(1);

            setData(taskSr.data.list);
            setTotal(taskSr.data.count);
            setCounters(taskCountersSr.status() ? taskCountersSr.data : INITIAL_TASK_GRID_COUNTERS);
            setIsLoading(false);
            if (initialRender && taskSr.data.tab) {
                const tabIndex = getTabIndexFromTabValue(taskSr.data.tab);
                onChangePageIndex(tabIndex);
                onChangeTabInitialRender({
                    tabIndex,
                    value: getTaskTab(tabIndex),
                });
            }
        });

        onInitialRenderChange(false);
    };

    const dayList = (taskScheduler: TaskSchedulerModel): WorkDay[] => {
        const daysOfWeek: Record<string, string> = {
            monday: TranslationKeys.DAY_WEEK_MONDAY_FIRST_LETTER,
            tuesday: TranslationKeys.DAY_WEEK_TUESDAY_FIRST_LETTER,
            wednesday: TranslationKeys.DAY_WEEK_WEDNESDAY_FIRST_LETTER,
            thursday: TranslationKeys.DAY_WEEK_THURSDAY_FIRST_LETTER,
            friday: TranslationKeys.DAY_WEEK_FRIDAY_FIRST_LETTER,
            saturday: TranslationKeys.DAY_WEEK_SATURDAY_FIRST_LETTER,
            sunday: TranslationKeys.DAY_WEEK_SUNDAY_FIRST_LETTER,
        };

        const workingdays: WorkDay[] = Object.keys(daysOfWeek).map((day, index) => ({
            id: index,
            letter: t(daysOfWeek[day]),
            isActive: taskScheduler[day as keyof TaskSchedulerModel] as boolean,
        }));

        return workingdays;
    };

    const handleNavigate = (path: string) => {
        const planId = urlParams.get("planId");
        if (currentPage === "plan" && !!planId) {
            nav(`${path}?planId=${planId}`);
            return;
        }
        nav(`${path}`);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const extraparams = fillTaskExtraParams(
            secondaryFilterValues,
            urlParams.get("planId"),
            selectAssigned(selectedColumnView),
            false,
            selectCompany.company.value
        );

        return actualService.GetDataExport({
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_TASK_GRID_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_TASK_GRID_SORT_FIELD : sortField,
            ...params,
        });
    };

    const handleModalClose = () => {
        setIsReasignModalOpen(false);
        setIsDeleteModalOpen(false);
    };
    const handleModalOpen = () => {
        if (!checkedRows.length) return;
        setIsReasignModalOpen(true);
    };
    const handleDeleteModalOpen = () => {
        if (!checkedRows.length) return;
        setIsDeleteModalOpen(true);
    };

    const handleReasign = async (reasignValue: IOnReasign) => {
        setIsReasignLoading(true);
        const reasignTaskIds = checkedRows.map((x) => Number(x.id));
        const { status } = await actualService.ReasignTask({
            ...reasignValue,
            taskId: reasignTaskIds,
            startDate: new Date(),
        });
        if (!status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setIsReasignLoading(false);
            return;
        }
        setIsReasignLoading(false);
        setIsReasignModalOpen(false);
        resetChecboxes();
        fetch({ pi: 0 });
        onChangePageIndex(1);
    };

    const resetChecboxes = () => {
        setCheckedRows([]);
        setClearAll((prev) => !prev);
    };

    const handleCleanFetch = () => {
        onQueryChange("");
        setShowSearchInput(false);
        fetch({ pi: 0 });
        onChangePageIndex(1);
    };

    const onFinishWizard = () => {
        handleCleanFetch();
        closeWizardTask();
    };

    const onSave = (filterData: TaskGridSecondaryFilterModel) => {
        onSFChange(filterData);
        fetch({ pi: 0, filterData });
        onChangePageIndex(1);
        setShowSecondaryFilter(false);
        resetChecboxes();
    };

    const handleDeleteTasks = async () => {
        setIsDeleting(true);
        const ids = checkedRows.map((task) => task.id);

        const sr = await actualService.MultipleDelete(ids);
        if (!sr.status()) {
            handleToast({
                title: sr.getParsedError(),
                variant: "danger",
                type: "alert",
            });
            return;
        } else {
            handleToast({
                title: t(TranslationModals.SUCCESS_DELETE),
                variant: "success",
                type: "alert",
            });
            setCheckedRows([]);
            fetch({ pi: 0 });
            onChangePageIndex(1);
            setClearAll((prev) => !prev);
        }
        setIsDeleting(false);
        setIsDeleteModalOpen(false);
    };

    const handlePlanChange = (value: string) => {
        urlParams.set("planId", value);
        setUrlParams(urlParams, { replace: true });
        handleCleanFetch();
    };

    const handleSearch = () => {
        if (!!query.length) {
            fetch({ pi: 0 });
            onChangePageIndex(1);
            return;
        }
        handleCleanFetch();
    };

    const handleChangeCompany = ({ label, value }: OptionsSearch) => {
        onCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        onChangePageIndex(1);
        fetch({ pi: 0, companyId: value });
        resetChecboxes();
        if (value != "-1") changeWorkingCompanyByCompanyId(Number(value));
    };

    useEffect(() => {
        fetch();
    }, [sortField, sortDirection]);

    useEffect(() => {
        if (currentPage === "plan") getPlans();
    }, []);

    return {
        checkedRows,
        clearAll,
        currentPage,
        currentTab,
        data,
        dayList,
        fetch,
        filterIcon,
        handleDeleteModalOpen,
        handleDeleteTasks,
        handleExport,
        handleModalClose,
        handleModalOpen,
        handleNavigate,
        handlePlanChange,
        handleReasign,
        handleSearch,
        initialFilterData,
        isWizardTaskOpen,
        closeWizardTask,
        isDeleteModalOpen,
        isDeleting,
        isLoading,
        isReasignLoading,
        isReasignModalOpen,
        onChangePageIndex,
        onCompanyChange,
        onFinishWizard,
        onQueryChange,
        onSave,
        onSortChange,
        pageIndex,
        planOptions,
        query,
        ref,
        secondaryFilterValues,
        onSFChange,
        selectCompany,
        selectedPlan: urlParams.get("planId") ?? "-1",
        setCheckedRows,
        setIsDeleteModalOpen,
        setShowSearchInput,
        setShowSecondaryFilter,
        showSearchInput,
        showSecondaryFilter,
        sortDirection,
        sortField,
        tableSubtitle,
        tableTitle,
        tabs,
        taskGridSecScreen,
        title,
        total,
        handleChangeCompany,
    };
};
