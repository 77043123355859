import { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { StarValoration } from "../StarValoration/StarValoration";
import { StarColorModel, StarSizeModel } from "app/shared/types/StarTypes";

type StarsReviewsProps = {
    stars: number;
    onRatechange?: (stars: number) => void;
    starColor?: StarColorModel;
    starSize?: StarSizeModel;
    editStarRating?: boolean;
};

export const StarsReviews: FC<StarsReviewsProps> = ({
    stars = 0,
    onRatechange,
    starColor = "yellow",
    starSize = "xs",
    editStarRating = true,
}) => {
    const [rating, setRating] = useState(stars);

    const yellowStars = Array.from({ length: stars }, (_, index) => (
        <FontAwesomeIcon
            className={`starsReviews__icon--${starColor === "primary" ? "primary" : "yellow"}`}
            key={index}
            icon={faStarSharp}
        />
    ));
    const grayStars = Array.from({ length: 5 - stars }, (_, index) => (
        <FontAwesomeIcon className="starsReviews__icon--disabled" key={index} icon={faStarSharp} />
    ));
    const handleStarChange = (stars: number) => {
        setRating(stars);
        onRatechange && onRatechange(stars);
    };

    useEffect(() => {
        setRating(stars);
    }, [stars]);

    return (
        <>
            <div className="starsReviews__stars">
                {starSize === "xl" && editStarRating ? (
                    <StarValoration
                        editable={true}
                        rating={rating}
                        setRating={handleStarChange}
                        starColor={starColor}
                    />
                ) : (
                    <>
                        {yellowStars}
                        {grayStars}
                        <p className="starsReviews__stars__text">{stars}</p>
                    </>
                )}
            </div>
        </>
    );
};
