import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { AutomaticRecordTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { sensorEntryLevel } from "app/shared/Constants";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { AutomaticRecordGridSF } from "app/pages/04-SEN/AutomaticRecordGrid/models/AutomaticRecordGridModels";
import { DateRangePicker, OptionsOutsideSelect, SelectOptions } from "app/components_v2/__inputs";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import SenSelectorService from "app/services/04-SEN/SenSelectorService";

export type AutomaticRecordFilterProps = {
    filterData: AutomaticRecordGridSF;
    initialFilterData: AutomaticRecordGridSF;
    onCancel?: () => void;
    onSave?: (values: AutomaticRecordGridSF) => void;
    isOpen: boolean;
};

export const AutomaticRecordFilter: FC<AutomaticRecordFilterProps> = ({
    filterData,
    initialFilterData,
    isOpen,
    onCancel,
    onSave,
}) => {
    const { t } = useTranslation();

    const [internalFilterData, setInternalFilterData] = useState<AutomaticRecordGridSF>(filterData);
    const [gatewayOptions, setGatewayOptions] = useState<OptionsSearch[]>([]);
    const [sensorOptions, setSensorOptions] = useState<OptionsSearch[]>([]);
    const bateryOptions: OptionsSearch[] = [
        { label: t(TranslationCommon.SELECT_OPTION), value: "" },
        { label: t(AutomaticRecordTranslations.BATTERY_LEVEL_LOW_LABEL), value: sensorEntryLevel.LOW },
        { label: t(AutomaticRecordTranslations.BATTERY_LEVEL_MEDIUM_LABEL), value: sensorEntryLevel.MEDIUM },
        { label: t(AutomaticRecordTranslations.BATTERY_LEVEL_HIGH_LABEL), value: sensorEntryLevel.HIGH },
    ];
    const signalOptions: OptionsSearch[] = [
        { label: t(TranslationCommon.SELECT_OPTION), value: "" },
        { label: t(AutomaticRecordTranslations.SIGNAL_LEVEL_LOW_LABEL), value: sensorEntryLevel.LOW },
        { label: t(AutomaticRecordTranslations.SIGNAL_LEVEL_MEDIUM_LABEL), value: sensorEntryLevel.MEDIUM },
        { label: t(AutomaticRecordTranslations.SIGNAL_LEVEL_HIGH_LABEL), value: sensorEntryLevel.HIGH },
    ];

    const handleInputChange = (values: Partial<AutomaticRecordGridSF>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleClickOutside = () => {
        if (!isOpen) return;
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    const handleReset = () => handleInputChange(initialFilterData);

    const handleSave = () => onSave && onSave(internalFilterData);

    const handleDeleteGateWay = (gateWayId: string) => {
        handleInputChange({
            gatewaySerialNumbers: internalFilterData.gatewaySerialNumbers?.filter(({ value }) => value !== gateWayId),
        });
    };
    const handleDeleteSerialNumber = (sensorSerialNumberId: string) => {
        handleInputChange({
            sensorSerialNumbers: internalFilterData.sensorSerialNumbers?.filter(
                ({ value }) => value !== sensorSerialNumberId
            ),
        });
    };

    const getGatewayOptions = async () => {
        const { status, data } = await SenSelectorService.GetGateways();

        if (!status()) {
            setGatewayOptions([]);
            return;
        }

        setGatewayOptions(data);
    };

    const getSensorOptions = async () => {
        const { status, data } = await SenSelectorService.GetSensors();

        if (!status()) {
            setSensorOptions([]);
            return;
        }

        setSensorOptions(data);
    };

    useEffect(() => {
        getGatewayOptions();
        getSensorOptions();
    }, []);

    return (
        <SecondaryFilter
            isOpen={isOpen}
            onClickOutside={handleClickOutside}
            onCancel={handleClickOutside}
            onReset={handleReset}
            onSave={handleSave}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
        >
            <DateRangePicker
                startDate={internalFilterData.dateTimeEntry1}
                endDate={internalFilterData.dateTimeEntry2}
                onChange={([dateTimeEntry1, dateTimeEntry2]) => handleInputChange({ dateTimeEntry1, dateTimeEntry2 })}
                label={"Fechas"}
                placeholder={t(TranslationKeys.ALL_TIME_DATES)}
                showMoreOptions
            />
            <OptionsOutsideSelect
                onChange={(gatewaySerialNumbers) => handleInputChange({ gatewaySerialNumbers })}
                onDeleteItem={handleDeleteGateWay}
                options={gatewayOptions}
                label={t(AutomaticRecordTranslations.GATEWAY_SERIAL_NUMBER_LABEL)}
                values={internalFilterData.gatewaySerialNumbers || []}
            />
            <OptionsOutsideSelect
                onChange={(sensorSerialNumbers) => handleInputChange({ sensorSerialNumbers })}
                onDeleteItem={handleDeleteSerialNumber}
                options={sensorOptions}
                label={t(AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_LABEL)}
                values={internalFilterData.sensorSerialNumbers || []}
            />
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => handleInputChange({ batteryLevel: value })}
                options={bateryOptions}
                label={t(AutomaticRecordTranslations.BATTERY_LEVEL_LABEL)}
                selectedValue={internalFilterData.batteryLevel}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
            />
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => handleInputChange({ signalLevel: value })}
                options={signalOptions}
                label={t(AutomaticRecordTranslations.SIGNAL_LEVEL_LABEL)}
                selectedValue={internalFilterData.signalLevel}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
            />
        </SecondaryFilter>
    );
};
