import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuditReport } from "../EditAudit/tabs/AuditReport/hooks/useAuditReport";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { PrivatePaths } from "app/shared/Constants";
import { getDayMonthFormatedWithYearNumbers } from "app/helpers";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { AuditReport } from "../EditAudit/tabs/AuditReport/AuditReport";

export const PrintAuditReport = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();

    const auditInstanceId = pathname.split("/")[2];
    const actualStatus: AuditInstanceStatus = pathname.split("/")[3].toUpperCase() as AuditInstanceStatus;
    const auditId = searchParams.get("auditId");
    const companyId = searchParams.get("companyId");

    if (!auditInstanceId || !actualStatus || !auditId || !companyId) nav(`/${PrivatePaths.AUDIT_INST_MANAG}`);

    const goBackURLs: Record<AuditInstanceStatus, string> = {
        IN_PROGRESS: "",
        COMPLETED: `/${PrivatePaths.AUDIT_INST_MANAG}/${auditInstanceId}/completed`,
        REVIEWED: `/${PrivatePaths.AUDIT_INST_MANAG}/${auditInstanceId}/reviewed`,
        CLOSED: `/${PrivatePaths.AUDIT_INST_MANAG}/${auditInstanceId}/closed`,
    };

    const { auditHistory, renderAuditStars, isLoading, auditInstance, fetchAuditReportData } = useAuditReport({
        auditInstanceId: Number(auditInstanceId),
        actualStatus,
        auditId: Number(auditId),
        companyId: Number(companyId),
    });

    const print = () => {
        window.print();
        nav(goBackURLs[actualStatus]);
    };

    useEffect(() => {
        fetchAuditReportData();
    }, []);

    useEffect(() => {
        auditInstance && auditHistory && setTimeout(() => print(), 1000);
    }, [auditInstance, auditHistory]);

    return (
        <div className="printAuditReport">
            <div className="printAuditReport__header">
                <div className="printAuditReport__header__title">
                    <h2>{isLoading ? "" : `${t(TranslationKeys.AUDIT)} "${auditInstance?.auditName}"`}</h2>
                    <h2>
                        {isLoading
                            ? ""
                            : `#${auditId} - ${getDayMonthFormatedWithYearNumbers(
                                  auditInstance?.closeDate
                                      ? auditInstance?.closeDate.toString()
                                      : auditInstance?.openDate.toString() || "",
                                  "/"
                              )}
                    `}
                    </h2>
                </div>
                {renderAuditStars(auditInstance?.grade ?? null)}
            </div>
            <AuditReport
                auditInstance={auditInstance}
                auditHistory={auditHistory}
                isLoading={isLoading}
                auditStarsNode={renderAuditStars}
            />
        </div>
    );
};
