import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { BaseAssociateUsersModel } from "app/models/01-SEG/BaseAssociateUsersModel";
import { SubdepartmentModel } from "app/models/01-SEG/Subdepartment/SubdepartmentModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { BasicData } from "app/dtos/01-SEG/BasicData";
import { AssociateUsersModel } from "app/dtos/01-SEG/DepartmentDto";

export default class SubdepartmentService {
    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<SubdepartmentModel>>({
            url: `${ApiPaths.SEG_API}/subdepartments`,
            paginationParams: paginationParams,
        });
    }

    public static async GetBasicData(paginationParams?: PaginationParams) {
        return FetchService.get<BasicData[]>({
            url: `${ApiPaths.SEG_API}/subdepartments/basic-info`,
            paginationParams: paginationParams,
        });
    }

    public static async GetOne(subdepartmentId: number, instanceId: number) {
        return FetchService.get<SubdepartmentModel>({
            url: `${ApiPaths.SEG_API}/subdepartments/${subdepartmentId}?customerInstanceId=${instanceId}`,
        });
    }

    public static async Save(values: SubdepartmentModel) {
        const body = {
            ...values,
            isActive: true,
        };
        return FetchService.post<SubdepartmentModel>({
            url: `${ApiPaths.SEG_API}/subdepartments`,
            body: body,
        });
    }

    public static async Edit(values: SubdepartmentModel, id: number) {
        const body = {
            ...values,
            isActive: true,
        };
        return FetchService.put<SubdepartmentModel>({
            url: `${ApiPaths.SEG_API}/subdepartments/${id}`,
            body: body,
        });
    }

    public static async Delete(subdepartmentId: number) {
        return FetchService.delete<SubdepartmentModel>({
            url: `${ApiPaths.SEG_API}/subdepartments/${subdepartmentId}`,
        });
    }

    public static async AddUsers(id: number, body: AssociateUsersModel) {
        return FetchService.post({
            url: `${ApiPaths.SEG_API}/subdepartments/${id}/users`,
            body: body,
        });
    }
    public static async PutUsersInSubdepartment(id: number, body: BaseAssociateUsersModel) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/subdepartments/${id}/users`,
            body: body,
        });
    }

    public static async DeleteUsers(id: number, body: AssociateUsersModel) {
        return FetchService.delete({
            url: `${ApiPaths.SEG_API}/subdepartments/${id}/users`,
            body: body,
        });
    }
}
