import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSession, useTitle } from "app/hooks";
import { useWindowSize } from "usehooks-ts";
import { useEmailDistributionList } from "./hooks/useEmailDistributionList";
import { faCirclePlus, faPen, faTemperatureList, faTrash } from "@fortawesome/pro-regular-svg-icons";
import {
    EmailDistributionListTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { PaginationDefaults, screenSize, SecScreen } from "app/shared/Constants";
import {
    INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS,
    INITIAL_EMAIL_DISTRIBUTION_LIST_FORM_VALUES,
} from "./constants/EmailDistributionListConstants";
import { ColumnsType } from "app/components_v2/Table/types";
import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { EmailDistributionListModel } from "app/models/01-SEG/EmailDistributionList/EmailDistributionListModel";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { EmailDistributionListUsersModal } from "./components/EmailDistributionListUsersModal";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { MobileEmailDistributionListRow } from "./components/MobileEmailDistributionListRow/MobileEmailDistributionListRow";
import { Badge } from "app/components_v2/Badge/Badge";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";

export const EmailDistributionListGrid: FC = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const session = useSession();

    useTitle(t(TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE));

    const {
        currentEmailDistributionList,
        emailDistributionList,
        emailDistributionListErrors,
        filterIcon,
        getEmailDistributionList,
        handleSubmit,
        isLoading,
        onChange,
        onChangePageIndex,
        onChangeSortDirectionField,
        onClickEdit,
        onClickDelete,
        onCompanyChange,
        onDelete,
        pageIndex,
        selectCompany,
        setCurrentEmailDistributionList,
        setEmailDistributionListErrors,
        setShowCreateModal,
        setShowDeleteModal,
        setShowEditModal,
        setShowSearchInput,
        showCreateModal,
        showDeleteModal,
        showEditModal,
        showSearchInput,
        sortDirection,
        sortField,
        total,
    } = useEmailDistributionList();

    const columns: ColumnsType<EmailDistributionListModel>[] = [
        {
            dataIndex: "Functionality",
            label: t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_FUNCTIONALITY_LABEL),
            sortedType: "default",
            render: ({ functionality }) => <CellTitle title={t(functionality)} />,
        },
        {
            dataIndex: "FK_Company",
            alignCenter: true,
            label: t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_COMPANY_LABEL),
            sortedType: "default",
            render: ({ companyName }) => (
                <div className="emailDistributionList__center">
                    <CellTitle title={companyName} />
                </div>
            ),
        },
        {
            dataIndex: "UserList",
            alignCenter: true,
            label: t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_USER_COUNT_LABEL),
            render: ({ userList }) => (
                <div className="emailDistributionList__center">
                    <Badge title={String(userList.length)} variant="blue" />
                </div>
            ),
        },
        {
            dataIndex: "actions",
            className: "emailDistributionList__actions",
            alignCenter: true,
            label: t(TranslationKeys.ACTIONS),
            render: ({ fK_Company, fK_DistributionList, userList }) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => onClickEdit(fK_Company, fK_DistributionList, userList),
                            hidden: !hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST),
                        },
                        {
                            icon: faTrash,
                            onClick: () => onClickDelete(fK_Company, fK_DistributionList, userList),
                            hidden: !hasPermissionToDelete(SecScreen.DISTRIBUTION_LIST),
                        },
                    ]}
                />
            ),
            hidden:
                !hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST) &&
                !hasPermissionToDelete(SecScreen.DISTRIBUTION_LIST),
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faCirclePlus,
            onClick: () => {
                setCurrentEmailDistributionList(INITIAL_EMAIL_DISTRIBUTION_LIST_FORM_VALUES);
                setShowCreateModal(true);
            },
            hidden: !hasPermissionToAdd(SecScreen.DISTRIBUTION_LIST),
        },
        {
            icon: filterIcon,
            onClick: () => {
                onCompanyChange({ isOpen: true });
                setShowSearchInput(false);
            },
            inputComponent: {
                component: (
                    <TableSelectCompany
                        onChange={({ label, value }) => {
                            onCompanyChange({
                                company: { label, value },
                                isOpen: false,
                            });
                            onChangePageIndex(1);
                            getEmailDistributionList({ pi: 0, companyId: value });
                        }}
                        value={selectCompany.company}
                        onClickOutside={() => onCompanyChange({ isOpen: false })}
                    />
                ),
                show: selectCompany.isOpen,
            },
        },
    ];

    useEffect(() => {
        getEmailDistributionList({ companyId: String(session?.workingCompany?.companyId) });
    }, []);

    return (
        <>
            {showCreateModal && (
                <EmailDistributionListUsersModal
                    title={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_CREATE_USERS_MODAL_TITLE)}
                    type="create"
                    emailDistributionList={currentEmailDistributionList}
                    emailDistributionListErrors={emailDistributionListErrors}
                    onClose={() => {
                        setEmailDistributionListErrors(INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS);
                        setShowCreateModal(false);
                    }}
                    onSubmit={handleSubmit}
                    onChange={onChange}
                />
            )}
            {showEditModal && (
                <EmailDistributionListUsersModal
                    title={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_EDIT_USERS_MODAL_TITLE)}
                    type="edit"
                    emailDistributionList={currentEmailDistributionList}
                    emailDistributionListErrors={emailDistributionListErrors}
                    onClose={() => {
                        setEmailDistributionListErrors(INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS);
                        setShowEditModal(false);
                    }}
                    onSubmit={handleSubmit}
                    onChange={onChange}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    title={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_DELETE_TITLE_LABEL)}
                    description={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_DELETE_DESCRIPTION_LABEL)}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={onDelete}
                    onClose={() => setShowDeleteModal(false)}
                    type="delete"
                />
            )}
            <PageContainer paddingTop>
                <TableCollapsable
                    title={
                        showSearchInput && width < screenSize.TABLET
                            ? ""
                            : t(TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE)
                    }
                    cols={columns}
                    data={emailDistributionList}
                    total={total}
                    placeholder={<TableError icon={faTemperatureList} />}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    onDoubleClick={({ fK_Company, fK_DistributionList, userList }) =>
                        onClickEdit(fK_Company, fK_DistributionList, userList)
                    }
                    onChangePageIndex={onChangePageIndex}
                    onChangeSortDirectionField={onChangeSortDirectionField}
                    sortDirection={sortDirection}
                    sortField={sortField}
                    actionButtons={actionButtons}
                    mobileBody={(row) => <MobileEmailDistributionListRow data={row} onClickEdit={onClickEdit} />}
                />
            </PageContainer>
        </>
    );
};
