import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSession, useTaskInstance, useTitle } from "app/hooks";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { TaskDetail } from "./TaskDetail";
import { PrivatePaths, SecScreen, TaskStatus } from "app/shared/Constants";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import Spinner from "app/components_v2/Spinner/Spinner";
import { validateStatus } from "app/helpers/__validates/validateTaskpillStatus";

type TaskDetailModalProps = {
    taskInstanceId: string;
    onComplete?: () => void;
    onClose?: () => void;
    editTaskDetail?: boolean;
    isReviewPhotos?: boolean;
    onStarsChange?: () => void;
    hideFeedBack?: boolean;
    editStarRating?: boolean;
    showValoratedBy?: boolean;
    allowOpenIssue?: boolean;
};

export const TaskDetailModal: FC<TaskDetailModalProps> = ({
    taskInstanceId,
    onComplete,
    onClose,
    editTaskDetail = true,
    isReviewPhotos,
    onStarsChange,
    hideFeedBack,
    editStarRating,
    showValoratedBy,
    allowOpenIssue = true,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const session = useSession();
    const nav = useNavigate();
    const { taskInstance, setTaskInstance, status } = useTaskInstance(Number(taskInstanceId), onClose);

    useTitle(t(TranslationTitles.TASK_DETAIL_TITLE));

    const handleClose = () => onClose && onClose();

    const isTaskInstanceEditable = ({
        editedBy,
        asignedToUserId,
        asignedToDepartmentId,
        asignedToSubdepartmentId,
        companyId,
        statusCode,
    }: TaskInstanceModel) => {
        const myId = session?.user.id;
        const myCompanies = session?.user.companies?.map(({ companyId }) => companyId);
        const myDepartments = session?.user.companies?.flatMap(({ departments }) =>
            departments?.map(({ departmentId }) => departmentId)
        );
        const mySubdepartments = session?.user.companies
            ?.flatMap(({ departments }) =>
                departments?.flatMap(({ subdepartments }) =>
                    subdepartments?.flatMap(({ subdepartmentId }) => subdepartmentId)
                )
            )
            .filter((departmentId) => departmentId != null);

        const isTaskComplete = statusCode === TaskStatus.COMPLETED;
        const isEditedByOtherUser = editedBy != null && editedBy !== myId;
        const isAsignedByOtherUser = asignedToUserId != null && asignedToUserId !== myId;
        const isNotAsignedToMyCompanies = !myCompanies?.includes(companyId);
        const isNotAsignedToMyDepartments =
            asignedToDepartmentId != null && !myDepartments?.includes(asignedToDepartmentId);
        const isNotAsignedToMySubdepartments =
            asignedToSubdepartmentId != null && !mySubdepartments?.includes(asignedToSubdepartmentId);

        if (isTaskComplete) return false;
        if (isEditedByOtherUser || isAsignedByOtherUser) return false;
        if (isNotAsignedToMyCompanies || isNotAsignedToMyDepartments || isNotAsignedToMySubdepartments) return false;

        return true;
    };

    return (
        <>
            <GenericModal
                header={{
                    title: t(TranslationTitles.TASK_DETAIL_TITLE),
                    onClose: handleClose,
                    actionButton: hasPermissionToEdit(SecScreen.TASK)
                        ? {
                              icon: faPen,
                              onClick() {
                                  nav(`/${PrivatePaths.TASK_PAGE}/edit/${taskInstance?.taskId}`);
                              },
                          }
                        : undefined,
                }}
                size="xl"
                hideChildrenPadding
                allowCloseOnEscape={false}
            >
                {status === "loading" && (
                    <div className="sporadicTaskDetailModal__spinner">
                        <Spinner />
                    </div>
                )}
                {status === "complete" && (
                    <TaskDetail
                        taskInstance={taskInstance}
                        setTaskInstance={setTaskInstance}
                        onCancel={handleClose}
                        isFeedback={location.pathname.includes(PrivatePaths.FEEDBACK_TASKS)}
                        onComplete={onComplete}
                        editable={editTaskDetail && taskInstance && isTaskInstanceEditable(taskInstance)}
                        isReviewPhotos={
                            isReviewPhotos ||
                            (!hideFeedBack &&
                                taskInstance &&
                                validateStatus(taskInstance) !== "PENDING" &&
                                validateStatus(taskInstance) !== "NEAR_END")
                        }
                        onStarsChange={onStarsChange}
                        hideFeedBack={hideFeedBack}
                        editStarRating={editStarRating}
                        showValoratedBy={showValoratedBy}
                        allowOpenIssue={allowOpenIssue}
                    />
                )}
            </GenericModal>
        </>
    );
};

export default TaskDetailModal;
