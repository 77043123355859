import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { AssetTranslation, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { AssetGridSF } from "app/pages/05-QUA/Asset/types";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { SelectOptions } from "app/components_v2/__inputs";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";

export type AssetFilterProps = {
    filterData: AssetGridSF;
    initialFilterData: AssetGridSF;
    showSecondaryFilter: boolean;
    onCancel?: () => void;
    onSave?: (values: AssetGridSF) => void;
    onClickOutside?: () => void;
};

export const AssetFilter: FC<AssetFilterProps> = ({
    filterData,
    initialFilterData,
    showSecondaryFilter,
    onCancel,
    onSave,
    onClickOutside,
}) => {
    const { t } = useTranslation();

    const [internalFilterData, setInternalFilterData] = useState(filterData);

    const hasConsecutiveIssuesOptions: OptionsSearch[] = [
        { value: "-1", label: t(TranslationCommon.SELECT_OPTION) },
        { value: "true", label: t(TranslationCommon.YES) },
        { value: "false", label: t(TranslationCommon.NO) },
    ];

    const isNotworkingOptions: OptionsSearch[] = [
        { value: "-1", label: t(TranslationCommon.SELECT_OPTION) },
        { value: "true", label: t(TranslationCommon.YES) },
        { value: "false", label: t(TranslationCommon.NO) },
    ];

    const handleInputChange = (values: Partial<AssetGridSF>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    return (
        <SecondaryFilter
            isOpen={showSecondaryFilter}
            onClickOutside={() => {
                onClickOutside && onClickOutside();
                handleInputChange(filterData);
            }}
            onCancel={() => {
                onCancel && onCancel();
                handleInputChange(filterData);
            }}
            onReset={() => handleInputChange(initialFilterData)}
            onSave={() => onSave && onSave(internalFilterData)}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
            variant="purple"
        >
            <SelectOptions
                isMulti={false}
                onChange={({ value }) =>
                    handleInputChange({
                        hasConsecutiveIssues: value === "-1" ? undefined : value,
                    })
                }
                options={hasConsecutiveIssuesOptions}
                label={t(TranslationKeys.ISSUE_COUNT)}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                selectedValue={
                    internalFilterData.hasConsecutiveIssues === "-1"
                        ? undefined
                        : internalFilterData.hasConsecutiveIssues
                }
                isSearchable
            />
            <SelectOptions
                isMulti={false}
                onChange={({ value }) =>
                    handleInputChange({
                        isNotWorking: value === "-1" ? undefined : value,
                    })
                }
                options={isNotworkingOptions}
                label={t(AssetTranslation.ASSET_NOT_WORKING)}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                selectedValue={internalFilterData.isNotWorking === "-1" ? undefined : internalFilterData.isNotWorking}
                isSearchable
            />
        </SecondaryFilter>
    );
};
