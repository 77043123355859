import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "app/state/reducer";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

export const useUsers = (value: number | null) => {
    const [users, setUsers] = useState<OptionModel[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const { session } = useSelector<IAppState, IAppState>((state) => state);

    useEffect(() => {
        if (!value) return;
        setLoading(true);
        getUsers();
        setLoading(false);
    }, [value]);
    const getUsers = async () => {
        const usersSr = await SegSelectorService.GetUserNamesWithIds({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}&CompanyId=${value}`,
        });

        if (!usersSr.status()) {
            console.error("error", usersSr);
            setError(usersSr.error);
            return;
        }
        setUsers(usersSr.data);
    };
    return { users, error, loading };
};
