import { FC, ReactNode, useState } from "react";
import { CollapsableTitle } from "./CollapsableTitle/CollapsableTitle";
import { CollapsableBody } from "./CollapsableBody/CollapsableBody";
import { useEffectAfterFirstRender } from "app/hooks/useEffectAfterFirstRender";
import { CollapsableBackground, CollapsableBorder } from "./types";

export type CollapsableProps = {
    children: ReactNode;
    headerChildren?: ReactNode;
    title?: string;
    open?: boolean;
    onChange?: (value: boolean) => void;
    isCollapsable?: boolean;
    openAfterEvent?: any;
    background?: CollapsableBackground;
    border?: CollapsableBorder;
};

export const Collapsable: FC<CollapsableProps> = ({
    children,
    onChange,
    open = true,
    title,
    isCollapsable = true,
    openAfterEvent,
    background = "white",
    border,
}) => {
    const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(open);
    const handleChange = (value: boolean) => {
        setIsCollapseOpen(value);
        onChange && onChange(value);
    };

    useEffectAfterFirstRender(() => {
        openAfterEvent && setIsCollapseOpen(true);
    }, [openAfterEvent]);

    return (
        <div
            data-testid="collapsableTestid"
            className={`collapsable${!isCollapseOpen ? "--gap0" : ""} collapsable--${background}`}
        >
            {title && (
                <CollapsableTitle
                    isCollapsable={isCollapsable}
                    title={title}
                    onClick={handleChange}
                    open={isCollapseOpen}
                    border={border}
                />
            )}

            <CollapsableBody open={isCollapseOpen}>{children}</CollapsableBody>
        </div>
    );
};
