import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { GroupCompanyModel } from "app/models/01-SEG/GroupCompany/GroupCompanyModel";
import { GroupCompanyService } from "app/services";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import CompanyGrid from "./Company/CompanyGrid";

type Props = {
    model: GroupCompanyModel;
    afterDelete: () => void;
    showOpened: boolean;
};

const GroupCompanyCollapse: FC<Props> = ({ model, afterDelete, showOpened }) => {
    const { t } = useTranslation();
    const session = useSession();
    const { handleToast } = useToast();

    const [group, setGroup] = useState<GroupCompanyModel>(model);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const fetch = async () => {
        const instanceId = session?.user.customerInstanceId;
        const sr = await GroupCompanyService.GetOne(model.id, instanceId || 0);
        if (sr.status()) setGroup(sr.data);
        else
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
    };

    const onDelete = async (id: number) => {
        setIsDeleting(true);
        const delSr = await GroupCompanyService.Delete(id);

        if (delSr.status()) {
            handleToast({
                title: t(TranslationModals.SUCCESS_DELETE),
                type: "alert",
                variant: "success",
            });
            afterDelete();
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
        }
        setIsDeleting(false);
    };

    const confirmDelete = () => {
        group.isDeletable ? setShowConfirmModal(true) : setShowDeleteModal(true);
    };

    return (
        <div>
            {showDeleteModal && (
                <ConfirmModal
                    onConfirm={() => setShowDeleteModal(false)}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    title={t(TranslationCommon.ACTION_CANNOT_BE_COMPLETED)}
                    description={t(TranslationCommon.ENTITY_HAS_CHILDREN)
                        .replace("{0}", t(TranslationTitles.GROUP_COMPANY_TITLE))
                        .replace("{1}", t(TranslationKeys.COMPANIES))}
                />
            )}
            {showConfirmModal && (
                <ConfirmModal
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={() => onDelete(group.id)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={`${t(TranslationKeys.DELETE_MESSAGE)} ${group.name}`}
                    isLoading={isDeleting}
                />
            )}
            <CompanyGrid
                groupId={group.id}
                showOpened={showOpened}
                groupName={group.name}
                onEdit={fetch}
                onDelete={confirmDelete}
            />
        </div>
    );
};

export default GroupCompanyCollapse;
