import { Suspense, useEffect } from "react";
import { TableHeader } from "./TableHeader/TableHeader";
import { TableBody } from "./TableBody/TableBody";
import { TableFooter } from "./TableFooter/TableFooter";
import { ColumnsType, SortedTypeModel, TableProps, handleColumnsMapProps } from "./types";
import Spinner from "../Spinner/Spinner";
import { CheckBox } from "../CheckBox/CheckBox";
import { useTable } from "./hooks/useTable";
import { useWindowSize } from "usehooks-ts";
import { MobileTableBody } from "./Mobile/MobileTableBody/MobileTableBody";
import { MobileTableHeader } from "./Mobile/MobileTableHeader/MobileTableHeader";
import { PaginationDefaults, screenSize } from "app/shared/Constants";

const Table = <T extends object>({
    cols,
    data,
    isLoading,
    onClickExportCsv,
    pageIndex,
    pageSize = PaginationDefaults.PAGE_SIZE,
    total,
    onChangePageIndex,
    isOpen = true,
    placeholder,
    onChangeSortDirectionField,
    sortDirection,
    sortField,
    allowCheckbox,
    clearAll,
    instantDowload,
    onDoubleClick,
    onCheckboxChange,
    mobileBody,
    fitHeight,
    variant = "primary",
}: TableProps<T>) => {
    const { checkedPage, columns, handleCheck, handleHeaderCheck, headerCheckBox, indeterminateCheckbox, setColumns } =
        useTable<T>({ cols, data, clearAll });

    const { width } = useWindowSize();

    useEffect(() => {
        if (isLoading) return;
        setColumns(() =>
            cols.map((col) =>
                col.dataIndex === sortField
                    ? {
                          ...col,
                          sortedType: sortDirection,
                      }
                    : {
                          ...col,
                          sortedType: col.sortedType ? "default" : undefined,
                      }
            )
        );
    }, [cols, isLoading]);

    const handleHeaderClick = (dataIndex: string, sortedType?: SortedTypeModel) => {
        const selectedcolumn = columns.find((col) => col.dataIndex === dataIndex);
        if (!selectedcolumn) return;
        if (!selectedcolumn.sortedType) return;

        setColumns((prev) => handleColumnsMap({ prev, dataIndex }));
        onChangeSortDirectionField &&
            onChangeSortDirectionField(
                selectedcolumn.dataIndex,
                sortedType || getSortedType(selectedcolumn.sortedType)
            );
    };

    const handleColumnsMap = ({ prev, dataIndex, sortedType }: handleColumnsMapProps<T>): ColumnsType<T>[] => {
        return prev.map((col) => {
            return col.dataIndex === dataIndex
                ? {
                      ...col,
                      sortedType: sortedType || getSortedType(col.sortedType),
                  }
                : { ...col, sortedType: col.sortedType ? "default" : undefined };
        });
    };

    const getSortedType = (sortedType?: SortedTypeModel) => {
        return sortedType === "asc"
            ? "desc"
            : sortedType === "desc"
            ? "default"
            : sortedType === "default"
            ? "asc"
            : undefined;
    };

    const mobileOnChangeChecked = (checked: boolean, row: T) => {
        if (!checked) {
            const value = checkedPage.filter((check) => {
                //@ts-ignore
                return row?.id !== check?.id;
            });
            handleCheck && handleCheck(value);
            return;
        }
        handleCheck && handleCheck([...checkedPage, row]);
    };

    useEffect(() => {
        onCheckboxChange && onCheckboxChange(checkedPage);
    }, [checkedPage]);

    return (
        <div className={`customTable ${isOpen ? "isOpen" : "isClosed"}`}>
            <Suspense
                fallback={
                    isLoading && (
                        <div className="tableSpinner">
                            <Spinner variant={variant} />
                        </div>
                    )
                }
            >
                {isLoading && (
                    <div className="tableSpinner">
                        <Spinner variant={variant} />
                    </div>
                )}
                {width < screenSize.TABLET && mobileBody ? (
                    <>
                        <MobileTableHeader
                            headerCheckBox={headerCheckBox}
                            indeterminateCheckbox={indeterminateCheckbox}
                            handleHeaderCheck={handleHeaderCheck}
                            sortedType={sortDirection}
                            allowCheckbox={allowCheckbox}
                            columns={columns}
                            selectedColumn={sortField || ""}
                            onChangeSortDirectionField={handleHeaderClick}
                        />
                        <MobileTableBody
                            data={data}
                            checkedPage={checkedPage}
                            mobileOnChangeChecked={mobileOnChangeChecked}
                            mobileBody={mobileBody}
                            placeholder={placeholder}
                            pageSize={pageSize}
                            isLoading={isLoading}
                            fitHeight={fitHeight}
                        />
                    </>
                ) : (
                    <table className={`customTable__table ${fitHeight ? "customTable__table--fitHeight" : ""}`}>
                        <thead className="customTable__table__header">
                            <tr>
                                {allowCheckbox && (
                                    <th className={`tableHeader__checkbox`}>
                                        <CheckBox
                                            checked={headerCheckBox}
                                            intermediate={indeterminateCheckbox}
                                            onChange={handleHeaderCheck}
                                        />
                                    </th>
                                )}
                                {columns.map(({ ...col }, colIndex) => (
                                    <TableHeader
                                        key={colIndex}
                                        {...col}
                                        sortedType={
                                            col.dataIndex === sortField
                                                ? sortDirection
                                                : col.sortedType
                                                ? "default"
                                                : undefined
                                        }
                                        onClick={handleHeaderClick}
                                    />
                                ))}
                            </tr>
                        </thead>
                        <tbody className="customTable__table__body">
                            <TableBody
                                columns={columns}
                                data={data}
                                pageSize={pageSize}
                                placeholder={placeholder}
                                isLoading={isLoading}
                                allowCheckbox={allowCheckbox}
                                checkedPage={checkedPage}
                                onChangeChecked={handleCheck}
                                onDoubleClick={onDoubleClick}
                                variant={variant}
                            />
                        </tbody>
                    </table>
                )}
                {(onChangePageIndex || onClickExportCsv) && (
                    <TableFooter
                        onChangePageIndex={onChangePageIndex}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        total={total}
                        variant={variant}
                        onClickExportCsv={onClickExportCsv}
                        instantDowload={instantDowload}
                    />
                )}
            </Suspense>
        </div>
    );
};

export default Table;
