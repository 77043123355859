import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { UserScanQRInsertModel } from "app/models/02-TAR/UserScanQR/UserScanQRModel";

export default class UserScanQrService {
    // POST New WorkingPosition
    public static async Save(workingPositionCode: string, values: UserScanQRInsertModel) {
        return FetchService.post({
            url: `${ApiPaths.TAR_API}/UserScanQR/${workingPositionCode}`,
            body: values,
        });
    }
}
