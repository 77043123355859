import { getDateFormattedSelects } from "app/helpers/Date.utilities";
import { isUserTypeWorker } from "app/helpers/userTypeCheck";
import { UserType } from "app/shared/types/UserTypes";
import { FilterData } from "app/pages/02-TAR/01-TAR-Dashboard/constants/DashboardResumeContext";
export type DashboardFilterdata = FilterData & {
    pending?: boolean;
    timedOut?: boolean;
    outOfTime?: boolean;
    inTime?: boolean;
    nearEnd?: boolean;
    firstLoad?: boolean;
};
export const taskInstancePillExtraParams = (
    filterData: DashboardFilterdata,
    type: "myTask" | "shared" | "counters" | "sporadic",
    isSharedViewSelected: boolean,
    userType: UserType
): string => {
    const {
        company,
        completed,
        critical,
        deadlineDate1,
        deadlineDate,
        department,
        firstLoad,
        fiveStar,
        fourStar,
        hasData,
        hasPhoto,
        myTasksUserId,
        name,
        nearEnd,
        noCritical,
        notEvaluatedCheck,
        oneStar,
        pending,
        plan,
        subdepartment,
        threeStar,
        timedOut,
        twoStar,
        user,
        isAssignedToQR,
    } = filterData;

    let extraParams = new URLSearchParams();

    extraParams.append("userType", userType);

    if (name && name !== "-1") extraParams.append("taskName", `${name}`);
    if (deadlineDate && !isUserTypeWorker(userType))
        extraParams.append("deadlineDate", `${getDateFormattedSelects(deadlineDate)}`);
    else extraParams.append("deadlineDate", `${getDateFormattedSelects(new Date())}`);
    if (deadlineDate1 && !isUserTypeWorker(userType))
        extraParams.append("deadlineDate1", `${getDateFormattedSelects(deadlineDate1)}`);
    else if (deadlineDate && !isUserTypeWorker(userType))
        extraParams.append("deadlineDate1", `${getDateFormattedSelects(deadlineDate)}`);
    else extraParams.append("deadlineDate1", `${getDateFormattedSelects(new Date())}`);

    if (completed !== undefined) {
        extraParams.append("inTime", `${completed}`);
        extraParams.append("outOfTime", `${completed}`);
    }
    nearEnd !== undefined && extraParams.append("nearEnd", `${nearEnd}`);
    isAssignedToQR && extraParams.append("isAssignedToQR", `${true}`);

    if (type === "counters") {
        extraParams.append("asignedToUserId", `${user}`);
        if (!subdepartment || subdepartment === "-1") extraParams.append("asignedToDepartmentId", `${department}`);
        if (!(subdepartment === "-1" && department && department !== "-1"))
            extraParams.append("asignedToSubdepartmentId", `${subdepartment}`);
    }

    if (type === "myTask" && !firstLoad) {
        extraParams.append("asignedToUserId", `${user}`);
    }
    if (type === "shared" && (!firstLoad || isSharedViewSelected)) {
        if (!subdepartment || subdepartment === "-1") extraParams.append("asignedToDepartmentId", `${department}`);
        if (!(subdepartment === "-1" && department && department !== "-1"))
            extraParams.append("asignedToSubdepartmentId", `${subdepartment}`);
        extraParams.append("ByDepartment", `${true}`);
    }
    if (firstLoad) {
        extraParams.append("asignedToUserId", `${user}`);
        if (!subdepartment || subdepartment === "-1") extraParams.append("asignedToDepartmentId", `${department}`);
        if (!(subdepartment === "-1" && department && department !== "-1"))
            extraParams.append("asignedToSubdepartmentId", `${subdepartment}`);
        extraParams.append("CheckMyTaskListEmpty", `${true}`);
    }

    company && company !== "-1" && extraParams.append("companyId", `${Number(company)}`);

    if (type === "sporadic") return extraParams.toString();

    plan && plan !== "-1" && extraParams.append("planId", `${Number(plan)}`);
    hasPhoto && extraParams.append("isPhotoRequired", `${hasPhoto}`);
    hasData && extraParams.append("dataReport", `${hasData}`);
    myTasksUserId && extraParams.append("myTasksUserId", `${myTasksUserId}`);
    notEvaluatedCheck && extraParams.append("notEvaluated", `${notEvaluatedCheck}`);
    critical && extraParams.append("critical", `${critical}`);
    noCritical && extraParams.append("noCritical", `${critical}`);
    fiveStar && extraParams.append("fiveStar", `${fiveStar}`);
    fourStar && extraParams.append("fourStar", `${fourStar}`);
    threeStar && extraParams.append("threeStar", `${threeStar}`);
    twoStar && extraParams.append("twoStar", `${twoStar}`);
    oneStar && extraParams.append("oneStar", `${oneStar}`);
    pending !== undefined && extraParams.append("pending", `${pending}`);
    timedOut !== undefined && extraParams.append("timedOut", `${timedOut}`);

    return extraParams.toString();
};
