import { getDateFormattedSelects } from "app/helpers";
import { AutomaticRecordGridSF } from "../models/AutomaticRecordGridModels";

type Props = AutomaticRecordGridSF;

export const fillExtraParams = ({
    dateTimeEntry1,
    dateTimeEntry2,
    batteryLevel,
    signalLevel,
    gatewaySerialNumbers,
    sensorSerialNumbers,
}: Props) => {
    const extraParams = new URLSearchParams();

    if (dateTimeEntry1) extraParams.append("dateTimeEntry1", getDateFormattedSelects(dateTimeEntry1));
    if (dateTimeEntry2) extraParams.append("dateTimeEntry2", getDateFormattedSelects(dateTimeEntry2));
    if (batteryLevel) extraParams.append("batteryLevel", batteryLevel);
    if (signalLevel) extraParams.append("signalLevel", signalLevel);
    if (!!gatewaySerialNumbers.length)
        extraParams.append("gatewaySerialNumbers", gatewaySerialNumbers.map(({ value }) => value).join(";"));
    if (!!sensorSerialNumbers.length)
        extraParams.append("sensorSerialNumbers", sensorSerialNumbers.map(({ value }) => value).join(";"));

    return extraParams.toString();
};
