import { useAssetForm } from "./hooks/useAssetForm";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { isEqual } from "lodash";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { AssetFormInfo } from "./AssetFormInfo/AssetFormInfo";
import { AssetDynamicFields } from "./AssetDynamicFields/AssetDynamicFields";
import { useTitle } from "app/hooks";

export const AssetForm = () => {
    const { t } = useTranslation();

    const {
        isAssetDeleting,
        isEditPage,
        data,
        onInputChange,
        handleSubmit,
        validations,
        isSaving,
        handleCancelModal,
        isCancelModalOpen,
        setIsCancelModalOpen,
        dataCopy,
        handleDelete,
        isAssetDeleted,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        isAssetDeleteModalOpen,
        isAssetLoading,
        assetId,
    } = useAssetForm();

    const title = isEditPage ? t(TranslationKeys.EDIT_ASSET) : t(TranslationKeys.NEW_ASSET);

    useTitle(t(isEditPage ? TranslationTitles.EDIT_ASSET_PAGE_TITLE : TranslationTitles.NEW_ASSET_PAGE_TITLE));

    const handleDeleteModal = async () => {
        await handleDelete();
        handleCloseDeleteModal();
    };

    const getDeleteModalDescription = (): string => {
        if (isAssetDeleted) return t(TranslationKeys.ASSET_MODAL_DESCRIPTION_ACTIVATE);
        if (data.isRelatedWithTasks) return t(TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE);
        return t(TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE_NO_RELATED);
    };

    return (
        <>
            {isAssetDeleteModalOpen && (
                <ConfirmModal
                    onClose={handleCloseDeleteModal}
                    onConfirm={handleDeleteModal}
                    onConfirmText={
                        isAssetDeleted
                            ? t(TranslationKeys.EDIT_FORM_USER_ACTIVATE)
                            : t(TranslationKeys.EDIT_FORM_USER_DEACTIVATE)
                    }
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={
                        isAssetDeleted
                            ? t(TranslationKeys.ASSET_MODAL_TITLE_ACTIVATE).replace("{0}", data.name)
                            : t(TranslationKeys.ASSET_MODAL_TITLE_DEACTIVATE).replace("{0}", data.name)
                    }
                    description={getDeleteModalDescription()}
                    isLoading={isAssetDeleting}
                    variant="purple"
                />
            )}

            {isCancelModalOpen && (
                <ConfirmModal
                    onClose={() => setIsCancelModalOpen(false)}
                    onConfirm={handleCancelModal}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationKeys.UNDO_CHANGES)}
                    variant="purple"
                />
            )}

            <FormLayout
                isLoading={isAssetLoading}
                variant="gray"
                headerOptions={{
                    buttonLabel: !isAssetDeleted
                        ? t(TranslationKeys.WORKING_POSITION_DEACTIVE)
                        : t(TranslationKeys.WORKING_POSITION_ACTIVE),
                    showDeleteButton: hasPermissionToDelete(SecScreen.ASSETS) && isEditPage,
                    type: "disable",
                    badge: { isVisible: isEditPage },
                    isActive: !isAssetDeleted,
                    onClick: handleOpenDeleteModal,
                    title: title,
                    variant: "purple",
                }}
                footer={
                    ((isEditPage && hasPermissionToEdit(SecScreen.ASSETS)) ||
                        (!isEditPage && hasPermissionToAdd(SecScreen.ASSETS))) &&
                    !isAssetDeleted && (
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationCommon.SAVE),
                                form: "assetForm",
                                type: "submit",
                                disabled: isAssetDeleted,
                                variant: "purple",
                            }}
                            closeButton={{
                                text: t(TranslationCommon.CANCEL),
                                onClick: () => setIsCancelModalOpen(true),
                                buttonType: "tertiary",
                                disabled: isEqual(data, dataCopy) || isAssetDeleted,
                                variant: "purple",
                            }}
                            loading={isSaving}
                        />
                    )
                }
            >
                <div className="assetFormPageContainer">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        className="assetForm"
                        id="assetForm"
                    >
                        <div className="assetForm__container">
                            <AssetFormInfo
                                {...data}
                                onInputChange={onInputChange}
                                isDisabled={
                                    (isEditPage && !hasPermissionToEdit(SecScreen.ASSETS)) ||
                                    (!isEditPage && !hasPermissionToAdd(SecScreen.ASSETS)) ||
                                    isAssetDeleted
                                }
                                customError={validations}
                                id={assetId != null ? Number(assetId) : undefined}
                            />
                            <AssetDynamicFields
                                {...data}
                                onInputChange={onInputChange}
                                isDisabled={
                                    (isEditPage && !hasPermissionToEdit(SecScreen.ASSETS)) ||
                                    (!isEditPage && !hasPermissionToAdd(SecScreen.ASSETS)) ||
                                    isAssetDeleted
                                }
                                customError={validations}
                            />
                        </div>
                    </form>
                </div>
            </FormLayout>
        </>
    );
};
