import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { PlanAPPCCWithCountersDto } from "app/dtos/05-QUA/PlanAPPCC/PlanAPPCCWithCountersDto";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { PlanAPPCCModel } from "app/models/05-QUA/PlanAPPCCModels/PlanAPPCCModels";
import { ActionType, OptionsSearch } from "app/models/FormComponentsModel";
import PlanAPPCCService from "app/services/05-QUA/PlanAPPCCService";
import { TranslationCommon, TranslationModals } from "app/translation/translationKeys";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { INITIAL_PLAN_SORT_DIRECTION, INITIAL_PLAN_SORT_FIELD } from "../../constants/PlanAPPCCContext";
import { fillExtraparams } from "../../helpers/fillExtraparams";
import { PlanAPPCCFetchParams } from "../../models/PlanAPPCCGridModels";
import { PlanAPPCCContex } from "../../state/context/planAPPCCProviderContext";

export const usePlanAPPCCGrid = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const session = useSession();
    const { onChangePageIndex, onSortChange, pageIndex, sortDirection, sortField, onSFChange, secondaryFilterValues } =
        useContext(PlanAPPCCContex);
    const { handleCompanyChange, selectCompany, filterIcon } = useSelectCompanyTable();

    const { query } = secondaryFilterValues;

    const [data, setData] = useState<PlanAPPCCWithCountersDto[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [plan, setPlan] = useState<PlanAPPCCModel | null>(null);
    const [isDeletePlanModalIsVisible, setIsDeletePlanModalIsVisible] = useState<boolean>(false);
    const [isCreatePlanModalVisible, setIsCreatePlanModalVisible] = useState<boolean>(false);

    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);

    const getData = async ({ pi, sortD, sortF, planSf, companyId }: PlanAPPCCFetchParams) => {
        setIsLoading(true);

        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;
        const filterData = planSf ? planSf : secondaryFilterValues;
        const companyIdFetch = companyId || selectCompany.company.value;

        const extraParams = fillExtraparams({ companyId: companyIdFetch });

        const { data, status } = await PlanAPPCCService.GetDataWithCounters({
            sortDirection: sortD || sortDirection,
            sortField: sortF || sortField,
            pageIndex: pageIndexFetch,
            query: filterData.query,
            extraParams,
        });

        if (!status()) {
            handleToast({
                title: t(TranslationCommon.FAILED_DATA_LOADED),
                variant: "danger",
                type: "alert",
            });
            setData([]);
            setTotal(0);
            setIsLoading(false);
            return;
        }

        setData(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    const handleSortChange = (sortF: string, sortD: SortedTypeModel) => {
        onSortChange(sortF, sortD);
        getData({ sortF, sortD });
    };

    const onQuerySearch = () => {
        if (isLoading) return;
        if (!!query?.length) {
            getData({ planSf: secondaryFilterValues });
            onChangePageIndex(1);
            return;
        }
        handleCleanFetch();
    };

    const handleCleanFetch = () => {
        onSFChange({ query: "" });
        setShowSearchInput(false);
        getData({ planSf: { ...secondaryFilterValues, query: "" }, pi: 0 });
        onChangePageIndex(1);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const companyIdFetch = selectCompany.company.value || String(session?.workingCompany?.companyId);

        const extraParams = fillExtraparams({ companyId: companyIdFetch });
        return PlanAPPCCService.GetDataExport({
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_PLAN_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_PLAN_SORT_FIELD : sortField,
            ...params,
            extraParams,
        });
    };

    const onEditPlan = (selectedPlan: PlanAPPCCModel) => {
        setIsCreatePlanModalVisible(true);
        setPlan(selectedPlan);
    };

    const onCreatePlan = (_: PlanAPPCCModel, type: ActionType) => {
        handleCleanFetch();
        setIsCreatePlanModalVisible(false);
        const title =
            type === "create" ? t(TranslationModals.PLANS_SUCCESS_CREATE) : t(TranslationModals.PLANS_SUCCESS_EDIT);
        handleToast({ title, variant: "success", type: "alert" });
        setPlan(null);
    };

    const onCloseEditCreatePlan = () => {
        setIsCreatePlanModalVisible(false);
        setPlan(null);
    };

    const onDeleteModalOpen = (selectedPlan: PlanAPPCCModel) => {
        setPlan(selectedPlan);
        setIsDeletePlanModalIsVisible(true);
    };

    const onCloseDeleteModal = () => {
        setPlan(null);
        setIsDeletePlanModalIsVisible(false);
    };

    const onDeletePlan = async () => {
        if (!plan) return;
        await PlanAPPCCService.Delete(plan.id);
        handleToast({ title: t(TranslationModals.PLANS_SUCCESS_DELETE), variant: "success", type: "alert" });
        onCloseDeleteModal();
        handleCleanFetch();
    };

    const onClickCompany = () => {
        handleCompanyChange({ isOpen: true });
        setShowSearchInput(false);
    };

    const onChangeCompany = ({ label, value }: OptionsSearch) => {
        handleCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        onChangePageIndex(1);
        getData({ pi: 0, companyId: value });
    };

    const onClickOutsideCompany = () => {
        handleCompanyChange({ isOpen: false });
    };

    useEffect(() => {
        getData({});
    }, []);

    return {
        onChangePageIndex,
        pageIndex,
        sortDirection,
        sortField,
        data,
        total,
        isLoading,
        showSearchInput,
        getData,
        setShowSearchInput,
        onSortChange,
        handleSortChange,
        onSFChange,
        secondaryFilterValues,
        onQuerySearch,
        handleCleanFetch,
        handleExport,
        onChangeVisibilityCreateModal: setIsCreatePlanModalVisible,
        isCreatePlanModalVisible,
        onCreatePlan,
        plan,
        onEditPlan,
        onCloseEditCreatePlan,
        isDeletePlanModalIsVisible,
        onDeleteModalOpen,
        onDeletePlan,
        onCloseDeleteModal,
        selectCompany,
        filterIcon,
        onClickCompany,
        onChangeCompany,
        onClickOutsideCompany,
    };
};
