import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { OptionsSearch } from "app/models/FormComponentsModel";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { AssetTypeModel } from "app/models/05-QUA/AssetModels/AssetTypeModel";

export default class QuaSelectorService {
    // GET all APPCC Plans
    public static async GetPlansAPPCC(params?: PaginationParams) {
        return FetchService.get<OptionsSearch[]>({
            url: `${ApiPaths.QUA_API}/QuaSelector/PlanAPPCC`,
            paginationParams: params,
        });
    }

    // Get a List of assets
    public static async GetAssets(params?: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.QUA_API}/QuaSelector/Assets`,
            paginationParams: params,
        });
    }

    // GET List of AssetTypes
    public static async GetAssetTypeList() {
        return FetchService.get<AssetTypeModel[]>({
            url: `${ApiPaths.QUA_API}/QuaSelector/AssetType`,
        });
    }

    // GET List of Asset dynamic field
    public static async GetAssetDynamicFieldList() {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.QUA_API}/QuaSelector/AssetDynamicField`,
        });
    }

    // GET List of corrective Meassures
    public static async GetCorrectiveMeassures() {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.QUA_API}/QuaSelector/CorrectiveMeasure`,
        });
    }

    // GET all audits
    public static async GetAudits(params?: PaginationParams) {
        return FetchService.get<OptionsSearch[]>({
            url: `${ApiPaths.QUA_API}/QuaSelector/Audit`,
            paginationParams: params,
        });
    }

    // GET all AuditInstances
    public static async GetIssueAuditInstances(params?: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.QUA_API}/QuaSelector/AuditInstance`,
            paginationParams: params,
        });
    }

    // GET all APPCC Plans
    public static async GetBaseAuditGroupCheckListItemType() {
        return FetchService.get<OptionsSearch[]>({
            url: `${ApiPaths.QUA_API}/QuaSelector/BaseAuditGroupCheckListItemType`,
        });
    }
}
