import { getMonthName } from "app/helpers";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { Asset2ToBackModel, AssetModelFormValues } from "app/models/05-QUA/AssetModels";
import AssetService from "app/services/05-QUA/AssetService";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import {
    AssetTranslation,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import { AssetFormValidationsModel } from "../models/AssetFormModel";
import { fillAsset } from "../utilities/fillAsset";
import { mapAsset } from "../utilities/mapAsset";
import { useAssetValidate } from "./useAssetValidate";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
const INITIAL_FORM_VALUES: AssetModelFormValues = {
    assetFields: [],
    assetId: "",
    description: "",
    fK_AssetType: 0,
    fK_Company: 0,
    fK_PlanAPPCC: 0,
    name: "",
    isRelatedWithTasks: false,
    notWorking: false,
    allowSensorSerialNumber: false,
    sensorSerialNumber: "",
};

const INITIAL_ERROR_MESSAGES: AssetFormValidationsModel = {
    errorCompany: "",
    errorDynamicAsset: {
        dynamicAssets: [],
        errorMessage: "",
    },
    errorName: "",
    errorType: "",
    errorPlanAPPCC: "",
    errorAssetId: "",
    errorSerialNumber: "",
};

export const useAssetForm = () => {
    const { assetId } = useParams();
    const { pathname } = useLocation();
    const nav = useNavigate();
    const session = useSession();
    const { t } = useTranslation();
    const { handleToast, removeToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { validate } = useAssetValidate();
    const [urlParams] = useSearchParams();

    const fkPlan = urlParams.get("planId") && urlParams.get("planId") != "-1" ? Number(urlParams.get("planId")) : 0;
    const serialNumber = urlParams.get("serialNumber");
    const hasPermissionsToViewSensors = hasPermissionToView(session, SecScreen.AUTOMATIC_RECORD);

    const [data, setData] = useState<AssetModelFormValues>({
        ...INITIAL_FORM_VALUES,
        fK_PlanAPPCC: fkPlan,
        assetId: serialNumber || "",
    });
    const [dataCopy, setDataCopy] = useState<AssetModelFormValues>({
        ...INITIAL_FORM_VALUES,
        fK_PlanAPPCC: fkPlan,
        assetId: serialNumber || "",
    });
    const [validations, setValidations] = useState<AssetFormValidationsModel>(INITIAL_ERROR_MESSAGES);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isAssetDeleted, setIsAssetDeleted] = useState<boolean>(false);
    const [isAssetDeleting, setIsAssetDeleting] = useState<boolean>(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

    const [isAssetDeleteModalOpen, setisAssetDeleteModalOpen] = useState<boolean>(false);

    const handleCloseDeleteModal = () => setisAssetDeleteModalOpen(false);
    const handleOpenDeleteModal = () => setisAssetDeleteModalOpen(true);

    const isEditPathName = useCallback(() => {
        return pathname.split("/").includes("edit");
    }, [pathname]);

    const isEditPage = !!(isEditPathName() && assetId);
    const [isAssetLoading, setIsAssetLoading] = useState<boolean>(isEditPage);

    const handleInputChange = (value: Partial<AssetModelFormValues>) => {
        setData((prev) => ({ ...prev, ...value }));
    };

    const getAsset = async () => {
        setIsAssetLoading(true);
        const { data: dataSr, status } = await AssetService.GetOne(Number(assetId));
        const shouldNotShowSensorSerialNumber = !hasPermissionsToViewSensors && !!dataSr?.sensorSerialNumber.length;
        if (!status() || shouldNotShowSensorSerialNumber) {
            handleToast({
                title: shouldNotShowSensorSerialNumber
                    ? t(AssetTranslation.ASSET_NOT_FOUND)
                    : t(TranslationCommon.FAILED_DATA_LOADED),
                type: "alert",
                variant: "danger",
            });
            handleNavigate(`/${PrivatePaths.ASSETS}`);
            setIsAssetLoading(false);
            return;
        }
        setIsAssetDeleted(!!dataSr.isDeleted);
        const dataMapped = fillAsset(dataSr);
        setData(dataMapped);
        setDataCopy(dataMapped);
        setIsAssetLoading(false);
    };

    const validation = () => {
        const { isValid, validations: newValidations } = validate({ data, validations });
        setValidations(newValidations);

        if (!isValid) {
            handleToast({
                title: t(TranslationModals.ASSET_FAILED_TITLE),
                subtitle: t(TranslationModals.ASSET_FAILED_DESCRIPTION),
                variant: "danger",
                type: "alert",
            });
            return false;
        }

        return true;
    };

    const handleNavigate = (path: string) => {
        const planId = urlParams.get("planId");
        if (planId) {
            nav(`${path}?planId=${planId}`);
            return;
        }
        nav(`${path}`);
    };

    const handleSubmit = async () => {
        const assetToBack = mapAsset({
            data,
            customerInstanceId: session?.user.customerInstanceId || 0,
        });
        if (!validation()) return;

        if (isEditPage) {
            handleUpdate(assetToBack);
            return;
        }

        await handleCreate(assetToBack);
    };

    const handleCreate = async (assetToBack: Asset2ToBackModel) => {
        setIsSaving(true);
        const { data: dataSr, status } = await AssetService.Save(assetToBack);
        if (!status()) {
            handleToast({
                title: t(TranslationModals.FAILED_SAVE),
                type: "alert",
                variant: "danger",
            });
            setIsSaving(false);
            return;
        }
        handleToast({
            title: t(TranslationModals.SUCCESS_SAVE),
            type: "alert",
            variant: "success",
        });
        const dataMapped = fillAsset(dataSr);
        setData(dataMapped);
        setDataCopy(dataMapped);
        setIsSaving(false);
        handleNavigate(`/${PrivatePaths.ASSETS}/edit/${dataSr.id}`);
    };

    const handleUpdate = async (assetToBack: Asset2ToBackModel) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        const toastId = v4();
        const day = tomorrow.getDate();
        const month = getMonthName(tomorrow.toString());
        handleToast({
            title: t(TranslationModals.REGENERATING_TASKS_TITLE),
            subtitle: t(TranslationModals.GENERATING_TASKS_DESCRIPTION)
                .replace("{0}", String(day))
                .replace("{1}", month),
            variant: "primary",
            type: "alert",
            isLoading: true,
            id: toastId,
        });

        setIsSaving(true);

        const { status, getParsedError } = await AssetService.Update(assetToBack, Number(assetId));
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            removeToast(toastId);
            setIsSaving(false);
            return;
        }
        removeToast(toastId);
        handleToast({
            title: t(TranslationModals.SUCCESS_SAVE),
            type: "alert",
            variant: "success",
        });
        setIsSaving(false);
        handleNavigate(`/${PrivatePaths.ASSETS}`);
    };

    const handleCancelModal = () => {
        setData(dataCopy);
        setIsCancelModalOpen(false);
    };

    const handleDelete = async () => {
        if (!assetId) return;
        if (isAssetDeleted) {
            await activateAsset(assetId);
            return;
        }

        await deactivateAsset(assetId);
    };

    const activateAsset = async (id: string) => {
        setIsAssetDeleting(true);
        const assetSr = await AssetService.Activate(id);
        if (!assetSr.status()) {
            handleToast({
                title: t(TranslationKeys.FAILED_ACTIVE_ASSET),
                type: "alert",
                variant: "danger",
            });
            setIsAssetDeleting(false);
            return;
        }

        handleToast({
            title: t(TranslationKeys.SUCCESS_ACTIVE_ASSET),
            type: "alert",
            variant: "success",
        });
        setIsAssetDeleting(false);
        setIsAssetDeleted(false);
    };
    const deactivateAsset = async (id: string) => {
        setIsAssetDeleting(true);
        const assetSr = await AssetService.Delete(id);
        if (!assetSr.status()) {
            handleToast({
                title: t(TranslationKeys.FAILED_DEACTIVE_ASSET),
                type: "alert",
                variant: "danger",
            });

            setIsAssetDeleting(false);

            return;
        }

        handleToast({
            title: t(TranslationKeys.SUCCESS_DEACTIVE_ASSET),
            type: "alert",
            variant: "success",
        });
        handleInputChange({ isRelatedWithTasks: false });
        setIsAssetDeleting(false);
        setIsAssetDeleted(true);
    };

    useEffect(() => {
        if (isEditPage) getAsset();
    }, []);

    useEffect(() => {
        if (serialNumber) {
            setData({ ...INITIAL_FORM_VALUES, sensorSerialNumber: serialNumber, allowSensorSerialNumber: true });
        }
    }, [serialNumber]);

    return {
        isEditPage,
        isAssetDeleting,
        onInputChange: handleInputChange,
        data,
        handleSubmit,
        validations,
        isSaving,
        dataCopy,
        handleCancelModal,
        isCancelModalOpen,
        setIsCancelModalOpen,
        isAssetDeleted,
        handleDelete,
        isAssetDeleteModalOpen,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        isAssetLoading,
        assetId: assetId != null ? Number(assetId) : undefined,
    };
};
