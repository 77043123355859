import {
    faCirclePlus,
    faClose,
    faMagnifyingGlass,
    faPen,
    faSearch,
    faTrash,
    faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { ColumnsType } from "app/components_v2/Table/types";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { Input } from "app/components_v2/__inputs";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { PlanAPPCCWithCountersDto } from "app/dtos/05-QUA/PlanAPPCC/PlanAPPCCWithCountersDto";
import { useSession, useTitle } from "app/hooks";
import {
    hasPermissionToAdd,
    hasPermissionToDelete,
    hasPermissionToEdit,
    hasPermissionToView,
} from "app/routes/HelperRoleBasedAccess";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { MobilePlanRow } from "../MobilePlanRow/MobilePlanRow";
import { PlanAPPCCCounters } from "../components/PlanAPPCCCounters/PlanAPPCCCounters";
import { INITIAL_PLAN_SORT_DIRECTION } from "../constants/PlanAPPCCContext";
import { EditPlanAPPCCModal } from "./components/EditPlanAPPCCModal/EditPlanAPPCCModal";
import { usePlanAPPCCGrid } from "./hooks/usePlanAPPCCGrid";

export const PlanAPPCCGrid = () => {
    const { t } = useTranslation();
    const session = useSession();
    const isOnBoarding = session?.isOnBoarding;

    useTitle(t(TranslationTitles.PLAN_APPCC_PAGE_TITLE));

    const {
        data,
        isLoading,
        onChangePageIndex,
        pageIndex,
        showSearchInput,
        sortDirection,
        sortField,
        total,
        getData,
        handleSortChange,
        setShowSearchInput,
        onSFChange,
        secondaryFilterValues,
        handleCleanFetch,
        onQuerySearch,
        handleExport,
        isCreatePlanModalVisible,
        onChangeVisibilityCreateModal,
        onCreatePlan,
        onEditPlan,
        plan,
        onCloseEditCreatePlan,
        isDeletePlanModalIsVisible,
        onDeleteModalOpen,
        onDeletePlan,
        onCloseDeleteModal,
        selectCompany,
        filterIcon,
        onClickCompany,
        onChangeCompany,
        onClickOutsideCompany,
    } = usePlanAPPCCGrid();

    const { query } = secondaryFilterValues;

    const columns: ColumnsType<PlanAPPCCWithCountersDto>[] = [
        {
            label: t(TranslationKeys.PLAN_ID),
            dataIndex: "Id",
            sortedType: "default",
            className: "planAppccGrid__id",
            render: (record) => <CellTitle title={record.id} />,
        },
        {
            label: t(TranslationKeys.PLAN_NAME),
            dataIndex: "name",
            sortedType: "default",
            render: (record) => <CellTitle title={record.name} />,
        },
        {
            label: t(TranslationKeys.PLAN_NAME_TASK_COUNT),
            dataIndex: "TaskCount",
            sortedType: "default",
            className: "planAppccGrid__taskCount",
            alignCenter: true,
            render: (record) => (
                <div className="planAppccGrid__counters">
                    <PlanAPPCCCounters
                        to={`/${PrivatePaths.REGISTRY_MANAGEMENT}?planId=${record.id}`}
                        title={String(record.taskCount)}
                        readonly={!hasPermissionToView(session, SecScreen.PLAN_APPCC) || !!isOnBoarding}
                    />
                </div>
            ),
        },
        {
            label: t(TranslationKeys.PLAN_NAME_ASSET_COUNT),
            dataIndex: "AssetCount",
            sortedType: "default",
            className: "planAppccGrid__assetCount",
            alignCenter: true,
            render: (record) => (
                <div className="planAppccGrid__counters">
                    <PlanAPPCCCounters
                        to={`/${PrivatePaths.ASSETS}?planId=${record.id}`}
                        title={String(record.assetCount)}
                        readonly={!hasPermissionToView(session, SecScreen.ASSETS) || !!isOnBoarding}
                    />
                </div>
            ),
        },
        {
            label: t(TranslationKeys.PLAN_NAME_CORRECTIVE_MEASSURE_COUNT),
            dataIndex: "CorectiveMeasureCount",
            sortedType: "default",
            className: "planAppccGrid__corectiveMeasureCount",
            alignCenter: true,
            render: (record) => (
                <div className="planAppccGrid__counters">
                    <PlanAPPCCCounters
                        to={`/${PrivatePaths.CORR_MEASURE_MANAGER}?planId=${record.id}`}
                        title={String(record.corectiveMeasureCount)}
                        readonly={!hasPermissionToView(session, SecScreen.CORR_MEASURE_MANAGER) || !!isOnBoarding}
                    />
                </div>
            ),
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "edit",
            className: "issueGrid__edit",
            render: ({ id, name }) => (
                <div className="assetGrid__edit">
                    <CellIcons
                        icons={[
                            {
                                icon: faPen,
                                onClick: () => onEditPlan({ id, name }),
                                hidden: !hasPermissionToEdit(SecScreen.PLAN_APPCC),
                                title: t(TranslationCommon.EDIT),
                            },
                            {
                                icon: faTrash,
                                onClick: () => onDeleteModalOpen({ id, name }),
                                hidden: !hasPermissionToDelete(SecScreen.PLAN_APPCC),
                                title: t(TranslationCommon.DELETE),
                            },
                        ]}
                    />
                </div>
            ),
        },
    ];

    return (
        <PageContainer paddingTop>
            {isCreatePlanModalVisible && (
                <EditPlanAPPCCModal
                    onClose={onCloseEditCreatePlan}
                    onSave={onCreatePlan}
                    value={plan}
                    type={plan ? "edit" : "create"}
                />
            )}
            {isDeletePlanModalIsVisible && (
                <ConfirmModal
                    onConfirm={onDeletePlan}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onClose={onCloseDeleteModal}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationModals.PLANS_DELETE_MODAL_DESCRIPTION)}
                    title={t(TranslationModals.PLANS_DELETE_MODAL_TITLE).replace("{0}", plan?.name || "")}
                    type="delete"
                />
            )}
            <TableCollapsable
                cols={columns.filter((col) => !col.hidden)}
                data={data}
                variant="purple"
                placeholder={<TableError title={t(TranslationErrors.PLANS_NOT_FOUND)} icon={faTriangleExclamation} />}
                title={`${t(TranslationTitles.PLAN_APPCC_PAGE_TITLE)} (${selectCompany.company.label})`}
                subTitle={`${total} ${t(TranslationKeys.PLANS)}`}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={(pi) => {
                    onChangePageIndex(pi);
                    getData({ pi: pi - 1 });
                }}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                    handleSortChange(sortFieldParam, sortedTypeParam || INITIAL_PLAN_SORT_DIRECTION);
                }}
                onDoubleClick={({ id, name }) => onEditPlan({ id, name })}
                onClickExportCsv={handleExport}
                actionButtons={[
                    {
                        icon: faCirclePlus,
                        onClick: () => onChangeVisibilityCreateModal(true),
                        hidden: !hasPermissionToAdd(SecScreen.PLAN_APPCC),
                        title: t(TranslationCommon.CREATE),
                    },
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <div>
                                    <Input
                                        focus
                                        onChange={(value) => onSFChange({ query: value })}
                                        iconRight={!!query?.length ? faSearch : faClose}
                                        value={query}
                                        onClickIcon={handleCleanFetch}
                                        onPressEnter={onQuerySearch}
                                        whiteInput
                                        transparent
                                    />
                                </div>
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                    {
                        icon: filterIcon,
                        onClick: onClickCompany,
                        inputComponent: {
                            component: (
                                <TableSelectCompany
                                    onChange={onChangeCompany}
                                    value={selectCompany.company}
                                    onClickOutside={onClickOutsideCompany}
                                />
                            ),
                            show: selectCompany.isOpen,
                        },
                        title: t(TranslationCommon.FILTER),
                    },
                ]}
                mobileBody={(row, _i, rowPosition) => (
                    <MobilePlanRow
                        data={row}
                        key={row.id}
                        rowPosition={rowPosition}
                        onEdit={() => onEditPlan({ id: row.id, name: row.name })}
                        onDelete={() => onDeleteModalOpen({ id: row.id, name: row.name })}
                    />
                )}
            />
        </PageContainer>
    );
};
