import { TaskStatus } from "app/shared/Constants";
import { convertUTCtoLocaleDate, getHoursFromDateUTC } from "../Date.utilities";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";

export const getTaskInstanceHour = ({
    taskType,
    statusCode,
    deadlineDate,
    finishedDate,
}: TaskInstanceModel): string | undefined => {
    if (taskType === "SPORADIC") return undefined;

    if (finishedDate && statusCode === TaskStatus.COMPLETED)
        return getHoursFromDateUTC(convertUTCtoLocaleDate(finishedDate));
    if (deadlineDate != null && statusCode === TaskStatus.PENDING)
        return getHoursFromDateUTC(convertUTCtoLocaleDate(deadlineDate));
    return undefined;
};
