import {
    faBars,
    faBell,
    faCheckDouble,
    faFolderGear,
    faImage,
    faListCheck,
    faShieldHalved,
    faStar,
    faRankingStar as inactiveAnalistRanking,
    faTicketAirline,
} from "@fortawesome/pro-regular-svg-icons";
import {
    faRankingStar as activeAnalistRanking,
    faBell as faBellSolid,
    faCheckDouble as faCheckDoubleSolid,
    faChevronDown,
    faShieldHalved as faShieldHalvedSolid,
    faStar as faStarSolid,
    faFolderGear as gearSolid,
    faImage as imageSolid,
    faTicketAirline as faTicketAirlineSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isCurrentPage } from "app/helpers/__validates/validateIsCurrentPage";
import { hasPermissionToViewConfigIcon } from "app/helpers/permissions/hasPermissionToViewConfigIcon";
import { useSession } from "app/hooks";
import { useGetPageTitle } from "app/hooks/useGetPageTitle";
import { useHeaderTitle } from "app/hooks/useHeaderTitle";
import { useUserType } from "app/hooks/useUserType";
import { Notification } from "app/pages/Notification/Notification/Notification";
import { hasPermission, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { PrivatePaths, SecScreen, screenSize } from "app/shared/Constants";
import { TranslationTitles } from "app/translation/translationKeys";
import Logo from "assets/common/4Link-white.png";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useOnClickOutside, useWindowSize } from "usehooks-ts";
import { AvatarPopover } from "../__popovers/AvatarPopover/AvatarPopover";
import InstanceSelector from "../AppHeader/InstanceSelector";
import { DivForwardRef } from "../ForwardRef/DivForwardRef";
import { MenuSideBar } from "../MenuSideBar/MenuSideBar";
import { Popover } from "../Popover/Popover";
import { HeaderContext } from "./context/headerContext";
import { HeaderConfigDropdown } from "./HeaderConfigDropdown/HeaderConfigDropdown";
import { HeaderItem } from "./HeaderItem/HeaderItem";
import { HeaderRightItem } from "./HeaderRightItem/HeaderRightItem";
import { HeaderItemsModel, HeaderProps } from "./headerTypes";

export const Header: FC<HeaderProps> = ({ isTransparent, headerColor, isGrayMode, variant = "primary" }) => {
    const session = useSession();
    const { isWorker, isSupervisor, isAnalist, isAuditor, isAdmin } = useUserType();
    const { width } = useWindowSize();
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const feedBackPhoto = queryParams.get("photo");
    const { hasNotification, handleClearAllNotifications } = useContext(HeaderContext);
    const pageTitle = useGetPageTitle();
    const { headerTitle } = useHeaderTitle();
    const nav = useNavigate();
    const notificationModalRef = useRef<any>();
    const configurationModalRef = useRef<any>();
    const isOnBoarding = session?.isOnBoarding;

    const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
    const [showConfigurationModal, setShowConfigurationModal] = useState<boolean>(false);
    const [showSideBar, setShowSideBar] = useState<boolean>(false);
    const [hide, setHide] = useState(false);
    const [showConfigurationIcon, setShowConfigurationIcon] = useState<boolean>(false);

    useOnClickOutside(notificationModalRef, () => {
        if (showNotificationModal) setShowNotificationModal(false);
    });
    useOnClickOutside(configurationModalRef, () => {
        if (showConfigurationModal) setShowConfigurationModal(false);
    });

    const handleMenuClick = () => {
        setHide(false);
        setShowSideBar(true);
    };

    const onClickConfiguartionIcon = (navigate: string) => {
        nav(navigate);
        setShowConfigurationModal(false);
    };

    const hasCompaniesDepartmentOrSubDepts = () => {
        const companies = session?.user?.companies;
        const department = session?.workingCompany?.departments;
        const subdepartment = session?.workingDepartment?.subdepartments;

        if (!isWorker && companies && companies.length > 1) {
            return true;
        } else if (
            isWorker &&
            ((companies && companies.length > 1) ||
                (department && department.length > 1) ||
                (subdepartment && subdepartment.length > 1))
        ) {
            return true;
        }

        return false;
    };

    const handleNotificationClick = () => {
        if (width >= screenSize.TABLET) {
            setShowNotificationModal(!showNotificationModal);
            handleClearAllNotifications();
            return;
        }
        nav(`/${PrivatePaths.NOTIFICATION}`);
    };

    const possibleItems: Record<string, HeaderItemsModel> = {
        DASHBOARD: {
            iconLeft: faListCheck,
            to: PrivatePaths.DASHBOARD,
            isActive: !!isCurrentPage(PrivatePaths.DASHBOARD),
            text: width >= screenSize.LAPTOP ? t(TranslationTitles.MY_TASKS_HEADER_TITLE) : "",
            show: !isOnBoarding,
        },
        REVIEW_TASKS: {
            iconLeft: isCurrentPage(PrivatePaths.TASK_PHOTO) ? imageSolid : faImage,
            to: PrivatePaths.TASK_PHOTO.split("/")[0],
            isActive: !!(isCurrentPage(PrivatePaths.TASK_PHOTO) && !feedBackPhoto),
            text: width >= screenSize.LAPTOP ? t(TranslationTitles.HEADER_REVIEW_TASKS_TITLE) : "",
            show: hasPermission(SecScreen.TASK_REVIEW) && !isOnBoarding,
        },
        MY_VALORATIONS: {
            iconLeft: isCurrentPage(PrivatePaths.FEEDBACK_TASKS) ? faStarSolid : faStar,
            to: `${PrivatePaths.FEEDBACK_TASKS}?comments=true`,
            isActive: isCurrentPage(PrivatePaths.FEEDBACK_TASKS),
            text: width >= screenSize.LAPTOP ? t(TranslationTitles.REVIEW_FEEDBACK_TITLE) : "",
            show: hasPermission(SecScreen.MY_VALORATION) && !isOnBoarding,
        },
        ANALIST_DATA: {
            iconLeft: isCurrentPage(PrivatePaths.COMPANY_RANKING) ? activeAnalistRanking : inactiveAnalistRanking,
            to:
                session?.user?.companies?.length === 1 && session.user.userType === "ANALIST"
                    ? `${PrivatePaths.COMPANY_RANKING}/company/${session?.user?.companies[0].companyId}`
                    : session?.user?.companies?.length === 1 && session.user.userType === "SUPERVISOR"
                    ? `${PrivatePaths.COMPANY_RANKING}/ranking-teams/${session?.user?.companies[0].companyId}`
                    : session?.user.userType === "SUPERVISOR"
                    ? `${PrivatePaths.COMPANY_RANKING}/ranking-teams`
                    : `${PrivatePaths.COMPANY_RANKING}`,
            isActive: !!isCurrentPage(PrivatePaths.COMPANY_RANKING),
            text: width >= screenSize.LAPTOP ? t(TranslationTitles.HEADER_REVIEW_WORKERS_TITLE) : "",
            show: hasPermission(SecScreen.COMPANY_RANKING) && !isOnBoarding,
        },
        CRUD_PLAN: {
            iconLeft: isCurrentPage(PrivatePaths.PLAN_APPCC) ? faShieldHalvedSolid : faShieldHalved,
            to: PrivatePaths.PLAN_APPCC,
            isActive: !!isCurrentPage(PrivatePaths.PLAN_APPCC),
            text: width >= screenSize.LAPTOP ? t(TranslationTitles.HEADER_PLAN_APPCC_TITLE) : "",
            show: hasPermission(SecScreen.PLAN_APPCC) && !isOnBoarding,
        },
        AUDITS: {
            iconLeft: isCurrentPage(PrivatePaths.AUDIT_INST_MANAG) ? faCheckDoubleSolid : faCheckDouble,
            to: PrivatePaths.AUDIT_INST_MANAG,
            isActive: !!isCurrentPage(PrivatePaths.AUDIT_INST_MANAG),
            text: width >= screenSize.LAPTOP ? t(TranslationTitles.AUDIT_GRID_TITLE) : "",
            show: hasPermission(SecScreen.AUDIT_INST_MANAG) && !isOnBoarding,
        },
        ONBOARDING: {
            iconLeft: isCurrentPage(PrivatePaths.ON_BOARDING) ? faTicketAirlineSolid : faTicketAirline,
            to: PrivatePaths.ON_BOARDING,
            isActive: !!isCurrentPage(PrivatePaths.ON_BOARDING),
            text: width >= screenSize.LAPTOP ? t(TranslationTitles.HEADER_ON_BOARDING_TITLE) : "",
            show: !!isOnBoarding,
        },
    };

    const auditorItems: HeaderItemsModel[] = [
        possibleItems["REVIEW_TASKS"],
        possibleItems["ANALIST_DATA"],
        possibleItems["AUDITS"],
        possibleItems["CRUD_PLAN"],
    ];

    const workerItems: HeaderItemsModel[] = [possibleItems["MY_VALORATIONS"]];
    const supervisorItems: HeaderItemsModel[] = [possibleItems["REVIEW_TASKS"], possibleItems["ANALIST_DATA"]];

    let leftItems: HeaderItemsModel[] = [possibleItems["DASHBOARD"]];

    if (isOnBoarding) leftItems = [possibleItems["ONBOARDING"]];
    else if (isAuditor)
        leftItems = leftItems
            .concat(auditorItems)
            .filter((x) => x.show)
            .slice(0, 3);
    else if (isWorker)
        leftItems = leftItems
            .concat(workerItems)
            .filter((x) => x.show)
            .slice(0, 3);
    else if (isSupervisor || isAnalist)
        leftItems = leftItems
            .concat(supervisorItems)
            .filter((x) => x.show)
            .slice(0, 3);

    const showConfigIcon = () => {
        if (!isOnBoarding)
            return (
                location.pathname.split("/")[1] !== "licenses" &&
                hasPermissionToViewConfigIcon(isAdmin) &&
                width >= screenSize.TABLET
            );

        return width >= screenSize.TABLET && isAdmin;
    };

    const rightItems: HeaderItemsModel[] = [
        {
            iconLeft: showNotificationModal ? faBellSolid : faBell,
            isActive: showNotificationModal,
            show:
                hasPermissionToView(session, SecScreen.SYSTEM_NOTIFICATION) &&
                width >= screenSize.TABLET &&
                !isOnBoarding,
            notification: hasNotification,
            ref: notificationModalRef,
            onClick: handleNotificationClick,
            children: showNotificationModal && (
                <Popover
                    target={notificationModalRef.current}
                    onResize={() => setShowNotificationModal(!showNotificationModal)}
                    position={!isOnBoarding ? "bottomCenter" : "bottomRight"}
                    portal={false}
                >
                    <div className="headerNotificationContainer">
                        <Notification />
                    </div>
                </Popover>
            ),
        },
        {
            ref: configurationModalRef,
            isActive: showConfigurationModal || showConfigurationIcon,
            show: showConfigIcon(),
            onClick: () => setShowConfigurationModal(!showConfigurationModal),
            children: showConfigurationModal && (
                <HeaderConfigDropdown
                    onClickConfiguartionIcon={onClickConfiguartionIcon}
                    onClose={() => setShowConfigurationModal(false)}
                    popoverTarget={configurationModalRef.current}
                />
            ),
            iconLeft: showConfigurationModal ? gearSolid : faFolderGear,
        },
    ];

    const headerRightItems: HeaderItemsModel[] = [
        {
            iconRight: hasCompaniesDepartmentOrSubDepts() ? faChevronDown : undefined,
            to: hasCompaniesDepartmentOrSubDepts() ? PrivatePaths.SELECT_DEPARTMENT : undefined,
            text: session?.workingCompany?.companyName,
            show: !!session?.workingCompany?.companyName.length,
        },
    ];

    useEffect(() => {
        setShowNotificationModal(false);
    }, [location.pathname]);

    useEffect(() => {
        location.pathname.includes(`/${PrivatePaths.CENTERS_MANAGEMENT}`) ||
        location.pathname.includes(`/${PrivatePaths.ROLE_MANAGEMENT}`) ||
        location.pathname.includes(`/${PrivatePaths.USER_MANAGEMENT}`) ||
        location.pathname.includes(`/${PrivatePaths.QR}`)
            ? setShowConfigurationIcon(true)
            : setShowConfigurationIcon(false);
    }, [location]);

    return (
        <nav
            className={`header${isTransparent ? "--transparent" : `--${variant}`} ${
                isGrayMode ? "header--grayMode" : ""
            }  ${headerColor ? (isGrayMode ? "header__scroll--grayMode" : `header__scroll--${variant}`) : ""}  ${
                session?.user.doNotDisturbMode ? "marginTop18" : ""
            }`}
        >
            <MenuSideBar setHide={setHide} setShowSideBar={setShowSideBar} hide={hide} showSideBar={showSideBar} />
            <div className="header__content">
                <div className="header__content__left">
                    <div className="header__content__left__hamburguerTitle">
                        {!isWorker && !isOnBoarding && (
                            <span className="header__content__left__habmurguerContainer" onClick={handleMenuClick}>
                                <FontAwesomeIcon className="header__content__left__hamburguerIcon" icon={faBars} />
                            </span>
                        )}
                        <div className="header__content__pageName">
                            {!!pageTitle.length && width < screenSize.TABLET ? (
                                location.pathname.includes(PrivatePaths.COMPANY_RANKING) ? (
                                    headerTitle
                                ) : (
                                    pageTitle
                                )
                            ) : (
                                <Link
                                    to={!isOnBoarding ? `/${PrivatePaths.DASHBOARD}` : `/${PrivatePaths.ON_BOARDING}`}
                                    className="header__logo"
                                >
                                    <img src={Logo} alt="4Link logo" className="header__logo__image" />
                                </Link>
                            )}
                        </div>
                    </div>
                    {width >= screenSize.TABLET && (
                        <div className="header__content__left__links">
                            {leftItems.map((item, i) => item.show && <HeaderItem key={i} {...item} />)}
                        </div>
                    )}
                </div>
                <div className="header__content__right">
                    <div className="header__content__right__icons">
                        {rightItems.map(
                            ({ ref, ...item }, i) =>
                                item.show && (
                                    <DivForwardRef ref={ref} key={i}>
                                        <HeaderItem {...item} variant={variant} grayMode={isGrayMode} />
                                    </DivForwardRef>
                                )
                        )}
                    </div>
                    <div className="header__content__right__links">
                        {headerRightItems.map((item, i) => item.show && <HeaderRightItem key={i} {...item} />)}
                        {width >= screenSize.TABLET && <AvatarPopover position="bottomRight" />}
                        {!(location.pathname.split("/")[1] === "licenses") && isAdmin && <InstanceSelector />}
                    </div>
                </div>
            </div>
        </nav>
    );
};
