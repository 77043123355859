import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useBaseTaskModal } from "./hooks/useBaseTaskModal";
import { SelectOptions } from "app/components_v2/__inputs";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { useLocation, useNavigate } from "react-router-dom";
import { PrivatePaths } from "app/shared/Constants";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { EditTaskDisabledFieldsModel } from "app/models/02-TAR/Task/EditTask";
import { AllSteps } from "app/models/02-TAR/TaskWizard";
import { MailModal } from "app/components_v2/__modals/base/MailModal/MailModal";

type BaseTaskModalProps = {
    onSave: (task: AllSteps, disableds: EditTaskDisabledFieldsModel, baseTask: BaseTaskModel) => void;
    onClose: () => void;
    task: AllSteps;
    hasPlan: boolean;
};

const formId = "baseTaskForm";

export const BaseTaskModal: FC<BaseTaskModalProps> = ({ onClose, onSave, task, hasPlan }) => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const { pathname } = useLocation();

    const {
        formValues,
        onInputChange,
        isLoading,
        baseTaskTypeOptions,
        baseTasksOptions,
        getBaseTasks,
        onSubmit,
        errorMessages,
    } = useBaseTaskModal(task, onSave, hasPlan);

    const { taskType, baseTaskId } = formValues;
    const variant = hasPlan ? "purple" : "primary";

    const handleTaskTypeChange = ({ value }: OptionsSearch) => {
        onInputChange({ taskType: value, baseTaskId: "-1" });
        getBaseTasks({ taskType: value, isAppcc: hasPlan });
    };

    return (
        <MailModal
            title={
                hasPlan ? t(TranslationKeys.BASE_TASK_MODAL_REGISTRY_TITLE) : t(TranslationKeys.BASE_TASK_MODAL_TITLE)
            }
            variant={variant}
            onClose={onClose}
            confirmButton={{ text: t(TranslationCommon.ACCEPT), type: "submit", form: formId, variant }}
            closeButton={{ text: t(TranslationKeys.CLOSE), onClick: onClose, variant }}
            loading={isLoading}
        >
            <form onSubmit={onSubmit} className="baseTaskModal" id={formId}>
                <p className="baseTaskModal__description">
                    {hasPlan
                        ? t(TranslationModals.BASE_TASK_COMPLETE_ALL_REGISTRY)
                        : t(TranslationModals.BASE_TASK_COMPLETE_ALL_TASK)}
                </p>
                <SelectOptions
                    isMulti={false}
                    onChange={handleTaskTypeChange}
                    options={baseTaskTypeOptions}
                    selectedValue={taskType}
                    label={t(TranslationKeys.BASE_TASK_MODAL_IS_TASK_TYPE_LABEL)}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
                <div className="baseTaskModal__footer">
                    <SelectOptions
                        isMulti={false}
                        onChange={({ value }) => onInputChange({ baseTaskId: value })}
                        options={baseTasksOptions}
                        selectedValue={baseTaskId}
                        label={t(TranslationKeys.BASE_TASK_MODAL_TASKS_LABEL)}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                        errorMessage={errorMessages}
                    />
                    <AddNewButton
                        onClick={() =>
                            nav(
                                `/${
                                    hasPlan
                                        ? PrivatePaths.BASE_TASK_APPCC_MANAGEMENT
                                        : PrivatePaths.BASE_TASK_MANAGEMENT
                                }/new?prevPath=${pathname}`
                            )
                        }
                    >
                        {t(TranslationKeys.BASE_TASK_MODAL_CREATE_NEW)}
                    </AddNewButton>
                </div>
            </form>
        </MailModal>
    );
};
