import { BaseTaskTranslations, TranslationKeys } from "app/translation/translationKeys";
import {
    EditDataModel,
    DataTaskModel,
    CustomErrorTaskDataModel,
    EditTaskDisabledTaskDataModel,
} from "app/models/02-TAR/Task/EditTask";
import { FC } from "react";
import { Input, TextArea } from "app/components_v2/__inputs";
import { InputWithPopover } from "app/components_v2/__inputs/InputWithPopover/InputWithPopover";
import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { TaskDataInputPopoverChildren } from "../TaskDataInputPopoverChildren/TaskDataInputPopoverChildren";
import { useTaskData } from "./hooks/useTaskData";
import { useTranslation } from "react-i18next";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";

type TaskDataProps = EditDataModel<DataTaskModel, CustomErrorTaskDataModel, EditTaskDisabledTaskDataModel> & {
    hasPlanAPPCC?: boolean;
    showPlanAPPCC?: boolean;
    inputWithPopover?: boolean;
    isBaseTaskPopoverLoading?: boolean;
    onChangeInputValue?: (newValue: string) => void;
    baseTaskOptions?: string[];
    isBaseTaskPopoverVisible?: boolean;
    setIsBaseTaskPopoverVisible?: (isVisible: boolean) => void;
    onFocusInputValue?: () => void;
};

export const TaskData: FC<TaskDataProps> = ({
    onChange,
    taskDesc,
    taskTitle,
    customError,
    isDisabled,
    disabledFields,
    hasPlanAPPCC,
    fK_PlanAPPCC,
    showPlanAPPCC = true,
    inputWithPopover,
    isBaseTaskPopoverLoading,
    onChangeInputValue,
    baseTaskOptions,
    isBaseTaskPopoverVisible,
    setIsBaseTaskPopoverVisible,
    onFocusInputValue,
}) => {
    const { t } = useTranslation();
    const { planAPPCCOptions } = useTaskData(!!hasPlanAPPCC, showPlanAPPCC);

    return (
        <div className="taskData">
            <WhiteBoxCollapsable
                whiteBoxOptions={{ fullWidth: true }}
                collapsableOptions={{ title: t(TranslationKeys.TASK_DATA), border: "none" }}
            >
                <div className="editTask__column">
                    {inputWithPopover ? (
                        <InputWithPopover
                            disabled={isDisabled}
                            errorMessage={customError.taskTitle}
                            label={t(TranslationKeys.TASK_NAME_LABEL)}
                            onChange={(inputvalue) => onChangeInputValue && onChangeInputValue(inputvalue)}
                            placeholder={t(TranslationKeys.PLACEHOLDER_TASK_TITLE)}
                            readonly={!isDisabled && disabledFields?.disabledTaskTitle}
                            value={taskTitle}
                            isPopoverVisible={!!isBaseTaskPopoverVisible}
                            onClickOutside={() => setIsBaseTaskPopoverVisible && setIsBaseTaskPopoverVisible(false)}
                            onFocus={onFocusInputValue}
                        >
                            <TaskDataInputPopoverChildren
                                isAPPCC={!!hasPlanAPPCC}
                                isLoading={!!isBaseTaskPopoverLoading}
                                options={baseTaskOptions || []}
                            />
                        </InputWithPopover>
                    ) : (
                        <Input
                            disabled={isDisabled}
                            errorMessage={customError.taskTitle}
                            label={t(TranslationKeys.TASK_NAME_LABEL)}
                            onChange={(newValue) => onChange({ taskTitle: newValue })}
                            placeholder={t(TranslationKeys.PLACEHOLDER_TASK_TITLE)}
                            readonly={!isDisabled && disabledFields?.disabledTaskTitle}
                            value={taskTitle}
                        />
                    )}

                    <TextArea
                        placeholder={t(TranslationKeys.PLACEHOLDER_TASK_DESCRIPTION)}
                        text={taskDesc}
                        onChange={(newValue) => onChange({ taskDesc: newValue })}
                        label={`${t(TranslationKeys.TASK_DESCRIPTION)} (${t(TranslationKeys.INPUT_OPTIONAL)})`}
                        disabled={isDisabled}
                        readonly={!isDisabled && disabledFields?.disabledTaskDesc}
                    />
                    {hasPlanAPPCC && showPlanAPPCC && (
                        <ReadOnlyInput
                            value={planAPPCCOptions.find(({ value }) => value === fK_PlanAPPCC)?.label || ""}
                            label={t(BaseTaskTranslations.BASE_TASKS_PLAN_APPCC_LABEL)}
                            placeholder={t(t(TranslationKeys.SELECT_A_PLAN))}
                            type="select"
                        />
                    )}
                </div>
            </WhiteBoxCollapsable>
        </div>
    );
};
