import { FC } from "react";
import { SelectOptions } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { useStartAuditModal } from "./hooks/useStartAuditModal";
import { faPlay } from "@fortawesome/pro-regular-svg-icons";

type StartAuditModalProps = {
    onClose: () => void;
    onSubmit: (auditInstanceId: number, companyId: number) => void;
};

const formId = "Start_Audit_FormId";

export const StartAuditModal: FC<StartAuditModalProps> = ({ onClose, onSubmit }) => {
    const { t } = useTranslation();

    const {
        auditOptions,
        companyOptions,
        errorFormValues,
        formValues,
        handleCompanyChange,
        handleInputChange,
        handleSubmit,
        isSubmiting,
    } = useStartAuditModal(onSubmit);

    const { auditId, companyId } = formValues;
    const { errorAuditId, errorCompanyId } = errorFormValues;

    return (
        <GenericModal
            size="md"
            header={{
                title: t(TranslationKeys.START_AUDIT_MODAL_TITLE),
                onClose,
                variant: "purple",
            }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationKeys.START_AUDIT),
                        type: "submit",
                        form: formId,
                        iconRight: faPlay,
                        variant: "purple",
                    }}
                    closeButton={{ text: t(TranslationKeys.CLOSE), onClick: onClose, variant: "purple" }}
                    loading={isSubmiting}
                />
            }
        >
            <form onSubmit={handleSubmit} className="startAudit" id={formId}>
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => handleCompanyChange(value)}
                    options={companyOptions}
                    selectedValue={companyId}
                    label={t(TranslationKeys.START_AUDIT_MODAL_COMPANY_LABEL)}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    errorMessage={errorCompanyId}
                />
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => handleInputChange({ auditId: value })}
                    options={auditOptions}
                    selectedValue={auditId}
                    label={t(TranslationKeys.START_AUDIT_MODAL_AUDIT_LABEL)}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    errorMessage={errorAuditId}
                    disabled={companyId === "-1"}
                />
            </form>
        </GenericModal>
    );
};
