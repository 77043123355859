import { v4 } from "uuid";
import { BaseTaskFormModel } from "../EditBaseTask/models/EditBaseTaskFormModels";
import { BaseTaskCheckList } from "app/models/02-TAR/BaseTask/BaseTaskCheckListModel";
import { BaseTaskFieldOptionModel } from "app/models/02-TAR/BaseTask/BaseTaskFieldOptionModel";
import {
    checkListModel,
    DynamicFieldsModel,
    DynamicFieldsType,
    DynamicTaskFieldOption,
} from "app/models/02-TAR/TaskWizard";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { BaseTaskFieldModel } from "app/models/02-TAR/BaseTask/BaseTaskFieldModel";

export const mapBaseTaskToDataTaskModel = ({
    name,
    description,
    fotoExample,
    imageBase64Content,
    isPhotoRequired,
    isCritical,
    baseTaskCheckList,
    baseTaskFields,
    allowAnyData,
    reportType,
}: BaseTaskModel): BaseTaskFormModel => {
    return {
        taskTitle: name || "",
        taskDesc: description || "",
        belongsToAPPCC: false,
        fK_PlanAPPCC: "",
        DepartmentForTask: { label: "", value: "" },
        SubDepartementForTask: { label: "", value: "" },
        userForTask: { label: "", value: "" },
        criticalTask: isCritical,
        somethingToReportImage: fotoExample || "",
        imageBase64: imageBase64Content,
        isPhotoRequired,
        checkList: mapBaseTaskCheckListToCheckListModel(baseTaskCheckList),
        dynamicFields: mapBaseTaskFieldsToDynamicFields(baseTaskFields),
        reportData: [],
        reportType: reportType || "none",
        somethingToReportCheckBox: false,
        allowAnyData,
    };
};

const mapBaseTaskCheckListToCheckListModel = (checklist?: BaseTaskCheckList[]): checkListModel[] =>
    checklist?.map(({ id, isDeleted, name }) => ({
        id: v4(),
        isDeleted,
        name,
        dbId: id,
    })) || [];

export const mapCheckListToBaseCheckList = (
    checkList: checkListModel[],
    isDuplicate: boolean | undefined
): BaseTaskCheckList[] =>
    checkList.map<BaseTaskCheckList>(({ id, isDeleted, name, dbId }) => ({
        baseTaskId: 0,
        id: isDuplicate ? 0 : id ? dbId || 0 : 0,
        name: name,
        isDeleted,
    }));

export const mapBaseTaskFieldsToDynamicFields = (baseTaskFields?: BaseTaskFieldModel[]): DynamicFieldsModel[] =>
    baseTaskFields?.map<DynamicFieldsModel>(
        ({ id, label, dynamicFieldType, isDeleted, isRequired, baseTaskFieldOptions }) => ({
            id: v4(),
            dbId: id,
            label,
            dynamicFieldsType: dynamicFieldType as DynamicFieldsType,
            isDeleted,
            isRequired,
            taskFieldOptions:
                baseTaskFieldOptions?.map<DynamicTaskFieldOption>(({ label, id }) => ({
                    id: v4(),
                    dbId: id,
                    label,
                })) || [],
        })
    ) || [];

export const mapDynamicFieldsToBaseTaskFields = (
    dynamicFields: DynamicFieldsModel[],
    baseTaskId?: string
): BaseTaskFieldModel[] =>
    dynamicFields.map<BaseTaskFieldModel>(
        ({ dynamicFieldsType, isRequired, label, taskFieldOptions, isDeleted, dbId }) => ({
            fK_BaseTask: baseTaskId ? Number(baseTaskId) : 0,
            id: dbId || 0,
            dynamicFieldType: dynamicFieldsType || "DATE",
            isDeleted,
            isRequired: !!isRequired,
            label: label || "",
            baseTaskFieldOptions:
                taskFieldOptions?.map<BaseTaskFieldOptionModel>(({ label, dbId }) => ({
                    label: label,
                    id: 0,
                    fK_BaseTaskField: 0,
                })) || [],
        })
    );
