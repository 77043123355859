import { BreadCrumbs, BreadcrumbModel } from "app/components_v2/BreadCrumbs";
import { Header } from "app/components_v2/Header/Header";
import { useSession } from "app/hooks";
import { useScrollTop } from "app/hooks/global/useScrollTop";
import { AppSource } from "app/shared/Constants";
import { FC, ReactNode } from "react";
import { TabHeaderVariants } from "./types";

type TabHeaderProps = {
    children?: ReactNode;
    tabs?: ReactNode;
    showHeader?: boolean;
    customHeader?: ReactNode;
    removePaddingTop?: boolean;
    grayMode?: boolean;
    paddingSize?: "sm" | "md" | "xl";
    breadCrums?: BreadcrumbModel[];
    isBreadcrumbLoading?: boolean;
    fullWidth?: boolean;
    paddingTop36?: boolean;
    classNameToScrollTop?: string;
    flexColumn?: boolean;
    variant?: TabHeaderVariants;
};
export const TabHeader: FC<TabHeaderProps> = ({
    children,
    tabs,
    showHeader = true,
    customHeader,
    removePaddingTop,
    grayMode,
    paddingSize = "md",
    breadCrums,
    isBreadcrumbLoading,
    fullWidth,
    paddingTop36,
    classNameToScrollTop = ".customContainer",
    flexColumn,
    variant = "primary",
}) => {
    const headerColor = useScrollTop(10, classNameToScrollTop);
    const session = useSession();
    const isOfflineApp = session?.appSource === AppSource.OFFLINE;

    return (
        <div
            className={
                grayMode
                    ? `grayBlendMode grayBlendMode--${variant}`
                    : `tabHeader${!children ? "--noChildren" : ""} tabHeader${
                          removePaddingTop || isOfflineApp ? "--noPadding" : ""
                      } ${paddingTop36 ? "tabHeader--p36" : ""} tabHeader--${variant} `
            }
        >
            <div
                className={`tabHeader__breadcrums--${paddingSize} ${
                    fullWidth ? "tabHeader__breadcrums--fullWidth" : ""
                }`}
            >
                {breadCrums && <BreadCrumbs crumbs={breadCrums} isLoading={isBreadcrumbLoading} variant={variant} />}
            </div>
            {showHeader && !isOfflineApp && <Header variant={variant} isTransparent={true} headerColor={headerColor} />}
            {customHeader}
            {children && (
                <div
                    className={`tabHeader__body tabHeader__body${
                        fullWidth ? "--fullWidth" : ""
                    } tabHeader__body--${paddingSize} ${flexColumn ? "tabHeder__column" : ""}`}
                >
                    {children}
                </div>
            )}
            {tabs && <div className="tabHeader__tabs">{tabs}</div>}
        </div>
    );
};
