import { FC, useEffect, useState } from "react";
import { AlertListViewModel } from "../models/Alerts";
import { AlertNotificationMapper } from "app/mappers/AlertNotificationMapper";
import { AlertPillList } from "app/components_v2/__containers/AlertPillList/AlertPillList";
import { AlertNotificationsModal } from "app/components_v2/__modals/AlertNotificationsModal/AlertNotificationsModal";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";

export const SendedAlerts: FC<AlertListViewModel> = ({
    refresh,
    getNotifications,
    isLoading,
    list,
    onChangePageIndex,
    pageIndex,
    total,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<AlertNotificationDto>();

    const handleAlertClick = (id: number) => {
        setSelectedData(list.find((item) => item.id === id));
        setShowModal(true);
    };

    useEffect(() => {
        getNotifications("sended", 0);
    }, [refresh]);
    return (
        <>
            {showModal && selectedData && (
                <AlertNotificationsModal
                    data={AlertNotificationMapper([selectedData], true)}
                    closeModal={() => setShowModal(false)}
                    alreadyReaded
                />
            )}
            <AlertPillList
                data={list}
                isLoading={isLoading}
                total={total}
                onAlertClick={handleAlertClick}
                loadPage={(pi) => getNotifications("sended", pi)}
                onChangePageIndex={onChangePageIndex}
                pageIndex={pageIndex}
                isSended
            />
        </>
    );
};
