import { useEffect, useState } from "react";
import { useToast } from "app/hooks/Toast/useToast";
import { BaseTaskTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { SelectAssignedModel } from "app/models/02-TAR/TaskPage";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

type IuseGetUserDeptSub = {
    responsable: SelectAssignedModel;
    showSecondaryFilter: boolean;
    companyId: string;
};

export const useGetUserDeptSub = ({ responsable, showSecondaryFilter, companyId }: IuseGetUserDeptSub) => {
    const { handleToast } = useToast();
    const { t } = useTranslation();

    const [users, setUsers] = useState<OptionsSearch[]>([]);
    const [departments, setDepartments] = useState<OptionsSearch[]>([]);
    const [subdepartments, setSubdepartments] = useState<OptionsSearch[]>([]);
    const [workingPositions, setWorkingPositions] = useState<OptionsSearch[]>([]);
    const [baseTasks, setBaseTasks] = useState<OptionsSearch[]>([]);

    const getUsers = async () => {
        const sr = await SegSelectorService.GetUserNamesWithIds({
            extraParams: getExtraparams(),
        });
        if (!sr.status()) {
            handleToast({
                title: t(TranslationKeys.CANNOT_FETCH).replace("{0}", t(TranslationKeys.USERS)),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setUsers([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...sr.data]);
    };

    const getDeparmtents = async () => {
        const sr = await SegSelectorService.GetDepartmentNamesWithIds({
            extraParams: getExtraparams(),
        });
        if (!sr.status()) {
            handleToast({
                title: t(TranslationKeys.CANNOT_FETCH).replace("{0}", t(TranslationKeys.DEPARTMENTS)),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setDepartments([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...sr.data]);
    };

    const getSubdepartments = async () => {
        const sr = await SegSelectorService.GetSubDepartmentNamesWithIds({
            extraParams: getExtraparams(),
        });
        if (!sr.status()) {
            handleToast({
                title: t(TranslationKeys.CANNOT_FETCH).replace("{0}", t(TranslationKeys.SUBDEPARTMENTS)),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setSubdepartments([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...sr.data]);
    };

    const getWorkingPositions = async () => {
        const sr = await TarSelectorService.GetWorkingPostions({
            extraParams: getExtraparams(),
        });
        if (!sr.status()) {
            handleToast({
                title: t(TranslationKeys.CANNOT_FETCH).replace("{0}", t(TranslationKeys.WORKING_POSITION)),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setWorkingPositions([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...sr.data]);
    };

    const getBaseTasks = async () => {
        const extraParams = `IsDeleted=false`;
        const { data, status } = await TarSelectorService.GetBaseTasks(extraParams);
        if (!status()) {
            handleToast({
                title: t(TranslationKeys.CANNOT_FETCH).replace("{0}", t(BaseTaskTranslations.BASE_TASKS)),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setBaseTasks([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...data]);
    };

    const fetch = async () => {
        getBaseTasks();
        if (responsable === "user") {
            await getUsers();
            return;
        }
        if (responsable === "department") {
            await getDeparmtents();
            return;
        }
        if (responsable === "workingPosition") {
            await getWorkingPositions();
            await getDeparmtents();
            return;
        }
        await getSubdepartments();
    };

    const getExtraparams = () => {
        const extraParams = new URLSearchParams();
        if (companyId !== "-1") extraParams.append("CompanyId", companyId);
        return extraParams.toString();
    };

    useEffect(() => {
        if (!showSecondaryFilter) return;
        fetch();
    }, [showSecondaryFilter]);

    return { users, departments, subdepartments, workingPositions, baseTasks };
};
