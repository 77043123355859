import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { AssetStatusPostScanned } from "../types";
import { ConfirmModal } from "../../ConfirmModal/ConfirmModal";
import { FC } from "react";
import { FullScreenQrModal } from "../../FullScreenQrModal/FullScreenQrModal";
import { MailModal } from "../../base/MailModal/MailModal";
import { SensorLinkedModalBody } from "./SensorLinkedModalBody/SensorLinkedModalBody";
import { SensorLinkedModalFooter } from "./SensorLinkedModalFooter/SensorLinkedModalFooter";
import { SensorModalTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useSensorLinkedModal } from "./hooks/useSensorLinkedModal";
import { useTranslation } from "react-i18next";

type SensorLinkedModalProps = {
    asset: AssetModel2;
    onClose: () => void;
};

export const SensorLinkedModal: FC<SensorLinkedModalProps> = ({ asset, onClose }) => {
    const { t } = useTranslation();

    const titleReplaceModal: Record<AssetStatusPostScanned, string> = {
        LINKED: t(SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_TITLE),
        "NOT-LINKED": t(SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_TITLE),
    };

    const descriptionReplaceModal: Record<AssetStatusPostScanned, string> = {
        LINKED: t(SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_DESCRIPTION),
        "NOT-LINKED": t(SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_DESCRIPTION).replace(
            "{0}",
            asset.name
        ),
    };

    const confirmText: Record<AssetStatusPostScanned, string> = {
        LINKED: t(SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON),
        "NOT-LINKED": t(SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_CONFIRM_BUTTON),
    };

    const {
        assetStatusPostScanned,
        getDeleteModalDescription,
        handleDeactivate,
        handleScanSensorQr,
        isAssetDeleting,
        showConfirmModal,
        showDeactivateAssetModal,
        showReplaceModal,
        handleReplaceSensor,
        isAssetReplacingLoading,
        setShowDeactivateAssetModal,
        setShowReplaceModal,
        handleCloseReplaceScanModal,
    } = useSensorLinkedModal({ asset, onClose });

    const { sensorSerialNumber, name } = asset;

    return (
        <>
            <MailModal
                variant="purple"
                closeButton={{ text: t(TranslationCommon.CANCEL), onClick: onClose, variant: "purple" }}
                onClose={onClose}
                title={t(SensorModalTranslations.SENSOR_NOT_LINKED_TITLE).replace("{0}", sensorSerialNumber)}
            >
                <div className="sensorLinkedModal">
                    <SensorLinkedModalBody asset={asset} />
                    <SensorLinkedModalFooter
                        onClickDeactivate={() => setShowDeactivateAssetModal(true)}
                        onClickReplace={() => setShowReplaceModal(true)}
                    />
                </div>
            </MailModal>
            {showDeactivateAssetModal && (
                <ConfirmModal
                    onConfirm={handleDeactivate}
                    onConfirmText={t(TranslationKeys.EDIT_FORM_USER_DEACTIVATE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setShowDeactivateAssetModal(false)}
                    title={t(TranslationKeys.ASSET_MODAL_TITLE_DEACTIVATE).replace("{0}", name)}
                    description={getDeleteModalDescription()}
                    isLoading={isAssetDeleting}
                    variant="purple"
                />
            )}
            {showReplaceModal && (
                <FullScreenQrModal
                    onClose={() => setShowReplaceModal(false)}
                    onScanQr={handleScanSensorQr}
                    type="SENSOR"
                    title={t(SensorModalTranslations.SENSOR_QR_SCANNER_TITLE)}
                />
            )}
            {showConfirmModal && assetStatusPostScanned && (
                <ConfirmModal
                    title={titleReplaceModal[assetStatusPostScanned]}
                    description={descriptionReplaceModal[assetStatusPostScanned]}
                    onConfirm={handleReplaceSensor}
                    onConfirmText={confirmText[assetStatusPostScanned]}
                    onClose={handleCloseReplaceScanModal}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    variant="purple"
                    isLoading={isAssetReplacingLoading}
                />
            )}
        </>
    );
};
