import { FC } from "react";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { PrivatePaths } from "app/shared/Constants";
import { IssueTranslation } from "app/translation/translationKeys";

type IssueBodyGoToTaskProps = {
    taskId: number;
};

export const IssueBodyGoToTask: FC<IssueBodyGoToTaskProps> = ({ taskId }) => {
    const nav = useNavigate();
    const { t } = useTranslation();
    return (
        <NewButton
            text={t(IssueTranslation.ISSUE_BODY_GO_TO_TASK)}
            iconRight={faArrowRight}
            handleClickButton={() => nav(`/${PrivatePaths.DASHBOARD}/${taskId}`)}
            buttonType="secondary"
        />
    );
};
