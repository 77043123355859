import { FC } from "react";

type SpinnerProps = {
    variant?: "primary" | "purple" | "danger";
};

const Spinner: FC<SpinnerProps> = ({ variant = "primary" }) => {
    return (
        <div className="spinner" role="status">
            <div className={`spinner__icon spinner__icon--${variant}`}></div>
        </div>
    );
};

export default Spinner;
