import { faMicrophone, faMicrophoneSlash, faPause, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Button } from "../__buttons/Button/Button";
import { TranslationKeys } from "app/translation/translationKeys";
import { useAudioRecorder } from "./hooks/useAudioRecorder";

export type AudioRecorderProps = {
    disabled?: boolean;
    audioUrl?: string | null;
    setAudioUrl?: (setAudioUrl: string | null) => void;
    audioBase64: string | null;
    setAudioBase64: (audioBase64: string | null) => void;
    isRecording: boolean;
    setIsRecording: (isRecording: boolean) => void;
};

const AudioRecorder: React.FC<AudioRecorderProps> = ({
    audioUrl,
    setAudioUrl,
    audioBase64,
    setAudioBase64,
    disabled = false,
    isRecording,
    setIsRecording,
}) => {
    const { t } = useTranslation();
    const { startRecording, stopRecording, discardAudio } = useAudioRecorder({
        audioBase64,
        setAudioBase64,
        setAudioUrl,
        isRecording,
        setIsRecording,
    });

    return (
        <div className="audioRecorder">
            <div className="audioRecorder__container">
                {!isRecording && !audioBase64 && !audioUrl && (
                    <Button
                        onClick={startRecording}
                        iconRight={disabled ? faMicrophoneSlash : faMicrophone}
                        isDisabled={disabled}
                        size="xs"
                    />
                )}

                {isRecording && (
                    <>
                        <p className="audioRecorder__container__recording">{t(TranslationKeys.RECORDING)}</p>
                        <Button onClick={stopRecording} iconRight={faPause} size="xs" />
                    </>
                )}
            </div>

            <div className="audioRecorder__playContainer">
                {!isRecording && (audioBase64 || audioUrl) && (
                    <>
                        <audio
                            controls
                            src={audioBase64 ? audioBase64 : audioUrl ? audioUrl : undefined}
                            controlsList="nodownload"
                        />
                        <Button onClick={discardAudio} iconRight={faTimes} size="xs" />
                    </>
                )}
            </div>
        </div>
    );
};

export default AudioRecorder;
