import { useSession } from "./useSession";
import { useDispatch } from "react-redux";
import { Actions } from "app/state/actions";
import { useUserType } from "./useUserType";
import { setWorkingEntity } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";
import { ChangeCompanyModel } from "app/models/ChangeCompanyModel";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";

export const useChangeWorkingSession = () => {
    const session = useSession();
    const dispatch = useDispatch();
    const { isWorker } = useUserType();

    const changeWorkingCompanyByDepartmentId = (selectedDepartmentId: number) => {
        const companies = session?.user.companies;
        if (!companies) return;
        let selectedCompany: UserCompanyModel | undefined = undefined;
        let selectedDepartment: UsrDepartmentModel | undefined = undefined;

        companies.forEach((company) => {
            const department = company.departments?.find(({ departmentId }) => departmentId === selectedDepartmentId);
            if (department !== undefined) {
                selectedCompany = company;
                selectedDepartment = department;
            }
        });

        if (!selectedCompany || !selectedDepartment) return;
        const workingCompanies: ChangeCompanyModel = {
            workingCompany: selectedCompany,
            workingDepartment: isWorker ? selectedDepartment : undefined,
        };
        changeWorkingCompany(workingCompanies);
    };

    const changeWorkingCompanyByCompanyId = (selectedCompanyId: number) => {
        const companies = session?.user.companies;
        if (!companies || isWorker) return;
        const selectedCompany: UserCompanyModel | undefined = companies.find(
            ({ companyId }) => companyId === selectedCompanyId
        );
        if (!selectedCompany) return;

        const workingCompany: ChangeCompanyModel = {
            workingCompany: selectedCompany,
            workingDepartment: undefined,
        };
        changeWorkingCompany(workingCompany);
    };

    const changeWorkingCompany = (workingCompany: ChangeCompanyModel) => {
        dispatch(Actions.changeCompany(workingCompany));
        setWorkingEntity(workingCompany);
    };

    return { changeWorkingCompanyByDepartmentId, changeWorkingCompanyByCompanyId };
};
