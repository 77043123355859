import { FC } from "react";
import { DateRangePicker } from "app/components_v2/__inputs";
import { ReasignDatesModel } from "../types";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type ReasignDatesProps = ReasignDatesModel & {
    onChangeDates: (value: ReasignDatesModel) => void;
    errorStartDateMessage?: string;
    errorEndDateMessage?: string;
    onChangeErrorMessage?: () => void;
};

export const ReasignDates: FC<ReasignDatesProps> = ({
    endDate,
    errorStartDateMessage,
    errorEndDateMessage,
    hideEndDate,
    onChangeDates,
    onChangeErrorMessage,
    startDate,
}) => {
    const { t } = useTranslation();
    return (
        <div className="reasignDates">
            <DateRangePicker
                range={false}
                onChange={(date) => {
                    onChangeDates({ startDate: date, endDate: undefined });
                    onChangeErrorMessage && onChangeErrorMessage();
                }}
                startDate={startDate}
                placeholder={t(TranslationKeys.REASIGN_TASK_START_DATE_PLACEHOLDER)}
                errorMessage={errorStartDateMessage}
                label={t(TranslationKeys.REASIGN_TASK_START_DATE_LABEL)}
                canDelete
                handleDeleteDates={() => {
                    onChangeDates({ startDate: undefined, endDate: undefined });
                    onChangeErrorMessage && onChangeErrorMessage();
                }}
                min={new Date()}
            />
            {!hideEndDate && (
                <DateRangePicker
                    range={false}
                    onChange={(date) => onChangeDates({ startDate, endDate: date })}
                    startDate={endDate}
                    placeholder={t(TranslationKeys.REASIGN_TASK_END_DATE_PLACEHOLDER)}
                    min={startDate}
                    disabled={!startDate}
                    label={t(TranslationKeys.REASIGN_TASK_END_DATE_LABEL)}
                    canDelete
                    errorMessage={errorEndDateMessage}
                    handleDeleteDates={() => {
                        onChangeDates({ startDate: startDate, endDate: undefined });
                        onChangeErrorMessage && onChangeErrorMessage();
                    }}
                />
            )}
        </div>
    );
};
