import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useIssueAssetModalStep } from "./hooks/useIssueAssetModalStep";
import { TranslationKeys } from "app/translation/translationKeys";
import { IssueAssetsModel } from "../types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { OptionsOutsideSelect, TextArea } from "app/components_v2/__inputs";
import { IssueAssetStepTitle } from "../IssueAssetStepTitle/IssueAssetStepTitle";
import { DragFileWithLabel } from "app/components_v2/DragFileWithLabel/DragFileWithLabel";
import { IssueAssetValues } from "app/state/Providers/types";
import { IssueAssetStepHeader } from "../IssueAssetStepHeader/IssueAssetStepHeader";

export type IssueAssetModalStepProps = {
    onChange: (fieldUpdated: IssueAssetsModel) => void;
    item: IssueAssetsModel;
    originalItem: IssueAssetValues[];
    assetName: string;
    correctiveMeassuresOptions: OptionModel[];
    next: () => void;
    onFinish: () => void;
    isLastStep?: boolean;
    showCorrectiveMeassures: boolean;
};

export const IssueAssetModalStep: FC<IssueAssetModalStepProps> = ({
    item,
    onChange,
    originalItem,
    correctiveMeassuresOptions,
    next,
    onFinish,
    isLastStep,
    assetName,
    showCorrectiveMeassures,
}) => {
    const { t } = useTranslation();

    const {
        errorCorrectiveMeassures,
        errorDescription,
        handleAddCorrectiveMeassure,
        handleDeleteCorrectiveMeassures,
        handleInputChange,
        validate,
        corrMesRef,
        handleChangeDragFile,
        handleDeleteImage,
        handleEditImage,
    } = useIssueAssetModalStep({
        item,
        onChange,
        originalItem,
        correctiveMeassuresOptions,
        next,
        onFinish,
        isLastStep,
        assetName,
        showCorrectiveMeassures,
    });
    const { correctiveMeassures, description, attachments } = item;

    const issueTotalCount = originalItem[0].issueCount;
    return (
        <WizardForm validate={validate}>
            <div className="issueAssetModalStep__titles">
                <IssueAssetStepTitle>
                    <span className="issueAssetModalStep__titles--regular">
                        {t(TranslationKeys.ISSUE_ASSET_STEP_TITLE)}
                    </span>
                    {t(TranslationKeys.ISSUE_ASSET_STEP_TITLE_2).replace("{1}", String(issueTotalCount + 1))}.
                    <span className="issueAssetModalStep__titles--regular">
                        {t(TranslationKeys.ISSUE_ASSET_STEP_TITLE_3)}
                    </span>
                    {t(TranslationKeys.ISSUE_ASSET_STEP_TITLE_4).replace("{0}", assetName)}.
                </IssueAssetStepTitle>

                <ul className="issueAssetModalStep__titles__plural">
                    {originalItem.map((values, key) => (
                        <li key={key}>
                            <IssueAssetStepHeader values={values} />
                        </li>
                    ))}
                </ul>
            </div>
            <div className="issueAssetModalStep__info">
                <TextArea
                    label={t(TranslationKeys.PROBLEM_DETECTED_LABEL)}
                    text={description}
                    onChange={(newValue) => handleInputChange({ description: newValue })}
                    errorMessage={errorDescription}
                    placeholder={t(TranslationKeys.PROBLEM_DETECTED_PLACEHOLDER)}
                    maxLength={250}
                />
                <DragFileWithLabel
                    onChange={handleChangeDragFile}
                    onDelete={handleDeleteImage}
                    images={attachments}
                    onEditImage={handleEditImage}
                />
            </div>
            {showCorrectiveMeassures && (
                <div ref={corrMesRef}>
                    <OptionsOutsideSelect
                        onChange={handleAddCorrectiveMeassure}
                        onDeleteItem={handleDeleteCorrectiveMeassures}
                        options={correctiveMeassuresOptions}
                        values={correctiveMeassures}
                        label={t(TranslationKeys.CORRECTIVE_MEASSURE_LABEL)}
                        isSearchable
                        placeholder={t(TranslationKeys.CORRECTIVE_MEASSURE_PLACEHOLDER)}
                        tagOptions={{ clamp: 2, size: "auto" }}
                        errorMessage={errorCorrectiveMeassures}
                    />
                </div>
            )}
        </WizardForm>
    );
};
