import { FC } from "react";
import { useTranslation } from "react-i18next";

import { DayOfMonth } from "../StepFive/components/DayOfMonth";
import { DayOfYear } from "../StepFive/components/DayOfYear";
import { DaysOfWeek } from "../StepFive/components/DaysOfWeek";
import { TaskFinishedInfo } from "./components";

import { TaskTranslations, TranslationKeys } from "app/translation/translationKeys";
import { Step4 } from "app/models/02-TAR/TaskWizard";
import { TaskType } from "app/models/02-TAR/Task/TaskBody";

export const StepFourInfo: FC<Step4> = ({ finish, recurringTask, taskHours, taskStart, taskType }) => {
    const { t } = useTranslation();
    const isTaskNormal = taskType === "NORMAL";
    const taskTypeTitle: Record<TaskType, string> = {
        SPORADIC: t(TaskTranslations.TASK_TYPE_SPORADIC),
        NORMAL: t(TaskTranslations.TASK_TYPE_NORMAL),
    };

    return (
        <div className="taskStepFourInfo">
            <div className="stepFivePlaceHolderInput">
                <span className="stepFiveLabel">{t(TranslationKeys.TASK_TEMPORALITY)}</span>

                <TaskFinishedInfo finish={finish} taskStart={taskStart} taskType={taskType} />
            </div>

            <div className="stepFivePlaceHolderInput">
                <span className="stepFiveLabel">{t(TaskTranslations.TASK_TYPE_TITLE)}</span>
                <p className="stepFivePlaceHolderInput">{taskTypeTitle[taskType]}</p>
            </div>

            {isTaskNormal && (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.IN_THE_HOUSR)}</span>
                    <div className="stepFivePlaceHolderInput">
                        <ul className="stepFivePlaceHolderInput__list">
                            {taskHours.map(({ hour, id, maxHour, type }) =>
                                type === "hour" ? (
                                    <li className="stepFivePlaceHolderInput" key={id}>
                                        {hour}
                                    </li>
                                ) : (
                                    <li className="stepFivePlaceHolderInput" key={id}>
                                        <span>{hour}</span> - <span>{maxHour}</span>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            )}

            {recurringTask && recurringTask.isRecurrent && isTaskNormal && (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.TASK_REPEAT)}</span>
                    <div className="stepFivePlaceHolderInput">
                        {recurringTask.value !== "custom" && recurringTask.text}

                        {recurringTask.value === "custom" &&
                            recurringTask.custom.customValue === "day" &&
                            `${t(TranslationKeys.EVERY)} ${recurringTask.custom.repeatEvery} ${t(
                                recurringTask.custom.repeatEvery > 1 ? TranslationKeys.DAYS : TranslationKeys.DAY
                            )}`}

                        {recurringTask.value === "custom" && recurringTask.custom.customValue === "week" && (
                            <DaysOfWeek recurringTask={recurringTask} repeatEvery={recurringTask.custom.repeatEvery} />
                        )}

                        {recurringTask.value === "custom" && recurringTask.custom.customValue === "everyMonth" && (
                            <DayOfMonth
                                text={recurringTask.custom.selectedOptions.text}
                                repeatEvery={recurringTask.custom.repeatEvery}
                            />
                        )}

                        {recurringTask.value === "custom" && recurringTask.custom.customValue === "everyYear" && (
                            <DayOfYear
                                text={recurringTask.custom.selectedOptions.text}
                                repeatEvery={recurringTask.custom.repeatEvery}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
