import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { FC, useState } from "react";
import { FrequencyTypes, TASK_TYPE } from "app/shared/Constants";
import { isEqual } from "lodash";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { SelectAssignedModel } from "app/models/02-TAR/TaskPage";
import { SelectOptions, TimeRangePicker } from "app/components_v2/__inputs";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { TaskGridSecondaryFilterModel } from "app/pages/02-TAR/07-TAR-CRUD/models/taskGridSecondaryFilterModel";
import { TypeCodeTask } from "app/models/02-TAR/Task/TaskBody";
import { useGetUserDeptSub } from "./hooks/useGetUserDeptSub";
import { useTranslation } from "react-i18next";
import {
    TaskTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
} from "app/translation/translationKeys";

type TaskFilterProps = {
    filterData: TaskGridSecondaryFilterModel;
    initialFilterData: TaskGridSecondaryFilterModel;
    showSecondaryFilter: boolean;
    companyId: string;
    variant?: TabHeaderVariants;
    onCancel?: () => void;
    onSave?: (values: TaskGridSecondaryFilterModel) => void;
};

export const TaskFilter: FC<TaskFilterProps> = ({
    filterData,
    initialFilterData,
    showSecondaryFilter,
    companyId,
    variant = "primary",
    onCancel,
    onSave,
}) => {
    const { t } = useTranslation();

    const [internalFilterData, setInternalFilterData] = useState(filterData);
    const [timeErrorMessage, setTimeErrorMessage] = useState<string>();

    const { responsable, workingPositionDepartments } = internalFilterData;

    const { departments, subdepartments, users, workingPositions, baseTasks } = useGetUserDeptSub({
        responsable: responsable.assignedTo,
        showSecondaryFilter,
        companyId,
    });

    const handleInputChange = (values: Partial<TaskGridSecondaryFilterModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const onReset = () => {
        onTimeUpdate("", "");
        handleInputChange(initialFilterData);
    };

    const handleLeaveFilter = () => {
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    const onTimeUpdate = (startTime: string, endTime: string) => {
        setTimeErrorMessage(undefined);
        handleInputChange({ fromHour: startTime, toHour: endTime });
    };

    const frequencyOptions: OptionsSearch[] = [
        { label: t(TranslationCommon.SELECT_OPTION), value: String(-1) },
        { label: t(TranslationKeys.ONE_TIME), value: FrequencyTypes.ONE_TIME },
        { label: t(TranslationKeys.DAILY), value: FrequencyTypes.DAILY },
        { label: t(TranslationKeys.WEEKLY), value: FrequencyTypes.WEEKLY },
        { label: t(TranslationKeys.MONTHLY), value: FrequencyTypes.MONTHLY },
        { label: t(TranslationKeys.ANNUALY), value: FrequencyTypes.ANNUALY },
    ];

    const taskTypeOptions: OptionsSearch[] = [
        { label: t(TranslationCommon.SELECT_OPTION), value: "-1" },
        { label: t(TaskTranslations.TASK_TYPE_NORMAL), value: TASK_TYPE.NORMAL },
        { label: t(TaskTranslations.TASK_TYPE_SPORADIC), value: TASK_TYPE.SPORADIC },
    ];

    const responsibleLabel: Record<SelectAssignedModel, string> = {
        user: t(TranslationKeys.TASK_FILTER_USER),
        department: t(TranslationKeys.TASK_FILTER_DEPARTMENT),
        subDepartment: t(TranslationKeys.TASK_FILTER_SUBDEPARTMENT),
        workingPosition: t(TranslationKeys.TASK_FILTER_WORKING_POSITION),
        all: "",
    };

    const responsibleOptions: Record<SelectAssignedModel, OptionsSearch[]> = {
        user: users,
        department: departments,
        workingPosition: workingPositions,
        subDepartment: subdepartments,
        all: [],
    };

    const onChangeFrequency = ({ value }: OptionsSearch) =>
        handleInputChange({
            frequency: value !== String(-1) ? (value as TypeCodeTask) : undefined,
        });

    const validTime = () => {
        let errorMessage = undefined;

        if (internalFilterData.fromHour && !internalFilterData.toHour)
            errorMessage = t(TranslationErrors.END_TIME_NULL_OR_EMPTY);
        if (
            internalFilterData.fromHour &&
            internalFilterData.toHour &&
            internalFilterData.fromHour > internalFilterData.toHour
        )
            errorMessage = t(TranslationErrors.TIME_RANGE_ERROR);

        setTimeErrorMessage(errorMessage);
        return errorMessage ? true : false;
    };

    return (
        <SecondaryFilter
            isOpen={showSecondaryFilter}
            onClickOutside={handleLeaveFilter}
            onCancel={handleLeaveFilter}
            onReset={onReset}
            onSave={() => !validTime() && onSave && onSave(internalFilterData)}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
            variant={variant}
        >
            {responsable.assignedTo !== "all" && (
                <SelectOptions
                    label={responsibleLabel[responsable.assignedTo]}
                    options={responsibleOptions[responsable.assignedTo]}
                    selectedValue={responsable.value === "-1" ? undefined : responsable.value}
                    onChange={({ label, value }) =>
                        handleInputChange({
                            responsable: { assignedTo: responsable.assignedTo, text: label, value },
                        })
                    }
                    isSearchable
                    isMulti={false}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            )}
            {responsable.assignedTo === "workingPosition" && (
                <SelectOptions
                    label={t(TranslationKeys.TASK_FILTER_DEPARTMENT)}
                    options={departments}
                    selectedValue={
                        workingPositionDepartments.value === "-1" ? undefined : workingPositionDepartments.value
                    }
                    onChange={({ label, value }) =>
                        handleInputChange({
                            workingPositionDepartments: { text: label, value },
                        })
                    }
                    isSearchable
                    isMulti={false}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            )}
            <SelectOptions
                label={t(TranslationKeys.BASE_TASK_LABEL)}
                options={baseTasks}
                selectedValue={internalFilterData.baseTask !== "-1" ? internalFilterData.baseTask : undefined}
                onChange={({ value }) => handleInputChange({ baseTask: value })}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                isSearchable
                isMulti={false}
            />
            <SelectOptions
                label={t(TranslationKeys.FREQUENCY)}
                options={frequencyOptions}
                selectedValue={internalFilterData.frequency}
                onChange={onChangeFrequency}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                isSearchable
                isMulti={false}
            />
            <SelectOptions
                label={t(TaskTranslations.TASK_TYPE_TITLE)}
                options={taskTypeOptions}
                selectedValue={internalFilterData.taskType !== "-1" ? internalFilterData.taskType : undefined}
                onChange={({ value }) => handleInputChange({ taskType: value })}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                isSearchable
                isMulti={false}
            />

            <TimeRangePicker
                labelStart={t(TranslationCommon.TIME_FROM)}
                labelEnd={t(TranslationCommon.TIME_TO)}
                startTime={internalFilterData.fromHour ? internalFilterData.fromHour : ""}
                endTime={internalFilterData.toHour ? internalFilterData.toHour : ""}
                onTimeUpdate={onTimeUpdate}
                reset={onReset}
                errorMessage={timeErrorMessage}
            />
            <div className="dashboardFilter__checkboxes">
                <div className="dashboardFilter__checkboxes__container">
                    <CheckBox
                        label={t(TranslationKeys.PIC_ASSOCIATED_TASK)}
                        htmlFor={TranslationKeys.PIC_ASSOCIATED_TASK}
                        onChange={(checked) => handleInputChange({ isPhotoRequired: checked })}
                        checked={internalFilterData.isPhotoRequired}
                    />
                    <CheckBox
                        label={t(TranslationKeys.REPORTED_DATA_TASK)}
                        htmlFor={TranslationKeys.REPORTED_DATA_TASK}
                        onChange={(checked) => handleInputChange({ isDataRequired: checked })}
                        checked={internalFilterData.isDataRequired}
                    />
                    <CheckBox
                        label={t(TranslationKeys.CRITICAL_CHECKBOXES)}
                        htmlFor={TranslationKeys.CRITICAL_CHECKBOXES}
                        onChange={(checked) => handleInputChange({ isCritical: checked })}
                        checked={internalFilterData.isCritical}
                    />
                </div>
            </div>
        </SecondaryFilter>
    );
};
