import { Input, SelectOptions } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useToast } from "app/hooks/Toast/useToast";
import { CorrectiveMeasureInsertModel } from "app/models/05-QUA/CorrectiveMeasureModels/CorrectiveMeasureModel";
import { ActionType, OptionsSearch } from "app/models/FormComponentsModel";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import CorrectiveMeasureService from "app/services/05-QUA/CorrectiveMeasureService";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { INITIAL_CORR_MESS } from "../../../constants/constants";
import { CorrMessFormId, INTIAL_ERROR_MESSAGE_CORR_MESS } from "./Constants/CorrectiveMeasureModalConstants";
import { CorrMessErrorMessageModel } from "./types";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";

type CorrectiveMeasureModalProps = {
    type: ActionType;
    value: CorrectiveMeasureInsertModel;
    onSave: (value: CorrectiveMeasureInsertModel, type: ActionType) => void;
    onClose: () => void;
};

export const CorrectiveMeasureModal: FC<CorrectiveMeasureModalProps> = ({ type, value, onSave, onClose }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [correctiveMeasure, setCorrectiveMeasure] = useState<CorrectiveMeasureInsertModel>(
        value || INITIAL_CORR_MESS
    );
    const [errorMessage, setErrorMessage] = useState<CorrMessErrorMessageModel>(INTIAL_ERROR_MESSAGE_CORR_MESS);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [planOptions, setPlanOptions] = useState<OptionsSearch[]>([]);

    const { description, fK_PlanAPPCC } = correctiveMeasure;
    const title: Record<ActionType, string> = {
        create: t(TranslationKeys.CORRECTIVE_MEASSURE_MODAL_TITLE_CREATE),
        edit: t(TranslationKeys.CORRECTIVE_MEASSURE_MODAL_TITLE_EDIT),
    };
    const actionButton: Record<ActionType, string> = {
        create: t(TranslationCommon.CREATE),
        edit: t(TranslationCommon.SAVE),
    };

    const handleInputChange = (newValue: Partial<CorrectiveMeasureInsertModel>) =>
        setCorrectiveMeasure((prev) => ({ ...prev, ...newValue }));

    const handleErrorMessageChange = (newError: Partial<CorrMessErrorMessageModel>) =>
        setErrorMessage((prev) => ({ ...prev, ...newError }));

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        setIsSubmitting(true);

        if (type === "edit") {
            await handleEdit();
            return;
        }

        await handleCreate();
    };

    const handleEdit = async () => {
        const { status } = await CorrectiveMeasureService.Edit(correctiveMeasure);

        if (!status()) {
            handleToast({ title: t(TranslationModals.PLANS_FAILED_EDIT), variant: "danger", type: "alert" });
            setIsSubmitting(false);
            return;
        }

        onSave(correctiveMeasure, "edit");
    };

    const handleCreate = async () => {
        const { status } = await CorrectiveMeasureService.Save(correctiveMeasure);

        if (!status()) {
            handleToast({ title: t(TranslationModals.PLANS_FAILED_CREATE), variant: "danger", type: "alert" });
            setIsSubmitting(false);
            return;
        }

        onSave(correctiveMeasure, "create");
    };

    const validate = () => {
        let isValid = true;
        setErrorMessage(INTIAL_ERROR_MESSAGE_CORR_MESS);
        if (!description.length) {
            handleErrorMessageChange({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isValid = false;
        }
        if (!fK_PlanAPPCC) {
            handleErrorMessageChange({ errorPlan: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isValid = false;
        }

        return isValid;
    };

    const getPlanOptions = async () => {
        setIsLoading(true);
        const { data, status } = await QuaSelectorService.GetPlansAPPCC();
        if (!status()) {
            setPlanOptions([]);
            setIsLoading(false);
            return;
        }

        setPlanOptions(data);
        setIsLoading(false);
    };

    useEffect(() => {
        getPlanOptions();
    }, []);

    return (
        <GenericModal
            header={{ title: title[type], onClose: onClose, variant: "purple" }}
            portal
            size="sm"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        type: "submit",
                        text: actionButton[type],
                        fullWidth: true,
                        form: CorrMessFormId,
                    }}
                    closeButton={{
                        onClick: onClose,
                        text: t(TranslationCommon.CANCEL),
                        fullWidth: true,
                        buttonType: "tertiary",
                    }}
                    buttonsJustification="end"
                    loading={isSubmitting}
                />
            }
        >
            <form className="correctiveMeasureModal" onSubmit={handleSubmit} id={CorrMessFormId}>
                <Input
                    onChange={(newValue) => handleInputChange({ description: newValue })}
                    value={description}
                    label={t(TranslationKeys.CORRECTIVE_MEASSURE_MODAL_LABEL)}
                    placeholder={t(TranslationKeys.CORRECTIVE_MEASSURE_MODAL_PLACEHOLDER)}
                    errorMessage={errorMessage.errorName}
                    focus
                />
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => handleInputChange({ fK_PlanAPPCC: Number(value) })}
                    options={planOptions}
                    label={t(TranslationKeys.EDIT_FORM_PLAN_NAME_LABEL)}
                    placeholder={t(TranslationKeys.EDIT_FORM_PLAN_NAME_PLACEHOLDER)}
                    errorMessage={errorMessage.errorPlan}
                    selectedValue={String(fK_PlanAPPCC)}
                    isLoading={isLoading}
                />
            </form>
        </GenericModal>
    );
};
