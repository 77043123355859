import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { BaseTaskFormModel, BaseTaskValidationModel } from "../models/EditBaseTaskFormModels";
import { OptionsSearch } from "app/models/FormComponentsModel";

export const INITIAL_BASE_TASK_VALUES: BaseTaskModel = {
    id: 0,
    name: "",
    description: "",
    customerInstanceId: 0,
    fK_BaseTaskType: 0,
    type: "",
    isPhotoRequired: false,
    allowAnyData: false,
    reportType: "NONE",
    isCritical: false,
};
export const INITIAL_DATA_TASK_MODEL: BaseTaskFormModel = {
    taskTitle: "",
    taskDesc: "",
    belongsToAPPCC: false,
    fK_PlanAPPCC: "",
    DepartmentForTask: { label: "", value: "" },
    SubDepartementForTask: { label: "", value: "" },
    userForTask: { label: "", value: "" },
    isPhotoRequired: false,
    somethingToReportImage: "",
    criticalTask: false,
    checkList: [],
    dynamicFields: [],
    reportData: [],
    reportType: "NONE",
    allowAnyData: false,

    // Unused
    somethingToReportCheckBox: false,
};
export const INITIAL_BASE_TASK_VALIDATIONS: BaseTaskValidationModel = {
    taskTitle: "",
    baseTaskType: "",
    planAPPCC: "",
    dynamicFields: [],
    reportData: "",
    checklist: [],

    // Unused
    companyForTask: "",
};

export const EMPTY_BASE_TASK_TYPE_OPTION: OptionsSearch = { label: "", value: "" };
