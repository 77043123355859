import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import { DepartmentService } from "app/services";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import SubdepartmentsGrid from "./Department/SubdepartmentGrid";

type DepartmentGroupCollapseProps = {
    model: DepartmentModel;
    afterDelete: () => void;
    showOpened: boolean;
    companyId: number;
    handleReloadData: () => Promise<void>;
};

const DepartmentGroupCollapse: FC<DepartmentGroupCollapseProps> = ({
    model,
    afterDelete,
    handleReloadData,
    showOpened,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const { handleToast } = useToast();

    const [department, setDepartment] = useState<DepartmentModel>(model);

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const fetch = async () => {
        const instanceId = session?.user.customerInstanceId;
        const sr = await DepartmentService.GetOne(model.id, instanceId || 0);
        if (sr.status()) setDepartment(sr.data);
        else
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
    };

    const onDelete = async (id: number) => {
        setIsDeleting(true);
        const delSr = await DepartmentService.Delete(id);

        if (delSr.status()) {
            handleToast({
                title: t(TranslationModals.SUCCESS_DELETE),
                type: "alert",
                variant: "success",
            });
            afterDelete();
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
        }
        setIsDeleting(false);
    };

    const confirmDelete = () => {
        setShowConfirmModal(true);
    };

    const getDeleteModalDescription = (): string => {
        return department.isRelatedWithTasks
            ? t(TranslationKeys.EDIT_COMPANY_DELETE_DEPARTMENT)
            : t(TranslationKeys.EDIT_COMPANY_DELETE_DEPARTMENT_NO_RELATED);
    };

    return (
        <>
            {showConfirmModal && (
                <ConfirmModal
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={() => onDelete(department.id)}
                    description={getDeleteModalDescription()}
                    title={`${t(TranslationKeys.DELETE_MESSAGE)} ${department.name}`}
                    isLoading={isDeleting}
                />
            )}
            <SubdepartmentsGrid
                onEdit={fetch}
                onDelete={confirmDelete}
                department={department}
                handleReloadData={handleReloadData}
                showOpened={showOpened}
            />
        </>
    );
};

export default DepartmentGroupCollapse;
