import { faPlus, faUsers } from "@fortawesome/pro-regular-svg-icons";
import WentWrong from "app/components_v2/Errors/WentWrong";
import Spinner from "app/components_v2/Spinner/Spinner";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { useTitle } from "app/hooks/CustomHooks";
import { useToast } from "app/hooks/Toast/useToast";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import { ComponentStatus } from "app/models/FormComponentsModel";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { DepartmentService } from "app/services";
import { SecScreen } from "app/shared/Constants";
import { TranslationKeys, TranslationModals, TranslationTitles } from "app/translation/translationKeys";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DepartmentGroupCollapse from "../DepartmentGroupCollapse";
import { AddDepartmentModal } from "./AddDepartmentModal";

type DepartmentsProps = {
    companyId: number;
};

const Departments: FC<DepartmentsProps> = ({ companyId }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [departments, setDepartments] = useState<DepartmentModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(true);
    const [showAddDepartmentModal, setShowAddDepartmentModal] = useState<boolean>(false);

    useTitle(t(TranslationTitles.DEPARTMENT_AND_SUBDEPARTMENT_PAGE_TITLE));

    const fetch = async () => {
        setLoading(true);
        const sr = await DepartmentService.GetData({
            extraParams: `companyId=${companyId}`,
        });
        if (sr.status()) {
            setDepartments(sr.data.list);
            setStatus("complete");
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setStatus("errored");
        }
        setRefresh(false);
        setLoading(false);
    };
    useEffect(() => {
        if (refresh) fetch();
    }, [companyId, refresh]);

    const handleAddDepartment = async (DeptName: string) => {
        setLoading(true);
        const body: DepartmentModel = {
            companyId: companyId,
            isActive: true,
            name: DeptName,
            usersCount: 0,
            isDeletable: true,
            id: 0,
        };
        await DepartmentService.Save(body);
        setShowAddDepartmentModal(false);
        setLoading(false);
        fetch();
    };

    if (status === "loading")
        return (
            <div className="companiesDepartment__spinner">
                <Spinner />
            </div>
        );
    if (status === "errored") return <WentWrong errorType="accessDenied" />;

    return (
        <div className="companiesDepartment">
            {loading && <Spinner />}

            {!loading && (
                <div className="departmentsContainer">
                    {hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT) &&
                        hasPermissionToAdd(SecScreen.TEAM_MANAGEMENT) && (
                            <NewButton
                                iconLeft={faPlus}
                                text={t(TranslationKeys.NEW_DEPARTMENT)}
                                handleClickButton={() => setShowAddDepartmentModal(true)}
                            />
                        )}

                    {showAddDepartmentModal && (
                        <AddDepartmentModal
                            onClose={() => setShowAddDepartmentModal(false)}
                            onSubmit={handleAddDepartment}
                        />
                    )}
                </div>
            )}
            {!loading && !departments.length && (
                <div className="departmentsContainer__noResults">
                    <TableError icon={faUsers} title={t(TranslationKeys.NO_DEPARTMENTS)} size="xl" />
                </div>
            )}
            {!loading &&
                departments.length > 0 &&
                departments.map((department) => (
                    <DepartmentGroupCollapse
                        model={department}
                        key={department.id}
                        afterDelete={() => setRefresh(true)}
                        showOpened={false}
                        companyId={companyId}
                        handleReloadData={fetch}
                    />
                ))}
        </div>
    );
};

export default Departments;
