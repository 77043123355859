import { forwardRef } from "react";

import { createPortal } from "react-dom";
import useEscapeToClose from "app/hooks/useEscapeToClose";
import { GenericModalFooter, ModalFooterButtonProps } from "../GenericModal/GenericModalFooter/GenericModalFooter";
import { GenericModalHeader } from "../GenericModal/GenericModalHeader/GenericModalHeader";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type MalModalProps = {
    title: string;
    children: React.ReactNode;
    portal?: boolean;
    onClose: () => void;
    loading?: boolean;
    confirmButton?: ModalFooterButtonProps;
    closeButton?: ModalFooterButtonProps;
    variant?: TabHeaderVariants;
    showBlur?: boolean;
};

export const MailModal = forwardRef<HTMLInputElement, MalModalProps>(
    (
        {
            children,
            onClose,
            title,
            portal = true,
            confirmButton,
            loading,
            closeButton,
            variant = "primary",
            showBlur = true,
        },
        ref
    ) => {
        useEscapeToClose(onClose);
        const ModalContent = (
            <div className={`mailModal ${showBlur ? "mailModal--showBlur" : ""}`} ref={ref} role="dialog">
                <div className="mailModal__container">
                    <GenericModalHeader title={title} onClose={onClose} radius variant={variant} />
                    <div className="mailModal__body">
                        <div className="mailModal__body__children">{children}</div>
                        <GenericModalFooter confirmButton={confirmButton} closeButton={closeButton} loading={loading} />
                    </div>
                </div>
            </div>
        );

        return <>{portal ? createPortal(ModalContent, document.body) : ModalContent}</>;
    }
);
