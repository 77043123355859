import { useToast } from "app/hooks/Toast/useToast";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { BaseTaskModelPaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { EditTaskDisabledFieldsModel } from "app/models/02-TAR/Task/EditTask";
import { AllSteps } from "app/models/02-TAR/TaskWizard";
import { OptionsSearch } from "app/models/FormComponentsModel";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import BaseTaskService from "app/services/02-TAR/BaseTaskService";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { TranslationCommon, TranslationErrors } from "app/translation/translationKeys";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { baseTaskToAllSteps } from "../helpers/baseTaskToAllSteps";
import { baseTaskToDisableds } from "../helpers/baseTaskToDisableds";
import { fillExtraparams } from "../helpers/fillExtraparams";
import { BaseTaskModalModel } from "../types";
import { useSearchParams } from "react-router-dom";

const INITIAL_BASE_TASK_VALUES: BaseTaskModalModel = {
    baseTaskId: "-1",
    taskType: "-1",
};

export const useBaseTaskModal = (
    task: AllSteps,
    onSave: (task: AllSteps, disableds: EditTaskDisabledFieldsModel, baseTask: BaseTaskModel) => void,
    hasPlan: boolean
) => {
    const { handleToast } = useToast();
    const { t } = useTranslation();

    const [urlParams] = useSearchParams();
    const baseTaskIdParams = urlParams.get("baseTaskId") || INITIAL_BASE_TASK_VALUES.baseTaskId;
    const [formValues, setFormValues] = useState<BaseTaskModalModel>({
        ...INITIAL_BASE_TASK_VALUES,
        baseTaskId: baseTaskIdParams,
    });
    const [baseTaskTypeOptions, setBaseTaskTypeOptions] = useState<OptionsSearch[]>([]);
    const [baseTasksOptions, setBaseTasksOptions] = useState<OptionsSearch[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isBaseTasksLoading, setIsBaseTasksLoading] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string>("");

    const { baseTaskId } = formValues;

    const onInputChange = (values: Partial<BaseTaskModalModel>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const getData = async () => {
        setIsLoading(true);
        const extraparams = fillExtraparams({ isAppcc: hasPlan });
        const baseTaskTypePromise = TarSelectorService.GetBaseTaskTypes();
        const baseTasksPromise = TarSelectorService.GetBaseTasks(extraparams);

        Promise.all([baseTaskTypePromise, baseTasksPromise])
            .then(([baseTaskTypeSr, baseTasksSr]) => {
                baseTaskTypeResponseManager(baseTaskTypeSr);
                baseTasksResponseManager(baseTasksSr);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const baseTaskTypeResponseManager = ({ status, data }: ServiceResponse<OptionsSearch[]>) => {
        if (!status()) {
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED) });
            setBaseTaskTypeOptions([]);
            setIsLoading(false);
            return;
        }

        setBaseTaskTypeOptions([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...data]);
    };

    const baseTasksResponseManager = ({ status, data }: ServiceResponse<OptionsSearch[]>) => {
        if (!status()) {
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED) });
            setBaseTasksOptions([]);
            setIsLoading(false);
            return;
        }

        setBaseTasksOptions([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...data]);
    };

    const getBaseTasks = async (params: Partial<BaseTaskModelPaginationParams>) => {
        setIsBaseTasksLoading(true);
        const extraparams = fillExtraparams(params);
        const baseTasksPromise = await TarSelectorService.GetBaseTasks(extraparams);
        baseTasksResponseManager(baseTasksPromise);
        setIsBaseTasksLoading(false);
    };

    const validate = (): boolean => {
        setErrorMessages("");
        if (!baseTaskId.length || baseTaskId === "-1") {
            setErrorMessages(t(TranslationCommon.INPUT_NOT_EMPTY));
            return false;
        }
        return true;
    };

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        setIsLoading(true);

        const { data, status } = await BaseTaskService.GetOne(Number(baseTaskId));

        if (!status()) {
            handleToast({ title: t(TranslationErrors.BASE_TASK_NOT_FOUND), variant: "danger", type: "alert" });
            setIsLoading(false);
            return;
        }
        const taskMapped = baseTaskToAllSteps(task, data);
        const disableds = baseTaskToDisableds(data);
        onSave(taskMapped, disableds, data);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        formValues,
        onInputChange,
        baseTaskTypeOptions,
        isLoading,
        baseTasksOptions,
        getBaseTasks,
        isBaseTasksLoading,
        onSubmit,
        errorMessages,
    };
};
