import { PrivatePaths, PublicPaths, SecScreen, Views } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSession } from "app/hooks";
import AcceptPolicyPage from "app/pages/02-TAR/13-TAR-AcceptPolicy/AcceptPolicyPage";
import LicensePage from "../Licenses/LicensesPage";
import { OnBoardingSteps } from "./OnBoardingSteps/OnBoardingSteps";
import GroupCompanyPage from "../Companies/CompaniesPage";
import PrivateRoute from "app/routes/PrivateRoute";
import RolePage from "../Role/RolePage";
import UsersPage from "../Users/UsersPage";
import { PlanAPPCCPage } from "app/pages/05-QUA/PlanAPPCC/PlanAPPCCPage";
import { CorrectiveMeasurePage } from "app/pages/05-QUA/CorrectiveMeasure/CorrectiveMeasurePage";
import { AssetPage } from "app/pages/05-QUA/Asset/AssetPage";
import { BaseTaskPage } from "app/pages/02-TAR/15-BaseTask/BaseTaskPage";
import { WorkingPositionPage } from "app/pages/02-TAR/14-WorkingPosition/WorkingPositionPage";
import TaskPage from "app/pages/02-TAR/07-TAR-CRUD/TaskPage";
import { TaskAPPCCPage } from "app/pages/05-QUA/TaskAPPCC/TaskAPPCCPage";
import { BaseAuditGroupCheckListItemPage } from "app/pages/05-QUA/BaseAuditGroupCheckListItem/BaseAuditGroupCheckListItemPage";
import { AuditTemplatePage } from "app/pages/05-QUA/AuditTemplate/AuditTemplatePage";
import { SubStepRoutes } from "./types";
import { ResetPasswordPage } from "app/pages/00-LOGIN/ResetPassword/ResetPasswordPage";
import MyProfilePage from "../MyProfile/MyProfilePage";

export const OnBoardingPage = () => {
    const session = useSession();

    const subStepRoutes: SubStepRoutes[] = [
        {
            path: PrivatePaths.CENTERS_MANAGEMENT,
            component: GroupCompanyPage,
            viewCode: SecScreen.CENTERS_MANAGEMENT,
        },
        {
            path: PrivatePaths.ROLE_MANAGEMENT,
            component: RolePage,
            viewCode: SecScreen.ROLE_MANAGEMENT,
        },
        {
            path: PrivatePaths.USER_MANAGEMENT,
            component: UsersPage,
            viewCode: SecScreen.USER_MANAGEMENT,
        },
        {
            path: PrivatePaths.PLAN_APPCC,
            component: PlanAPPCCPage,
            viewCode: SecScreen.PLAN_APPCC,
        },
        {
            path: PrivatePaths.CORR_MEASURE_MANAGER,
            component: CorrectiveMeasurePage,
            viewCode: SecScreen.CORR_MEASURE_MANAGER,
        },
        {
            path: PrivatePaths.ASSETS,
            component: AssetPage,
            viewCode: SecScreen.ASSETS,
        },
        {
            path: PrivatePaths.BASE_TASK_MANAGEMENT,
            component: BaseTaskPage,
            viewCode: SecScreen.BASE_TASK_MANAGEMENT,
        },
        {
            path: PrivatePaths.QR,
            component: WorkingPositionPage,
            viewCode: SecScreen.QR,
        },
        {
            path: PrivatePaths.TASK_PAGE,
            component: TaskPage,
            viewCode: SecScreen.TASK,
        },
        {
            path: PrivatePaths.REGISTRY_MANAGEMENT,
            component: TaskAPPCCPage,
            viewCode: SecScreen.REGISTRY_MANAGEMENT,
        },
        {
            path: PrivatePaths.BASE_AUDIT_GROUP_CHECKLIST_ITEM,
            component: BaseAuditGroupCheckListItemPage,
            viewCode: SecScreen.AUDIT_MANAGEMENT,
        },
        {
            path: PrivatePaths.AUDIT_MANAGEMENT,
            component: AuditTemplatePage,
            viewCode: SecScreen.AUDIT_MANAGEMENT,
        },
        {
            path: PrivatePaths.MY_PROFILE,
            component: MyProfilePage,
            viewCode: Views.MY_PROFILE,
        },
    ];

    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={PrivatePaths.ON_BOARDING} />} />
            <Route path={`${PrivatePaths.ON_BOARDING}/*`} element={<OnBoardingSteps />} />
            <Route path={PrivatePaths.ACCEPT_POLICY} element={<AcceptPolicyPage />} />
            {subStepRoutes.map(({ component, path, viewCode }) => (
                <Route
                    key={path}
                    path={`${path}/*`}
                    element={<PrivateRoute component={component} viewCode={viewCode} />}
                />
            ))}

            {!!session?.user.isActivationPending && (
                <Route path={`${PublicPaths.NEW_PASSWORD}/*`} element={<ResetPasswordPage isNewPassword />} />
            )}
            {!session?.user.customerInstanceId && (
                <Route path={`${PrivatePaths.LICENSE_PAGE}/*`} element={<LicensePage />} />
            )}
        </Routes>
    );
};
