import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { AlertNotificationService } from "app/services";
import { TranslationKeys } from "app/translation/translationKeys";
import { AlertExtraparamsProps, alertExtraparams } from "../helpers/alertExtraparams";
import { AlertFilterModel } from "app/components_v2/__filters/AlertFilter/types";
import { AlertTabsModel } from "../types";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";

export const useAlert = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const session = useSession();

    const initialFilterData: AlertFilterModel = {
        userId: "-1",
        departmentId: "-1",
        companyId: "-1",
        receivedStartDate: undefined,
        receivedEndDate: undefined,
        seenStartDate: undefined,
        seenEndDate: undefined,
    };

    const [filterData, setFilterData] = useState<AlertFilterModel>(initialFilterData);
    const [list, setList] = useState<AlertNotificationDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [total, setTotal] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState(0);

    const getNotifications = async (
        currentTab: AlertTabsModel,
        pageIndex?: number,
        filterDataProps?: AlertFilterModel
    ) => {
        setIsLoading(true);
        const filterDataExtraParam = filterDataProps || filterData;
        const extraParams: AlertExtraparamsProps = {
            ...filterDataExtraParam,
            currentTab,
            userId: filterDataExtraParam.userId === "-1" ? String(session?.user.id) : filterDataExtraParam.userId,
            departmentId: filterDataExtraParam.departmentId === "-1" ? "" : filterDataExtraParam.departmentId,
            companyId: filterDataExtraParam.companyId === "-1" ? "" : filterDataExtraParam.companyId,
            sessionUserId: session?.user.id || 0,
        };

        const { data, status } = await AlertNotificationService.GetData({
            pageIndex: pageIndex ? pageIndex - 1 : 0,
            extraParams: alertExtraparams(extraParams),
        });

        if (!status()) {
            handleToast({
                title: t(TranslationKeys.FAILED_ALERT_SENT),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            return;
        }

        setList(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    return {
        list,
        isLoading,
        total,
        pageIndex,
        onChangePageIndex: setPageIndex,
        getNotifications,
        setIsLoading,
        initialFilterData,
        filterData,
        setFilterData,
    };
};
