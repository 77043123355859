import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { faCheckDouble, faClock } from "@fortawesome/pro-regular-svg-icons";
import { UserService } from "app/services";
import { CloseCompanyTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { getDayMonthFormatedWithYearNumbers } from "app/helpers";
import { CloseCompanyModalContext } from "app/state/context/CloseCompanyModalContext/CloseCompanyModalContext";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { OptionCompany } from "app/components_v2/OptionCompany/OptionCompany";

export const CloseCompanyModal: FC = () => {
    const { t } = useTranslation();
    const { closeOpenCompanies, reset } = useContext(CloseCompanyModalContext);
    const session = useSession();
    const userId = session?.user.id;
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    const openCompanies = closeOpenCompanies.filter((cc) => cc.state == "OPEN");
    const closeCompanies = closeOpenCompanies.filter((cc) => cc.state == "CLOSED");

    const getTitle = (date1: Date, date2: Date): string => {
        if (date1 === date2) return getDayMonthFormatedWithYearNumbers(date1.toString() || "", "/", "YYYY");

        return t(TranslationKeys.COMPANY_CLOSED_DAYS).replace(
            "{1}",
            `${getDayMonthFormatedWithYearNumbers(
                date1.toString() || "",
                "/",
                "YYYY"
            )} - ${getDayMonthFormatedWithYearNumbers(date2.toString() || "", "/", "YYYY")}`
        );
    };

    const handleUpdateCloseCompanySeen = async () => {
        if (!userId) return;
        setIsSubmiting(true);
        const closeCompaniesSeenIds = closeOpenCompanies.map(({ id }) => id);
        await UserService.UpdateCloseCompanySeen(userId, closeCompaniesSeenIds);
        setIsSubmiting(false);
        reset();
    };

    return (
        <GenericModal
            allowCloseOnEscape={false}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationCommon.ACCEPT),
                        iconRight: faCheckDouble,
                        onClick: handleUpdateCloseCompanySeen,
                    }}
                    loading={isSubmiting}
                />
            }
            size="md"
        >
            <div className="closeCompanyModal">
                <h1 className="closeCompanyModal__title">{t(CloseCompanyTranslations.CLOSE_COMPANIES_MODAL_TITLE)}</h1>
                {!!openCompanies.length && (
                    <Collapsable title={t(CloseCompanyTranslations.OPEN_COMPANIES_COLLAPSABLE_TITLE)}>
                        <div className="closeCompanyModal__list">
                            {openCompanies.map(({ id, companyName, logoURL, closeDate, openDate }) => (
                                <div key={id}>
                                    <OptionCompany
                                        name={companyName}
                                        logoImage={logoURL}
                                        badge={{
                                            title: getTitle(closeDate, openDate),
                                            icon: faClock,
                                            theme: "light",
                                            variant: "green",
                                            outline: false,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </Collapsable>
                )}
                {!!closeCompanies.length && (
                    <Collapsable title={t(CloseCompanyTranslations.CLOSE_COMPANIES_COLLAPSABLE_TITLE)}>
                        <div className="closeCompanyModal__list">
                            {closeCompanies.map(({ id, companyName, logoURL, closeDate, openDate }) => (
                                <div key={id}>
                                    <OptionCompany
                                        name={companyName}
                                        logoImage={logoURL}
                                        badge={{
                                            title: getTitle(closeDate, openDate),
                                            icon: faClock,
                                            theme: "light",
                                            variant: "red",
                                            outline: false,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </Collapsable>
                )}
            </div>
        </GenericModal>
    );
};
