import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "app/hooks";
import { SignalRService } from "app/services";
import SystemNotificationService from "app/services/02-TAR/SystemNotificationService";
import { SystemNotificationModel } from "app/models/02-TAR/SystemNotification/SystemNotificationModel";
import { HeaderContext } from "../../../components_v2/Header/context/headerContext";
import { SystemNotificationList } from "./SystemNotificationList";

export const Notification = () => {
    const { handleClearAllNotifications } = useContext(HeaderContext);
    const nav = useNavigate();
    const userId = useSession()?.user.id;
    const signalR = SignalRService.getSignalR();

    const [systemNotifications, setSystemNotifications] = useState<SystemNotificationModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getSystemNotifications = async () => {
        const sr = await SystemNotificationService.GetSystemNotifications({
            extraParams: `ReceiverUserId=${userId}`,
            pageSize: 20,
            pageIndex: 0,
        });
        if (sr.status()) setSystemNotifications(sr.data.list);
        setIsLoading(false);
    };

    useEffect(() => {
        getSystemNotifications();
    }, []);

    const handleSeenNotification = async ({ id, redirectURL }: Partial<SystemNotificationModel>) => {
        const systemNotificationSr = await SystemNotificationService.Edit(id!);
        if (systemNotificationSr.status()) {
            setSystemNotifications(systemNotificationSr.data.list);
            signalR.SignalRConnection.send("HandleNotificationMobile", signalR.SignalRConnection.connectionId);
            if (redirectURL) nav(`${redirectURL}`);
        }
    };

    const handleMarkAllSeen = async () => {
        const sr = await SystemNotificationService.MarkAllAsSeen();
        if (sr.status()) {
            getSystemNotifications();
            handleClearAllNotifications();
        }
    };

    return (
        <SystemNotificationList
            list={systemNotifications}
            isLoading={isLoading}
            handleMarkAllSeen={handleMarkAllSeen}
            handleSeenNotification={handleSeenNotification}
        />
    );
};
