import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { NotificationVariant } from "app/shared/Constants";
import { SystemNotificationModel } from "app/models/02-TAR/SystemNotification/SystemNotificationModel";
import { NotificationVariantModel } from "app/models/NotificationVariant";
import { NotificationPill } from "app/components_v2/NotificationPill/NotificationPill";
import { SystemNotificationSkelleton } from "app/components_v2/__skelletons/SystemNotificationSkelleton";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { faBell } from "@fortawesome/pro-regular-svg-icons";

type SystemNotificationListProps = {
    list: SystemNotificationModel[];
    isLoading: boolean;
    handleMarkAllSeen: () => Promise<void>;
    handleSeenNotification: ({ id, redirectURL }: Partial<SystemNotificationModel>) => Promise<void>;
};

export const SystemNotificationList: FC<SystemNotificationListProps> = ({
    list,
    isLoading,
    handleMarkAllSeen,
    handleSeenNotification,
}) => {
    const { t } = useTranslation();
    const variantRecord: Record<string, NotificationVariantModel> = {
        [NotificationVariant.DANGER.toString()]: "danger",
        [NotificationVariant.INFO.toString()]: "primary",
        [NotificationVariant.STAR_DANGER.toString()]: "star-danger",
        [NotificationVariant.STAR_INFO.toString()]: "star-info",
        [NotificationVariant.STAR_YELLOW.toString()]: "star-yellow",
        [NotificationVariant.USER_REGISTRATION.toString()]: "danger",
        [NotificationVariant.USER_DEREGISTRATION.toString()]: "danger",
        [NotificationVariant.SPORADIC_TASK.toString()]: "sporadic-task",
        [NotificationVariant.AUDIT_REPORT.toString()]: "audit-report",
        [NotificationVariant.PATCH_NOTES.toString()]: "patch-notes",
    };

    const getVariant = (variant?: string): NotificationVariantModel => {
        if (!variant) return "primary";
        return variantRecord[variant] || "primary";
    };

    return (
        <>
            <div className="systemNotifications__seen">
                <p className="systemNotifications__seen__text" onClick={handleMarkAllSeen}>
                    {t(TranslationKeys.MARK_ALL_AS_VIEWED)}
                </p>
            </div>
            <div className="systemNotifications">
                <div className="systemNotifications__list">
                    {isLoading ? (
                        <SystemNotificationSkelleton quantity={8} />
                    ) : list.length !== 0 ? (
                        list.map(
                            (
                                {
                                    body,
                                    title,
                                    sendDate,
                                    seenDate,
                                    id,
                                    redirectURL,
                                    variant,
                                    profilePictureURL,
                                    nameInitials,
                                    userId,
                                },
                                i
                            ) => {
                                return (
                                    <NotificationPill
                                        body={body}
                                        title={title}
                                        date={new Date(sendDate)}
                                        key={i}
                                        seen={!seenDate}
                                        onClick={() => handleSeenNotification({ id, redirectURL })}
                                        nameInitials={nameInitials}
                                        userId={userId}
                                        variant={getVariant(variant)}
                                        profilePictureURL={profilePictureURL}
                                    />
                                );
                            }
                        )
                    ) : (
                        <TableError description={t(TranslationKeys.RECEIVED_NOTIFICATION)} icon={faBell} />
                    )}
                </div>
            </div>
        </>
    );
};
