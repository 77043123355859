import { RadioButton } from "app/components_v2/RadioButton/RadioButton";
import { SelectOptions } from "app/components_v2/__inputs";
import { Label } from "app/components_v2/__inputs/Label";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type SelectWorkingPositionProps = {
    changeWorkingPositionelected: (value: string, text: string) => void;
    value: string;
    options: OptionModel[];
    isEnabled: boolean;
    isDisabled?: boolean;
    optional?: boolean;
};

export const SelectWorkingPosition: FC<SelectWorkingPositionProps> = ({
    changeWorkingPositionelected,
    isEnabled,
    options,
    value,
    isDisabled,
    optional = true,
}) => {
    const { t } = useTranslation();

    const [selectorOptions, setSelectorOptions] = useState<OptionModel[]>([]);

    useEffect(() => {
        setSelectorOptions(options);
    }, [options]);

    return (
        <div className="stepTwoSelectWithRadio">
            <Label
                label={t(TranslationTitles.WORKING_POSITION_TITLE)}
                className={`stepTwoSelectWithRadio__label`}
                isOptional={optional}
            />
            <div
                className={`stepTwoSelectWithRadio__container ${
                    isDisabled ? "stepTwoSelectWithRadio__container--disabled" : ""
                }`}
            >
                {!isDisabled && <RadioButton checked={!isEnabled} disabled={isDisabled} />}
                <SelectOptions
                    options={selectorOptions}
                    isMulti={false}
                    onChange={(newValue) => {
                        changeWorkingPositionelected(newValue.value, newValue.label);
                    }}
                    selectedValue={value}
                    htmlFor="selectWorkingPosition"
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
};
