import { SelectOptions } from "app/components_v2/__inputs";
import { Switch } from "app/components_v2/__inputs/Switch/Switch";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { FieldValue, FieldValues } from "app/components_v2/WizardTask";
import { DataToReportList } from "app/components_v2/WizardTask/DataToReportList/DataToReportList";
import { DynamicFields } from "app/components_v2/WizardTask/DynamicFields/DynamicFields";
import {
    onAddNewCheckList,
    onBlurCheckList,
    onDeleteCheckList,
    onResetBaseTaskReportFields,
    onSelectCheckList,
} from "app/helpers/dataToReport/dataToReportChecklistHelper";
import {
    onAddNewDynamicDropDownField,
    onChangeDynamicDropDownField,
    onDeleteDynamicDropDownField,
    onDeleteDynamicField,
    onDynamicFieldCheckboxChange,
    onDynamicFieldInputChange,
    onSelectDynamicField,
} from "app/helpers/dataToReport/dataToReportDynamicfieldsHelper";
import { useGetDynamicFieldsTranslations } from "app/hooks/dynamicFields/useGetDynamicFieldsTranslations";
import { useDynamicFields } from "app/hooks/useDynamicFields";
import { CustomErrorReportDataModel } from "app/models/02-TAR/Task/EditTask";
import { DynamicFieldsType, reportType } from "app/models/02-TAR/TaskWizard";
import { OptionsSearch, OptionsSearchT } from "app/models/FormComponentsModel";
import { BaseTaskTranslations, TranslationKeys } from "app/translation/translationKeys";
import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BaseTaskFormModel } from "../../models/EditBaseTaskFormModels";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";

type BaseTaskDataToReportProps = BaseTaskFormModel & {
    onChange: (value: Partial<BaseTaskFormModel>) => void;
    customError: CustomErrorReportDataModel;
    isDisabled: boolean;
};
export type BaseTaskFieldValues = {
    selectableValue: reportType;
    fieldValue: FieldValue | null;
    dynamicFieldsType?: DynamicFieldsType;
};

export type BaseTaskSelectDataToReportOptions = "NONE" | "CHECKLIST" | "DYNAMIC_FIELD" | "ASSET";

export const BaseTaskDataToReport: FC<BaseTaskDataToReportProps> = ({
    isDisabled,
    allowAnyData,
    onChange,
    checkList,
    customError,
    dynamicFields,
    reportType,
}) => {
    const { t } = useTranslation();
    const ref = useRef(false);

    const { dynamicFieldsOptions, getDynamicFields, isLoadingDynamicFields } = useDynamicFields();
    const { getDynamicFieldTranslation } = useGetDynamicFieldsTranslations();

    const fillOptions = (): OptionsSearch[] => {
        const options: OptionsSearchT<BaseTaskSelectDataToReportOptions>[] = [
            {
                label: t(BaseTaskTranslations.BASE_TASK_NOT_ALLOWED_DATA),
                value: "NONE",
            },
            {
                label: t(BaseTaskTranslations.BASE_TASK_ADD_CHECKLIST),
                value: "CHECKLIST",
            },
            {
                label: t(BaseTaskTranslations.BASE_TASK_ADD_DYNAMIC_FIELDS),
                value: "DYNAMIC_FIELD",
            },
            {
                label: t(BaseTaskTranslations.BASE_TASK_ASSETS_IS_REQUIRED),
                value: "ASSET",
            },
        ];

        return options;
    };

    const resetReportFields = ({ selectableValue, dynamicFieldsType }: Partial<FieldValues>) => {
        onChange(onResetBaseTaskReportFields(checkList, dynamicFields, selectableValue, dynamicFieldsType));
    };

    const handleChange = async (inputValues: Partial<FieldValues>) => {
        if (inputValues.fieldValue === null || inputValues.selectableValue === "NONE") {
            resetReportFields(inputValues);

            const customContainer = document.querySelector(".formLayoutScroll__children ");
            if (!customContainer) return;
            customContainer.scrollTo({ top: customContainer.scrollHeight, behavior: "smooth" });
            return;
        }

        if (inputValues.selectableValue === "ASSET") {
            onChange({ reportType: "ASSET" });
            return;
        }

        if (inputValues.selectableValue === "CHECKLIST") {
            handleSelectCheckList(inputValues);
            onChange({ reportType: "CHECKLIST" });
            return;
        }

        if (inputValues.selectableValue === "DYNAMIC_FIELD" && inputValues.dynamicFieldsType) {
            handleSelectDynamicField(inputValues.dynamicFieldsType);
            onChange({ reportType: "DYNAMIC_FIELD" });
            return;
        }
    };

    // Checklist
    const handleAddNewCheckList = () => {
        onChange({ checkList: onAddNewCheckList(checkList) });
    };

    const handleDeleteCheckList = (checkListId: string) => {
        onChange({ checkList: onDeleteCheckList(checkListId, checkList) });
    };

    const handleBlurCheckList = () => {
        onChange({ checkList: onBlurCheckList(checkList) });
    };

    const handleSelectCheckList = ({ fieldValue }: Partial<FieldValues>) => {
        fieldValue && onChange({ checkList: onSelectCheckList(fieldValue, checkList) });
    };
    // Checklist

    // DynamicFields

    const handleSelectDynamicField = (dynamicFieldType: DynamicFieldsType) => {
        onChange({ dynamicFields: onSelectDynamicField(dynamicFieldType, dynamicFields) });
    };

    const handleDynamicFieldsChange = ({ value }: OptionsSearch) => {
        ref.current = true;
        handleChange({
            dynamicFieldsType: (value as DynamicFieldsType) || "DATE",
            selectableValue: "DYNAMIC_FIELD",
        });
    };

    const handleDynamicFieldCheckboxChange = (value: boolean, id: string) => {
        onChange({ dynamicFields: onDynamicFieldCheckboxChange(value, id, dynamicFields) });
    };

    const handleDynamicFieldInputChange = (value: string, id: string) => {
        onChange({ dynamicFields: onDynamicFieldInputChange(value, id, dynamicFields) });
    };

    const handleDeleteDynamicField = (id: string) => {
        onChange({ dynamicFields: onDeleteDynamicField(id, dynamicFields) });
    };

    const handleDeleteDynamicDropDownField = (id: string, itemId: string) => {
        onChange({ dynamicFields: onDeleteDynamicDropDownField(id, itemId, dynamicFields) });
    };

    const handleChangeDynamicDropDownField = (id: string, itemId: string, value: string) => {
        onChange({ dynamicFields: onChangeDynamicDropDownField(id, itemId, value, dynamicFields) });
    };

    const handleAddNewDynamicDropDownField = (id: string) => {
        onChange({ dynamicFields: onAddNewDynamicDropDownField(id, dynamicFields) });
    };

    // DynamicFields

    useEffect(() => {
        if (reportType === "DYNAMIC_FIELD") {
            getDynamicFields();
        }
    }, [reportType]);

    return (
        <div className="baseTaskDataToReport">
            <WhiteBoxCollapsable
                collapsableOptions={{ title: t(TranslationKeys.DATA_TO_REPORT_ATTACHED), border: "none" }}
                whiteBoxOptions={{ fullWidth: true }}
            >
                <Switch
                    label={t(BaseTaskTranslations.BASE_TASK_ALLOW_ANY_DATA)}
                    checked={allowAnyData}
                    onChange={(isChecked) => {
                        onChange({ allowAnyData: isChecked });
                        if (isChecked) resetReportFields({ selectableValue: "NONE" });
                    }}
                    disabled={isDisabled}
                />
                {!allowAnyData && (
                    <>
                        <div className="selectDataToReport">
                            <div className="baseTaskDataToReport__selector">
                                <SelectOptions
                                    options={fillOptions()}
                                    selectedValue={reportType}
                                    isMulti={false}
                                    onChange={({ value }) =>
                                        handleChange({ fieldValue: null, selectableValue: value as reportType })
                                    }
                                    disabled={isDisabled}
                                />
                            </div>
                            {reportType === "CHECKLIST" && (
                                <div className="baseTaskDataToReport__checklist">
                                    <DataToReportList
                                        items={checkList.map(({ id, isDeleted, name }) => ({
                                            id,
                                            value: name,
                                            isDeleted,
                                        }))}
                                        onAddNew={handleAddNewCheckList}
                                        onBlur={handleBlurCheckList}
                                        onInputChange={(values) => handleSelectCheckList({ fieldValue: values })}
                                        customError={customError.checklist}
                                        onDelete={handleDeleteCheckList}
                                        placeholder={t(TranslationKeys.PLACEHOLDER_CHECKLIST)}
                                        disabled={isDisabled}
                                    />
                                </div>
                            )}
                            {reportType === "DYNAMIC_FIELD" && (
                                <div className="dynamicFields">
                                    <DynamicFields
                                        isLoadingDynamicFields={isLoadingDynamicFields}
                                        dynamicFields={dynamicFields}
                                        onCheckboxChange={handleDynamicFieldCheckboxChange}
                                        onInputChange={handleDynamicFieldInputChange}
                                        onDeleteDynamicField={handleDeleteDynamicField}
                                        onDeleteDynamicDropDownField={handleDeleteDynamicDropDownField}
                                        onChangeDynamicDropDownField={handleChangeDynamicDropDownField}
                                        onAddNewDynamicDropDownField={handleAddNewDynamicDropDownField}
                                        errors={customError.dynamicFields}
                                        focus={ref.current}
                                        disabled={isDisabled}
                                    />
                                    <SelectOptions
                                        isMulti={false}
                                        onChange={handleDynamicFieldsChange}
                                        options={dynamicFieldsOptions.map(({ label, value }) => ({
                                            label: getDynamicFieldTranslation(label as DynamicFieldsType),
                                            value,
                                        }))}
                                        errorMessage={customError.reportData}
                                        placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_FIELD)}
                                        disabled={isDisabled}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </WhiteBoxCollapsable>
        </div>
    );
};
