import { AuditIssueType } from "app/shared/types/IssueTypes";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { DragFileWithLabel } from "app/components_v2/DragFileWithLabel/DragFileWithLabel";
import { faPaperPlane, faTriangleExclamation, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { IssueModalHeader } from "./IssueModalHeader/IssueModalHeader";
import {
    IssueTranslation,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { OptionsOutsideSelect, SelectOptions, Switch, TextArea } from "app/components_v2/__inputs";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useIssueModal } from "app/hooks/Issue/useIssueModal";
import { useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import Spinner from "app/components_v2/Spinner/Spinner";

const formId = "issueModelForm";

type FooterModel = {
    text?: string;
    icon?: IconDefinition;
};

type IssueModalProps = {
    onCloseModal?: (isSubmitting: boolean) => void;
    disableAsset?: boolean;
    variant?: TabHeaderVariants;
    type?: AuditIssueType;
    footerOptions?: FooterModel;
};

export const IssueModal: FC<IssueModalProps> = ({
    onCloseModal,
    disableAsset,
    variant = "primary",
    type = "ISSUE",
    footerOptions,
}) => {
    const {
        handleInputChange,
        isDataLoading,
        isFormSubmitting,
        supervisorsOptions,
        description,
        supervisors,
        handleAddSupervisors,
        handleDeleteSupervisors,
        handleSubmit,
        errorFormValues,
        reset,
        selectsRef,
        assetOptions,
        fk_Asset,
        issueType,
        handleChangeDragFile,
        handleDeleteImage,
        handleEditImage,
        auditInstanceId,
        auditInfo,
        isCritic,
        attachments,
    } = useIssueModal({ type });

    const { t } = useTranslation();

    useTitle(t(TranslationTitles.NEW_ISSUE_PAGE_TITLE));

    const { errorDescription, errorSupervisor } = errorFormValues;

    const handleClose = () => {
        reset();
        onCloseModal && onCloseModal(false);
    };

    return (
        <GenericModal
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: footerOptions?.text || t(TranslationKeys.SEND_MANUAL_ISSUE),
                        form: formId,
                        type: "submit",
                        iconLeft: footerOptions?.icon || faPaperPlane,
                        variant,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "tertiary",
                        onClick: handleClose,
                        variant,
                    }}
                    buttonsJustification="spaceBetween"
                    loading={isFormSubmitting}
                />
            }
            size="md-tall"
        >
            {isDataLoading ? (
                <div className="issueModal__sppiner">
                    <Spinner />
                </div>
            ) : (
                <form className="issueModal" id={formId} onSubmit={(e) => handleSubmit(e, onCloseModal)}>
                    <div className="issueModal__header">
                        <TableError
                            icon={faTriangleExclamation}
                            title={t(TranslationTitles.NEW_ISSUE_PAGE_TITLE)}
                            variant="warning"
                        />
                    </div>
                    <div className="issueModal__info">
                        {auditInstanceId && auditInfo && <IssueModalHeader values={auditInfo} />}
                        <TextArea
                            label={t(TranslationKeys.ISSUE_PROBLEM_DETECTED_LABEL)}
                            text={description}
                            onChange={(value) => handleInputChange({ description: value })}
                            errorMessage={errorDescription}
                            placeholder={t(TranslationKeys.PROBLEM_DETECTED_PLACEHOLDER)}
                            maxLength={500}
                        />
                        <DragFileWithLabel
                            onChange={handleChangeDragFile}
                            onDelete={handleDeleteImage}
                            onEditImage={handleEditImage}
                            images={attachments}
                        />
                    </div>
                    {issueType !== "TASK" && issueType !== "AUDIT" && (
                        <SelectOptions
                            isMulti={false}
                            onChange={({ value }) => handleInputChange({ fk_Asset: value })}
                            options={assetOptions}
                            selectedValue={fk_Asset === "-1" ? undefined : fk_Asset}
                            isSearchable
                            isOptional={!onCloseModal}
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                            label={t(TranslationKeys.ISSUE_ASSET_LABEL)}
                            disabled={disableAsset}
                        />
                    )}
                    {issueType === "AUDIT" && (
                        <Switch
                            label={t(IssueTranslation.ISSUE_MODAL_AUDIT_NON_CONFORMITY)}
                            checked={isCritic}
                            onChange={(value) => handleInputChange({ isCritic: value })}
                        />
                    )}
                    {issueType !== "AUDIT" && (
                        <div ref={selectsRef} className="issueModal__selects">
                            <Collapsable border="none" title={t(TranslationKeys.SUPERVISOR_ISSUE_LABEL)}>
                                <OptionsOutsideSelect
                                    onChange={handleAddSupervisors}
                                    onDeleteItem={handleDeleteSupervisors}
                                    options={supervisorsOptions}
                                    values={supervisors}
                                    isSearchable
                                    autoInitials
                                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                                    errorMessage={errorSupervisor}
                                />
                            </Collapsable>
                        </div>
                    )}
                </form>
            )}
        </GenericModal>
    );
};
