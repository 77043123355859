import { BasicUserDto } from "app/dtos/01-SEG/User/BasicUserDto";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { getTimeZoneOffset } from "app/helpers";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { CIDepartamentalStructureModel } from "app/models/01-SEG/CIDepartamentalStructureModel";
import { Bool } from "app/dtos/Bool";
import { UserCounters } from "app/dtos/00-LOGIN/User/UserCounters";

export default class UserService {
    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<UserModel>>({
            url: `${ApiPaths.SEG_API}/users`,
            paginationParams: paginationParams,
        });
    }
    public static async GetCounters(paginationParams?: PaginationParams) {
        return FetchService.get<UserCounters>({
            url: `${ApiPaths.SEG_API}/users/counters`,
            paginationParams: paginationParams,
        });
    }

    public static async GetBasicData(paginationParams?: PaginationParams) {
        return FetchService.get<BasicUserDto[]>({
            url: `${ApiPaths.SEG_API}/users/basic-info`,
            paginationParams: paginationParams,
        });
    }

    public static async GetDataExport(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<UserModel>>({
            url: `${ApiPaths.SEG_API}/users/export`,
            paginationParams: paginationParams,
            csvExport: true,
        });
    }

    public static async GetUsersByDepartment(departmentId: number) {
        return FetchService.get<PaginatedResult<UserModel>>({
            url: `${ApiPaths.SEG_API}/users?departmentId=${departmentId}`,
        });
    }

    public static async GetUsersByRole(roleId: number) {
        return FetchService.get<PaginatedResult<UserModel>>({
            url: `${ApiPaths.SEG_API}/users?roleId=${roleId}`,
        });
    }

    public static async GetOne(userId: string | number, instanceId: number) {
        return FetchService.get<UserModel>({
            url: `${ApiPaths.SEG_API}/users/${userId}?customerInstanceId=${instanceId}`,
        });
    }

    public static async Save(values: UserModel) {
        const body = {
            ...values,
            timeZoneCode: "UTC", //TODO: resolve timezone iso format
            timeZoneOffset: getTimeZoneOffset(),
        };
        return FetchService.post<number>({
            url: `${ApiPaths.SEG_API}/users`,
            body: body,
        });
    }

    public static async Delete(userId: string | number) {
        return FetchService.delete<UserModel[]>({
            url: `${ApiPaths.SEG_API}/users/${userId}`,
        });
    }

    public static async Edit(values: UserModel, userId: string | number) {
        const body = {
            ...values,
            timeZoneCode: "UTC",
            timeZoneOffset: getTimeZoneOffset(),
        };
        return FetchService.put<UserModel>({
            url: `${ApiPaths.SEG_API}/users/${userId}`,
            body: body,
        });
    }

    public static async GetActiveScreens(userId: string | number) {
        return FetchService.get<[]>({
            url: `${ApiPaths.SEG_API}/users/${userId}/active-screens`,
        });
    }

    public static async ActivateUser(userId: string | number, isActive: Bool) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/users/Activation/${userId}`,
            body: isActive,
        });
    }

    public static async ResetPaswordManual(userId: string | number) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/users/ResetPaswordManual/${userId}`,
        });
    }
    public static async GetAllCompanies(userId: string | number) {
        return FetchService.get<CIDepartamentalStructureModel[]>({
            url: `${ApiPaths.SEG_API}/users/${userId}/allCompanies`,
        });
    }

    public static async PutAllCompanies(userId: string | number, body: CIDepartamentalStructureModel[]) {
        return FetchService.put<CIDepartamentalStructureModel[]>({
            url: `${ApiPaths.SEG_API}/users/${userId}/allCompanies`,
            body,
        });
    }

    public static async UpdateCloseCompanySeen(userId: number, closeCompanySeenIds: number[]) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/users/${userId}/UpdateCloseCompanySeen`,
            body: closeCompanySeenIds,
        });
    }
}
