import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import { FC, SetStateAction } from "react";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { AuditGroupCheckListInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditGroupCheckListInstanceModel";
import { AuditGroupCheckList } from "./components/AuditGroupCheckList/AuditGroupCheckList";
import Spinner from "app/components_v2/Spinner/Spinner";

type AuditStartProps = {
    auditInstanceId: number;
    actualStatus: AuditInstanceStatus;
    isAuditInstanceLoading: boolean;
    auditInstance: AuditInstanceModel | null;
    setAuditInstance: (ai: SetStateAction<AuditInstanceModel | null>) => void;
    fetchEditAuditHeaderData?: () => Promise<void>;
};

export const AuditStart: FC<AuditStartProps> = ({
    auditInstanceId,
    actualStatus,
    isAuditInstanceLoading,
    auditInstance,
    setAuditInstance,
    fetchEditAuditHeaderData,
}) => {
    const handleChecklistChange = (checklistGroupId: number, auditGroupChecklist: AuditGroupCheckListInstanceModel) => {
        const selectedGroupChecklist = auditInstance?.auditGroupInstances.find(({ id }) => id === checklistGroupId);
        if (!selectedGroupChecklist) return;

        setAuditInstance((prev) => {
            if (!prev) return auditInstance;

            const values: AuditInstanceModel = {
                ...prev,
                auditGroupInstances: prev.auditGroupInstances.map((auditGroupInstance) =>
                    auditGroupInstance.id === selectedGroupChecklist.id
                        ? {
                              ...auditGroupInstance,
                              auditGroupCheckListInstances: selectedGroupChecklist.auditGroupCheckListInstances.map(
                                  (agcl) => (agcl.id === auditGroupChecklist.id ? auditGroupChecklist : agcl)
                              ),
                          }
                        : auditGroupInstance
                ),
            };
            return values;
        });
    };

    if (isAuditInstanceLoading)
        return (
            <div className="spinnerFullHeightCenteredContainer">
                <Spinner />
            </div>
        );

    return (
        <div className="editAudit">
            <div className="editAudit__group">
                {auditInstance?.auditGroupInstances.map(({ auditGroupCheckListInstances, name, id }) => (
                    <AuditGroupCheckList
                        auditGroupCheckListInstances={auditGroupCheckListInstances}
                        name={name}
                        key={id}
                        onChange={(value) => handleChecklistChange(id, value)}
                        actualStatus={actualStatus}
                        auditInstanceId={auditInstanceId}
                        fetchEditAuditHeaderData={fetchEditAuditHeaderData}
                    />
                ))}
            </div>
        </div>
    );
};
