export const wizards = {
    company: {
        wizardCompanyName: "¿Cuál es el nombre del nuevo Centro de Trabajo?",
        wizardCompanyLogo: "Logotipo del centro de trabajo",
        wizardCompanyType: "¿Qué tipo de Centro de Trabajo es?",
        wizardCompanyBelongsTo: "¿Pentenece a algun grupo de Centros de Trabajo?",
        wizardCompanyCif: "¿Cuál es el CIF?",
        wizardCompanyFiscalName: "¿Cuál es el nombre fiscal?",
        wizardCompanyWhereIs: "Dirección",
        wizardCompanyHowToContact: "¿Cómo nos contactamos?",
        wizardCompanyInventoryFreq: "¿Cuál es la frecuencia de inventario?",

        wizardCompanyWantToClone:
            "¿Deseas clonar la estructura de departamentos de alguna de los Centro de Trabajo existentes?",
        wizardCompanySelectToInclude: "Selecciona los que deaseas incluir.",
        wizardCompanyWhichOf: "¿De cuál de ellas?",
        wizardCompanyNoDepartments: "El Centro de Trabajo seleccionada aún no tiene departamentos",
        wizardCompanyNoSubdepartments: "Este departamento aún no tiene subdepartamentos",
        wizardCompanyWantToCloneTasks:
            "¿Deseas clonar también las tareas de los departamentos y subdepartamentos que seleccionaste?",
        wizardCompanyImportUserStruct: "¿Deseas importar la estructura de usuarios?",
        wizardCompanyImportUserStructInstructions:
            "Selecciona los usuarios que deseas incluir en el nuevo Centro de Trabajo. Clickea en el icono para verlos individualmente.",
        wizardCompanySureToCreate:
            "¿Confirmas que deseas crear el Centro de Trabajo {name} clonando estos elementos?",
        wizardCompanySureToCreateNoItems:
            "¿Confirmas que deseas crear El Centro de Trabajo {name}?",
        wizardCompanyCongrats:
            "¡Felicitaciones, has creado El Centro de Trabajo {name} exitosamente!",
        wizardEditInternalOrg: "Editar organización interna",
        wizardCompanyAtLeastOne: "Selecciona al menos un departamento con usuarios.",
        wizardCompanyStepThreeInfo:
            "Por favor selecciona una compañia en el paso previo para ejecutar esta accion.",
        wizardCompanyMarkSubdepartment: "Marca el subdepartamento para administrar usuarios",
        noDepartmentsMessage: "El Centro de Trabajo seleccionada aún no cuenta con departamentos",
        noSubDepartmentsMessage: "Este departamento no cuenta con subdepartamentos.",
        wizardCompanySelectCompany: "Por favor, seleccion un Centro de Trabajo",
        wizardCompanySelectOneDepartment: "Selecciona al menos un departamento.",
    },
    license: {
        wizardInstanceName: "¿Cómo se llama la nueva instancia?",
        wizardInstanceNamePlaceholder: "Ingresa el nombre de la instancia",
        wizardInstanceMainContact: "¿Quien es el contacto principal?",
        wizardInstanceStepTwoTitle:
            "¿Qué módulos y funcionalidades deseas habilitarle a esta instancia?",
        wizardInstancePrimaryAdmin: "¿Quién es el administrador principal de esta cuenta?",
        wizardInstanceEmail: "¿Cuál es su email?",
        wizardInstanceDocument: "¿Cuál es su número de documento?",
        wizardInstanceConfirmation: "¿Confirmas que deseas dar de alta la instancia?",
    },
    role: {
        wizardRoleName: "¿Como se llama el nuevo rol?",
        wizardRoleCopyPermissions: "¿Quieres copiar los permisos de un rol existente?",
        wizardRoleSelectToClone: "¿Qué rol existente deseas usar de base?",
        wizardRoleSelectRole: "Selecciona un rol para continuar o elija no",
        wizardRoleSelectPermission: "Debes elegir al menos un permiso.",
        wizardRoleConfirmCreation: "¿Confirmas que deseas crear el rol?",
        wizardTemporaryRoleUserSelect: "¿A qué usuario deseas otorgarle permisos temporales?",
        wizardTemporaryRoleDatePicker: "¿Desde qué día hasta que día?",
        wizardTemporaryRoleConfirm:
            "¿Confirmas que deseas otorgar permisos temporales a {0} desde {1} hasta {2}?",
    },
    department: {
        selectCompanyTitle: "¿En qué Centro de Trabajo estás trabajando hoy?",
        selectDepartmentTitle: "¿En cuál departamento?",
        selectSubdepartmentTitle: "¿En cuál subdepartamento?",
        chooseCompany: "- Seleccionar Centro de Trabajo -",
        chooseDepartment: "- Seleccionar departamento -",
        chooseSubDepartment: "- Seleccionar subdepartamento -",
        confirmConfig: "¿Confirmas querer trabajar en {0} {1} {2}?",
    },
};
