import { createContext } from "react";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { BaseTaskReportType } from "app/shared/BaseTaskConstantTypes";

export type BaseTaskGridContextModel = {
    pageIndex: number;
    onChangePageIndex: (pageIndex: number) => void;
    sortField: string;
    sortDirection: SortedTypeModel;
    onSortChange: (sortF: string, sortD: SortedTypeModel) => void;
    secondaryFilterValues: BaseTaskGridSFModel;
    onSFChange: (sfValues: Partial<BaseTaskGridSFModel>) => void;
};

export type BaseTaskGridSFModel = {
    query?: string;
    baseTaskTypeId: string;
    planId: string;
    isPhotoRequired?: boolean;
    reportType?: BaseTaskReportType;
};

export const BaseTaskGridContext = createContext<BaseTaskGridContextModel>({} as BaseTaskGridContextModel);
