import { CompanyOptionModel } from "app/dtos/01-SEG/Company/CompanyOptionModel";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionsSearch } from "app/models/FormComponentsModel";

export default class SegSelectorService {
    // GET Returns a list of company names who have a task instance assigned
    public static async GetCompanyNamesWithIds(params?: PaginationParams) {
        return FetchService.get<CompanyOptionModel[]>({
            url: `${ApiPaths.SEG_API}/SegSelector/Companies`,
            paginationParams: params,
        });
    }

    // TODO: Mirar perque no existeix
    // GET api/v1/SegSelector/TaskInstanceCompanies
    public static async GetTaskInstanceCompanies(params?: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.SEG_API}/SegSelector/TaskInstanceCompanies`,
            paginationParams: params,
        });
    }

    // GET Returns a list of department names who have a task instance assigned
    public static async GetDepartmentNamesWithIds(params?: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.SEG_API}/SegSelector/Departments`,
            paginationParams: params,
        });
    }

    // GET Returns a list of subdepartment names who have a task instance assigned
    public static async GetSubDepartmentNamesWithIds(params?: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.SEG_API}/SegSelector/Subdepartments`,
            paginationParams: params,
        });
    }

    // GET Returns a list of the user names who have a task instance assigned
    public static async GetUserNamesWithIds(params?: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.SEG_API}/SegSelector/Users`,
            paginationParams: params,
        });
    }

    // GET all JobDescriptions
    public static async GetJobDescriptions(params?: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.SEG_API}/SegSelector/JobDescription`,
            paginationParams: params,
        });
    }

    // GET all DistributionListTypes
    public static async GetDistributionListTypes(companyId: string) {
        return FetchService.get<OptionsSearch[]>({
            url: `${ApiPaths.SEG_API}/SegSelector/DistributionListTypes`,
            paginationParams: { extraParams: `companyId=${companyId}` },
        });
    }
}
