import { FC, useEffect, useState } from "react";
import { AnalistDetailFilterData } from "./types";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { isEqual } from "lodash";
import { SelectOptions } from "app/components_v2/__inputs";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";

type AnalistDetailFilterProps = {
    filterData: AnalistDetailFilterData;
    initialFilterdata: AnalistDetailFilterData;
    isOpen: boolean;
    taskTypeOptions: OptionsSearch[];
    workingPositionOptions?: OptionsSearch[];
    onSave: (values: AnalistDetailFilterData) => void;
    onCancel?: () => void;
    onReset?: () => void;
};

export const AnalistDetailFilter: FC<AnalistDetailFilterProps> = ({
    filterData,
    initialFilterdata,
    isOpen,
    onSave,
    onCancel,
    onReset,
    taskTypeOptions,
    workingPositionOptions,
}) => {
    const { t } = useTranslation();

    const [internalFilterdata, setInternalFilterdata] = useState<AnalistDetailFilterData>(filterData);
    const { taskType, workingPosition } = internalFilterdata;

    const handleInputChange = (values: Partial<AnalistDetailFilterData>) => {
        setInternalFilterdata((prev) => ({ ...prev, ...values }));
    };

    const handleSave = () => {
        onSave(internalFilterdata);
    };

    const handleReset = () => {
        handleInputChange(initialFilterdata);
        onReset && onReset();
    };

    const handleCancel = () => {
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    useEffect(() => {
        setInternalFilterdata(filterData);
    }, [isOpen, filterData]);

    return (
        <SecondaryFilter
            onSave={handleSave}
            showResetFilter={!isEqual(initialFilterdata, internalFilterdata)}
            onReset={handleReset}
            isOpen={isOpen}
            onCancel={handleCancel}
        >
            <SelectOptions
                options={taskTypeOptions}
                isMulti={false}
                onChange={({ value }) => {
                    handleInputChange({ taskType: value });
                }}
                selectedValue={taskType}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
            />

            {!!workingPositionOptions?.length && workingPositionOptions?.length > 1 && (
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => handleInputChange({ workingPosition: value })}
                    selectedValue={workingPosition}
                    options={workingPositionOptions}
                    isSearchable
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            )}
        </SecondaryFilter>
    );
};
