import { FC } from "react";
import { IssueTranslation, TranslationCommon } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { IssueBodyDescription } from "./IssueBodyDescription/IssueBodyDescription";
import { IssueModel } from "app/models/05-QUA/IssueModels/IssueModel";
import { IssueBodyAsset } from "./IssueBodyAsset/IssueBodyAsset";
import { IssueBodyResponsible } from "./IssueBodyResponsible/IssueBodyResponsible";
import { IssueBodyAttachments } from "./IssueBodyAttachments/IssueBodyAttachments";
import { IssueAttachmentModel } from "app/models/05-QUA/IssueModels/IssueAttachmentModel";
import { IssueBodyGoToTask } from "./IssueBodyGoToTask/IssueBodyGoToTask";
import { hasPermissionToAdd, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { useSession } from "app/hooks";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";

type IssueBodyProps = {
    issue: IssueModel;
    onAddNewAttachments: (values: IssueAttachmentModel[]) => void;
};

export const IssueBody: FC<IssueBodyProps> = ({ issue, onAddNewAttachments }) => {
    const { t } = useTranslation();
    const session = useSession();

    const { issueType, asset, taskInstance, receivers, issueAttachments, id, fK_TaskInstance } = issue;

    const showAttachments = () => {
        if (!hasPermissionToView(session, SecScreen.ATTACH_FILES)) return false;
        if (!issueAttachments.length && !hasPermissionToAdd(SecScreen.ATTACH_FILES)) return false;
        return true;
    };

    return (
        <>
            <Collapsable title={t(TranslationCommon.DATA)}>
                <div className="issueBody">
                    <div className="issueBody__container">
                        {issue.auditGroupCheckListItemInstance?.name && (
                            <IssueBodyDescription
                                label={t(IssueTranslation.ISSUE_BODY_CHECKLIST_ITEM_TITLE)}
                                description={issue.auditGroupCheckListItemInstance.name}
                            />
                        )}
                        <IssueBodyDescription
                            label={t(IssueTranslation.ISSUE_BODY_DESCRIPTION_TITLE)}
                            description={issue.description}
                            audioURL={issue.audioURL}
                        />
                        {issueType === "TASK_ASSET" && asset && (
                            <IssueBodyAsset asset={asset} taskInstance={taskInstance} />
                        )}
                        {!!receivers.length && <IssueBodyResponsible receivers={receivers} />}
                        {showAttachments() && (
                            <IssueBodyAttachments
                                attachments={issueAttachments}
                                onAddNewAttachments={onAddNewAttachments}
                                id={id}
                            />
                        )}
                        {issueType === "TASK" && fK_TaskInstance && (
                            <div className="issueBody__goToTask">
                                <IssueBodyGoToTask taskId={fK_TaskInstance} />
                            </div>
                        )}
                    </div>
                </div>
            </Collapsable>
        </>
    );
};
