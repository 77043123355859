import { FC, useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useOnClickOutside } from "usehooks-ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { faCircleQuestion, faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { DateRangePickerProps } from "./DateRangePickerTypes";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-GB";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Hint } from "../Hint/Hint";
import DateRangeSkelleton from "app/components_v2/__skelletons/DateRangeSkelleton/DateRangeSkelleton";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { IDropDownListItem } from "app/components_v2/Dropdown/types";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { areDatesSameDay, getFormatedFullDate, getFormatedFullDatemultiple } from "app/helpers";
import { Label } from "../Label";
import { ReadOnlyInput } from "../ReadOnlyInput/ReadOnlyInput";

registerLocale("es-ES", es);
registerLocale("en-EN", en);

export const DateRangePicker: FC<DateRangePickerProps> = ({
    startDate,
    onChange,
    endDate,
    readonly,
    disabled,
    inverted,
    range = true,
    label,
    errorMessage,
    hint,
    canDelete,
    handleDeleteDates,
    isLoading,
    showTime,
    showMoreOptions,
    isOptional,
    showToday = true,
    disableFutureDates,
    placeholder,
    min,
    max,
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [openCalendar, setOpenCalendar] = useState<boolean>(false);
    const [isHintVisible, setIsHintVisible] = useState<boolean>(false);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>("");
    const { t } = useTranslation();

    const isDropDownListVisible = showOptions && range && showMoreOptions;

    useOnClickOutside(containerRef, () => {
        if (!isDropDownListVisible) return;
        setOpenCalendar(false);
        setShowOptions(false);
        setDates();
    });

    const handleChangeHint = () => {
        if (!!errorMessage?.length) {
            setIsHintVisible(false);
            return;
        }
        setIsHintVisible(!isHintVisible);
    };

    const getDates = (rango: string): (Date | null)[] => {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        const todayTranslation = t(TranslationCommon.TODAY);
        const yesterdayTranslation = t(TranslationCommon.YESTERDAY);
        const sevenDays = t(TranslationKeys.LAST_SEVEN_DAYS);
        const thirtyDays = t(TranslationKeys.LAST_THIRTY_DAYS);
        const todayDays = new Date();
        if (!showToday) todayDays.setDate(todayDays.getDate() - 1);

        if (rango === todayTranslation) {
            return [today, today];
        } else if (rango === yesterdayTranslation) {
            return [yesterday, yesterday];
        } else if (rango === sevenDays) {
            today.setDate(today.getDate() - 7);
            return [today, todayDays];
        } else if (rango === thirtyDays) {
            today.setDate(today.getDate() - 30);
            return [today, todayDays];
        } else {
            throw new Error(t(TranslationErrors.GENERIC_ERROR));
        }
    };
    const today = new Date();

    const isYesterday = (fecha: Date | undefined) => {
        if (!fecha) return false;
        const fechaAyer = new Date(today);
        fechaAyer.setDate(today.getDate() - 1);

        return fecha.setHours(0, 0, 0, 0) === fechaAyer.setHours(0, 0, 0, 0);
    };

    const isOneWeekAgo = (fecha: Date | undefined) => {
        if (!fecha) return false;
        const fechaHaceSieteDias = new Date(today);
        fechaHaceSieteDias.setDate(today.getDate() - 7);

        return fecha.setHours(0, 0, 0, 0) === fechaHaceSieteDias.setHours(0, 0, 0, 0);
    };

    const isOneMonthAgo = (fecha: Date | undefined) => {
        if (!fecha) return false;
        const fechaHaceUnMes = new Date(today);
        fechaHaceUnMes.setDate(today.getDate() - 30);

        return fecha.setHours(0, 0, 0, 0) === fechaHaceUnMes.setHours(0, 0, 0, 0);
    };

    const isEndDateToday = (date: Date | undefined) => {
        if (!date) return false;
        const today = new Date();
        if (!showToday) today.setDate(today.getDate() - 1);
        return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
    };

    const setDates = () => {
        const endDateFormated = endDate ? new Date(endDate) : undefined;
        if (
            startDate?.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) &&
            endDate &&
            areDatesSameDay(startDate, endDate)
        ) {
            setSelectedOption(t(TranslationCommon.TODAY));
            return;
        }

        if (isYesterday(startDate) && isYesterday(endDate)) {
            setSelectedOption(t(TranslationCommon.YESTERDAY));
            return;
        }

        if (isOneWeekAgo(startDate) && isEndDateToday(endDateFormated)) {
            setSelectedOption(t(TranslationKeys.LAST_SEVEN_DAYS));
            return;
        }

        if (isOneMonthAgo(startDate) && isEndDateToday(endDateFormated)) {
            setSelectedOption(t(TranslationKeys.LAST_THIRTY_DAYS));
            return;
        }
        setSelectedOption("");
    };

    const handleIconClick: React.MouseEventHandler<SVGSVGElement> = (e) => {
        if (disabled) return;
        e.stopPropagation();

        (!disabled && !range) || !showMoreOptions ? setOpenCalendar(true) : !openCalendar && setShowOptions(true);
    };
    const handleOption = (value?: string) => {
        if (!value) {
            setSelectedOption("");
        } else {
            onChange(getDates(value));
            setShowOptions(false);
            setSelectedOption(value);
            setOpenCalendar(false);
            setShowCalendar(false);
        }
    };
    const handleCustomOption = () => {
        setShowOptions(false);
        setOpenCalendar(true);
        setShowCalendar(true);
    };

    const items: IDropDownListItem[] = [];

    if (showToday) {
        items.push({
            text: t(TranslationCommon.TODAY),
            onClick: () => handleOption(t(TranslationCommon.TODAY)),
            active: selectedOption === t(TranslationCommon.TODAY),
        });
    }

    items.push(
        {
            text: t(TranslationCommon.YESTERDAY),
            onClick: () => handleOption(t(TranslationCommon.YESTERDAY)),
            active: selectedOption === t(TranslationCommon.YESTERDAY),
        },
        {
            text: t(TranslationKeys.LAST_SEVEN_DAYS),
            onClick: () => handleOption(t(TranslationKeys.LAST_SEVEN_DAYS)),
            active: selectedOption === t(TranslationKeys.LAST_SEVEN_DAYS),
        },
        {
            text: t(TranslationKeys.LAST_THIRTY_DAYS),
            onClick: () => handleOption(t(TranslationKeys.LAST_THIRTY_DAYS)),
            active: selectedOption === t(TranslationKeys.LAST_THIRTY_DAYS),
        },
        {
            text: t(TranslationKeys.PERSONALIZED),
            onClick: () => {
                handleCustomOption();
                handleOption();
            },
            active: selectedOption === t(TranslationKeys.PERSONALIZED),
        }
    );
    const currentDate = new Date();

    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    const formatEndDate = (endDateToFormat: Date | undefined): Date | null | undefined => {
        if (!endDateToFormat) return undefined;
        const today = new Date().setHours(0, 0, 0, 0);
        const endDateFormated = new Date(endDateToFormat);

        if (!showToday && endDateFormated.setHours(0, 0, 0, 0) === today) {
            endDateToFormat.setDate(endDateToFormat.getDate() - 1);
        }
        return endDateToFormat;
    };

    const getReadOnlyValue = () => {
        if (!showCalendar) return selectedOption;
        if (!startDate) return "";
        if (range) return getFormatedFullDatemultiple(startDate, endDate);

        return getFormatedFullDate(startDate);
    };

    useEffect(() => {
        if (startDate === undefined && endDate === undefined) {
            setSelectedOption("");
        }
        if (endDate) {
            setDates();
        }
    }, [startDate, endDate]);

    useEffect(() => {
        endDate && setOpenCalendar(false);
        endDate && setShowOptions(false);
    }, [endDate]);

    useEffect(() => {
        startDate && setShowCalendar(true);
        !range && setShowCalendar(true);
        !range && startDate && setOpenCalendar(false);
        !range && startDate && setShowOptions(false);
    }, [startDate]);

    if (readonly)
        return <ReadOnlyInput value={getReadOnlyValue()} label={label} type="date" placeholder={placeholder} />;

    return (
        <div ref={containerRef} className="fullWidth">
            {isLoading ? (
                <DateRangeSkelleton />
            ) : (
                <div className={`dateRangePicker__container${showMoreOptions ? "--relative" : ""}`}>
                    {label && (
                        <div className="selectOptions__label">
                            <Label isOptional={isOptional} label={label} disabled={disabled} inverted={inverted} />
                            {hint && !errorMessage?.length && (
                                <FontAwesomeIcon
                                    icon={faCircleQuestion}
                                    className={`hintIcon${inverted ? "--inverted" : ""}`}
                                    onClick={handleChangeHint}
                                />
                            )}
                        </div>
                    )}

                    <div
                        className={`dateRangePicker${disabled ? "--disabled" : ""} ${
                            inverted ? `dateRangePickerInverted${disabled ? "--disabled" : ""}` : ""
                        }  ${!!errorMessage?.length ? "dateError" : ""} ${
                            !inverted && (showOptions || openCalendar) ? "dateRangePickerFocus" : ""
                        }`}
                        onClick={() => {
                            if (disabled) return;
                            (!disabled && !range) || !showMoreOptions
                                ? setOpenCalendar(true)
                                : !openCalendar && setShowOptions(true);
                        }}
                    >
                        {selectedOption && showMoreOptions ? (
                            <p>{selectedOption}</p>
                        ) : !selectedOption && !showCalendar ? (
                            <p>{placeholder ?? t(TranslationKeys.PLACEHOLDER_WIZARD_TASK_START)}</p>
                        ) : (
                            showCalendar && (
                                <DatePicker
                                    selected={startDate}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={formatEndDate(endDate)}
                                    selectsRange={range}
                                    dateFormat={showTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
                                    className={`dateRangePicker${inverted ? "Inverted" : ""}__input`}
                                    disabled={disabled}
                                    open={openCalendar}
                                    locale="es-ES"
                                    readOnly
                                    placeholderText={placeholder ?? t(TranslationKeys.PLACEHOLDER_WIZARD_TASK_START)}
                                    showTimeSelect={showTime}
                                    timeFormat="HH:mm"
                                    minDate={min}
                                    maxDate={max ? max : disableFutureDates ? yesterday : undefined}
                                />
                            )
                        )}
                        <div className="dateRangePicker__icons">
                            <FontAwesomeIcon icon={faCalendar} onClick={handleIconClick} />
                            {canDelete && (startDate || endDate) && (
                                <FontAwesomeIcon
                                    className="dateRangePicker__icons--red"
                                    icon={faCircleXmark}
                                    onClick={handleDeleteDates}
                                />
                            )}
                        </div>
                    </div>
                    {isDropDownListVisible && (
                        <div className="dateRangePicker__options">
                            <DropdownList items={items} size="md" />
                        </div>
                    )}

                    {!!errorMessage?.length && !disabled ? (
                        <ErrorMessage errorMessage={errorMessage} />
                    ) : (
                        hint && isHintVisible && <Hint text={hint} inverted={inverted} />
                    )}
                </div>
            )}
        </div>
    );
};
