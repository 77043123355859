import { FC, ReactNode } from "react";
import { Tab } from "../Tab";

export type TabPropsReduced = {
    text?: string;
    leftCount?: number;
    rightCount?: number;
    type?: "dark" | "transparent";
    onClick: (current: number) => void;
    children?: ReactNode;
    hidden?: boolean;
    size?: "xs" | "md";
    fullWidth?: boolean;
};

export type TabsProps = {
    tabs: TabPropsReduced[];
    currentTab?: number;
    hideChildren?: boolean;
    isLoading?: boolean;
    height?: 40 | 50;
};

export const Tabs: FC<TabsProps> = ({ tabs, currentTab, hideChildren, isLoading = false, height = 40 }) => {
    return (
        <div className="tabs">
            <div className="tabs__container">
                {tabs.map((tabProps, key) => {
                    return (
                        !tabProps.hidden && (
                            <Tab
                                key={key}
                                {...tabProps}
                                id={key}
                                current={key === currentTab}
                                isLoading={isLoading}
                                height={height}
                            />
                        )
                    );
                })}
            </div>
            {!hideChildren && currentTab && tabs[currentTab].children && (
                <div className="tabs__content">{tabs[currentTab].children}</div>
            )}
        </div>
    );
};
