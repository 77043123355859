import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useGoBack, useSession, useTitle } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { useWizard } from "app/hooks/useWizard";
import { useUserType } from "app/hooks/useUserType";
import { useSelectDepartmentWizard } from "./hooks";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { setWorkingEntity } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { Actions } from "app/state/actions";
import { PrivatePaths, PublicPaths } from "app/shared/Constants";
import { headerStepNumbersModel } from "app/components_v2/wizards/types";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";
import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";
import { UsrSubDepartmentModel } from "app/models/01-SEG/UserCompanyDepartmentSubdepartment/UserCompanyDepartmentSubdepartmentModel";
import Loading from "app/components_v2/Loading/Loading";
import { PostLoginContext } from "./state/context/PostLoginContext";
import { AuthLayout } from "app/components_v2/__containers/AuthLayout/AuthLayout";
import { WizardHeader } from "app/components_v2/WizardHeader/WizardHeader";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { StepOne } from "./Step1/StepOne";
import { StepTwo } from "./Step2/StepTwo";
import { StepThree } from "./Step3/StepThree";

export const SelectDepartmentWizard = () => {
    const { t } = useTranslation();
    const { isSupervisorOrAnalist } = useUserType();
    const { goBack } = useGoBack();
    const session = useSession();
    const nav = useNavigate();
    const dispatch = useDispatch();
    const { departments, onSelectCompany, onSelectDepartment, subdepartments, redirectToDashboardIfIsSupervisor } =
        useSelectDepartmentWizard();

    useTitle(t(TranslationTitles.SELECT_DEPARTMENT_SUBDEPARTMENT_PAGE_TITLE));

    const headerStepNumbersValues: headerStepNumbersModel[] = [
        {
            title: t(TranslationKeys.COMPANY),
            hidden: isSupervisorOrAnalist,
        },
        {
            title: t(TranslationKeys.DEPARTMENT),
            hidden: isSupervisorOrAnalist,
        },
        {
            title: t(TranslationKeys.SUBDEPARTMENT),
            hidden: isSupervisorOrAnalist,
        },
    ];

    const { currentStepIndex, goTo, isFirstStep, isLastStep, next, onStepChange, prev } = useWizard(
        headerStepNumbersValues.length
    );

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [workingCompany, setWorkingCompany] = useState<UserCompanyModel>();
    const [workingDepartment, setWorkingDepartment] = useState<UsrDepartmentModel>();
    const [workingSubDepartment, setWorkingSubDepartment] = useState<UsrSubDepartmentModel>();

    const handleSelectCompany = (company: UserCompanyModel) => {
        redirectToDashboardIfIsSupervisor(company);
        onSelectCompany(company);
    };

    const steps: ReactElement[] = [
        <StepOne next={next} onSelectCompany={handleSelectCompany} />,
        <StepTwo next={next} departments={departments || []} onSelectDepartment={onSelectDepartment} />,
        <StepThree next={next} subDepartments={subdepartments || []} departments={departments || []} />,
    ];

    const saveSession = (
        companiesToAdd: UserCompanyModel,
        departmentsToAdd?: UsrDepartmentModel,
        subdepartmentsToAdd?: UsrSubDepartmentModel
    ) => {
        setWorkingEntity({
            workingCompany: companiesToAdd,
            workingDepartment: departmentsToAdd,
            workingSubDepartment: subdepartmentsToAdd,
        });

        dispatch(
            Actions.changeCompany({
                workingCompany: companiesToAdd,
                workingDepartment: departmentsToAdd,
                workingSubDepartment: subdepartmentsToAdd,
            })
        );

        dispatch(Actions.switchWizardMode(false));
        nav(`/${PrivatePaths.DASHBOARD}`);
    };

    useEffect(() => {
        if (!session?.user?.isPrivacyAccepted) {
            nav(PrivatePaths.ACCEPT_POLICY);
            return;
        }

        if (session?.isActivationPending) {
            nav(PublicPaths.NEW_PASSWORD);
            return;
        }

        setIsLoading(true);
        const companies = session?.user?.companies;

        let departmentsToAdd: UsrDepartmentModel | null = null;
        let subdepartmentsArray: UsrSubDepartmentModel[] = [];
        if (!companies || companies.length > 1) {
            setIsLoading(false);
            return;
        }

        const companiesToAdd: UserCompanyModel = companies[0];
        const firstCompanyDepartments: UsrDepartmentModel[] | undefined = companiesToAdd.departments;
        setWorkingCompany({
            ...companiesToAdd,
        });

        if (isSupervisorOrAnalist) {
            redirectToDashboardIfIsSupervisor(companiesToAdd);
            return;
        }
        onSelectCompany(companiesToAdd);
        next();

        if (!firstCompanyDepartments) {
            saveSession(companiesToAdd, undefined, undefined);
            return;
        }

        if (firstCompanyDepartments.length > 1) {
            setIsLoading(false);
            return;
        }

        if (firstCompanyDepartments.length === 1) {
            departmentsToAdd = firstCompanyDepartments[0];
            onSelectDepartment(firstCompanyDepartments[0]);
            if (firstCompanyDepartments[0].subdepartments)
                subdepartmentsArray = firstCompanyDepartments[0].subdepartments;
        }
        next();

        if (departmentsToAdd?.subdepartments === null) {
            saveSession(companiesToAdd, departmentsToAdd, undefined);
            setIsLoading(false);
            return;
        }

        if (!departmentsToAdd?.subdepartments || departmentsToAdd.subdepartments.length > 1) {
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        return;
    }, []);

    const handlePrev = () => {
        if (!isLastStep) return prev();

        const departments = session?.user.companies;
        if (!departments || !departments.length) return prev();
        if (!departments[0].departments) return prev();
        if (departments[0].departments.length > 1) return prev();
        return onStepChange(1);
    };

    const showPreviousButton = !isFirstStep && !!session?.user.companies?.length && session?.user.companies?.length > 1;

    const showFooterButtons = showPreviousButton || isLastStep;

    return (
        <PostLoginContext.Provider
            value={{
                setStepOne: setWorkingCompany,
                setStepTwo: setWorkingDepartment,
                setStepThree: setWorkingSubDepartment,
                workingCompany,
                workingDepartment,
                workingSubDepartment,
            }}
        >
            {isLoading ? (
                Loading()
            ) : (
                <AuthLayout>
                    <div className={`selectDepartmentWizard ${showFooterButtons ? "showFooterButtons" : ""}`}>
                        <div className="selectDepartmentWizard__formContainer">
                            {!isSupervisorOrAnalist && (
                                <WizardHeader
                                    goTo={goTo}
                                    currentStep={currentStepIndex}
                                    headerItems={headerStepNumbersValues}
                                />
                            )}
                            {steps[currentStepIndex - 1]}
                        </div>
                        {showFooterButtons && (
                            <div className="selectDepartmentWizard__footer">
                                {showPreviousButton && (
                                    <NewButton
                                        text={t(TranslationCommon.PREVIOUS)}
                                        handleClickButton={handlePrev}
                                        buttonType="secondary"
                                    />
                                )}{" "}
                                {isLastStep && (
                                    <NewButton
                                        text={t(TranslationCommon.SKIP)}
                                        buttonType="primary"
                                        form="wizard__form"
                                        type="submit"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    {!!session?.workingCompany && (
                        <div className="selectDepartmentWizard__cancel">
                            <NewButton
                                text={t(TranslationCommon.CANCEL)}
                                buttonType="primary"
                                handleClickButton={goBack}
                                type="submit"
                                transparent
                            />
                        </div>
                    )}
                </AuthLayout>
            )}
        </PostLoginContext.Provider>
    );
};
