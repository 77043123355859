import { useEffect, useState } from "react";
import { useToast } from "./Toast/useToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { TaskHisotryGridSfModel } from "app/pages/02-TAR/09-TAR-Historic/state/context/taskHistoryGridContext";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

export const useFilterSelectors = (active: boolean, companyId: string) => {
    const { handleToast } = useToast();
    const { t } = useTranslation();

    const [users, setUsers] = useState<OptionsSearch[]>([]);
    const [departments, setDepartments] = useState<OptionsSearch[]>([]);
    const [subdepartments, setSubdepartments] = useState<OptionsSearch[]>([]);

    const getUsers = async () => {
        const sr = await SegSelectorService.GetUserNamesWithIds({ extraParams: getExtraparams() });
        if (!sr.status()) {
            handleToast({
                title: t(TranslationKeys.CANNOT_FETCH).replace("{0}", t(TranslationKeys.USERS)),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setUsers(sr.data);
    };

    const getDeparmtents = async () => {
        const sr = await SegSelectorService.GetDepartmentNamesWithIds({ extraParams: getExtraparams() });
        if (!sr.status()) {
            handleToast({
                title: t(TranslationKeys.CANNOT_FETCH).replace("{0}", t(TranslationKeys.DEPARTMENTS)),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setDepartments(sr.data);
    };

    const getSubdepartments = async () => {
        const sr = await SegSelectorService.GetSubDepartmentNamesWithIds({ extraParams: getExtraparams() });
        if (!sr.status()) {
            handleToast({
                title: t(TranslationKeys.CANNOT_FETCH).replace("{0}", t(TranslationKeys.SUBDEPARTMENTS)),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setSubdepartments(sr.data);
    };

    const getExtraparams = (): string => {
        const extraparams = new URLSearchParams();
        if (!!companyId.length && companyId !== "-1") extraparams.append("CompanyId", companyId);
        return extraparams.toString();
    };

    const taskStatus: OptionsSearch[] = [
        { label: t(TranslationKeys.PENDING_STATUS), value: "pending" },
        { label: t(TranslationKeys.TIMED_OUT_STATUS), value: "timedOut" },
        { label: t(TranslationKeys.IN_TIME_STATUS), value: "inTime" },
        { label: t(TranslationKeys.OUT_OF_TIME_STATUS), value: "outOfTime" },
    ];

    const mapStatusOptions = ({
        pending,
        nearEnd,
        timedOut,
        inTime,
        outOfTime,
    }: TaskHisotryGridSfModel): OptionsSearch[] => {
        let options: OptionsSearch[] = [];
        if (pending) options.push({ label: t(TranslationKeys.PENDING_STATUS), value: "pending" });
        if (nearEnd) options.push({ label: t(TranslationKeys.NEAR_END_STATUS), value: "nearEnd" });
        if (timedOut) options.push({ label: t(TranslationKeys.TIMED_OUT_STATUS), value: "timedOut" });
        if (inTime) options.push({ label: t(TranslationKeys.IN_TIME_STATUS), value: "inTime" });
        if (outOfTime) options.push({ label: t(TranslationKeys.OUT_OF_TIME_STATUS), value: "outOfTime" });

        return options;
    };

    useEffect(() => {
        if (active) Promise.all([getUsers(), getDeparmtents(), getSubdepartments()]);
    }, [active]);

    return { users, departments, subdepartments, taskStatus, mapStatusOptions };
};
