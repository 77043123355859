import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { TranslationCommon } from "app/translation/translationKeys";
import { OptionsSearch } from "app/models/FormComponentsModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

export const useAlertFilter = () => {
    const session = useSession();
    const { t } = useTranslation();

    const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
    const [usersOptions, setUsersOptions] = useState<OptionsSearch[]>([]);
    const [isDepartmentLoading, setIsDepartmentLoading] = useState<boolean>(false);
    const [departmentsOptions, setDepartmentsOptions] = useState<OptionsSearch[]>([]);
    const [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(false);
    const [companiesOptions, setCompaniesOptions] = useState<OptionsSearch[]>([]);

    const getUsers = async () => {
        setIsUserLoading(false);
        const companyId = session?.workingCompany?.companyId;
        const userId = session?.user?.id;
        let extraParams = companyId ? `companyId=${companyId}&` : "";
        extraParams += userId ? `supervisorId=${userId}&` : "";
        const { data, status } = await SegSelectorService.GetUserNamesWithIds({ extraParams });
        const defaultOption: OptionsSearch = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: "-1",
        };
        if (!status()) {
            setUsersOptions([defaultOption]);
            setIsUserLoading(false);
            return;
        }

        setUsersOptions([defaultOption, ...data.map(({ label, value }) => ({ label, value }))]);
        setIsUserLoading(false);
    };

    const getDepartments = async () => {
        setIsDepartmentLoading(false);
        const companyId = session?.workingCompany?.companyId;
        const userId = session?.user?.id;
        let extraParams = companyId ? `companyId=${companyId}&` : "";
        extraParams += userId ? `supervisorId=${userId}&` : "";
        const { data, status } = await SegSelectorService.GetDepartmentNamesWithIds({ extraParams });
        const defaultOption: OptionsSearch = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: "-1",
        };
        if (!status()) {
            setDepartmentsOptions([defaultOption]);
            setIsDepartmentLoading(false);
            return;
        }

        setDepartmentsOptions([defaultOption, ...data.map(({ label, value }) => ({ label, value }))]);
        setIsDepartmentLoading(false);
    };

    const getCompanies = async () => {
        setIsCompanyLoading(false);
        const companyId = session?.workingCompany?.companyId;
        const userId = session?.user?.id;
        let extraParams = companyId ? `companyId=${companyId}&` : "";
        extraParams += userId ? `supervisorId=${userId}&` : "";
        const { data, status } = await SegSelectorService.GetCompanyNamesWithIds({ extraParams });
        const defaultOption: OptionsSearch = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: "-1",
        };
        if (!status()) {
            setCompaniesOptions([defaultOption]);
            setIsCompanyLoading(false);
            return;
        }

        setCompaniesOptions([defaultOption, ...data.map(({ label, value }) => ({ label, value }))]);
        setIsCompanyLoading(false);
    };

    return {
        isUserLoading,
        usersOptions,
        getUsers,
        isDepartmentLoading,
        departmentsOptions,
        getDepartments,
        isCompanyLoading,
        companiesOptions,
        getCompanies,
    };
};
