import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditIssuePillList } from "./components/AuditIssuePillList/AuditIssuePillList";
import { IssueListModel } from "app/models/05-QUA/IssueModels/IssueListModel";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { Issue } from "app/components_v2/Issue/Issue";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { IssueHistoryReopen } from "app/components_v2/Issue/IssueHistory/IssueHistoryReopen/IssueHistoryReopen";
import {
    AuditTranslation,
    IssueTranslation,
    TranslationCommon,
    TranslationModals,
} from "app/translation/translationKeys";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { useToast } from "app/hooks/Toast/useToast";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { hasPermissionToDelete } from "app/routes/HelperRoleBasedAccess";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import AuditInstanceIssueService from "app/services/05-QUA/AuditInstanceIssueService";

type AuditIssuesProps = {
    fetchData: ({ pi }: { pi?: number | undefined }) => Promise<void>;
    isLoading: boolean;
    pageIndex: number;
    setPageIndex: (pageIndex: number) => void;
    issueList: IssueListModel[];
    totalIssueList: number;
    fetchEditAuditHeaderData?: () => Promise<void>;
    auditStatus: AuditInstanceStatus;
};

export const AuditIssues: FC<AuditIssuesProps> = ({
    fetchData,
    isLoading,
    pageIndex,
    setPageIndex,
    issueList,
    totalIssueList,
    fetchEditAuditHeaderData,
    auditStatus,
}) => {
    const { t } = useTranslation();
    const [selectedIssue, setSelectedIssue] = useState<IssueListModel | null>(null);
    const [showDeleteIssueConfirm, setShowDeleteIssueConfirm] = useState<boolean>(false);
    const [isDeleteIssueLoading, setIsDeleteIssueLoading] = useState(false);
    const { handleToast } = useToast();

    const hasPermissionsToDeleteNC = hasPermissionToDelete(SecScreen.AUDIT_INST_REV_NC);

    const handleNavigate = (issue: IssueListModel) => setSelectedIssue(issue);

    const handleDeleteAuditIssue = async (fk_AuditInstanceChecklistItemId: number | null) => {
        if (fk_AuditInstanceChecklistItemId == null) {
            handleToast({
                title: t(TranslationModals.AUDIT_CHECKLIST_ITEM_FAILED_UPDATE),
                variant: "danger",
                type: "alert",
            });
            return;
        }
        setIsDeleteIssueLoading(true);
        const { status: deleteIssueStatus } = await AuditInstanceIssueService.DeleteIssueByChecklistIdAsync(
            fk_AuditInstanceChecklistItemId
        );

        if (!deleteIssueStatus()) {
            handleToast({
                title: t(TranslationModals.AUDIT_CHECKLIST_ITEM_FAILED_UPDATE),
                variant: "danger",
                type: "alert",
            });
            setIsDeleteIssueLoading(false);
            return;
        }

        const { status: completeItemStatus } = await AuditInstanceService.CompleteAuditChecklistItem(
            fk_AuditInstanceChecklistItemId,
            {
                value: "OK",
            }
        );

        if (!completeItemStatus()) {
            handleToast({
                title: t(TranslationModals.AUDIT_CHECKLIST_ITEM_FAILED_UPDATE),
                variant: "danger",
                type: "alert",
            });
            setIsDeleteIssueLoading(false);
            return;
        }

        fetchData({ pi: 1 });
        setPageIndex(1);
        setSelectedIssue(null);
        setIsDeleteIssueLoading(false);
        setShowDeleteIssueConfirm(false);
    };

    return (
        <>
            <AuditIssuePillList
                isLoading={isLoading}
                issueList={issueList}
                handleNavigate={handleNavigate}
                pageIndex={pageIndex}
                setPageIndex={(pi) => {
                    setPageIndex(pi);
                    fetchData({ pi });
                }}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={totalIssueList}
            />
            {selectedIssue && (
                <>
                    <GenericModal
                        header={{
                            title: t(AuditTranslation.AUDIT_ISSUE_MODAL_TITLE),
                            onClose: () => {
                                setSelectedIssue(null);
                                setShowDeleteIssueConfirm(false);
                            },
                            variant: "purple",
                        }}
                        size="lg"
                        footer={
                            <GenericModalFooter
                                confirmButton={{
                                    text: t(AuditTranslation.AUDIT_ISSUE_DELETE_NON_CONFORMITY_BUTTON),
                                    danger: true,
                                    onClick: () => setShowDeleteIssueConfirm(true),
                                    buttonType: "secondary",
                                    isHidden: !hasPermissionsToDeleteNC || auditStatus !== "IN_PROGRESS",
                                    variant: "purple",
                                }}
                                customButton={
                                    <IssueHistoryReopen
                                        actualStatus={selectedIssue.actualStatus}
                                        id={selectedIssue.id}
                                        onFinish={() => {
                                            fetchEditAuditHeaderData && fetchEditAuditHeaderData();
                                            fetchData({ pi: pageIndex });
                                            setSelectedIssue(null);
                                        }}
                                        variant="purple"
                                        type="AUDIT"
                                    />
                                }
                                buttonsJustification={
                                    hasPermissionsToDeleteNC || auditStatus === "IN_PROGRESS" ? "spaceBetween" : "end"
                                }
                            />
                        }
                    >
                        <Issue id={selectedIssue.id} type="AUDIT" />
                    </GenericModal>
                    {showDeleteIssueConfirm && (
                        <ConfirmModal
                            onConfirm={async () => {
                                await handleDeleteAuditIssue(selectedIssue.fK_AuditInstanceChecklistItemId);
                                fetchEditAuditHeaderData && (await fetchEditAuditHeaderData());
                            }}
                            onClose={() => setShowDeleteIssueConfirm(false)}
                            title={t(IssueTranslation.DELETE_NON_CONFORMITY_TITLE)}
                            description={`${t(IssueTranslation.DELETE_NON_CONFORMITY_DESCRIPTION)} ${t(
                                AuditTranslation.AUDIT_ISSUE_DELETE_NON_CONFORMITY
                            )}`}
                            onCloseText={t(TranslationCommon.CANCEL)}
                            onConfirmText={t(TranslationCommon.ACCEPT)}
                            isLoading={isDeleteIssueLoading}
                            variant="purple"
                        />
                    )}
                </>
            )}
        </>
    );
};
