import { faClone } from "@fortawesome/pro-regular-svg-icons";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { CompanyOptionModel } from "app/dtos/01-SEG/Company/CompanyOptionModel";
import { addMonths, convertUTCtoLocaleDate, getMonthName } from "app/helpers";
import { fillTaskInstanceExtraParams } from "app/helpers/ExtraParams/taskInstanceFillExtraParams";
import { useSession } from "app/hooks";
import { useTitle } from "app/hooks/CustomHooks";
import { useToast } from "app/hooks/Toast/useToast";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { EditTaskDisabledFieldsModel } from "app/models/02-TAR/Task/EditTask";
import { TaskBodyWithIdModel } from "app/models/02-TAR/Task/TaskBody";
import { AllSteps, reportType } from "app/models/02-TAR/TaskWizard";
import { OptionsSearchT } from "app/models/FormComponentsModel";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import BaseTaskService from "app/services/02-TAR/BaseTaskService";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import TaskInstanceService from "app/services/02-TAR/TaskInstanceService";
import TaskService from "app/services/02-TAR/TaskService";
import TaskAPPCCService from "app/services/05-QUA/TaskAPPCCService";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { IAppState } from "app/state/reducer";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { isEqual } from "lodash";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import { CurrentPage } from "../TaskGrid/TaskGrid";
import { TaskBelongs, TaskData, TaskTemporality } from "./Components";
import { BaseTaskModal } from "./Components/BaseTaskModal/BaseTaskModal";
import { baseTaskToDisableds } from "./Components/BaseTaskModal/helpers/baseTaskToDisableds";
import { DataToReport } from "./Components/DataToReport/DataToReport";
import { INITIAL_EDIT_TASK_DISABLEDS } from "./constants/editTaskConstants";
import { useEditTask } from "./hooks/useEditTask";
import { fillFormValues, mapTask } from "./utilities";
import { initialStepsValues } from "./utilities/initialValues";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

export const EditTask = () => {
    const {
        data,
        onChange,
        setData,
        validate,
        validations,
        isEnabledUserDept,
        setEnableUserDept,
        dataCopy,
        setDataCopy,
        onCloseModalHoursOutOfTime,
        onConfirmModalHoursOutOfTime,
        showModalHoursOutOfTime,
    } = useEditTask(initialStepsValues);

    const instanceId = useSelector<IAppState, number | undefined>((state) => state.customerInstanceId);
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { id } = useParams();
    const nav = useNavigate();
    const { handleToast, removeToast } = useToast();
    const session = useSession();
    const location = useLocation();
    const [urlParams] = useSearchParams();

    const currentPage: CurrentPage = location.pathname.includes(`/${PrivatePaths.REGISTRY_MANAGEMENT}`)
        ? "plan"
        : "task";
    const actualService = currentPage === "plan" ? TaskAPPCCService : TaskService;
    const actualGenerateService =
        currentPage === "plan"
            ? TaskInstanceService.GenerateAPPCCTaskInstances
            : TaskInstanceService.GenerateTaskInstances;

    const [companies, setCompanies] = useState<CompanyOptionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [showCancelReasignModal, setShowCancelReasignModal] = useState<boolean>(false);
    const [isCancelReasingLoading, setIsCancelReasingLoading] = useState<boolean>(false);
    const [baseTaskDisabledFields, setBaseTaskDisabledFields] =
        useState<EditTaskDisabledFieldsModel>(INITIAL_EDIT_TASK_DISABLEDS);
    const [reportTypeOptions, setReportTypeOptions] = useState<OptionsSearchT<reportType>[]>([]);
    const [baseTask, setBaseTask] = useState<BaseTaskModel | null>(null);

    const hasPlan = location.pathname.includes(PrivatePaths.REGISTRY_MANAGEMENT);
    const taskGridSecScreen = hasPlan ? SecScreen.REGISTRY_MANAGEMENT : SecScreen.TASK;
    const isOnBoarding = session?.isOnBoarding;
    const variant: TabHeaderVariants = hasPlan ? "purple" : "primary";

    const handleOk = async () => {
        handleDeleteTask();
        handleNavigate(PrivatePaths.TASK_PAGE);
    };

    const isDuplicatePage = useCallback(() => {
        return pathname.split("/").includes("duplicate");
    }, [pathname]);

    const isFastNewPage = useCallback(() => {
        return pathname.split("/").includes("fastNew");
    }, [pathname]);

    const [isBaseTaskModalVisible, setIsBaseTaskModalVisible] = useState<boolean>(isFastNewPage());

    useTitle(
        t(
            isFastNewPage()
                ? currentPage === "plan"
                    ? TranslationTitles.NEW_TASK_APPCC_PAGE_TITLE
                    : TranslationTitles.NEW_TASK_PAGE_TITLE
                : isDuplicatePage()
                ? currentPage === "plan"
                    ? TranslationTitles.DUPLICATE_TASK_APPCC_PAGE_TITLE
                    : TranslationTitles.DUPLICATE_TASK_PAGE_TITLE
                : currentPage === "plan"
                ? TranslationTitles.EDIT_TASK_APPCC_PAGE_TITLE
                : TranslationTitles.EDIT_TASK_PAGE_TITLE
        )
    );

    const handleNavigate = (path: string) => {
        const planId = urlParams.get("planId");
        if (hasPlan && !!planId) {
            nav(`${path}?planId=${planId}`);
            return;
        }
        nav(`${path}`);
    };

    const fireToastSuccessEdit = () => {
        handleToast({
            title: t(TranslationModals.SUCCESS_EDIT_TITLE_TOAST),
            subtitle: t(TranslationModals.SUCCESS_EDIT_SUBTITLE_TOAST),
            variant: "success",
            type: "alert",
        });
    };

    const generateTask = async (taskId: number) => {
        const deadlineDate =
            new Date() >= new Date(data.taskStart)
                ? convertUTCtoLocaleDate(new Date())
                : convertUTCtoLocaleDate(new Date(data.taskStart));
        const deadlineDate1 = addMonths(convertUTCtoLocaleDate(new Date()), 1);

        const toastId = v4();
        const day = deadlineDate.getDate();
        const month = getMonthName(deadlineDate.toString());
        handleToast({
            title: t(TranslationModals.GENERATING_TASKS_TITLE),
            subtitle: t(TranslationModals.GENERATING_TASKS_DESCRIPTION)
                .replace("{0}", String(day))
                .replace("{1}", month),
            variant: "primary",
            type: "alert",
            isLoading: true,
            id: toastId,
        });

        const generateTaskSr = await actualGenerateService(
            fillTaskInstanceExtraParams({
                deadlineDate: deadlineDate,
                deadlineDate1: deadlineDate1,
                taskId: taskId,
                customerInstanceId: instanceId,
            })
        );

        if (!generateTaskSr.status()) {
            handleToast({
                title: t(TranslationModals.FAILED_EDIT),
                variant: "danger",
                type: "alert",
            });
            removeToast(toastId);
            setIsSaving(false);
            return;
        }

        removeToast(toastId);
        fireToastSuccessEdit();
        setIsSaving(false);
        return;
    };

    const setFormValuesOnEditDuplicate = (dataResponse: TaskBodyWithIdModel) => {
        const fotoExample = data.somethingToReportImage;
        const modifiedData: AllSteps = {
            ...fillFormValues(dataResponse, false),
            somethingToReportImage: fotoExample,
            activeReassignedTo: data.activeReassignedTo,
            companyForTask: data.companyForTask,
            imageBase64: data.imageBase64,
        };

        setDataCopy(modifiedData);
        setData(modifiedData);
    };

    const handleDuplicateTask = async () => {
        const taskServiceSr = await actualService.Save(mapTask(data, instanceId, undefined, true));
        if (!taskServiceSr.status()) {
            handleToast({
                title: t(TranslationModals.FAILED_SAVE),
                variant: "danger",
                type: "alert",
            });
            setIsSaving(false);
            return;
        }
        if (taskServiceSr.data.taskType === "NORMAL") {
            await generateTask(taskServiceSr.data.id);
        } else {
            fireToastSuccessEdit();
        }

        setFormValuesOnEditDuplicate(taskServiceSr.data as TaskBodyWithIdModel);
        const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
        handleNavigate(`/${path}/edit/${taskServiceSr.data.id}`);
        setIsSaving(false);
    };

    const handleEditTask = async () => {
        const taskServiceSr = await actualService.Edit(mapTask(data, instanceId, id));
        if (!taskServiceSr.status()) {
            handleToast({
                title: t(TranslationModals.FAILED_EDIT),
                variant: "danger",
                type: "alert",
            });
            setIsSaving(false);
            return;
        }
        if (taskServiceSr.data.taskType === "NORMAL") {
            await generateTask(Number(id));
        } else {
            fireToastSuccessEdit();
        }
        setIsSaving(false);
        const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
        handleNavigate(`/${path}`);

        setFormValuesOnEditDuplicate(taskServiceSr.data as TaskBodyWithIdModel);
    };

    const handleDeleteTask = async () => {
        const sr = await actualService.Delete(parseInt(id!));
        if (!sr.status()) {
            handleToast({
                title: sr.getParsedError(),
                variant: "danger",
                type: "alert",
            });
            return;
        }
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate(e)) return;

        saveTask();
    };

    const saveTask = () => {
        setIsSaving(true);

        if (isDuplicatePage() || isFastNewPage()) {
            handleDuplicateTask();
            return;
        }

        handleEditTask();
    };

    const showModal = () => setShowConfirmModal(true);

    const handleCancelReasign = async () => {
        if (!id) return;
        setIsCancelReasingLoading(true);
        const { status } = await actualService.CancelReasign(Number(id));
        if (!status()) {
            handleToast({
                title: t(TranslationErrors.ERROR_MESSAGE_CANCEL_REASIGNATION_FAILED),
                variant: "danger",
                type: "alert",
            });
            setIsCancelReasingLoading(false);
            setShowCancelReasignModal(false);
            return;
        }
        setIsSaving(true);
        setIsCancelReasingLoading(false);
        setShowCancelReasignModal(false);
        onChange({ activeReassignedTo: undefined });

        if (data.taskType === "NORMAL") {
            await generateTask(Number(id));
            return;
        }
        fireToastSuccessEdit();
        setIsSaving(false);
    };

    // Hace la llamada a la base de datos para conseguir la información
    const fetchAll = async () => {
        setIsLoading(true);
        const taskSr = await actualService.GetOne(id ?? 0);
        const companySr = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });
        const [taskResponse, companyResponse] = await Promise.all([taskSr, companySr]);

        if (taskResponse.status() && companyResponse.status()) {
            setCompanies(companyResponse.data);
            let values = fillFormValues(taskResponse.data, isDuplicatePage());
            const selectedCompany = companyResponse.data?.find(({ value }) => value === values.companyForTask.value);
            values = {
                ...values,
                companyForTask: {
                    ...values.companyForTask,
                    worksWithQr: !!selectedCompany?.worksWithQR,
                },
            };

            if (values.fK_BaseTask) await handleBaseTask(values.fK_BaseTask);

            setData(values);
            setDataCopy(values);
        } else {
            if (taskResponse.httpCodeStatus === 404) {
                const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
                handleNavigate(`/${path}`);
                return;
            }
            handleToast({
                title: t(TranslationCommon.FAILED_DATA_LOADED),
                variant: "danger",
                type: "alert",
            });
        }

        setIsLoading(false);
    };

    // Fetch solo si es para crear una nueva tarea
    const fetch = async () => {
        const { data, status } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });
        if (!status()) {
            handleToast({
                title: t(TranslationCommon.FAILED_DATA_LOADED),
                variant: "danger",
                type: "alert",
            });
        }
        if (!session?.workingCompany?.companyId && !isOnBoarding) {
            const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
            handleNavigate(`/${path}`);
            return;
        }
        setCompanies(data);
        const selectedCompany = data.find((c) => c.value === String(session.workingCompany?.companyId));
        onChange({
            companyForTask: {
                label: session?.workingCompany?.companyName || "",
                value: String(session?.workingCompany?.companyId),
                worksWithQr: !!selectedCompany?.worksWithQR,
            },
            taskStart: new Date().toString(),
        });
        setIsLoading(false);
    };

    const hideDeleteBtn = () => {
        if (isDuplicatePage()) return false;
        if (isFastNewPage()) return false;

        return true;
    };

    const handleCancel = async () => {
        if (isFastNewPage()) {
            await fetch();
            setIsCancelModalOpen(false);
            return;
        }
        await fetchAll();
        setIsCancelModalOpen(false);
    };

    const isDisabled = () => {
        if (isDuplicatePage() || isFastNewPage()) return !hasPermissionToAdd(taskGridSecScreen);
        return !hasPermissionToEdit(taskGridSecScreen);
    };

    const getMinDate = (): Date => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        return isDuplicatePage() || isFastNewPage() ? new Date() : tomorrow;
    };

    const getDisableds = (baseTask: BaseTaskModel, status: boolean) => {
        const disableds = baseTaskToDisableds(baseTask);
        setBaseTaskDisabledFields(status ? disableds : INITIAL_EDIT_TASK_DISABLEDS);
    };

    const handleBaseTask = async (id: number) => {
        const { data, status } = await BaseTaskService.GetOne(id);
        getDisableds(data, status());

        const options = fillReportTypeOptions(data);
        setReportTypeOptions(options);
        setBaseTask(data);
    };

    const handleCloseBaseTaskModal = () => {
        const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
        handleNavigate(`/${path}`);
    };

    const handleSaveBaseTaskModal = (
        task: AllSteps,
        disabledsFields: EditTaskDisabledFieldsModel,
        baseTask: BaseTaskModel
    ) => {
        setData(task);
        setIsBaseTaskModalVisible(false);
        setBaseTaskDisabledFields(disabledsFields);
        const options = fillReportTypeOptions(baseTask);
        setReportTypeOptions(options);
        setBaseTask(baseTask);
    };

    const getTitle = (): string => {
        if (isDuplicatePage())
            return hasPlan ? t(TranslationKeys.DUPLICATE_REGISTER) : t(TranslationKeys.DUPLICATE_TASK);

        if (isFastNewPage()) return hasPlan ? t(TranslationKeys.NEW_REGISTER) : t(TranslationKeys.NEW_TASK);

        return hasPlan ? t(TranslationKeys.EDIT_REGISTER) : t(TranslationKeys.EDIT_TASK);
    };

    const fillReportTypeOptions = (baseTask: BaseTaskModel): OptionsSearchT<reportType>[] => {
        const options: OptionsSearchT<reportType>[] = [
            {
                label: t(TranslationKeys.DATA_TO_REPORT_NO),
                value: "NONE",
            },
        ];

        if (baseTask.allowAnyData)
            options.push(
                {
                    label: t(TranslationKeys.CHECKLIST),
                    value: "CHECKLIST",
                },
                {
                    label: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_FIELDS),
                    value: "DYNAMIC_FIELD",
                },
                {
                    label: t(TranslationKeys.DATA_TO_REPORT_ASSET),
                    value: "ASSET",
                }
            );

        return options;
    };

    useEffect(() => {
        if ((isDuplicatePage() || isFastNewPage()) && isDisabled()) {
            const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
            handleNavigate(`/${path}`);
            return;
        }

        if (isFastNewPage()) {
            fetch();
            return;
        }
        fetchAll();
    }, []);

    return (
        <>
            {isBaseTaskModalVisible && (
                <BaseTaskModal
                    onClose={handleCloseBaseTaskModal}
                    onSave={handleSaveBaseTaskModal}
                    task={data}
                    hasPlan={hasPlan}
                />
            )}
            {showModalHoursOutOfTime && (
                <ConfirmModal
                    onConfirm={() => {
                        onConfirmModalHoursOutOfTime();
                        saveTask();
                    }}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={onCloseModalHoursOutOfTime}
                    title={t(TranslationKeys.HOURS_OUT_OF_RANGE_TITLE)}
                    description={t(TranslationKeys.HOURS_OUT_OF_RANGE_DESCRIPTION)}
                    portal
                    variant={variant}
                />
            )}
            {showCancelReasignModal && (
                <ConfirmModal
                    onConfirm={handleCancelReasign}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    onClose={() => setShowCancelReasignModal(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={t(TranslationKeys.CANCEL_REASIGNATION_TITLE)}
                    isLoading={isCancelReasingLoading}
                    variant={variant}
                />
            )}

            {showConfirmModal && (
                <ConfirmModal
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={() => handleOk()}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={hasPlan ? t(TranslationKeys.DELETE_REGISTER) : t(TranslationModals.DELETE_TASK_TITLE)}
                    type="delete"
                    variant={variant}
                />
            )}

            {isCancelModalOpen && (
                <ConfirmModal
                    onClose={() => setIsCancelModalOpen(false)}
                    onConfirm={handleCancel}
                    isLoading={isLoading}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationKeys.UNDO_CHANGES)}
                    variant={variant}
                />
            )}

            <FormLayout
                fullWidth
                isLoading={isLoading}
                variant="gray"
                headerOptions={{
                    buttonLabel: hasPlan ? t(TranslationKeys.DELETE_REGISTER) : t(TranslationModals.DELETE_TASK_TITLE),
                    showDeleteButton: hideDeleteBtn() && hasPermissionToDelete(taskGridSecScreen),
                    type: "delete",
                    onClick: showModal,
                    variant: currentPage === "plan" ? "purple" : "primary",
                    secondaryButton:
                        hasPermissionToAdd(taskGridSecScreen) && location.pathname.includes("edit")
                            ? {
                                  label: hasPlan
                                      ? t(TranslationKeys.DUPLICATE_REGISTER)
                                      : t(TranslationKeys.DUPLICATE_TASK),
                                  icon: faClone,
                                  onClickSecondaryButton: () => {
                                      const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
                                      handleNavigate(`/${path}/duplicate/${id}`);
                                  },
                              }
                            : undefined,
                    title: getTitle(),
                }}
                footer={
                    <>
                        {!isDisabled() && (
                            <GenericModalFooter
                                confirmButton={{
                                    text: t(TranslationCommon.SAVE),
                                    type: "submit",
                                    form: "editTaskId",
                                    variant,
                                }}
                                closeButton={
                                    !isFastNewPage()
                                        ? {
                                              text: t(TranslationCommon.CANCEL),
                                              onClick: () => setIsCancelModalOpen(true),
                                              disabled: isEqual(data, dataCopy),
                                              buttonType: "tertiary",
                                              variant,
                                          }
                                        : undefined
                                }
                                loading={isSaving}
                            />
                        )}
                    </>
                }
            >
                <div className="editTask">
                    <form onSubmit={onSubmit} id="editTaskId" className="editTask__container">
                        <div className="editTask__container__body">
                            <TaskData
                                {...data}
                                disabledFields={baseTaskDisabledFields}
                                onChange={onChange}
                                customError={validations}
                                isDisabled={isDisabled()}
                                hasPlanAPPCC={hasPlan}
                            />
                            <TaskBelongs
                                {...data}
                                companyId={Number(data.companyForTask.value) || 0}
                                onChange={onChange}
                                departmentId={Number(data.DepartmentForTask.value)}
                                isEnabledUserDept={isEnabledUserDept}
                                setEnableUserDept={setEnableUserDept}
                                customError={validations}
                                onCancelReasign={() => setShowCancelReasignModal(true)}
                                isEditing={!isDuplicatePage()}
                                companyValues={companies}
                                isDisabled={isDisabled()}
                            />
                            <TaskTemporality
                                {...data}
                                onChange={onChange}
                                customError={validations}
                                isDisabled={isDisabled()}
                                min={getMinDate()}
                            />
                            <DataToReport
                                {...data}
                                companyId={Number(data.companyForTask.value)}
                                onChange={onChange}
                                customError={validations}
                                isDisabled={isDisabled()}
                                disabledFields={baseTaskDisabledFields}
                                allowAnyData={!!baseTask?.allowAnyData}
                                options={reportTypeOptions}
                            />
                        </div>
                    </form>
                </div>
            </FormLayout>
        </>
    );
};
