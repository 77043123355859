import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
    AuditGroupCheckListItemTranslations,
    BaseAuditGroupCheckListItemTranslations,
    TranslationCommon,
    TranslationKeys,
} from "app/translation/translationKeys";
import { AuditGroupCheckListModalProps } from "./types/AuditGroupCheckListModalTypes";
import { useAuditGroupCheckListModal } from "./hooks/useAuditGroupCheckListModal";
import { Input, Label, SelectOptions } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { AuditGroupCheckListItem } from "./AuditGroupCheckListItem/AuditGroupCheckListItem";

export const AuditGroupCheckListModal: FC<AuditGroupCheckListModalProps> = ({
    data,
    onCloseModal,
    onSubmit,
    isLoading,
}) => {
    const { t } = useTranslation();

    const {
        baseAuditGroupCheckListItemOptions,
        auditGroupCheckListItemSelectedOptions,
        isLoading: optionsLoading,
        errorMessage,
        itemErrorMessage,
        name,
        handleChangeName,
        handleChange,
        handleChangePonderationLevel,
        handleDeleteItem,
        handleSubmit,
    } = useAuditGroupCheckListModal(data, onSubmit);

    return (
        <GenericModal
            size="xl"
            header={{ title: t(TranslationKeys.EDIT_AUDIT_GROUP_CHECKLIST), onClose: onCloseModal, variant: "purple" }}
            footer={
                <GenericModalFooter
                    confirmButton={{ text: t(TranslationCommon.SAVE), form: "edit", type: "submit", variant: "purple" }}
                    closeButton={{ text: t(TranslationCommon.CANCEL), onClick: onCloseModal, variant: "purple" }}
                    loading={isLoading}
                />
            }
        >
            <form id="edit" onSubmit={handleSubmit} className="auditGroupCheckListModal">
                <Input
                    label={t(TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL)}
                    placeholder={t(TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL)}
                    value={name}
                    onChange={handleChangeName}
                    errorMessage={errorMessage}
                    focus
                />
                {!!auditGroupCheckListItemSelectedOptions.filter(({ isDeleted }) => !isDeleted).length && (
                    <div className="auditGroupCheckListModal__items">
                        <Label
                            label={t(AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECKLIST_ITEM_PONDERATION_LABEL)}
                            className="auditGroupCheckListModal__items__label"
                        />
                        {auditGroupCheckListItemSelectedOptions
                            .filter(({ isDeleted }) => !isDeleted)
                            .map((agcli, i) => (
                                <AuditGroupCheckListItem
                                    key={i}
                                    data={agcli}
                                    onChangeItem={handleChangePonderationLevel}
                                    onDeleteItem={handleDeleteItem}
                                />
                            ))}
                    </div>
                )}
                <SelectOptions
                    label={t(BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SELECTOR_LABEL)}
                    placeholder={t(
                        BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SELECTOR_LABEL
                    )}
                    onChange={handleChange}
                    options={baseAuditGroupCheckListItemOptions}
                    errorMessage={itemErrorMessage}
                    isMulti={false}
                    isSearchable
                    isLoading={optionsLoading}
                />
            </form>
        </GenericModal>
    );
};
