import { useEffect, useState } from "react";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { OptionsSearch } from "app/models/FormComponentsModel";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { ErrorConstants } from "app/shared/errorConstants";
import { AssetTypeModel } from "app/models/05-QUA/AssetModels/AssetTypeModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";

export const useAssetFormInfo = () => {
    const session = useSession();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const [companyOptions, setCompanyOptions] = useState<OptionsSearch[]>([]);
    const [iscompanyLoading, setIscompanyLoading] = useState<boolean>(true);

    const [assetTypeOptions, setAssetTypeOptions] = useState<AssetTypeModel[]>([]);
    const [isAssetTypeLoading, setIsAssetTypeLoading] = useState<boolean>(true);

    const [planAPPCCOptions, setPlanAPPCCOptions] = useState<OptionsSearch[]>([]);
    const [isPlanLoading, setIsPlanLoading] = useState<boolean>(true);

    const getCompany = async () => {
        setIscompanyLoading(true);
        const { status, data } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });

        if (!status()) {
            setCompanyOptions([]);
            handleToast({
                title: getErrorMessage(ErrorConstants.COMPANIES_FAILED_LOAD_DATA),
                type: "alert",
                variant: "danger",
            });
            setIscompanyLoading(false);
            return;
        }

        setCompanyOptions(data.map(({ label, value }) => ({ label, value })));
        setIscompanyLoading(false);
    };

    const getAssetType = async () => {
        setIsAssetTypeLoading(true);
        const { data, status, getParsedError } = await QuaSelectorService.GetAssetTypeList();
        if (!status()) {
            setAssetTypeOptions([]);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsAssetTypeLoading(false);
            return;
        }
        setAssetTypeOptions(data);
        setIsAssetTypeLoading(false);
    };

    const getPlans = async () => {
        setIsPlanLoading(true);
        const { data, status, getParsedError } = await QuaSelectorService.GetPlansAPPCC();
        if (!status()) {
            setAssetTypeOptions([]);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsPlanLoading(false);
            return;
        }
        setPlanAPPCCOptions(data);
        setIsPlanLoading(false);
    };

    useEffect(() => {
        getCompany();
        getAssetType();
        getPlans();
    }, []);

    return {
        companyOptions,
        iscompanyLoading,
        assetTypeOptions,
        isAssetTypeLoading,
        planAPPCCOptions,
        isPlanLoading,
    };
};
