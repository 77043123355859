import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { faCalendarDays, faPaperPlaneTop } from "@fortawesome/pro-regular-svg-icons";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import AlertNotificationService from "app/services/02-TAR/AlertNotificationService";
import { TextArea } from "app/components_v2/__inputs";
import { SelectCompanyUserDepartment } from "app/components_v2/__inputs/SelectCompanyUserDepartment/SelectCompanyUserDepartment";
import { ISelectCompanyUserDepartment } from "app/components_v2/__inputs/SelectCompanyUserDepartment/types";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { ImageNamesModel, OptionsSearchT } from "app/models/FormComponentsModel";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { ScheduleNotificationModal } from "../ScheduleNotificationModal/ScheduleNotificationModal";
import { NotificationFileInsertModel } from "app/models/02-TAR/AlertNotification/AlertNotificationModel";

type NotificationsModalProps = {
    onClose: () => void;
    handleSubmitMessage?: () => void;
    refreshList?: () => void;
    userId?: number;
    userName?: string;
    profileURL?: string;
    portal?: boolean;
};

export const NotificationsModal: FC<NotificationsModalProps> = ({
    onClose,
    handleSubmitMessage,
    refreshList,
    userId,
    userName,
    profileURL,
    portal,
}) => {
    const { handleToast } = useToast();
    const { t } = useTranslation();
    const session = useSession();

    const [selectedCompanies, setSelectedCompanies] = useState<OptionModel[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<OptionsSearchT<number>[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<OptionsSearchT<number>[]>([]);
    const [showProgramNotifsModal, setShowProgramNotifsModal] = useState<boolean>(false);
    const [textAreaValue, setTextAreaValue] = useState<string>("");
    const [textAreaError, setTextAreaError] = useState<boolean>(false);
    const [noOneToSend, setNoOneToSend] = useState<boolean>(false);
    const [onlyCompany, setOnlyCompany] = useState(false);
    const [files, setFiles] = useState<ImageNamesModel[]>([]);
    const [audioBase64, setAudioBase64] = useState<string | null>(null);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSelect = ({ companies, departments, users }: ISelectCompanyUserDepartment) => {
        setNoOneToSend(false);
        setSelectedCompanies(companies?.length >= 1 ? companies : []);
        setSelectedUsers(!onlyCompany ? users.map((user) => ({ ...user, value: Number(user.value) })) : []);
        setSelectedDepartments(!onlyCompany ? departments.map((dep) => ({ ...dep, value: Number(dep.value) })) : []);
    };

    const validations = () => {
        let error = false;
        const validateHasUsers = !!selectedUsers?.length || userId;
        const validateHasDepartments = !!selectedDepartments?.length;
        const error1 = onlyCompany && !selectedCompanies?.length;
        const error2 = !onlyCompany && !validateHasUsers && !validateHasDepartments;

        if (error1 || error2) {
            setNoOneToSend(true);
            error = true;
        } else {
            setNoOneToSend(false);
        }
        if (!textAreaValue.length && !audioBase64?.length) {
            setTextAreaError(true);
            error = true;
        } else {
            setTextAreaError(false);
        }
        return error;
    };

    const handleSubmit = async (date?: Date) => {
        const filesToSend: NotificationFileInsertModel[] = [];
        if (files.length) {
            files.map(({ base64 }: ImageNamesModel) => {
                filesToSend.push({ fileBase64: base64 || "" });
            });
        }
        if (!session) return;
        const { user } = session;
        if (!validations()) {
            setLoading(true);
            const { status } = await AlertNotificationService.Save({
                alertNotification: {
                    customerInstanceId: user.customerInstanceId || 0,
                    receivedDate: date
                        ? date < new Date()
                            ? new Date(new Date().toUTCString())
                            : new Date(new Date(date).toUTCString())
                        : undefined,
                    isProgrammed: date ? true : false,
                    title: t(TranslationKeys.SCHEDULED_ALERT),
                    body: textAreaValue,
                    senderUserId: session?.user.id,
                    audioBase64: audioBase64?.length ? `${audioBase64}` : "",
                    notificationFiles: filesToSend,
                },
                companyIdList: onlyCompany ? selectedCompanies.map((company) => Number(company.value)) : [],
                departmentIdList: onlyCompany
                    ? []
                    : selectedDepartments.map((department: OptionsSearchT<number>) => department.value),
                userIdList: onlyCompany
                    ? []
                    : userId
                    ? [userId]
                    : selectedUsers.map((user: OptionsSearchT<number>) => user.value),
            });
            handleSubmitMessage && handleSubmitMessage();
            refreshList && refreshList();
            onClose();
            if (status()) {
                handleToast({
                    title: t(TranslationModals.TOAST_ALERT_SENDED),
                    type: "alert",
                    variant: "success",
                });
            } else {
                handleToast({
                    title: t(TranslationModals.TOAST_GENERIC_ERROR),
                    type: "alert",
                    variant: "danger",
                });
            }
        }
    };

    const sumFileSizes = (arr: ImageNamesModel[]) => {
        let totalSize = 0;

        for (const obj of arr) {
            if (obj.file && obj.file.size) {
                totalSize += obj.file.size;
            }
        }

        return totalSize;
    };

    const [maxSizeError, setMaxSizeError] = useState<string>("");
    const maxSize = 31457280;

    useEffect(() => {
        if (sumFileSizes(files) > maxSize) {
            setMaxSizeError(t(TranslationKeys.MAX_30_MB));
        } else {
            setMaxSizeError("");
        }
    }, [files]);

    return (
        <>
            {showProgramNotifsModal && (
                <ScheduleNotificationModal
                    onSubmit={handleSubmit}
                    closeModal={() => setShowProgramNotifsModal(false)}
                />
            )}

            <GenericModal
                header={{ title: t(TranslationKeys.NEW_ALERT), onClose }}
                size="lg"
                hideChildrenPadding
                portal={portal}
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationKeys.SEND),
                            iconRight: faPaperPlaneTop,
                            type: "submit",
                            form: "notifiactionsModal",
                            disabled: !!maxSizeError,
                        }}
                        closeButton={{
                            text: t(TranslationKeys.NOTIFICATION_SCHEDULE),
                            iconRight: faCalendarDays,
                            buttonType: "secondary",
                            onClick: () => {
                                if (!validations()) setShowProgramNotifsModal(true);
                            },
                            disabled: !!maxSizeError,
                        }}
                        loading={loading}
                    />
                }
            >
                <form
                    className="notifiactionsModal"
                    id="notifiactionsModal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <div className="notifiactionsModal__container">
                        {userId && userName ? (
                            <div className="notifiactionsModal__container__avatar">
                                <p className="notifiactionsModal__container__avatar__title">
                                    {t(TranslationKeys.SEND_TO)}
                                </p>
                                <Avatar
                                    avatarName={userName}
                                    colorId={userId}
                                    size="xs"
                                    name={userName}
                                    img={profileURL ? profileURL : ""}
                                />
                            </div>
                        ) : (
                            <>
                                <SelectCompanyUserDepartment
                                    onCheckboxChange={setOnlyCompany}
                                    onSelect={handleSelect}
                                />
                                {noOneToSend && (
                                    <p className="notifiactionsModal__error">{t(TranslationKeys.FILL_THIS_FIELD)}</p>
                                )}
                            </>
                        )}
                        <div className="notifiactionsModal__body">
                            <div className="flex--between">
                                <p className="selectLabel__label">{t(TranslationKeys.SINGLE_ALERT)}</p>
                            </div>
                            <TextArea
                                text={textAreaValue}
                                maxLength={300}
                                placeholder={t(TranslationKeys.ALERT_TEXTAREA_PLACEHOLDER)}
                                errorMessage={
                                    textAreaError
                                        ? t(TranslationKeys.FILL_THIS_FIELD)
                                        : maxSizeError
                                        ? maxSizeError
                                        : undefined
                                }
                                onChange={setTextAreaValue}
                                uploadFile={{
                                    showSendFiles: true,
                                    sendFiles: setFiles,
                                }}
                                uploadAudio={{
                                    showSendAudio: false,
                                    audioBase64: audioBase64,
                                    setAudioBase64: setAudioBase64,
                                    isRecording: isRecording,
                                    setIsRecording: setIsRecording,
                                }}
                            />
                        </div>
                    </div>
                </form>
            </GenericModal>
        </>
    );
};
